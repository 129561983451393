import { CourtsResponse } from '@/store/apis/hosted-filters-api'

// Get all state court ids
export function getAllStateCourtIds(courts: CourtsResponse): string[] {
  const ids: string[] = []

  Object.keys(courts.State).forEach((stateKey) => {
    const stateValue = courts.State[stateKey]

    Object.keys(stateValue).forEach((childKey) => {
      const childValue = stateValue[childKey]

      switch (true) {
        case typeof childValue === 'string':
          ids.push(childValue)
          break

        case Array.isArray(childValue):
          ids.push(...childValue)
          break

        default:
          console.error(`State court child value from key ${childKey} is not a string or array of strings: `, stateValue)
          break
      }
    })
  })

  return ids
}

// Get all federal court ids
export function getAllFederalCourtIds(courts: CourtsResponse): string[] {
  const ids: string[] = []

  Object.keys(courts.Federal).forEach((courtKey) => {
    const courtValue = courts.Federal[courtKey]

    if (typeof courtValue === 'string') {
      ids.push(courtValue)
    } else if (typeof courtValue === 'object') {
      Object.keys(courtValue).forEach((childKey) => {
        const childValue = courtValue[childKey]

        switch (true) {
          case typeof childValue === 'string':
            ids.push(childValue)
            break

          case Array.isArray(childValue):
            ids.push(...childValue)
            break

          default:
            console.error(`Federal court child value from key ${childKey} is not a string or array of strings: `, courtValue)
            break
        }
      })
    } else {
      console.error(`Federal court value from key ${courtKey} is not a string or object: `, courtValue)
    }
  })

  return ids
}

// CountSelectedStateCourts
export function countSelectedStateCourts(courts: CourtsResponse, selectedCourtIds: string[]): number {
  let count = 0

  // Get all state courts
  const stateCourtIds = getAllStateCourtIds(courts)

  // Count selected state courts
  stateCourtIds.forEach((courtId) => {
    if (selectedCourtIds.includes(courtId)) {
      count++
    }
  })

  return count
}

// CountSelectedFederalCourts
export function countSelectedFederalCourts(courts: CourtsResponse, selectedCourtIds: string[]): number {
  let count = 0

  // Get all federal courts
  const federalCourtIds = getAllFederalCourtIds(courts)

  // Count selected federal courts
  federalCourtIds.forEach((courtId) => {
    if (selectedCourtIds.includes(courtId)) {
      count++
    }
  })

  return count
}
