import { Promotion } from '@/routes/dashboard/subscribe/schema/promo-code.schema.ts'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'

const millisecondsPerDay = 24 * 60 * 60 * 1000
const currentDate = new Date()

export function DueSection(props: { amountDue: number; originalPrice?: number | null; promotion?: Promotion | null }) {
  const subscriptionState = useAppSelector((state: RootState) => state.subscriptionState)
  const trialDays = subscriptionState.trialDays
  const dueDate = new Date(currentDate.getTime() + trialDays * millisecondsPerDay)
  const dueDateString = dueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })
  const amountDue = props.amountDue.toString().includes('.') ? props.amountDue.toFixed(2) : props.amountDue.toString()
  return (
    <div className="space-y-2 mb-3 bg-white rounded-lg">
      {/* Due now */}
      <div className="flex justify-between items-center">
        <span className="text-gray-700">
          <span className="font-bold">Due today</span>
          {'  '}
          <span className="text-gray-500">({trialDays} days free)</span>
        </span>
        <span className="text-gray-500">$0</span>
      </div>
      {/* Due when trial ends */}
      <div className="flex justify-between items-center">
        <span className="text-gray-700 font-semibold">Due {dueDateString}</span>
        <div className="flex items-center">
          {props.originalPrice && <span className="text-gray-400 line-through mr-2">${props.originalPrice}</span>}
          <span className="text-gray-500">${amountDue}</span>
        </div>{' '}
      </div>
      {/* Promotion description */}
      {props.promotion && (
        <div className="flex justify-between items-center gap-4">
          <span className="text-green-700 font-semibold">{props.promotion.code}</span>
          <span className="text-green-700">{props.promotion.coupon.name}</span>
        </div>
      )}
    </div>
  )
}

export default DueSection
