/**
 * Wrap Tables With <div> tags
 * @param htmlString
 * @returns
 */
export function wrapTablesWithDiv(htmlString: string): string {
  // Regular expression to match <table> tags including their content
  const tableRegex = /<table[\s\S]*?<\/table>/gi

  // Replace each table with the table wrapped in a div
  const wrappedHtml = htmlString.replace(tableRegex, (match) => `<div class="source-html-table-wrapper">${match}</div>`)

  return wrappedHtml
}
