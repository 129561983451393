import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppDispatch } from '@/store/store-hooks'
import { ChatV2Conversation } from '@/chat-common/store/chat-v2.slice'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { kSegmentTrackDraftingQuerySubmit } from '@/constants/constants-segment'
import { chatV2CurrentSourceDraftingMetadata } from '@/chat-common/store/chat-v2.slice'
import { ChatV2QueryMetadataDrafting, initialDraftingMetadata } from '@/chat-common/schemas/chat-query-metadata-schema'

type DraftingChatDialogProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversation: ChatV2Conversation
}

const DraftingChatDialog: React.FC<DraftingChatDialogProps> = (props) => {
  const { open, onClose, conversation } = props

  const currentSource = (conversation.currentSource as ChatV2QueryMetadataDrafting) ?? initialDraftingMetadata
  const [typeOfDocument, setTypeOfDocument] = useState<string>(currentSource.doc_type)
  const [relevantParties, setRelevantParties] = useState<string>(currentSource.relevant_parties)
  const [keyDetails, setKeyDetails] = useState<string>(currentSource.key_details)
  const [jurisdiction, setJurisdiction] = useState<string>(currentSource.jurisdiction)
  const [additionalPreferences, setAdditionalPreferences] = useState<string>(currentSource.additional_preferences)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(chatV2CurrentSourceDraftingMetadata({ conversationId: conversation.id, draftingMetadata: currentSource }))
    setTypeOfDocument(currentSource.doc_type)
    setRelevantParties(currentSource.relevant_parties)
    setKeyDetails(currentSource.key_details)
    setJurisdiction(currentSource.jurisdiction)
    setAdditionalPreferences(currentSource.additional_preferences)
  }, [conversation.id])

  useEffect(() => {
    dispatch(
      chatV2CurrentSourceDraftingMetadata({
        conversationId: conversation.id,
        draftingMetadata: {
          doc_type: typeOfDocument,
          relevant_parties: relevantParties,
          key_details: keyDetails,
          jurisdiction: jurisdiction,
          additional_preferences: additionalPreferences,
        },
      })
    )
  }, [typeOfDocument, relevantParties, keyDetails, jurisdiction, additionalPreferences])

  return (
    <Transition.Root show={open} as={Fragment} appear>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto px-4 py-20 lg:ml-[300px]">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="mt-6 border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-3 sm:px-0">
                      <dt className="text-md font-medium leading-6 text-gray-900">Drafting</dt>
                      <div className="my-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Type of document</dt>
                        <dt className="text-xs font-medium leading-6 text-gray-400">Contract, will, legal notice</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <input
                            type="text"
                            name="document-type"
                            id="document-type"
                            value={typeOfDocument}
                            onChange={(e) => setTypeOfDocument(e.target.value)}
                            className="block max-w-xs w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </dd>
                      </div>

                      <div className="my-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Relevant parties</dt>
                        <dt className="text-xs font-medium leading-6 text-gray-400">Names of individuals, companies involved</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <input
                            type="text"
                            name="relevant-parties"
                            id="relevant-parties"
                            value={relevantParties}
                            onChange={(e) => setRelevantParties(e.target.value)}
                            className="block max-w-xs w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </dd>
                      </div>

                      <div className="my-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Key details</dt>
                        <dt className="text-xs font-medium leading-6 text-gray-400">
                          Specific terms, conditions, clauses, or stipulations you want to include
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <textarea
                            rows={3}
                            name="key-details"
                            id="key-details"
                            value={keyDetails}
                            onChange={(e) => setKeyDetails(e.target.value)}
                            className="block max-w-xs w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </dd>
                      </div>

                      <div className="my-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Jurisdiction</dt>
                        <dt className="text-xs font-medium leading-6 text-gray-400">The legal jurisdiciton or country laws the document should comply with</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <input
                            type="text"
                            name="relevant-parties"
                            id="relevant-parties"
                            value={jurisdiction}
                            onChange={(e) => setJurisdiction(e.target.value)}
                            className="block max-w-xs w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </dd>
                      </div>

                      <div className="my-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Additional preferences</dt>
                        <dt className="text-xs font-medium leading-6 text-gray-400">Style, format or specific legal language requirements</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <textarea
                            rows={3}
                            name="relevant-parties"
                            id="relevant-parties"
                            value={additionalPreferences}
                            onChange={(e) => setAdditionalPreferences(e.target.value)}
                            className="block max-w-xs w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </dd>
                      </div>
                    </div>
                  </dl>
                  <div className="flex items-end mb-8">
                    <button
                      onClick={async () => {
                        analytics.track(kSegmentTrackDraftingQuerySubmit)
                        onClose(false)
                      }}
                      className="w-full sm:w-auto rounded-md bg-sky-600 px-3 py-1 mt-1 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 "
                    >
                      Done
                      <ButtonLoader loading={false} />
                    </button>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default DraftingChatDialog
