import { DocumentEditingAnnotationType } from '../schema/document-editing-websocket.schema'
import { createHtmlDiffString } from './document-diffing'

/**
 * Given a list of annotations and a markdown string, annotate the markdown string with the annotations
 * Performs the operations starting with the last annotation, to maintain token indices as updates are made
 *
 * TODO: Move this out of the render loop and perform the operation when we receive the data from the server
 *
 * @param annotations the full list of annotations received from the server
 * @param markdown the markdown string to be annotated
 */
export function annotateTheMarkdown(annotations: DocumentEditingAnnotationType[], markdown: string): string {
  // Highlighted starts as the original markdown string
  let highlighted = markdown

  // For each element in the annotations array, we need to inject annotation UX into the highlighted string
  // Start at the end of the array to maintain token indices as we update the markdown string
  for (let i = annotations.length - 1; i >= 0; i--) {
    const sourceText = annotations[i].source_text
    const replacementText = annotations[i].replacement_text
    const issueText = annotations[i].issue
    const matchIndex = highlighted.indexOf(sourceText)

    // Create the diff string
    const diffString = createHtmlDiffString(sourceText, replacementText)

    // If the source text is not found in the markdown, skip this annotation
    if (matchIndex === -1) {
      console.error(`Doc Editing: source_text not found for annotation ${i}: ${sourceText}`)
      continue
    }

    // If the source text is found, inject the annotation UX
    const textBeforeAnnotationTags = highlighted.substring(0, matchIndex)
    const remainderAfterFirstTag = highlighted.substring(matchIndex)
    const originalMatchedStringForMarkup = remainderAfterFirstTag.substring(0, sourceText.length)
    const textAfterAnnotationTags = remainderAfterFirstTag.substring(sourceText.length)

    // Split the string for highlighting into individual segments for highlighting (don't highlight across carriage returns / line breaks)
    const textLines = originalMatchedStringForMarkup.split(/\r\n|\r|\n/)

    // Remove empty lines
    const filteredLines = textLines.filter((line) => line.trim() !== '')

    // Wrap each line with the highlight span individually
    const wrappedLines = filteredLines.map(
      (line, index) =>
        `<span id="annotation-inline-problem-${i}-${index}" class="annotation-selector-${i} annotation-inline-original-problem-text transition-all duration-300 ease-in-out">${line}</span>`
    )

    // Recombine with line breaks
    const wrappedText = wrappedLines.join('\r\n')

    // if (i == 3) {
    //   console.log('\n\n=================\nText before annotation tags: ', textBeforeAnnotationTags)
    //   console.log('\n\n=================\nText after annotation tags: ', textAfterAnnotationTags)
    //   console.log('\n\n=================\nOriginal matched string for markup: ', originalMatchedStringForMarkup)
    //   console.log('\n\n=================\nText after annotation tags: ', textAfterAnnotationTags)
    // }

    // Wraps the original matched text in a span tag for styling
    // Adds the annotation HTML and wraps it in a div tag for visibility toggling
    // WARNING: DO NOT INDENT OR INTRODUCE WHITESPACE THE BELOW MULTI-LINE STRING OR IT WILL BREAK MARKDOWN RENDERING
    highlighted = `
${textBeforeAnnotationTags}${wrappedText}<button>[${i}]</button>
<div id="annotation-area-${i}" class="edit-annotation-area hidden">
  <div class="flex justify-between mb-2 align-top">
    <div class="edit-annotation-label">[ ${i + 1} ]</div>
    <div class="text-sm font-bold cursor-pointer" id="close-annotation-${i}">close</div>
  </div>
  <div class="edit-annotation-label">Issue</div>
  <div class="annotation-issue-highlight mb-2">${issueText}</div>
  
  <div class="edit-annotation-label">Replacement</div>
  <div class="annotation-replacement-text-highlight mb-2">${replacementText}</div>
  
  <div class="edit-annotation-label">Difference</div>
  <div class="annotation-diff">${diffString}</div>
</div>
${textAfterAnnotationTags}
    `
  }

  return highlighted
}
