import { useState, useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import * as Switch from '@radix-ui/react-switch'
import { advance } from '../store/onboarding.slice'
import { useAppDispatch } from '@/store/store-hooks'
import OnboardingOrganizationSetup from './OnboardingOrganizationSetup'
import { useCreateOrganizationMutation } from '@/onboarding/store/onboarding.slice.ts'

type OnboardingFormProps = {
  visible: boolean
}

export default function OnboardingAccountSetup({ visible }: OnboardingFormProps) {
  const { userAccountData } = useContext(AuthContext)
  const isOrgAccount = userAccountData?.isOrgAccount

  const [enabled, setEnabled] = useState(false)
  const [orgName, setOrgName] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [emailValue, setEmailValue] = useState('')
  const [annualBilling, setAnnualBilling] = useState(true)

  const [createOrganization, { isLoading, error }] = useCreateOrganizationMutation()

  const dispatch = useAppDispatch()

  const handleAdvance = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const billingFrequency = annualBilling ? 'annual' : 'monthly'
    if (enabled && orgName) {
      try {
        const result = await createOrganization({ name: orgName, seats: 1, users: emails, billing_frequency: billingFrequency }).unwrap()
        if (result.organization) {
          dispatch(advance())
        }
      } catch (error) {
        console.error('Failed to create organization:', error)
      }
    } else {
      dispatch(advance())
    }
  }

  const handleSkip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(advance())
  }

  if (!visible) return null

  if (isOrgAccount) {
    return (
      <div className=" py-5">
        <h3 className="text-xl font-semibold text-gray-900">
          It looks like you've already created your organization. Please advance to continue to your account.
        </h3>
      </div>
    )
  }

  return (
    <div className=" py-5">
      <h3 className="text-xl font-semibold text-gray-900">Create an organization</h3>
      <div className="mt-2 sm:flex sm:items-start sm:justify-between">
        <div className="max-w-xl text-sm text-gray-500">
          <p>Create an organization to unlock collaboration features, centralize billing, and manage users in organization settings.</p>
        </div>
        <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
          <div className="flex items-center">
            <Switch.Root
              className="w-[42px] h-[25px] bg-gray-400 hover:bg-gray-500 rounded-full relative shadow-black focus:shadow-[0_0_0_2px] focus:shadow-black data-[state=checked]:bg-sky-600 outline-none cursor-pointer"
              id="airplane-mode"
              checked={enabled}
              onCheckedChange={setEnabled}
            >
              <Switch.Thumb className="block w-[21px] h-[21px] bg-white rounded-full shadow-[0_1px_2px] shadow-blackA4 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
            </Switch.Root>
          </div>
        </div>
      </div>
      {enabled && (
        <OnboardingOrganizationSetup
          orgName={orgName}
          setOrgName={setOrgName}
          emails={emails}
          setEmails={setEmails}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
          advance={handleAdvance}
          isLoading={isLoading}
          annualBilling={annualBilling}
          setAnnualBilling={setAnnualBilling}
        />
      )}
      <div className="flex mt-8 justify-end gap-6">
        <button
          type="button"
          className={`rounded-md bg-gray-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleSkip}
          disabled={isLoading}
        >
          Skip
        </button>
        {error && <p className="text-red-500 content-center justify-end">Error creating organization</p>}
      </div>
    </div>
  )
}
