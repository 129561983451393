import {
  chatV2CurrentSourceResearchRemoveAllFederalLRRSources,
  chatV2CurrentSourceResearchAddFederalCourtIds,
  chatV2CurrentSourceResearchAddFederalLRRSource,
  chatV2CurrentSourceResearchRemoveAllFederalCourtIds,
  chatV2CurrentSourceResearchRemoveAllStateCourtIds,
  chatV2CurrentSourceResearchRemoveAllStateLRRSources,
} from '@/chat-common/store/chat-v2.slice'
import { getAllFederalCourtIds } from '../caselaw/caselaw-court-utils'
import { getFederalCourtIdsByState, getStandardFederalLRRSources, getStandardFederalCourtIds } from './research-source-maps'
import { CourtsResponse } from '@/store/apis/hosted-filters-api'
import { Dispatch, AnyAction } from '@reduxjs/toolkit'

/**
 * Adds all standard federal LRR sources (ones which accompany any federal court and are applicable to any state) to the current source.
 *
 * @param dispatch - The Redux dispatch function.
 * @param conversationId - The ID of the current conversation.
 */
export function addAllFederalLRRSources(dispatch: Dispatch<AnyAction>, conversationId: string) {
  const federalLrr = getStandardFederalLRRSources()
  federalLrr.forEach((source) => {
    dispatch(chatV2CurrentSourceResearchAddFederalLRRSource({ conversationId: conversationId, source_name: source }))
  })
}

/**
 * Adds all federal courts and federal LRR sources to the current source.
 *
 * @param dispatch - The Redux dispatch function.
 * @param conversationId - The ID of the current conversation.
 * @param courts - The response containing court data.
 */
export function addAllFederalCourtsAndLRR(dispatch: Dispatch<AnyAction>, conversationId: string, courts: CourtsResponse) {
  // Add all federal courts
  const courtIds = getAllFederalCourtIds(courts)
  dispatch(chatV2CurrentSourceResearchAddFederalCourtIds({ conversationId: conversationId, courtIds: courtIds }))

  // Add all federal LRR sources
  addAllFederalLRRSources(dispatch, conversationId)
}

/**
 * Removes all Federal courts and LRR sources from the current source.
 *
 * @param dispatch - The Redux dispatch function.
 * @param conversationId - The ID of the current conversation.
 */
export function removeAllFederalCourtsAndLRR(dispatch: Dispatch<AnyAction>, conversationId: string) {
  // Remove all federal courts
  dispatch(chatV2CurrentSourceResearchRemoveAllFederalCourtIds({ conversationId: conversationId }))

  // Remove all federal LRR sources
  dispatch(chatV2CurrentSourceResearchRemoveAllFederalLRRSources({ conversationId: conversationId }))
}

/**
 * Adds all standard federal courts and LRR sources, as well as Federal courts applicable to the state to the current source.
 *
 * @param dispatch - The Redux dispatch function.
 * @param conversationId - The ID of the current conversation.
 * @param state - The state for which related federal courts are to be added.
 */
export function addAllRelatedFederalCourtsAndLRRByState(dispatch: Dispatch<AnyAction>, conversationId: string, state: string) {
  // Add all federal LRR sources
  addAllFederalLRRSources(dispatch, conversationId)

  // Add standard federal courts (ones applicable to any state)
  const standardFederalCourts = getStandardFederalCourtIds()
  dispatch(chatV2CurrentSourceResearchAddFederalCourtIds({ conversationId: conversationId, courtIds: standardFederalCourts }))

  // Add federal courts related to the state
  const relatedFederalCourts = getFederalCourtIdsByState(state)
  dispatch(chatV2CurrentSourceResearchAddFederalCourtIds({ conversationId: conversationId, courtIds: relatedFederalCourts }))
}

/**
 * Removes all state courts and LRR sources from the current source.
 *
 * @param dispatch - The Redux dispatch function.
 * @param conversationId - The ID of the current conversation.
 */
export function removeAllStateCourtsAndLRR(dispatch: Dispatch<AnyAction>, conversationId: string) {
  // Remove all state courts
  dispatch(chatV2CurrentSourceResearchRemoveAllStateCourtIds({ conversationId: conversationId }))

  // Remove all state LRR sources
  dispatch(chatV2CurrentSourceResearchRemoveAllStateLRRSources({ conversationId: conversationId }))
}
