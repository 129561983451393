import { User } from 'firebase/auth'
import * as Sentry from '@sentry/browser'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import { isPaxtonBrand } from '@/util/enterprise'

export function analyticsIdentifyUser(user: User | null, brand: BrandEnum = BrandEnum.NONE) {
  if (user == null) return

  analytics.identify(user.uid, {
    ...(user.email && { email: user.email }),
    ...(user.displayName && { name: user.displayName }),
  })

  const userProperties = {
    id: user.uid,
    ...(user.email && { email: user.email }),
    brand: brand,
  }

  if (userProperties['email'] && !isPaxtonBrand(brand)) {
    delete userProperties['email']
  }

  // Sentry user identification
  Sentry.setUser(userProperties)
}
