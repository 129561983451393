import { Docx2HtmlCommentType } from '@/document-editing/schema/docx2html.schema'

type Docx2HtmlCommentTypeChipProps = {
  commentType: Docx2HtmlCommentType
}

export default function Docx2HtmlCommentTypeChip(props: Docx2HtmlCommentTypeChipProps) {
  const { commentType } = props

  function translateTypeToText() {
    switch (commentType) {
      case Docx2HtmlCommentType.insertion:
        return '+ Insertion'
      case Docx2HtmlCommentType.deletion:
        return '- Deletion'
      case Docx2HtmlCommentType.replacement:
        return '- / + Replacement'
      case Docx2HtmlCommentType.no_change:
        return 'No Change'
      default:
        return ''
    }
  }

  return <div className={'inline bg-sky-800 text-white rounded-lg text-xs font-bold px-3 py-1'}>{translateTypeToText()}</div>
}
