import { Outlet } from 'react-router'
import DashboardSidebar from './sidebar/dashboard-sidebar'
import { ChevronLeftIcon } from '@radix-ui/react-icons'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { toggleSidebarVisibility } from '@/store/slices/ui-state.slice'
import StripePortalDialog from '@/routes/dashboard/subscribe/stripe-portal-dialog.tsx'
import { isPaxtonBrand, overridePaxton } from '@/util/enterprise'
import { useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import ReferralDialog from '@/referral/views/referral-dialog'

export default function Dashboard() {
  // Store - UI State
  const dispatch = useAppDispatch()
  const uiState = useAppSelector((state: RootState) => state.uiState)
  const hideSidebars = uiState.hideSidebars
  const isPaxtonHost = isPaxtonBrand()
  const isOverridePaxton = overridePaxton()

  // Auth Context
  const authContext = useContext(AuthContext)
  const { user } = authContext
  const userIsAnon = user?.isAnonymous

  // Attach sidebar for Paxton or authed enterprise users
  const attachSidebar = isPaxtonHost || !userIsAnon || isOverridePaxton

  return (
    <main className={'bg-gray-100 h-[calc(100dvh)]'}>
      {/* Global Dialogs */}
      <StripePortalDialog />
      <ReferralDialog />

      {/* Sidebar - shown for mobile screen sizes */}
      {attachSidebar && (
        <div className={`fixed w-full h-12 lg:hidden z-[5]`}>
          <DashboardSidebar hideSidebars={hideSidebars} />
        </div>
      )}

      <div className={'flex justify-center h-full'}>
        {/* Sidebar shown for desktop screen sizes */}
        {attachSidebar && (
          <div className={`hidden lg:flex h-full items-center`}>
            <DashboardSidebar hideSidebars={hideSidebars} />

            {/* Desktop Sidebar Collapse Button */}
            <button
              onClick={() => dispatch(toggleSidebarVisibility())}
              className={`relative z-0 group h-full flex transition-all ease-in-out duration-200 items-center justify-center ${hideSidebars ? 'w-8' : 'w-6'}`}
            >
              <div className={'relative h-full flex items-center justify-center'}>
                <div className={'absolute z-10 bg-gray-100 py-4 rounded-full'}>
                  <div
                    className={`group-hover:w-6 h-6 bg-gray-300 group-hover:bg-gray-400 transition-all ease-in-out duration-300 time rounded-full ${
                      hideSidebars ? 'w-6 rotate-180 duration-1000' : 'w-[3px]'
                    }`}
                  >
                    <ChevronLeftIcon className={'w-full h-full text-white'} />
                  </div>
                </div>
                <div
                  className={
                    'absolute h-2/3 group-hover:h-4/5 w-[1px] bg-gray-300 group-hover:bg-gray-400 transition-all ease-in-out rounded-full duration-200 time'
                  }
                ></div>
              </div>
            </button>
          </div>
        )}

        {/* Main Content */}
        <Outlet />
      </div>
    </main>
  )
}
