import { z } from 'zod'

export const ChatV2QueryMetadataContractSearchSchema = z.object({
  chunk_id: z.string(),
  document_id: z.string(),
  title: z.string(),
  source: z.string(),
  s3_path: z.string(),
})
export type ChatV2QueryMetadataContractSearch = z.infer<typeof ChatV2QueryMetadataContractSearchSchema>
export const initialContractSearchMetadata: ChatV2QueryMetadataContractSearch = {
  chunk_id: '',
  document_id: '',
  title: '',
  source: '',
  s3_path: '',
}

// Chat Query Metadata: drafting
export const ChatV2QueryMetadataDraftingSchema = z.object({
  doc_type: z.string(),
  relevant_parties: z.string(),
  key_details: z.string(),
  jurisdiction: z.string(),
  additional_preferences: z.string(),
})

export type ChatV2QueryMetadataDrafting = z.infer<typeof ChatV2QueryMetadataDraftingSchema>

export const initialDraftingMetadata: ChatV2QueryMetadataDrafting = {
  doc_type: '',
  relevant_parties: '',
  key_details: '',
  jurisdiction: '',
  additional_preferences: '',
}

// OLD SCHEMA
// // Chat Query Metadata: lrr
// export const ChatV2QueryMetadataLrrSchema = z.object({
//   paths: z.array(z.string()),
//   source_name: z.string(),
// })
// export type ChatV2QueryMetadataLrr = z.infer<typeof ChatV2QueryMetadataLrrSchema>

// Chat Query Metadata: lrr_v2
const LRRV2SelectionsSchema = z.object({
  paths: z.array(z.string()),
  source_name: z.string(),
})
export const ChatV2QueryMetadataLrrV2Schema = z.object({
  lrr_selections: z.array(LRRV2SelectionsSchema),
})
export type ChatV2QueryMetadataLrrV2 = z.infer<typeof ChatV2QueryMetadataLrrV2Schema>

// Chat Query Metadata: caselaw
export const ChatV2QueryMetadataCaseLawSchema = z.object({
  court_ids: z.array(z.string()),
  year_low: z.number().nullable(),
  year_high: z.number().nullable(),
  include_unpublished_cases: z.boolean().default(false),
})
export type ChatV2QueryMetadataCaseLaw = z.infer<typeof ChatV2QueryMetadataCaseLawSchema>

// Chat Query Metadata: research
export const ChatV2QueryMetadataResearchSchema = z.object({
  state_lrr_selections: z.array(LRRV2SelectionsSchema).nullable(),
  federal_lrr_selections: z.array(LRRV2SelectionsSchema).nullable(),
  state_court_ids: z.array(z.string()).nullable(),
  federal_court_ids: z.array(z.string()).nullable(),
  case_law_year_low: z.number().nullable(),
  case_law_year_high: z.number().nullable(),
  include_unpublished_cases: z.boolean().default(false),
})

export type ChatV2QueryMetadataResearch = z.infer<typeof ChatV2QueryMetadataResearchSchema>

// Chat Query Metadata: documentquery
export const ChatV2QueryMetadataDocumentQuerySchema = z.object({
  selected_files: z.array(z.string()),
})
export type ChatV2QueryMetadataDocumentQuery = z.infer<typeof ChatV2QueryMetadataDocumentQuerySchema>

// Chat Query Metadata: contractanalysis
export const ChatV2QueryMetadataContractAnalysisSchema = z.object({
  file_path: z.string(),
})
export type ChatV2QueryMetadataContractAnalysis = z.infer<typeof ChatV2QueryMetadataContractAnalysisSchema>

// Chat Query Metadata: websearch
export const ChatV2QueryMetadataWebsearchQuerySchema = z.object({
  type: z.enum(['news', 'web']),
  site: z.string().nullable(),
})
export type ChatV2QueryMetadataWebsearchQuery = z.infer<typeof ChatV2QueryMetadataWebsearchQuerySchema>

// Chat Query Metadata: summarization
export const ChatV2QueryMetadataDocumentSummarizationSchema = z.object({
  selected_file: z.string(),
})
export type ChatV2QueryMetadataDocumentSummarization = z.infer<typeof ChatV2QueryMetadataDocumentSummarizationSchema>

export const initialWebsearchMetadata: ChatV2QueryMetadataWebsearchQuery = {
  type: 'web',
  site: null,
}

// Chat Query Metadata: assistant
// TODO: Use the corresponding ChatV2Feature as the values here to reduce duplication - requires moving the
// ChatV2Feature enum (used widely) and this enum into its own file to avoid circular dependencies
export enum FocusChoice {
  ASSISTANT = 'assistant',
  DRAFTING = 'drafting',
  DOCUMENTQUERY = 'documentquery',
}

export const ChatV2QueryMetadataAssistantSchema = z.object({
  selected_files: z.array(z.string()),
  focus_choice: z.nativeEnum(FocusChoice).default(FocusChoice.ASSISTANT),
})
export type ChatV2QueryMetadataAssistant = z.infer<typeof ChatV2QueryMetadataAssistantSchema>
