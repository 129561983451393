import { AuthContext } from '@/context/auth-context'
import { useContext } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import InviteAuth from './InviteAuth'
import InviteError from './InviteError'
import InviteAccept from './InviteAccept'

export default function InvitePage() {
  const { inviteId } = useParams()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const brand = queryParams.get('brand') || undefined;
  // Get auth context
  const { user } = useContext(AuthContext)

  // If not logged in / anonymous, show sign in / sign up options
  if (!user || user.isAnonymous) {
    return <InviteAuth />
  }

  // If no invite ID
  if (!inviteId) {
    return <InviteError />
  }

  return <InviteAccept inviteId={inviteId} brand={brand} />;
}
