import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import { ChatV2QueryMetadataContractAnalysis, ChatV2QueryMetadataContractAnalysisSchema } from '../schemas/chat-query-metadata-schema'

// Toggle a selected file
export const reducerCurrentSourceContractAnalysisToggleSelectedFile = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; filePath: string }>
) => {
  const { conversationId, filePath } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  const validation = ChatV2QueryMetadataContractAnalysisSchema.safeParse(conversation.currentSource)
  if (!validation.success) {
    // Set defaults
    conversation.currentSource = {
      file_path: filePath,
    }

    // Return because we've already applied the file
    return
  }

  // Get the currentSource for manipulation
  const currentSource = conversation.currentSource as ChatV2QueryMetadataContractAnalysis

  // If the filePath is already in the list, remove it (OPTIMIZED FOR SINGLE FILE ONLY)
  if (currentSource.file_path.includes(filePath)) {
    currentSource.file_path = ''
  } else {
    // Otherwise, add it to the list
    currentSource.file_path = filePath
  }
}

// Set As The Only Selected File
export const reducerCurrentSourceContractAnalysisSetAsOnlySelectedFile = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; filePath: string }>
) => {
  const { conversationId, filePath } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  const validation = ChatV2QueryMetadataContractAnalysisSchema.safeParse(conversation.currentSource)
  if (!validation.success) {
    // Set defaults
    conversation.currentSource = {
      file_path: filePath,
    }

    // Return because we've already applied the file
    return
  }

  // Get the currentSource for manipulation
  const currentSource = conversation.currentSource as ChatV2QueryMetadataContractAnalysis

  // Set the filePath as the only selected file
  currentSource.file_path = filePath
}

// Clear all selected files
export const reducerCurrentSourceContractAnalysisClearAll = (state: ChatV2State, action: PayloadAction<{ conversationId: string }>) => {
  const { conversationId } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  const validation = ChatV2QueryMetadataContractAnalysisSchema.safeParse(conversation.currentSource)
  if (!validation.success) {
    // Set defaults
    conversation.currentSource = {
      file_path: '',
    }

    // Return because we've already applied the file
    return
  }

  // Get the currentSource for manipulation
  const currentSource = conversation.currentSource as ChatV2QueryMetadataContractAnalysis

  // Clear all selected files
  currentSource.file_path = ''
}
