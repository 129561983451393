import { kProduction } from './constants-ui'

/**
 * Feature Flag Staging Only
 * @returns true if we are not on production
 */
function kFeatureFlagStagingOnly(): boolean {
  return !kProduction
}


// Feature Flags
/**
 * Represents the feature flags in the application.
 */
export enum kFeatureFlags {
  WEBSOCKET_CHAT = 'WEBSOCKET_CHAT',
  ASSISTANT = 'ASSISTANT',
}

// Feature Flag Statuses
/**
 * Represents the status of each feature flags.
 */
const kFeatureFlagStatus = {
  [kFeatureFlags.WEBSOCKET_CHAT]: kFeatureFlagStagingOnly(),
  [kFeatureFlags.ASSISTANT]: kFeatureFlagStagingOnly(),
}

/**
 * Get Feature Flag Status
 * to be used in the application to check if a feature flag is enabled or not.
 */
export function kGetFeatureFlagStatus(flag: kFeatureFlags): boolean {
  return kFeatureFlagStatus[flag]
}
