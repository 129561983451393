import { kLinkPrivacyPolicyUrl, kLinkTermsOfServiceUrl } from '@/constants/constants-links'

export const PaxtonDisclaimer = () => (
  <p>
    Paxton provides self-help services at your specific direction. We are not a law firm or a substitute for an attorney or law firm. Communications between you
    and Paxton are protected by our{' '}
    <a className="text-sky-600" target="_blank" rel="noopener noreferrer" href={kLinkPrivacyPolicyUrl}>
      Privacy Policy
    </a>
    , but not by the attorney-client privilege or as work product. We cannot provide any kind of advice, explanation, opinion, or recommendation about possible
    legal rights, remedies, defenses, options, selection of forms, or strategies. Your access to our website is subject to our{' '}
    <a className="text-sky-600" target="_blank" rel="noopener noreferrer" href={kLinkTermsOfServiceUrl}>
      Terms of Service
    </a>
    .
  </p>
)
