import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import { ChatV2QueryMetadataCaseLaw, ChatV2QueryMetadataCaseLawSchema } from '../schemas/chat-query-metadata-schema'

// Default new case law source
export const defaultNewCaseLawValues: ChatV2QueryMetadataCaseLaw = {
  court_ids: [],
  year_low: null,
  year_high: null,
  include_unpublished_cases: false,
}

// Set case law date range
export const reducerSetCaseLawDateRange = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; yearLow: number | null; yearHigh: number | null }>
) => {
  const { conversationId, yearLow, yearHigh } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  const validation = ChatV2QueryMetadataCaseLawSchema.safeParse(conversation.currentSource)
  if (!validation.success) {
    // If not a match, set defaults
    conversation.currentSource = {
      ...defaultNewCaseLawValues,
      year_low: yearLow,
      year_high: yearHigh,
    }
  }

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataCaseLaw

  // Update the year values
  currentSource.year_low = yearLow
  currentSource.year_high = yearHigh
}

// ADD COURTS
export const reducerAddCaseLawCourtIds = (state: ChatV2State, action: PayloadAction<{ conversationId: string; courtIds: string[] }>) => {
  const { conversationId, courtIds } = action.payload
  const conversation = state.conversations[conversationId]
  if (conversation.currentSource == null)
    conversation.currentSource = {
      ...defaultNewCaseLawValues,
      court_ids: courtIds,
    }
  const validation = ChatV2QueryMetadataCaseLawSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataCaseLaw

  // Add the new selections and ensure they are unique
  const newSelections = Array.from(new Set([...currentSource.court_ids, ...courtIds]))
  currentSource.court_ids = newSelections
}

// REMOVE COURTS
export const reducerRemoveCaseLawCourtIds = (state: ChatV2State, action: PayloadAction<{ conversationId: string; courtIds: string[] }>) => {
  const { conversationId, courtIds } = action.payload
  const conversation = state.conversations[conversationId]
  if (conversation.currentSource == null)
    conversation.currentSource = {
      ...defaultNewCaseLawValues,
      court_ids: courtIds,
    }
  const validation = ChatV2QueryMetadataCaseLawSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataCaseLaw

  // Remove the new selections
  const newSelections = currentSource.court_ids.filter((courtId) => !courtIds.includes(courtId))
  currentSource.court_ids = newSelections
}

// SET INCLUDE UNPUBLISHED CASES
export const reducerSetIncludeUnpublishedCases = (state: ChatV2State, action: PayloadAction<{ conversationId: string; includeUnpublishedCases: boolean }>) => {
  const { conversationId, includeUnpublishedCases } = action.payload
  const conversation = state.conversations[conversationId]
  if (conversation.currentSource == null)
    conversation.currentSource = {
      ...defaultNewCaseLawValues,
      includeUnpublishedCases: includeUnpublishedCases,
    }
  const validation = ChatV2QueryMetadataCaseLawSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataCaseLaw

  // Set the include unpublished cases value as not null boolean
  currentSource.include_unpublished_cases = includeUnpublishedCases
}
