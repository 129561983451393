import { useAppDispatch } from '@/store/store-hooks'
import { nanoid } from 'nanoid'
import { useNavigate, useParams } from 'react-router-dom'
import { ChatV2Conversation, ChatV2Feature, chatV2AddConversation } from '../store/chat-v2.slice'
import getDefaultQueryMetadataByFeature from '../schemas/chat-default-schema-values'
import { setLastViewedConvoIdByFeature } from '../store/chat-v2-ux.slice'
import { newChatButtonLabel } from './chat-window-utils'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { kConversationPendingPrefix } from '@/constants/constants-ui'

type NewChatButtonProps = {
  onClickCallback?: () => void
}

export default function NewChatButton(props: NewChatButtonProps) {
  const { onClickCallback } = props
  const { trackEvent } = useAnalytics()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { chatFeature } = useParams()

  return (
    <button
      onClick={() => {
        const newChatId = kConversationPendingPrefix + nanoid()

        // Construct the pending conversation
        const pendingConversation: ChatV2Conversation = {
          id: newChatId,
          isPending: true,
          isLoading: false,
          feature: chatFeature as ChatV2Feature,
          messages: {},
          created_at: new Date().toISOString(),
          currentSource: getDefaultQueryMetadataByFeature(chatFeature as ChatV2Feature),
          currentTextInput: '',
          isError: false,
          formValidationError: false,
          visibleReference: null,
        }

        // insert it into state
        dispatch(chatV2AddConversation(pendingConversation))

        // Navigate to the empty pending conversation
        navigate(`/dashboard/chat/${chatFeature}/${pendingConversation.id}`)

        // Set the last viewed conversation id for the feature
        dispatch(setLastViewedConvoIdByFeature({ feature: chatFeature as ChatV2Feature, conversationId: newChatId }))

        // Callback
        if (onClickCallback) onClickCallback()

        trackEvent(AnalyticsEvent.NewChat, { feature: chatFeature as ChatV2Feature })
      }}
      className={
        'w-full rounded-md bg-sky-600 py-2 text-sm font-semibold text-white align-middle shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
      }
    >
      <span className={'inline-block whitespace-nowrap'}>{newChatButtonLabel(chatFeature as ChatV2Feature)}</span>
    </button>
  )
}
