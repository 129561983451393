export const kLinkPrivacyPolicyUrl = 'https://www.paxton.ai/legal/privacy-policy'
export const kLinkTermsOfServiceUrl = 'https://www.paxton.ai/legal/terms-of-service'

// Determine the Paxton Users API base url by the environment
const kPaxtonUsersApiCloudStaging = 'https://paxton-users-api-mla66wcquq-uc.a.run.app'
const kPaxtonUsersApiCloudProduction = 'https://paxton-users-api-xmfvvq3waa-uc.a.run.app'
const kPaxtonUsersApiLocal = 'http://localhost:3000'

export const kPaxtonAppBaseDomain = () => {
  switch (import.meta.env.MODE) {
    case 'development': {
      return 'http://localhost:5173'
    }
    case 'staging': {
      return 'https://staging.paxton.ai'
    }
    case 'production': {
      return 'https://app.paxton.ai'
    }
    default: {
      return 'https://app.paxton.ai'
    }
  }
}

export const kPaxtonUsersApiBaseUrl = () => {
  switch (import.meta.env.MODE) {
    case 'development': {
      return kPaxtonUsersApiLocal
    }
    case 'staging': {
      return kPaxtonUsersApiCloudStaging
    }
    case 'production': {
      return kPaxtonUsersApiCloudProduction
    }
    default: {
      return kPaxtonUsersApiCloudProduction
    }
  }
}

// Determine the Document Interface API base url by the environment
const kPaxtonAppApiCloudStaging = 'stagingapi.paxton.ai'
const kPaxtonAppApiCloudProduction = 'api2.paxton.ai'
const kPaxtonAppApiLocal = 'localhost:5002'

export const kPaxtonAppApiBaseUrl = (websocket = false) => {
  switch (import.meta.env.MODE) {
    case 'development': {
      return websocket ? 'ws://' + kPaxtonAppApiLocal : 'http://' + kPaxtonAppApiLocal
    }
    case 'staging': {
      return websocket ? 'wss://' + kPaxtonAppApiCloudStaging : 'https://' + kPaxtonAppApiCloudStaging
    }
    case 'production': {
      return websocket ? 'wss://' + kPaxtonAppApiCloudProduction : 'https://' + kPaxtonAppApiCloudProduction
    }
    default: {
      return websocket ? 'wss://' + kPaxtonAppApiCloudProduction : 'https://' + kPaxtonAppApiCloudProduction
    }
  }
}
