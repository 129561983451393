import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { DotsVerticalIcon, DownloadIcon, Pencil2Icon, TrashIcon } from '@radix-ui/react-icons'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  onSelect: () => void
  onRenameClick: () => void
  onDeleteClick: () => void
  onDownloadClick?: () => void
}

const ItemActionsMenu: React.FC<Props> = (props: Props) => {
  const { onSelect, onRenameClick, onDownloadClick, onDeleteClick } = props

  const menuElements = [
    {
      name: 'Rename',
      icon: Pencil2Icon,
      current: false,
      onClick: () => onRenameClick(), //Will open the rename modal
    },
    {
      name: 'Delete',
      icon: TrashIcon,
      current: false,
      onClick: () => onDeleteClick(),
    },
    //TODO: Uncomment this when moving item functionality is enable
    // {
    //   name: 'Move item',
    //   icon: EnterIcon,
    //   current: false,
    //   onClick: () => {},
    // }
  ]

  if (onDownloadClick) {
    menuElements.push({
      name: 'Download',
      icon: DownloadIcon,
      current: false,
      onClick: () => onDownloadClick(),
    })
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className={`p-2`}>
          <DotsVerticalIcon width={18} height={18} className={'hover:text-sky-600'} />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-50 min-w-[200px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >
          <ul role="list" className="space-y-1.5">
            {menuElements.map((item) => (
              <li key={item.name} onMouseEnter={() => (item.current = true)}>
                <DropdownMenu.Item
                  onClick={() => {
                    item.onClick()
                    onSelect()
                  }}
                  className={classNames(
                    item.current ? 'bg-gray-50 text-sky-600' : 'text-gray-700 hover:text-sky-600 hover:bg-gray-50 cursor-pointer',
                    'rounded-md group flex items-center flex-nowrap gap-x-1 py-[2px] text-sm leading-6 w-full'
                  )}
                >
                  <item.icon className={classNames(item.current ? 'text-sky-600' : 'h-5 w-5 shrink-0')} aria-hidden="true" />
                  <div className={`whitespace-nowrap `}>{item.name}</div>
                </DropdownMenu.Item>
              </li>
            ))}
          </ul>

          <DropdownMenu.Arrow className="fill-white" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default ItemActionsMenu
