import { ChatV2Conversation, ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import ReferenceViewContentCaseLaw from './reference-view-caselaw'
import ReferenceViewContentLrr from './reference-view-lrr'
import ReferenceViewContentWeb from './reference-view-web'
import ReferenceViewContentCaseLawV2 from './reference-view-caselaw-v2'
import ReferenceViewContentContract from './reference-view-contract'
import ReferenceViewContentLrrV2 from './reference-view-lrr-v2'

// Construct the appropriate reference view based on the feature
import { Dispatch, SetStateAction } from 'react'
import ReferenceViewGcsMarkdown from './gcs-markdown/reference-view-gcs-markdown'

export default function ReferenceViewerConstructor(props: {
  conversation: ChatV2Conversation
  setReferenceHtmlCallback?: Dispatch<SetStateAction<string>>
}): JSX.Element | null {
  const { conversation, setReferenceHtmlCallback } = props
  const reference = conversation.visibleReference

  if (!reference) return <div>No reference selected</div>

  switch (conversation.feature) {
    case ChatV2Feature.caselaw:
      // Check the metadata version to determine which reference rendering component to use
      if (reference.metadata.version === 2) {
        const visibleReference = conversation.visibleReference
        if (visibleReference) {
          return <ReferenceViewContentCaseLawV2 conversation={conversation} setReferenceHtmlCallback={setReferenceHtmlCallback} />
        } else {
          return <div>No active reference</div>
        }
      } else {
        return <ReferenceViewContentCaseLaw conversation={conversation} setReferenceHtmlCallback={setReferenceHtmlCallback} />
      }
    case ChatV2Feature.lrr:
      return <ReferenceViewContentLrr conversation={conversation} setReferenceHtmlCallback={setReferenceHtmlCallback} />

    case ChatV2Feature.lrr_v2:
      return <ReferenceViewContentLrrV2 conversation={conversation} setReferenceHtmlCallback={setReferenceHtmlCallback} />

    case ChatV2Feature.research: {
      const visibleReference = conversation.visibleReference
      if (visibleReference) {
        // Check the reference_type to determine which reference viewer to use (caselaw or lrr_v2)
        if (visibleReference.metadata.reference_type == ChatV2Feature.caselaw) {
          return <ReferenceViewContentCaseLawV2 conversation={conversation} setReferenceHtmlCallback={setReferenceHtmlCallback} />
        }
        return <ReferenceViewContentLrrV2 conversation={conversation} setReferenceHtmlCallback={setReferenceHtmlCallback} />
      }
      return <div>No active reference</div>
    }

    case ChatV2Feature.documentquery:
    case ChatV2Feature.documentSummarization:
      // return <ReferenceHTMXLoader conversation={conversation} />
      return <ReferenceViewGcsMarkdown conversation={conversation} />

    case ChatV2Feature.websearch:
      return <ReferenceViewContentWeb conversation={conversation} />

    case ChatV2Feature.contractsearch:
      return <ReferenceViewContentContract conversation={conversation} />

    default:
      return null
  }
}
