import { initializeApp, getApps } from 'firebase/app'
import { isHaikuHost } from '@/util/enterprise'

const isHaiku = isHaikuHost()

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: isHaiku ? import.meta.env.VITE_FIREBASE_HAIKU_AUTH_DOMAIN : import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_AP_ID,
}

// Initialize Firebase if not already initialized
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0]

// If local, use auth emulator
// if (import.meta.env.MODE == 'development') {
//   console.log('Using auth emulator')
//   connectAuthEmulator(getAuth(firebase_app), 'http://127.0.0.1:9099')
// }

export default firebase_app
