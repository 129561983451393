import { BillingCycle, SubscriptionType } from '@/firebase/auth/auth-jwt-schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { prepareRtkQueryHeaders, transformRtkQueryResponse } from '@/store/apis/rtk-query-utils'
import {
  kSubscriptionValidatePromoCodeApiPath,
  kSubscriptionCurrentPriceApiPath,
  kSubscriptionCheckoutPricesApiPath,
  kSubscriptionTrialDaysApiPath,
} from '@/constants/constants-api-paths'
import { PromoCodeResponseSchema, PromoCodeResponse, Promotion } from '@/routes/dashboard/subscribe/schema/promo-code.schema.ts'
import { PriceResponseSchema, PriceResponse, Price, CheckoutPricesSchema, CheckoutPrices } from '@/routes/dashboard/subscribe/schema/price.schema.ts'
import { formatCurrency } from '@/routes/dashboard/subscribe/utils/pricing'
import { getDiscountForAmountDue } from '@/routes/dashboard/subscribe/subscription-utils'
import { TrialDaysSchema } from '@/routes/dashboard/subscribe/schema/trial.schema.ts'

interface subscriptionState {
  billingCycle: BillingCycle
  promotion: Promotion | null
  currentSubscriptionFormattedPrice: string
  currentSubscriptionInterval: string
  currentSubscriptionFrequency: string
  checkoutStandardAnnualPrice: Price | null
  checkoutStandardMonthlyPrice: Price | null
  checkoutStudentAnnualPrice: Price | null
  checkoutStudentMonthlyPrice: Price | null
  annualPlanMonthlyPrice: number
  studentAnnualPlanMonthlyPrice: number
  monthlyPlanMonthlyPrice: number
  studentMonthlyPlanMonthlyPrice: number
  trialDays: number
}

const initialState: subscriptionState = {
  billingCycle: BillingCycle.ANNUAL,
  promotion: null,
  currentSubscriptionFormattedPrice: '',
  currentSubscriptionInterval: '',
  currentSubscriptionFrequency: '',
  checkoutStandardAnnualPrice: null,
  checkoutStandardMonthlyPrice: null,
  checkoutStudentAnnualPrice: null,
  checkoutStudentMonthlyPrice: null,
  annualPlanMonthlyPrice: 0,
  studentAnnualPlanMonthlyPrice: 0,
  monthlyPlanMonthlyPrice: 0,
  studentMonthlyPlanMonthlyPrice: 0,
  trialDays: 7,
}

const subscriptionSlice = createSlice({
  name: 'subscriptionState',
  initialState,
  reducers: {
    nullifyData: () => initialState,
    setBillingCycle: (state, action: PayloadAction<BillingCycle>) => {
      state.billingCycle = action.payload
    },
    setPromoCodeResponse: (state, action: PayloadAction<Promotion | null>) => {
      state.promotion = action.payload
    },
    setCurrentSubscriptionFormattedPrice: (state, action: PayloadAction<string>) => {
      state.currentSubscriptionFormattedPrice = action.payload
    },
    setCurrentSubscriptionInterval: (state, action: PayloadAction<string>) => {
      state.currentSubscriptionInterval = action.payload
    },
    setCurrentSubscriptionFrequency: (state, action: PayloadAction<string>) => {
      state.currentSubscriptionFrequency = action.payload
    },
    setCheckoutPrices: (state, action: PayloadAction<CheckoutPrices>) => {
      state.checkoutStandardAnnualPrice = action.payload.standard_annual_price
      state.checkoutStandardMonthlyPrice = action.payload.standard_monthly_price
      state.checkoutStudentAnnualPrice = action.payload.student_annual_price
      state.checkoutStudentMonthlyPrice = action.payload.student_monthly_price

      state.annualPlanMonthlyPrice = action.payload.standard_annual_price.unit_amount / 12 / 100
      state.studentAnnualPlanMonthlyPrice = action.payload.student_annual_price.unit_amount / 12 / 100
      state.monthlyPlanMonthlyPrice = action.payload.standard_monthly_price.unit_amount / 100
      state.studentMonthlyPlanMonthlyPrice = action.payload.student_monthly_price.unit_amount / 100
    },
    setTrialDays: (state, action: PayloadAction<number>) => {
      state.trialDays = action.payload
    },
  },
})

// Add RTK Query API
export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: kPaxtonAppApiBaseUrl(),
    prepareHeaders: async (headers) => await prepareRtkQueryHeaders(headers),
  }),
  endpoints: (builder) => ({
    validatePromoCode: builder.query<PromoCodeResponse, string>({
      query: (promoCode) => ({
        url: `${kSubscriptionValidatePromoCodeApiPath}`,
        method: 'GET',
        params: {
          promo_code: promoCode,
        },
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        const validatedResponse = PromoCodeResponseSchema.safeParse(response)
        if (!validatedResponse.success) {
          throw new Error(validatedResponse.error.message)
        }
        return validatedResponse.data as PromoCodeResponse
      },

      // Capture errors to Sentry
      transformErrorResponse: async (baseQueryReturnValue, meta, arg) => await transformRtkQueryResponse(baseQueryReturnValue, meta, arg),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data && data.success && data.promo_code) {
            dispatch(setPromoCodeResponse(data.promo_code))
          }
        } catch (error) {
          console.log('Error validating promo code', error)
        }
      },
    }),
    fetchCurrentPrice: builder.query<PriceResponse, void>({
      query: () => ({
        url: `${kSubscriptionCurrentPriceApiPath}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 60,
      transformResponse: (response) => {
        const validatedResponse = PriceResponseSchema.safeParse(response)
        if (!validatedResponse.success) {
          throw new Error(validatedResponse.error.message)
        }
        return validatedResponse.data as PriceResponse
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data && data.price && data.price.recurring) {
            dispatch(setCurrentSubscriptionFormattedPrice(formatCurrency(data.price.unit_amount)))
            dispatch(setCurrentSubscriptionInterval(data.price.recurring.interval))
            const frequency = data.price.recurring.interval === 'month' ? 'monthly' : 'annually'
            dispatch(setCurrentSubscriptionFrequency(frequency))
          }
        } catch (error) {
          console.log('Error fetching current price', error)
        }
      },
      // Capture errors to Sentry
      transformErrorResponse: async (baseQueryReturnValue, meta, arg) => await transformRtkQueryResponse(baseQueryReturnValue, meta, arg),
    }),
    fetchCheckoutPrices: builder.query<CheckoutPrices, void>({
      query: () => ({
        url: `${kSubscriptionCheckoutPricesApiPath}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 60,
      transformResponse: (response) => {
        const validatedResponse = CheckoutPricesSchema.safeParse(response)
        if (!validatedResponse.success) {
          throw new Error(validatedResponse.error.message)
        }
        return validatedResponse.data as CheckoutPrices
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setCheckoutPrices(data))
          }
        } catch (error) {
          console.log('Error fetching checkout prices', error)
        }
      },
      // Capture errors to Sentry
      transformErrorResponse: async (baseQueryReturnValue, meta, arg) => await transformRtkQueryResponse(baseQueryReturnValue, meta, arg),
    }),
    fetchTrialDays: builder.query<number, void>({
      query: () => ({
        url: `${kSubscriptionTrialDaysApiPath}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 60,
      transformResponse: (response) => {
        const validatedResponse = TrialDaysSchema.safeParse(response)
        if (!validatedResponse.success) {
          throw new Error(validatedResponse.error.message)
        }
        return validatedResponse.data.trial_days
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setTrialDays(data))
        } catch (error) {
          console.log('Error fetching trial days', error)
        }
      },
    }),
  }),
})

export const { useValidatePromoCodeQuery, useFetchCurrentPriceQuery, useFetchCheckoutPricesQuery, useFetchTrialDaysQuery } = subscriptionApi

export const {
  nullifyData,
  setBillingCycle,
  setPromoCodeResponse,
  setCurrentSubscriptionFormattedPrice,
  setCurrentSubscriptionInterval,
  setCurrentSubscriptionFrequency,
  setCheckoutPrices,
  setTrialDays,
} = subscriptionSlice.actions

export default subscriptionSlice.reducer

export const getMonthlyCost = (state: subscriptionState, billingCycle: BillingCycle, subscriptionType: SubscriptionType): number => {
  if (billingCycle === BillingCycle.ANNUAL) {
    return subscriptionType === SubscriptionType.EDUCATION ? state.studentAnnualPlanMonthlyPrice : state.annualPlanMonthlyPrice
  } else {
    return subscriptionType === SubscriptionType.EDUCATION ? state.studentMonthlyPlanMonthlyPrice : state.monthlyPlanMonthlyPrice
  }
}

export const getAnnualCostOfMonthlyPlan = (state: subscriptionState, subscriptionType: SubscriptionType): number => {
  return getMonthlyCost(state, BillingCycle.MONTHLY, subscriptionType) * 12
}

export const getAnnualPlanDiscountPercentage = (state: subscriptionState, subscriptionType: SubscriptionType): number => {
  const originalPrice = getAnnualCostOfMonthlyPlan(state, subscriptionType)
  const discountedPrice = (subscriptionType === SubscriptionType.EDUCATION ? state.studentAnnualPlanMonthlyPrice : state.annualPlanMonthlyPrice) * 12

  return Math.round(((originalPrice - discountedPrice) / originalPrice) * 100)
}

export const getAmountDue = (state: subscriptionState, billingCycle: BillingCycle, subscriptionType: SubscriptionType, promotion: Promotion | null): number => {
  const monthlyCost = getMonthlyCost(state, billingCycle, subscriptionType)
  const discount = getDiscountForAmountDue(promotion, monthlyCost, billingCycle)
  const nonDiscountedAmountDue = billingCycle === BillingCycle.ANNUAL ? monthlyCost * 12 : monthlyCost
  const amountDue = Math.max(0, nonDiscountedAmountDue - discount)
  if (amountDue < 0) {
    return 0
  }
  return amountDue
}
