import { kChatV2ApiSuggestionsBasePath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { store } from '@/store/store'
import { getAuth } from 'firebase/auth'
import {
  addClientErrorMessageBubble,
  addSuggstedQuestionsMessageBubble,
  chatV2SetConversationLoadingStatus,
  updateSuggstedQuestionsMessageBubble,
} from '../store/chat-v2.slice'
import { z } from 'zod'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { k429RateLimitError, kAnonymousUserLimitMessage429 } from '@/constants/constants-strings'
import { AuthDialogType, openAuthDialog } from '@/store/slices/ui-state.slice'

export const ChatV2SuggestedQuestionsResponseSchema = z.object({
  suggestions: z.array(
    z.object({
      suggestion_number: z.number(),
      suggestion: z.string(),
    })
  ),
})
export type ChatV2SuggestedQuestions = z.infer<typeof ChatV2SuggestedQuestionsResponseSchema>

export async function fetchConversationFollowUpQuestions(conversationId: string, feature: string) {
  const suggestedQuestionsMessageId = nanoid()
  const sentry_transaction_id = nanoid()

  try {
    // Create a new bubble for the suggested questions
    store.dispatch(
      addSuggstedQuestionsMessageBubble({
        conversationId: conversationId,
        newMessageId: suggestedQuestionsMessageId,
        loading: true,
      })
    )

    // Set loading status
    store.dispatch(chatV2SetConversationLoadingStatus({ conversationId: conversationId, isLoading: true }))

    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw Error(kErrorCodeAuthError)

    // Construct body params
    const body = {
      conversation_id: conversationId,
      feature_name: feature,
    }

    // Construct request
    const apiUrl = kPaxtonAppApiBaseUrl() + kChatV2ApiSuggestionsBasePath
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    }

    // console.log(`Querying ${apiUrl} with ${JSON.stringify(requestOptions, null, 2)}`)
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // Handle error response
    if (!response.ok) {
      store.dispatch(
        addClientErrorMessageBubble({
          conversationId: conversationId,
          errorMessage: response.status == 429 ? k429RateLimitError : 'Unable to retrieve suggested follow up questions.',
        })
      )

      // If 429 error and user is anon, show dialog
      if (response.status == 429 && getAuth().currentUser?.isAnonymous) {
        store.dispatch(
          openAuthDialog({
            authDialogType: AuthDialogType.SIGN_UP,
            tooltipMessage: kAnonymousUserLimitMessage429,
          })
        )
      }

      // Don't capture for 429
      if (response.status != 429) {
        // Capture to sentry
        Sentry.withScope((scope) => {
          scope.setTags({ transaction_id: sentry_transaction_id })

          Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`), {
            extra: { requestUrl: apiUrl, requestOptions: requestOptions, response: data, onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
          })
        })
      }
      return
    }

    // Validate response
    const responseParsed = ChatV2SuggestedQuestionsResponseSchema.safeParse(data)
    if (!responseParsed.success) {
      console.error('fetchConversationFollowUpQuestions response data not valid', requestOptions, responseParsed.error)
      store.dispatch(
        addClientErrorMessageBubble({
          conversationId: conversationId,
          errorMessage: 'Unable to retrieve suggested follow up questions.',
        })
      )
      return
    }

    // Dispatch response
    store.dispatch(
      updateSuggstedQuestionsMessageBubble({
        conversationId: conversationId,
        messageId: suggestedQuestionsMessageId,
        suggestedQuestions: responseParsed.data,
      })
    )
  } catch (e) {
    console.error('Error fetching follow up questions: ', e)

    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })

    store.dispatch(
      updateSuggstedQuestionsMessageBubble({
        conversationId: conversationId,
        messageId: suggestedQuestionsMessageId,
        isError: true,
      })
    )
  } finally {
    // Set loading status
    store.dispatch(chatV2SetConversationLoadingStatus({ conversationId: conversationId, isLoading: false }))

    // Set the suggested questions message to loading false and error to true
    store.dispatch(
      updateSuggstedQuestionsMessageBubble({
        conversationId: conversationId,
        messageId: suggestedQuestionsMessageId,
        loading: false,
      })
    )
  }
}
