import { useEffect, useState } from 'react'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { footnoteAnchorIdFromIndex } from '../chat/components-shared/ResponseReferencesWrapper'
import CopySearchStringButton from './CopySearchStringButton'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import rehypeTagNameChange from '@/util/rehype-custom-plugins/rehype-tag-swap'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rehypeWrap from 'rehype-wrap-all'
import { rehypeCustomFootnotes } from '@/util/rehype-custom-plugins/rehype-custom-footnotes'

/**
 * THIS IS A CLONE OF THE CHAT RESPONSE COMPONENT
 */

type BooleanBuilderResponseProps = {
  queryTitle: string
  responseString: string
  footnoteClicked: (index: number) => void
}

const BlinkingCursor = (props: { blink: boolean }) => {
  return <span className={`${props.blink ? 'animate-cursor-blink' : ''} inline-block align-text-bottom w-2 h-4 bg-sky-800`}></span>
}

export default function BooleanBuilderResponse(props: BooleanBuilderResponseProps) {
  const { queryTitle, responseString, footnoteClicked } = props
  const { streamLoading } = useAppSelector((state: RootState) => state.booleanBuilderApiResponse)

  // Local State
  const [matchedSearchString, setMatchedSearchString] = useState<string | null>(null)
  // Copy search string regex and search string value
  const searchStringRegex = /Search String:\s*\n([\s\S]*?)\nExplanation:/
  const searchStringMatch = responseString.match(searchStringRegex)

  const shouldBlink = () => {
    console.log('calculating should blink')
    if ((responseString == null || responseString == '') && streamLoading == true) {
      return true
    } else if (responseString != null && responseString != '' && streamLoading == true) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (searchStringMatch != null) {
      setMatchedSearchString(searchStringMatch[1].trim())
    } else {
      setMatchedSearchString(null)
    }
  }, [searchStringMatch])

  if ((responseString == null || responseString == '') && streamLoading == false) return null

  function FormmatedAndFootnoteLinkedResponseMD() {
    return (
      <div>
        <Markdown
          className={'markdown-bubble-container'}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[
            rehypeRaw,
            rehypeCustomFootnotes,
            [rehypeWrap, { selector: 'table', wrapper: 'div.source-html-table-wrapper' }],
            rehypeCustomFootnotes,
            [
              // Swap pre and code tags with div tags to fix markdown rendering issues
              rehypeTagNameChange,
              [
                { tagName: 'pre', newTagName: 'div' },
                { tagName: 'code', newTagName: 'div' },
              ],
            ],
          ]}
          components={{
            // This span override is used to render the footnote span as a ChatFootnotePopover component
            // This component is used to render a clickable button that triggers a popover
            // Markdown is unable to accept react components so we must manually place our ChatFootnotePopover component
            // The replacement is done by checking if the span has a data attribute 'data-footnote'
            // Spans with the 'data-footnote' attribute come from the rehypeCustomFootnotes plugin
            // Go to src/chat-common/util/chat-remark-footnote.tsx to see how the plugin works

            span: ({ node, ...props }) => {
              if ('data-footnote' in props) {
                const footnoteIndex = parseInt(props['data-footnote'] as string, 10) // Parse the footnoteIndex as an integer
                const anchorLinkId = footnoteAnchorIdFromIndex(footnoteIndex)
                return (
                  <button
                    key={footnoteIndex}
                    onClick={() => {
                      footnoteClicked(footnoteIndex)
                      const scrollToElement = document.getElementById(anchorLinkId)
                      scrollToJustAbove(scrollToElement)
                    }}
                    className="text-sky-600 hover:text-sky-800 px-[2px]"
                  >
                    {`[${footnoteIndex}]`}
                  </button>
                )
              }

              // Render regular spans as usual if they don't have the footnote data attribute
              return <span {...props}>{props.children}</span>
            },
          }}
        >
          {responseString}
        </Markdown>
      </div>
    )
  }

  // Scroll to just above the element
  function scrollToJustAbove(element: HTMLElement | null, margin = 75) {
    if (element == null) return

    const dims = element.getBoundingClientRect()
    window.scrollTo(window.scrollX, dims.top - margin)
  }

  // Render the output
  return (
    <>
      <CopySearchStringButton matchedSearchString={matchedSearchString} />
      <h3 className="text-base font-semibold leading-6 text-gray-900">{queryTitle}</h3>
      <div id="chat-response-bubble" className="text-sm text-gray-500">
        <FormmatedAndFootnoteLinkedResponseMD />
        <BlinkingCursor blink={shouldBlink()} />
      </div>
    </>
  )
}
