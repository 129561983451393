import { useEffect, useRef } from 'react'

/**
 * Use Title
 * This custom hook sets the title of the document.
 * It stores the default title in a ref and restores it on unmount.
 * @param title
 * @param restoreOnUnmount
 */
export function useTitle(title: string, restoreOnUnmount = true) {
  const defaultTitle = useRef(document.title) // Store original title

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(() => {
    return () => {
      if (restoreOnUnmount) {
        document.title = defaultTitle.current // Restore on unmount
      }
    }
  }, [restoreOnUnmount])
}
