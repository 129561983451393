import { useEffect } from 'react'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rehypeWrap from 'rehype-wrap-all'
import { kDocumentEditingAllowedTags } from '@/constants/constants-ui'

import AnnotationToggleButton from './components/AnnotationToggleButton'
import { annotateTheMarkdown } from '../service/markdown-formatting'
import { DocumentEditingAnnotationType } from '../schema/document-editing-websocket.schema'
import rehypeTagNameChange from '@/util/rehype-custom-plugins/rehype-tag-swap'

type DocumentEditingRenderDocumentProps = {
  markdownString: string
  annotations: DocumentEditingAnnotationType[]
  loading: boolean
  documentLoading: boolean
  error: boolean
  currentAnnotationIndex: number
}

export default function DocumentEditingRenderDocument(props: DocumentEditingRenderDocumentProps) {
  const { markdownString, annotations, loading, error, currentAnnotationIndex } = props

  // Scroll to the current annotation index
  // Re-run when
  //  1. the currentAnnotationIndex changes
  //  2. when the annotations are available
  useEffect(() => {
    if (annotations.length == 0 || markdownString == null) return

    // Find the annotation element, if exists, scroll to it
    const elements = document.getElementsByClassName(`annotation-selector-${currentAnnotationIndex}`)
    if (elements.length > 0) {
      const firstElement = elements[0]

      firstElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [currentAnnotationIndex, annotations, markdownString])

  // Annotate the markdown if we have it, otherwise show the provided markdown
  const annotated = annotations == null ? markdownString : annotateTheMarkdown(annotations, markdownString)
  // console.log('Original Text: ', markdownString)
  // console.log('Annotated Text: ', annotated)

  // ===============================================================>
  // RENDERING
  // Render document with react-markdown
  //
  // Supports rehypePlugin to transform HTML: https://github.com/rehypejs/rehype/blob/main/doc/plugins.md#list-of-plugins
  // Supports remarkPlugin to transform markdown: https://github.com/remarkjs/remark/blob/main/doc/plugins.md#list-of-plugins
  //
  // SANITIZATION:
  // - remarkPlugin remark-gfm allows for GitHub Flavored Markdown (GFM) support (tables, task lists, strikethrough, etc.)
  //
  // - rehypePlugin rehype-wrap-all allows us to wrap tables with <div> tags to fix overflow styles (works same as wrapTablesWithDiv function)
  // - rehypePlugin rehype-raw allows HTML injected into markdown (for styling and annotations)
  // - rehypePlugin rehypeTagNameChange (custom in this repo) allows us to swap tags to fix markdown rendering issues with opinionated formatting tags
  //
  // - allowedElements sanitizes the HTML with allowedElements from: kDocumentEditingAllowedTags
  // - components allows us to replace <html> elements with React components
  return (
    <div className={'grow flex flex-col items-center justify-center'}>
      {annotations.length == 0 && loading && (
        <div className={'font-bold center w-full flex items-center justify-center py-3'}>
          Generating annotations... <CircularProgressContinuous />
        </div>
      )}
      {error && !loading && (
        <div className={'font-bold center w-full flex items-center justify-center py-3 text-red-700'}>There was an error analyzing this document.</div>
      )}
      {annotations.length == 0 && !loading && (
        <div className={'font-bold center w-full flex items-center justify-center py-3 text-red-700'}>No annotations available.</div>
      )}
      <div id="document-editing-md-render" className={'md:mx-2 py-5 px-2 md:px-5 bg-white shadow'}>
        <div id="document-editing-document-content">
          <Markdown
            className={'max-w-3xl'}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[
              rehypeRaw,
              [rehypeWrap, { selector: 'table', wrapper: 'div.source-html-table-wrapper' }],
              [
                // Swap pre and code tags with div tags to fix markdown rendering issues
                rehypeTagNameChange,
                [
                  { tagName: 'pre', newTagName: 'div' },
                  { tagName: 'code', newTagName: 'div' },
                ],
              ],
            ]}
            allowedElements={kDocumentEditingAllowedTags}
            components={{ button: AnnotationToggleButton }}
          >
            {annotated}
          </Markdown>
        </div>
      </div>
    </div>
  )
}
