import * as Sentry from '@sentry/browser'
import { onAuthStateChanged, getAuth, User, AuthCredential, linkWithCredential, onIdTokenChanged } from 'firebase/auth'
import { createContext, ReactNode, useState, useEffect } from 'react'
import firebase_app from '../firebase/firebase-config'
import FullPageLoader from '@/components/loaders/FullPageLoader'
import { analyticsIdentifyUser } from '@/analytics/utils/identify-user'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { kSegmentTrackSignUp } from '@/constants/constants-segment'
import { FirebaseError } from 'firebase/app'
import { UserAccountData } from '@/firebase/auth/user-account-data'
import ErrorComponent from '@/components/error/error-component'
import createStripeCustomer from '@/chat-common/fetch/fetch-create-customer'
import { BrandEnum, InferredPollingState } from '@/firebase/auth/auth-jwt-schema'
import { fetchOrgLogoAsUrl } from '@/organizations/services/org-logo-services'
import { Userpilot } from 'userpilot'

import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

// Create an auth instance
const auth = getAuth(firebase_app)

// AuthContext type
type AuthContextType = {
  user: User | null
  updated: string | null
  logoUrl: string
  linkCredential: (credential: AuthCredential) => Promise<void>
  userAccountData: UserAccountData | null
  refreshUserAccountData: () => Promise<void>
  setRunJWTRefreshPolled: (value: boolean) => void
}

// Default context values
const defaultAuthContextValues: AuthContextType = {
  user: null,
  updated: null,
  logoUrl: brandLogoUrl,
  linkCredential: async () => {
    return
  },
  userAccountData: null,
  refreshUserAccountData: async () => {
    return
  },
  setRunJWTRefreshPolled: () => {
    return
  },
}

export const AuthContext = createContext<AuthContextType>(defaultAuthContextValues)

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null)
  const [updated, setUpdated] = useState<string | null>(null)
  const [logoUrl, setLogoUrl] = useState<string>(brandLogoUrl)
  const [loading, setLoading] = useState(true) // Always start as loading
  const [pollingStarted, setPollingStarted] = useState(false)
  const [paymentPollingStarted, setPaymentPollingStarted] = useState(false)
  const [authContextError, setAuthContextError] = useState<string | null>(null)
  const [userAccountData, setUserAccountData] = useState<UserAccountData | null>(null)
  const { trackEvent } = useAnalytics()
  const [runJWTRefreshPolled, setRunJWTRefreshPolled] = useState(false)

  // INITIAL LOAD
  // *** MUST RUN ALL CRITICAL NEW USER AUTH PROCESSES ***
  // Update the user object when the auth state changes (login / logout / user change)
  // DOES NOT RUN on linkWithCredential
  useEffect(() => {
    setAuthContextError(null)

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user == null) {
        console.log('Cleaning up after logout...')

        // State updates
        setUser(null)
        setUserAccountData(null)
        setLoading(false)
        return
      }

      // Sign out legacy tenant users
      if (user.tenantId) {
        console.log('Tenant user detected, forcing sign out for migration to new org system...')
        await auth.signOut()
        auth.tenantId = null
        return
      }

      let brand = hostBrand

      // Initialize the user subscription data
      try {
        const userAccountData = await UserAccountData.create(user)
        if (!userAccountData) {
          console.error('Error constructing user account data for user: ', user)
          setAuthContextError('Unable to initialize your account information. Please try again.')
          return
        }

        // Fetch brand
        brand = userAccountData.brand ?? hostBrand

        // Fetch the org logo
        const orgId = userAccountData.legacyDriveCompatibleOrganizationId
        const logoUrl = await fetchOrgLogoAsUrl(orgId, brand)

        // Update state
        setUserAccountData(userAccountData)
        setLogoUrl(logoUrl)
      } catch (e) {
        console.error('Error constructing user account data', e)
        setAuthContextError('Unable to initialize your account information. Please try again.')
      }

      // Update the user state
      setUser(user)

      // Set updated (force re-render)
      setUpdated(Date.now().toString())

      // Send identification signals
      analyticsIdentifyUser(user, brand)

      // Set loading
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  // ID TOKEN REFRESH TRIGGER
  // When the user id token is refreshed, ensure we update the auth context for all listeners
  useEffect(() => {
    const unsubscribeTokenRefresh = onIdTokenChanged(auth, async (user) => {
      if (user) {
        // Update the user state
        setUser(user)

        Userpilot.identify(user.uid, {
          email: user.email,
        })

        // Set updated (force re-render)
        setUpdated(Date.now().toString())
      }
    })

    return () => unsubscribeTokenRefresh()
  }, [])

  // UPDATED TRIGGER
  // When Auth State Updated
  // Check for and poll for pending subscription
  useEffect(() => {
    if (!user) return
    if (!userAccountData) return

    if (!pollingStarted && userAccountData.inferredPollingState == InferredPollingState.POLL_SUBSCRIPTION) {
      pollWhilePendingSubscription(user, userAccountData)
    } else if (runJWTRefreshPolled || (!paymentPollingStarted && userAccountData.inferredPollingState == InferredPollingState.POLL_PAYMENT)) {
      pollWhilePendingPayment(user, userAccountData)
    }
  }, [user, userAccountData, updated, pollingStarted])

  /**
   * Refresh User Account and Account Data
   * This function can be manually triggered from anywhere that has access to the AuthContext
   * in order to refresh the user's Firebase account (JWT) and the user account data object.
   * @returns
   */
  const refreshUserAccountData = async () => {
    if (!user) return
    if (!userAccountData) return

    // Get the current user
    const currentUser = auth.currentUser
    if (currentUser == null) throw new Error('Cannot refresh user account data for a null user.')

    // Refresh the Firebase user and their JWT
    await currentUser.reload()

    const refreshedUserAccountData = await UserAccountData.create(currentUser)
    setUserAccountData(refreshedUserAccountData)
    setUser(currentUser)
  }

  // Link credential
  // *** MUST RUN ALL CRITICAL NEW USER AUTH PROCESSES ***
  // Link a credential to the current anonymous user
  // Converts an anonymous firebase account to a permanent account, maintaining the existing user id
  const linkCredential = async (credential: AuthCredential) => {
    setAuthContextError(null)

    const currentUser = auth.currentUser
    if (currentUser == null) throw new Error(`Cannot convert ${credential.signInMethod} from a null user.`)

    console.log('Linking credential of type:', credential.signInMethod)

    try {
      const linkedUserCredential = await linkWithCredential(currentUser, credential)
      // console.log('Linked user credential: ', linkedUserCredential)

      let brand = hostBrand

      // Initialize the user subscription data
      try {
        const userAccountData = await UserAccountData.create(linkedUserCredential.user)
        if (!userAccountData) {
          console.error('Error constructing user account data for user: ', linkedUserCredential.user)
          setAuthContextError('Unable to initialize your account information. Please try again.')
          return
        }

        trackEvent(AnalyticsEvent.SignUp)

        // Fetch brand
        brand = userAccountData.brand ?? hostBrand

        // Fetch the org logo
        const orgId = userAccountData.legacyDriveCompatibleOrganizationId
        const logoUrl = await fetchOrgLogoAsUrl(orgId, brand)

        // Update state
        setUserAccountData(userAccountData)
        setLogoUrl(logoUrl)
      } catch (e) {
        console.error('Error constructing user account data: ', e)
        setAuthContextError('Unable to initialize your account information. Please try again.')
      }

      // Update the user
      setUser(linkedUserCredential.user)

      // Set updated (force re-render)
      setUpdated(Date.now().toString())

      // Send identification signals
      analyticsIdentifyUser(linkedUserCredential.user, brand)

      // Report ad conversion

      // track sign up
      analytics.track(kSegmentTrackSignUp, {
        method: credential.signInMethod,
      })
    } catch (e) {
      if (e instanceof FirebaseError) {
        // Email already registered, this is handled gracefully in the UX, throw to calling ux function
        if (e.code == 'auth/email-already-in-use' && credential.signInMethod == 'password') {
          throw e
        }
      }

      // Other error, should record to sentry
      console.error(`Error linking credential. Method: ${credential.signInMethod}. Credential: ${JSON.stringify(credential.toJSON(), null, 2)}: `, e)
      throw e
    }
  }

  const refreshUserAccountDataForPayment = async (startTime: number, user: User): Promise<void> => {
    // 10 minutes timeout
    if (Date.now() - startTime > 600000) {
      console.warn('Subscription polling exceeded 10 minutes...')
      Sentry.captureException(new Error(`Subscription polling exceeded 10 minutes.`), {
        extra: {
          orgData: JSON.stringify(userAccountData?.orgData ?? 'not available'),
          subscriptionStatus: userAccountData?.subscriptionStatus,
          pollingState: userAccountData?.inferredPollingState,
        },
      })
      return
    }

    // Check if the user has logged out or is anonymous
    if (auth.currentUser === null || auth.currentUser.isAnonymous) {
      console.log('User has logged out or is anonymous, stopping polling.')
      setPaymentPollingStarted(false)
      return
    }

    try {
      console.log('Polling for update to pending payment...')
      const refreshedSubscriptionData = await UserAccountData.create(user)
      // If still a pending subscription, try again
      if (refreshedSubscriptionData?.pendingPaymentMethod) {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        return refreshUserAccountDataForPayment(startTime, user)
      }

      // Subscription is valid
      console.log('Subscription payment no longer pending: ', refreshedSubscriptionData?.subscriptionStatus)

      // Refresh the user object JWT and reload the user
      await user.getIdToken(true)
      await user.reload()

      // Update the user and subscription state, and trigger the update
      setUser(user)
      setUserAccountData(refreshedSubscriptionData)
      setUpdated(Date.now().toString())

      return
    } catch (e) {
      console.error('Error initializing user subscription data: ', e)
      setAuthContextError('Unable to initialize your account information. Please try again.')
    }
  }

  const refreshUserAccountDataForSubscription = async (startTime: number, user: User): Promise<void> => {
    console.log('Starting refreshUserAccountDataForSubscription...')

    // 12 seconds timeout
    if (Date.now() - startTime > 20000) {
      console.error('Subscription polling exceeded 20 seconds, ', userAccountData)
      setAuthContextError('Unable to initialize your account information. Please try again.')
      return
    }

    try {
      console.log('Polling for update to pending subscription...')
      const refreshedSubscriptionData = await UserAccountData.create(user)

      // If still a pending subscription, try again
      if (refreshedSubscriptionData?.pendingSubscription) {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        return refreshUserAccountDataForSubscription(startTime, user)
      }

      // Subscription is valid
      console.log('Subscription no longer pending: ', refreshedSubscriptionData?.subscriptionStatus)

      // Refresh the user object JWT and reload the user
      await user.getIdToken(true)
      await user.reload()

      // Update the user and subscription state, and trigger the update
      setUser(user)
      setUserAccountData(refreshedSubscriptionData)
      setUpdated(Date.now().toString())

      return
    } catch (e) {
      console.error('Error initializing user subscription data: ', e)
      setAuthContextError('Unable to initialize your account information. Please try again.')
    }
  }

  // Poll While Pending Payment (userAccountData?.pendingPaymentMethod)
  // Updates state whenever the subscription status changes to non-pending
  // Throws an error if polling for more than 20 seconds
  const pollWhilePendingPayment = async (user: User, userAccountData: UserAccountData) => {
    // Not needed for anon users or users not yet email verified (that guard will block them)
    if (user.isAnonymous || userAccountData.isAnonymous) {
      return
    }

    // If the subscription is valid, we don't need to poll, return early
    if (userAccountData?.validSubscription || userAccountData?.pendingSubscription) {
      return
    }

    // If user subscription is not pending, we don't need to poll, return early
    if (userAccountData?.pendingPaymentMethod == false) {
      return
    }

    // Set the polling started flag
    setPaymentPollingStarted(true)

    // Recursive function that continues to refresh a new UserAccountDataData object until the user has a valid subscription
    const startTime = Date.now()

    // Start the polling
    refreshUserAccountDataForPayment(startTime, user)
  }

  // Poll While Pending Subscription (userAccountData?.pendingSubscription)
  // Updates state whenever the subscription status changes to non-pending
  // Throws an error if polling for more than 10 seconds
  const pollWhilePendingSubscription = async (user: User, userAccountData: UserAccountData) => {
    // Not needed for anon users or users not yet email verified (that guard will block them)
    if (user.isAnonymous || userAccountData.isAnonymous) {
      return
    }

    // If the subscription is valid, we don't need to poll, return early
    if (userAccountData?.validSubscription) {
      return
    }

    // If user subscription is not pending, we don't need to poll, return early
    if (userAccountData?.pendingSubscription == false) {
      return
    }

    // Set the polling started flag
    setPollingStarted(true)

    // Request customer + subscription setup
    // Pending subscription means the user has not yet completed the subscription setup
    if (hostBrand !== BrandEnum.HAIKU) {
      try {
        await createStripeCustomer()
        await new Promise((resolve) => setTimeout(resolve, 3000))
      } catch (e) {
        console.error('Error creating customer: ', e)
        setAuthContextError('Unable to initialize your account information. Please try again.')
      }
    }

    // Recursive function that continues to refresh a new UserAccountDataData object until the user has a valid subscription
    const startTime = Date.now()

    // Start the polling
    refreshUserAccountDataForSubscription(startTime, user)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        updated,
        logoUrl,
        linkCredential,
        userAccountData,
        refreshUserAccountData,
        setRunJWTRefreshPolled,
      }}
    >
      {/* Handle authentication errors */}
      {authContextError && (
        <ErrorComponent
          code="500"
          title="Authentication Error"
          message={'There was an error authenticating with Paxton. Support has been notified.'}
          showRefreshButton={true}
        />
      )}

      {/* Show full page loader while loading, or the subscription is pending otherwise show children */}
      {!authContextError && loading && <FullPageLoader />}

      {/* Otherwise, return the children */}
      {!authContextError && !loading && children}
    </AuthContext.Provider>
  )
}
