import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { useContext, useState } from 'react'
import * as EmailValidator from 'email-validator'
import { useSendInviteMutation } from '../apis/org-admin.api'
import { SendInviteBody } from '../schema/invite.schema'
import { AuthContext } from '@/context/auth-context'

type InviteMemberDialogProps = {
  visible: boolean
  onInviteSuccess: () => void
  onClose: () => void
}

/**
 * The InviteMemberDialog handles fetching and displaying of the Stripe portal Link.
 * This is to decouple the logic from the buttons that may activate this UI.
 */
export default function InviteMemberDialog(props: InviteMemberDialogProps) {
  const { visible, onInviteSuccess, onClose } = props

  // Local state
  const [error, setError] = useState<string | null>(null)
  const [emailInput, setEmailInput] = useState<string>('')

  // RTK-Query Mutation
  const [sendInvite, { isLoading, error: sendInviteError }] = useSendInviteMutation()

  // User Context
  const { userAccountData } = useContext(AuthContext)
  const organizationId = userAccountData?.orgData?.id

  // Handle submit
  async function handleSubmit() {
    if (!isLoading) {
      setError(null)

      // Validate the email address
      if (!EmailValidator.validate(emailInput)) {
        setError('Please enter a valid email address.')
        return
      }

      try {
        // Send the invite
        const inviteArgs: SendInviteBody = {
          invite: {
            organization_id: organizationId || '',
            invitee_email: emailInput,
          },
        }
        console.log('Inviting user args:', inviteArgs)

        const result = await sendInvite(inviteArgs)
        console.log('Inviting user result:', result)

        // Clear
        setEmailInput('')

        // Close the dialog
        onInviteSuccess()
        onClose()
      } catch (error) {
        console.error('Send invite error: ', error)
        console.error('Send invite error (RTK-Query): ', sendInviteError)
        setError('TODO: Show appropriate error based on problem.')
      }
    }
  }

  // Handle close
  function handleClose() {
    setError(null)
    setEmailInput('')
    onClose()
  }

  // Do not show if not visible
  if (!visible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={handleClose}></div>

      {/* Dialog Content */}
      <div className="bg-white p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px] max-w-2xl sm:min-w-[600px]">
        <h2 className="mb-2 text-lg font-semibold flex gap-x-2 items-center">
          <PaperAirplaneIcon className="h-10 p-2 rounded-full text-sky-700 bg-sky-100" />
          Invite member
        </h2>
        <input
          className={`w-full rounded-md focus:ring-sky-700`}
          autoFocus={true}
          placeholder="Email address"
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />
        {error && <div className={'mt-2 text-left text-red-700 text-sm'}>{error}</div>}
        <div className={'h-2'} />
        <div className={'flex gap-x-4 justify-end items-center'}>
          <button
            className="inline-flex justify-center rounded-md bg-white px-5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            className="flex items-center gap-x-2 justify-center rounded-md bg-sky-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500"
            onClick={handleSubmit}
          >
            <div className={'flex items-center gap-x-1'}>
              <div>Send invitation</div>
              <ButtonLoader loading={isLoading} />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}
