import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition, Tab } from '@headlessui/react'
import { ChatV2Conversation } from '@/chat-common/store/chat-v2.slice'
import { CourtsResponse } from '@/store/apis/hosted-filters-api'
import ResearchDialogFederalCourtSelection from './research-dialog-federal-court-selection'
import { useAppDispatch } from '@/store/store-hooks'
import ResearchDialogStateSelection from './research-dialog-state-selection'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import IncludeFederalContentPopover from './include-federal-content-popover'
import { inferSelectedStateFromCourtIds } from './research-source-maps'
import {
  addAllFederalCourtsAndLRR,
  addAllRelatedFederalCourtsAndLRRByState,
  removeAllFederalCourtsAndLRR,
  removeAllStateCourtsAndLRR,
} from './research-source-utils'

type ResearchDialogProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversation: ChatV2Conversation
  courts: CourtsResponse
}

enum TabType {
  STATE = 'state',
  FEDERAL = 'federal',
}

export default function ResearchDialog(props: ResearchDialogProps) {
  const { open, onClose, conversation, courts } = props

  // Store
  const dispatch = useAppDispatch()

  const selectedFederalCourts = conversation.currentSource?.federal_court_ids ?? []
  const selectedStateCourts = conversation.currentSource?.state_court_ids ?? []
  const selectedState = inferSelectedStateFromCourtIds(selectedStateCourts)

  const areFederalCourtsSelected = selectedFederalCourts?.length > 0
  const [includeFederal, setIncludeFederal] = useState(areFederalCourtsSelected)

  // Tab styles
  const inactiveTabStyles =
    'grow border-gray-200 text-gray-500 hover:border-gray-300 hover:text-gray-700 w-1/4 border-b-2 pb-3 px-1 text-center text-sm lg:text-base font-medium'
  const activeTabStyles = 'grow border-sky-500 text-sky-600 w-1/4 border-b-2 pb-3 px-1 text-center text-sm lg:text-base font-medium'

  // Done button styles
  const primaryButtonStyles =
    'inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-3'

  // Select all and clear button styles
  const secondaryButtonStyles =
    'mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0'

  // Toggle all federal sources
  function toggleAllFederalSources(add: boolean) {
    if (add) {
      addAllFederalCourtsAndLRR(dispatch, conversation.id, courts)
    } else {
      removeAllFederalCourtsAndLRR(dispatch, conversation.id)
    }
  }

  // Handle include federal change
  function handleIncludeFederalChange() {
    const updatedIncludeFederal = !includeFederal
    setIncludeFederal(updatedIncludeFederal)

    if (updatedIncludeFederal) {
      if (selectedState) {
        addAllRelatedFederalCourtsAndLRRByState(dispatch, conversation.id, selectedState)
      }
    } else {
      removeAllFederalCourtsAndLRR(dispatch, conversation.id)
    }
  }

  // Handle clear state selections
  function handleClearStateSelections() {
    removeAllStateCourtsAndLRR(dispatch, conversation.id)
  }

  /*
   * When there is a state selected, and the federal court selections are being modified,
   * this checks if there are selected federal courts and sets includeFederal accordingly.
   */
  useEffect(() => {
    if (selectedState) {
      // There are selected federal courts
      if (selectedFederalCourts?.length > 0) {
        setIncludeFederal(true)
      } else {
        // There are no selected federal courts
        setIncludeFederal(false)
      }
    }
  }, [selectedState, selectedFederalCourts, selectedFederalCourts?.length])

  // Render action buttons based on tab type
  function renderActionButtons(tabType: TabType) {
    return (
      <div className="mt-2 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
        <button type="button" className={primaryButtonStyles} onClick={() => onClose(false)}>
          Done
        </button>
        {tabType === TabType.FEDERAL && (
          <>
            <button type="button" className={secondaryButtonStyles + ' sm:col-start-2'} onClick={() => toggleAllFederalSources(true)}>
              Select All
            </button>
            <button type="button" className={secondaryButtonStyles + ' sm:col-start-1'} onClick={() => toggleAllFederalSources(false)}>
              Clear
            </button>
          </>
        )}
        {tabType === TabType.STATE && (
          <button type="button" className={secondaryButtonStyles + ' sm:col-start-1'} onClick={handleClearStateSelections}>
            Clear
          </button>
        )}
      </div>
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto sm:px-4 lg:ml-56">
          <div className="flex items-end justify-center px-2 py-5 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div>
                  <Tab.Group defaultIndex={conversation.currentSource?.selected_federal_courts?.length > 1 ? 1 : 0}>
                    <Tab.List className="flex justify-between">
                      <Tab className={({ selected }) => (selected ? activeTabStyles : inactiveTabStyles)}>State</Tab>
                      <Tab className={({ selected }) => (selected ? activeTabStyles : inactiveTabStyles)}>Federal</Tab>
                    </Tab.List>
                    <div className="mb-0 pb-0">
                      <div className="rounded-lg bg-yellow-50 p-2 pr-4 mt-2 flex items-center w-full">
                        <div className="flex-shrink-0 flex h-7 w-7 text-yellow-700 bg-yellow-50 rounded-lg p-1 ring-4 ring-white items-center justify-center">
                          <InformationCircleIcon />
                        </div>
                        <span className="ml-2 text-xs text-yellow-900 text-opacity-70">
                          Select sources from the State Tab, the Federal tab, or both. Results will include case law, statutory, and regulatory authority.
                        </span>
                      </div>
                    </div>
                    <Tab.Panels>
                      <Tab.Panel>
                        <div className="flex items-center my-2 ml-1">
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600"
                            id="includeFederal"
                            name="includeFederal"
                            value="true"
                            checked={includeFederal}
                            onChange={handleIncludeFederalChange}
                          />
                          <label htmlFor="includeFederal" className="pl-2 text-sm text-gray-500">
                            Include related federal content
                          </label>
                          <IncludeFederalContentPopover />
                        </div>
                        <div className="px-1 mt-2 overflow-y-scroll max-h-96 sm:max-h-[600px]">
                          <ResearchDialogStateSelection
                            conversation={conversation}
                            states={Object.keys(courts.State)}
                            selectedState={selectedState}
                            includeFederal={includeFederal}
                          />
                        </div>
                        {renderActionButtons(TabType.STATE)}
                      </Tab.Panel>
                      <Tab.Panel>
                        <div className="px-1 mt-2 overflow-y-scroll max-h-96 sm:max-h-[600px]">
                          <ResearchDialogFederalCourtSelection
                            conversation={conversation}
                            courts={courts}
                            selectedCourts={selectedFederalCourts}
                            checkboxClassName="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                          />
                        </div>
                        {renderActionButtons(TabType.FEDERAL)}
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
