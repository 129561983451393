import { ChatV2Message } from '../store/chat-v2.slice'

/**
 * Get References In Message
 */
export default function getUniqueReferenceNumbersInMessage(message: ChatV2Message): string[] {
  const trimmedResponse = message.text.trim()

  // // TEST: insert hallucinated footnotes to the middle and end
  // trimmedResponse =
  //   trimmedResponse.slice(0, 30) +
  //   '. This is a hallucinated footnote [99] containing sentence injected [22] into the middle of the message. [88][87][86]. ' +
  //   trimmedResponse.slice(30) +
  //   ' More hallucinated text footnotes [55][57][56]. '
  // console.log('trimmedResponse: ', trimmedResponse)

  // Regex used to get footnotes
  const footnoteRegex = /\[(\d+)\]/g

  // Hold matches in a set to avoid duplicates
  const matches = new Set<string>()

  // Find all matches
  let match
  while ((match = footnoteRegex.exec(trimmedResponse)) !== null) {
    // Add the first captured group (the digits) to the Set
    matches.add(match[1])
  }

  // Convert the Set to an array
  return Array.from(matches)
}
