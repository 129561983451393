import { BillingCycle } from '@/firebase/auth/auth-jwt-schema'
import { Promotion } from '@/routes/dashboard/subscribe/schema/promo-code.schema.ts'

export const annualBillingMessage = `billed annually. Save {discountPercentage}% over monthly billing.`
export const monthlyBillingMessage = `billed monthly (save {discountPercentage}% with annual billing).`

// create an enum for coupon duration forever, once, repeating
enum CouponDuration {
  FOREVER = 'forever',
  ONCE = 'once',
  REPEATING = 'repeating',
}

/**
 * Get Discount From Promotion
 * @param promotion the promotion to apply
 * @param monthlyCost the monthly cost of the subscription
 * @returns the discount amount
 */
export function getDiscountFromPromotion(promotion: Promotion | null, monthlyCost: number): number {
  if (promotion?.coupon?.percent_off) {
    return monthlyCost * (promotion.coupon.percent_off / 100)
  } else if (promotion?.coupon?.amount_off) {
    return promotion.coupon.amount_off / 100
  }
  return 0
}

/**
 * Get Discount For Amount Due
 * @param promotion the promotion to apply
 * @param monthlyCost the monthly cost of the subscription
 * @param billingCycle the billing cycle for the subscription
 * @returns the discount amount
 */
export function getDiscountForAmountDue(promotion: Promotion | null, monthlyCost: number, billingCycle: BillingCycle): number {
  const monthlyDiscount = getDiscountFromPromotion(promotion, monthlyCost)
  const durationOfDiscount = (promotion?.coupon.duration as CouponDuration) || CouponDuration.ONCE
  const annualDiscount = calculateAnnualDiscount(durationOfDiscount, monthlyDiscount, promotion)
  return billingCycle === BillingCycle.ANNUAL ? annualDiscount : monthlyDiscount
}

/**
 * Calculate Annual Discount
 * @param durationOfDiscount the duration of the discount
 * @param monthlyDiscount the monthly discount
 * @param promotion the promotion to apply
 * @returns the annual discount
 */
export function calculateAnnualDiscount(durationOfDiscount: CouponDuration, monthlyDiscount: number, promotion: Promotion | null): number {
  let annualDiscount = 0
  if (durationOfDiscount === CouponDuration.ONCE) {
    annualDiscount = monthlyDiscount
  } else if (durationOfDiscount === CouponDuration.REPEATING) {
    if (promotion?.coupon.amount_off) {
      annualDiscount = monthlyDiscount
    } else if (promotion?.coupon.percent_off) {
      annualDiscount = monthlyDiscount * 12
    }
  } else if (durationOfDiscount === CouponDuration.FOREVER) {
    annualDiscount = monthlyDiscount * 12
  }
  return annualDiscount
}
