import { z } from 'zod'

export const CouponSchema = z.object({
  id: z.string(),
  object: z.literal('coupon'),
  amount_off: z.number().nullable().optional(),
  created: z.number(),
  currency: z.string().nullable(),
  duration: z.string(),
  duration_in_months: z.number().nullable().optional(),
  livemode: z.boolean(),
  max_redemptions: z.number().nullable(),
  metadata: z.record(z.string(), z.string()),
  name: z.string().nullable(),
  percent_off: z.number().nullable().optional(),
  times_redeemed: z.number(),
  valid: z.boolean(),
})

export const PromotionSchema = z.object({
  id: z.string(),
  object: z.literal('promotion_code'),
  active: z.boolean(),
  code: z.string(),
  coupon: CouponSchema,
  created: z.number(),
  customer: z.string().nullable(),
  expires_at: z.number().nullable(),
  livemode: z.boolean(),
  max_redemptions: z.number().nullable(),
  metadata: z.record(z.string(), z.string()),
  times_redeemed: z.number(),
})

export const PromoCodeResponseSchema = z.object({
  success: z.boolean(),
  promo_code: PromotionSchema.optional(),
})
export type PromoCodeResponse = z.infer<typeof PromoCodeResponseSchema>
export type Promotion = z.infer<typeof PromotionSchema>
