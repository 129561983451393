import { Link, useParams } from 'react-router-dom'
import EditingSessionDocx2Html from './v2-redlines/EditingSessionDocx2Html'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import DocumentEditingSessionV1 from './DocumentEditingSessionV1'
import { useGetEditingSessionQuery } from '../apis/document-editing.api'
import { DocumentEditingSession } from '../store/document-editing.models'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import { DocumentEditingVersionEnum } from '../schema/document-editing.api.schema'

export default function DocumentEditingPage() {
  const pathParams = useParams()
  const editingSessionId = pathParams['editingSessionId'] ?? '' // Router should guarantee this is always a valid string path parameter
  const existingEditingSession = useAppSelector((state: RootState) => state.documentEditingState.editingSession[editingSessionId])

  // RTK Query Fetch
  const { data, isLoading, refetch, isError, error } = useGetEditingSessionQuery(editingSessionId, {
    // Skip if we already have the data in the store (created by the /new page, coming through websocket)
    skip: typeof existingEditingSession != 'undefined',
  })

  // Error (RTK-Query ONLY)
  if (!existingEditingSession && (isError || !data) && !isLoading) {
    console.error('Error loading DocumentEditingPage...', error)
    return (
      <div className={'grow'}>
        <div className={'flex flex-col justify-center items-center pt-16'}>
          <div className={'text-red-700 mb-2'}>Error loading document {editingSessionId}</div>
          <div>
            <button
              className={
                'inline-block rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              }
              onClick={() => refetch()}
            >
              Retry
            </button>
          </div>
        </div>
      </div>
    )
  }

  // If the editing session doesn't exist, show a 404
  if (!existingEditingSession && !data && !isLoading)
    return (
      <div className={'grow py-20 text-lg font-bold'}>
        404: Editing session not found.{' '}
        <Link to={'/dashboard/document-editing/list'} className={'text-sky-600 underline'}>
          Continue.
        </Link>
      </div>
    )

  // Loading
  if (isLoading) {
    return (
      <div className={'grow flex justify-center pt-16'}>
        <CircularProgressContinuous />
      </div>
    )
  }

  // Construct DocumentEditingSession based on existing state (live websocket OR previous fetch) OR RTK-Query "data"
  const constructedEditingSession: DocumentEditingSession = existingEditingSession
    ? { ...existingEditingSession }
    : {
        loading: isLoading,
        error: isError,
        summary: data?.summary ?? '',
        annotations: data?.annotations ?? [],
        header: {
          id: editingSessionId,
          title: data?.title ?? '',
          user_query: data?.user_query ?? '',
          file_path: data?.file_path ?? '',
          created_at: data?.created_at ?? '',
          version: data?.version ?? DocumentEditingVersionEnum.contract_analysis_v1,
        },
      }

  // Load the appropriate renderer based on the analysis version

  if (
    constructedEditingSession.header.version === DocumentEditingVersionEnum.contract_analysis_v2 &&
    // Additional check since we erroneously called all new onces v2 instead of just the new docx
    // and currently only .docx files are supported in v2
    // All others should load V1
    // Backend expected to make v2 actually v2 with db fix (Ticket: ENG-4185)
    constructedEditingSession.header.file_path.endsWith('docx')
  ) {
    return <EditingSessionDocx2Html editingSession={constructedEditingSession} />
  } else {
    return <DocumentEditingSessionV1 editingSession={constructedEditingSession} />
  }
}
