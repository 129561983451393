import { useAppDispatch } from '@/store/store-hooks'
import { ChatV2Conversation, chatV2AddCaseLawCourtIds, chatV2RemoveCaseLawCourtIds } from '@/chat-common/store/chat-v2.slice'
import { CourtsResponse } from '@/store/apis/hosted-filters-api'
import { ReactElement } from 'react'

type CaseLawStateDialogCourtSelectionProps = {
  conversation: ChatV2Conversation
  courts: CourtsResponse
}

export default function CaseLawStateDialogCourtSelection(props: CaseLawStateDialogCourtSelectionProps) {
  const { conversation, courts } = props

  // Store
  const selectedCourts = conversation.currentSource?.court_ids ?? []
  const dispatch = useAppDispatch()

  // Get All Child Court Ids of a State
  function getAllChildIds(stateKey: string): string[] {
    const stateValue = courts.State[stateKey]

    const childIds: string[] = []

    Object.keys(stateValue).forEach((childKey) => {
      const childValue = stateValue[childKey]

      switch (true) {
        case typeof childValue === 'string':
          childIds.push(childValue)
          break

        case Array.isArray(childValue):
          childIds.push(...childValue)
          break

        default:
          console.error(`State court child value from key ${childKey} is not a string or array of strings: `, stateValue)
          break
      }
    })

    return childIds
  }

  // Check if state is checked where every child is checked
  function isCourtIdChecked(courtIds: string[]): boolean {
    return courtIds.every((courtId) => selectedCourts.includes(courtId))
  }

  // Check if all children are checked
  function areAllStateChildrenChecked(stateKey: string): boolean {
    // Get the child ids
    const childIds = getAllChildIds(stateKey)

    // Check if all children are checked
    return childIds.every((childId) => selectedCourts.includes(childId))
  }

  // Toggle All Children
  // Action based on checked value
  function toggleStateChildren(checked: boolean, stateKey: string) {
    // Get the child ids
    const childIds = getAllChildIds(stateKey)

    // Dispatch the action
    if (checked) {
      dispatch(chatV2AddCaseLawCourtIds({ conversationId: conversation.id, courtIds: childIds }))
    } else {
      dispatch(chatV2RemoveCaseLawCourtIds({ conversationId: conversation.id, courtIds: childIds }))
    }
  }

  // Toggle Single Court
  // Action based on checked value
  function toggleCourtIds(checked: boolean, courtIds: string[]) {
    if (checked) {
      dispatch(chatV2AddCaseLawCourtIds({ conversationId: conversation.id, courtIds: courtIds }))
    } else {
      dispatch(chatV2RemoveCaseLawCourtIds({ conversationId: conversation.id, courtIds: courtIds }))
    }
  }

  function CourtIdsSelector(props: { label: string; courtIds: string[] }) {
    const { label, courtIds } = props

    const htmlLabel = 'courtIds-' + courtIds.join(',')

    return (
      <div className={'flex items-center mb-1'}>
        <input
          type="checkbox"
          className={'checked:bg-sky-600 rounded-sm'}
          id={htmlLabel}
          name={label}
          value={courtIds}
          checked={isCourtIdChecked(courtIds)}
          onChange={(event) => {
            console.log(`Checked ${label} with ids: ${courtIds}`)
            toggleCourtIds(event.target.checked, courtIds)
          }}
        />
        <label htmlFor={htmlLabel} className={'pl-2 text-sm'}>
          {label}
        </label>
      </div>
    )
  }

  // Recursive court list renderer that can handle nested child court records
  function RenderStates(props: { states: Record<string, any> }): ReactElement[] {
    const { states } = props

    return Object.keys(states).map((stateKey) => {
      const stateValue = states[stateKey]

      // Render the parent state checkbox,
      // And for each of its children, render the children
      return (
        <div key={stateKey} className={'mb-5 break-inside-avoid'}>
          <div className={'flex items-center mb-1'}>
            <input
              type="checkbox"
              className={'checked:bg-sky-600 rounded-sm'}
              id={stateKey}
              name={stateKey}
              checked={areAllStateChildrenChecked(stateKey)}
              onChange={(event) => {
                console.log(`Toggling ${stateKey} children to`, event.target.checked)
                toggleStateChildren(event.target.checked, stateKey)
              }}
            />
            <label htmlFor={stateKey} className={'pl-2 text-sm font-medium'}>
              {stateKey}
            </label>
          </div>
          <div className={'pl-5'}>
            {Object.keys(stateValue).map((childKey) => {
              const childValue = stateValue[childKey]

              switch (true) {
                // value is string
                case typeof childValue === 'string':
                  return <CourtIdsSelector key={childKey} label={childKey} courtIds={[childValue]} />

                // value is array of strings
                case Array.isArray(childValue):
                  return <CourtIdsSelector key={childKey} label={childKey} courtIds={childValue} />

                default:
                  console.warn(`Court Is Unknown key: ${childKey}, value: ${JSON.stringify(childValue)}`)
                  return <div key={childKey}></div>
              }
            })}
          </div>
        </div>
      )
    })
  }

  // Render state courts list
  return (
    <div className={'columns-2 md:columns-3 pt-2'}>
      <RenderStates states={courts.State} />
    </div>
  )
}
