import { ChatV2Conversation, ChatV2Message, chatV2SetVisibleReference } from '@/chat-common/store/chat-v2.slice'
import referenceMetadataToTitle from '@/chat-common/util/reference-metadata-to-title'
import referenceMetadataToTitleSuffix from '@/chat-common/util/reference-metadata-to-title-suffix'
import CaseLawReferenceRunCitatorButton from '@/citator/views/CaseLawRunCitatorReferenceButton'
import { useAppDispatch } from '@/store/store-hooks'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { Link } from 'react-router-dom'
import { isCitatorEnabled } from '@/chat-common/components/reference-viewer/reference-view-utils'
import { ChatV2MessageReferenceType } from '@/chat-common/store/chat-v2.slice'

type ChatMessageBubbleReferenceProps = {
  conversation: ChatV2Conversation
  message: ChatV2Message
  referenceKey: string
}

function isBBCitation(reference: ChatV2MessageReferenceType): boolean {
  return typeof reference.metadata?.bb_citation === 'string'
}

export default function ChatMessageBubbleReference(props: ChatMessageBubbleReferenceProps) {
  const { conversation, message, referenceKey } = props
  const dispatch = useAppDispatch()

  // Inferred values
  const reference = message.metadata.references[referenceKey]
  const hasBBCitation = isBBCitation(reference)
  const title = referenceMetadataToTitle(reference, hasBBCitation)
  const titleSuffix = referenceMetadataToTitleSuffix(reference, hasBBCitation)
  const highlight: boolean = conversation.visibleReference != null && conversation.visibleReference == reference
  const parent_id = reference?.metadata.parent_id

  return (
    <div className={`mb-2 p-1 last:mb-2 ${highlight ? 'bg-sky-100 bg-opacity-90' : ''}`}>
      <div className={'flex gap-x-3'}>
        <button
          onClick={() => {
            dispatch(
              chatV2SetVisibleReference({
                conversationId: message.metadata.conversation_id,
                reference: reference,
              })
            )
          }}
          className={`font-bold text-xs text-sky-800 cursor-pointer hover:underline inline-block`}
        >
          [{reference.reference_number}] View source
        </button>
        {isCitatorEnabled(conversation.feature, reference) && parent_id && (
          <Link
            to={`/dashboard/reference-viewer/caselaw/${parent_id}`}
            target="_blank"
            className={'flex items-end text-sky-800 font-bold text-xs cursor-pointer hover:underline'}
          >
            <span className={''}>New tab</span> <ExternalLinkIcon className={'w-4 h-4 pl-[4px] pb-[0px]'} />
          </Link>
        )}
      </div>
      {hasBBCitation && (
        <div className={`text-xs`}>
          <i>{title}</i>
          {titleSuffix}
        </div>
      )}
      {!hasBBCitation && <div className={`text-xs`}>{title}</div>}
      {isCitatorEnabled(conversation.feature, reference) && parent_id && (
        <CaseLawReferenceRunCitatorButton
          parent_id={parent_id}
          tailwindTextSize="text-xs"
          onClickCallback={() => {
            // Open the reference tab to the citator tab
            dispatch(
              chatV2SetVisibleReference({
                conversationId: message.metadata.conversation_id,
                reference: reference,
                openRefToCitatorTab: true,
              })
            )
          }}
        />
      )}
    </div>
  )
}
