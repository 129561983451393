import { useEffect, useRef, useState } from 'react'
import RenderDocx2HtmlTopBar from './RenderDocx2HtmlTopBar'
import { Docx2HtmlCommentTree, Docx2HtmlCommentsRelativeTop } from '@/document-editing/schema/docx2html.schema'
import { docx2HtmlExtractComments } from '@/document-editing/service/docx2html-extract-comments'
import debounce from 'lodash.debounce'
import { docx2HtmlGetCommentPositions } from '@/document-editing/service/docx2Html-get-comment-positions'
import RenderDocx2HtmlComments from './RenderDocx2HtmlComments'
import { Parser } from 'html-to-react'
import sanitizeHtml from 'sanitize-html'
import { kDocx2HtmlAllowedTags } from '@/constants/constants-ui'
import { docx2HtmlMarkupHighlightComments } from '@/document-editing/service/docx2html-markup-highlight-comments'
import { DocumentEditingSession } from '@/document-editing/store/document-editing.models'
import { getDocx2HtmlFromGcs } from '@/document-editing/apis/get-docx2html-from-gcs'
import { getAuth } from 'firebase/auth'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { wrapTablesWithDiv } from '@/chat-common/util/source-html-editing-functions'
import RenderDocx2HtmlCommentsMobile from './RenderDocx2HtmlCommentsMobile'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'

type EditingSessionDocx2HtmlProps = {
  editingSession: DocumentEditingSession
}

// Configure the HTML parser
const htmlParser = Parser()

/**
 * This component encapsulates the editing session for
 * .docx -> html redlines viewing and editing.
 */
export default function EditingSessionDocx2Html(props: EditingSessionDocx2HtmlProps) {
  const { editingSession } = props

  // Render container ref
  const renderContainerRef = useRef<HTMLDivElement>(null)

  // Global UI State
  const uiState = useAppSelector((state: RootState) => state.uiState)
  const sidebarHidden = uiState.hideSidebars

  // Local UI State
  const [showMobileComments, setShowMobileComments] = useState<boolean>(false)

  // Local state - Content Loading
  const [originalCleanedHtml, setOriginalCleanedHtml] = useState<string | null>(null)
  const [htmlLoading, setHtmlLoading] = useState<boolean>(false)
  const [htmlLoadingErrorString, setHtmlLoadingErrorString] = useState<string | null>(null)

  // Local state - Processed document HTML Rendering + Annotations
  const [renderReadyHtml, setRenderReadyHtml] = useState<string | null>(null)
  const [extractedComments, setExtractedComments] = useState<Docx2HtmlCommentTree | null>(null)
  const [sortedCommentIds, setSortedCommentIds] = useState<string[]>([])
  const [commentPositions, setCommentPositions] = useState<Docx2HtmlCommentsRelativeTop>({})
  const [activeCommentId, setActiveCommentId] = useState<string | null>(null)
  const [activeCommentVisible, setActiveCommentVisible] = useState<boolean>(false)
  const [scrollToCommentId, setScrollToCommentId] = useState<string | null>(null)

  // Inferred values
  const currentCommentIndex = !activeCommentId ? 0 : sortedCommentIds.indexOf(activeCommentId)
  // console.log(`Rendering with current active comment index: ${currentCommentIndex}, and id: ${activeCommentId}`)

  // Fetch the document from GCS
  async function handleDocumentFetch() {
    const user = getAuth().currentUser
    if (!user) return

    // Do not refetch
    if (editingSession.loading || editingSession.error || originalCleanedHtml) return

    // Construct the path
    const path = `users/${user.uid}/contract_analysis/${editingSession.header.id}.html`

    try {
      setHtmlLoading(true)
      setHtmlLoadingErrorString(null)
      setOriginalCleanedHtml(null)

      // Fetch the document
      const rawHtml = await getDocx2HtmlFromGcs(path)

      // TODO: For potentially enhanced Word style rendering, extract and parse styles from the raw
      // HTML prepending each style with our wrapper class and insert them into this component's stylesheet

      //****************************************************************/
      // PROCESSING FLOW FOR RAW HTML FROM .docx -> .html process
      //****************************************************************/

      // Sanitize the string
      const sanitizedHtml = sanitizeHtml(rawHtml, {
        allowedTags: kDocx2HtmlAllowedTags,
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          '*': ['id', 'class', 'style', 'data-date', 'data-author'],
        },
      })

      // Apply table wrapper fix to the HTML string
      const correctedHtml = wrapTablesWithDiv(sanitizedHtml)

      // Set the cleaned HTML
      // FUTURE REPROCESSING IN OTHER useEffect functions WILL USE THIS CLEANED HTML
      setOriginalCleanedHtml(correctedHtml)

      // Extract the comments from the html (helps inform the markup process)
      const extractedCommentsInitial = docx2HtmlExtractComments(correctedHtml)

      // Highlight the text
      const highlightedHtml = docx2HtmlMarkupHighlightComments(correctedHtml, activeCommentId, extractedCommentsInitial)

      // Extract the comments after markup for more complete extraction of comment metadata
      const extractedComments = docx2HtmlExtractComments(highlightedHtml)
      setExtractedComments(extractedComments)

      // Parse the HTML
      const renderReadyHtml = htmlParser.parse(highlightedHtml)
      setRenderReadyHtml(renderReadyHtml)

      // Sort the commentIds by the sortOrder
      if (extractedComments != null) {
        const sortedCommentIds: string[] = Object.keys(extractedComments ?? [])
        sortedCommentIds.sort((a, b) => extractedComments[a].sortOrder - extractedComments[b].sortOrder)
        setSortedCommentIds(sortedCommentIds)

        // Activate and scroll to the first comment (SORTED)
        if (sortedCommentIds.length > 0) {
          const firstCommentId = sortedCommentIds[0]
          setActiveCommentId(firstCommentId)
          setActiveCommentVisible(true)
          setShowMobileComments(true)
          setScrollToCommentId(firstCommentId)
        }
      }
    } catch (error) {
      console.error('Error fetching document...', error)
      setHtmlLoadingErrorString('There was an error fetching the document. Please try again.')
    } finally {
      setHtmlLoading(false)
    }
  }

  // Fetch the document when the editingSession changes
  useEffect(() => {
    handleDocumentFetch()
  }, [editingSession])

  // // Handle file change
  // // TEMPORARY uploaded file rendering method
  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0]

  //   if (file) {
  //     const reader = new FileReader()
  //     reader.onload = (e) => {
  //       const rawHtml = e.target?.result as string
  //       setRawHtml(rawHtml)

  //       //****************************************************************/
  //       // PROCESSING FLOW FOR RAW HTML FROM .docx -> .html process
  //       //****************************************************************/

  //       // Sanitize the string
  //       const sanitizedHtml = sanitizeHtml(rawHtml, {
  //         allowedTags: kDocx2HtmlAllowedTags,
  //         allowedAttributes: {
  //           ...sanitizeHtml.defaults.allowedAttributes,
  //           '*': ['id', 'class', 'style', 'data-date', 'data-author'],
  //         },
  //       })

  //       // Extract the comments from the marked up and highlighted html
  //       const extractedCommentsInitial = docx2HtmlExtractComments(sanitizedHtml)

  //       // Highlight the text
  //       const highlightedHtml = docx2HtmlMarkupHighlightComments(sanitizedHtml, activeCommentId, extractedCommentsInitial)

  //       // Extract the comments after markup to determine type
  //       const extractedComments = docx2HtmlExtractComments(highlightedHtml)
  //       setExtractedComments(extractedComments)

  //       // Parse the HTML
  //       const renderReadyHtml = htmlParser.parse(highlightedHtml)
  //       setRenderReadyHtml(renderReadyHtml)

  //       // Sort the commentIds by the sortOrder
  //       if (extractedComments != null) {
  //         const sortedCommentIds: string[] = Object.keys(extractedComments ?? [])
  //         sortedCommentIds.sort((a, b) => extractedComments[a].sortOrder - extractedComments[b].sortOrder)
  //         setSortedCommentIds(sortedCommentIds)

  //         // Activate and scroll to the first comment (SORTED)
  //         if (sortedCommentIds.length > 0) {
  //           const firstCommentId = sortedCommentIds[0]
  //           setActiveCommentId(firstCommentId)
  //           setActiveCommentVisible(true)
  //           setScrollToCommentId(firstCommentId)
  //         }
  //       }
  //     }
  //     reader.readAsText(file)
  //   }
  // }

  // RECALCULATE EVERY TIME ACTIVE COMMENT CHANGES
  // - Reprocess the HTML to highlight the active comment
  useEffect(() => {
    if (originalCleanedHtml == null || extractedComments == null) return

    // Re-Mark-Up the HTML for rendering with the active comment
    const highlightedHtml = docx2HtmlMarkupHighlightComments(originalCleanedHtml, activeCommentId, extractedComments)
    const renderReadyHtml = htmlParser.parse(highlightedHtml)
    setRenderReadyHtml(renderReadyHtml)
  }, [activeCommentId, originalCleanedHtml, extractedComments])

  // COMMENT INTERACTIVITY HANDLER
  // Run when renderReadyHtml changes
  // Listen for clicks on tracked changes on class "doc-comment-click-trigger"
  useEffect(() => {
    const elements = renderContainerRef.current?.getElementsByClassName('doc-comment-click-trigger') ?? []
    // console.log('Elements with click trigger: ', elements.length)

    const handleClick = (event: Event) => {
      const thisElement = event.currentTarget as HTMLElement

      // Extract the comment id from the class part-of-comment-id-${commentId}
      const commentId = thisElement.className.match(/part-of-comment-id-([a-zA-Z0-9-]+)/)?.[1]
      if (commentId == null) return
      // console.log('Clicked on comment: ', commentId)

      // Do not scroll on these clicks
      setScrollToCommentId(null)
      setActiveCommentId(commentId)
      setActiveCommentVisible(true)
      setShowMobileComments(true)
    }

    for (let i = 0; i < elements.length; i++) {
      const thisElement = elements[i]
      thisElement.addEventListener('click', handleClick)
      // console.log('Set click event listener')
    }

    // Cleanup function to remove the event listeners
    return () => {
      for (let i = 0; i < elements.length; i++) {
        const thisElement = elements[i]
        thisElement.removeEventListener('click', handleClick)
      }
    }
  }, [renderReadyHtml])

  // SCREEN RESIZE HANDLER
  // Functions that should run every time the screen size changes
  useEffect(() => {
    // Debounce to reduce the number of calls
    const debouncedHandleResize = debounce(() => {
      // Get the comment positions
      if (extractedComments != null && renderContainerRef.current != null) {
        const commentPositions = docx2HtmlGetCommentPositions(extractedComments, renderContainerRef)
        setCommentPositions(commentPositions)
      }
    }, 300)

    window.addEventListener('resize', debouncedHandleResize)

    debouncedHandleResize() // Initial calculation

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      debouncedHandleResize.cancel() // Cancel any pending debounced calls
    }
  }, [extractedComments])

  // SIDEBAR COLLAPSE HANDLER
  // Re-run when the sidebar collapses or expands (after animation delay)
  useEffect(() => {
    async function repositionAfterSidebarAnimationDelay() {
      // Wait for the sidebar to animate
      await new Promise((resolve) => setTimeout(resolve, 500))

      // Get the comment positions
      if (extractedComments != null && renderContainerRef.current != null) {
        const commentPositions = docx2HtmlGetCommentPositions(extractedComments, renderContainerRef)
        setCommentPositions(commentPositions)
      }
    }

    repositionAfterSidebarAnimationDelay()
  }, [sidebarHidden, extractedComments])

  // Scroll to the current annotation index
  // Re-run when
  //  1. the currentAnnotationIndex changes
  //  2. when the extracted comments are available
  useEffect(() => {
    if (extractedComments == null || Object.keys(extractedComments).length == 0 || renderReadyHtml == null) return

    // Find the annotation element, if exists, scroll to it
    const commentAnchorId = `comment-anchor-${scrollToCommentId}`
    const element = renderContainerRef.current?.querySelector(`#${commentAnchorId}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [scrollToCommentId, extractedComments, renderReadyHtml])

  return (
    // Accommodate the height of the top bar depending on screen size with top padding

    <div className={'grow flex flex-col h-full overflow-y-scroll pb-10'}>
      <RenderDocx2HtmlTopBar
        loading={editingSession.loading || htmlLoading}
        title={editingSession.header.title}
        editingSessionId={editingSession.header.id}
        currentIndex={currentCommentIndex}
        totalCount={sortedCommentIds.length}
        changeIndex={(index) => {
          // Update the active comment
          setActiveCommentId(sortedCommentIds[index])
          setActiveCommentVisible(true)
          setShowMobileComments(true)
          setScrollToCommentId(sortedCommentIds[index])
        }}
      />
      <div className={'pt-32 lg:pt-20'}></div>

      {/* <input type="file" accept=".html" onChange={handleFileChange} /> */}

      <div ref={renderContainerRef} id="docx2html-render-container">
        <div className={'grow flex flex-col items-center justify-center'}>
          <div
            className={
              'grid w-full max-w-[1400px] grid-cols-[auto] md:grid-cols-[auto_300px] lg:grid-cols-[auto_400px] xl:grid-cols-[auto_500px] 2xl:grid-cols-[auto_600px] '
            }
          >
            <div className={'relative md:mx-2'}>
              <div className={'mb-5 px-2 md:px-5'}>
                {editingSession.header.user_query && (
                  <div>
                    <p className={`font-bold text-base`}>Query</p>
                    <div className={'text-sm'}>{editingSession.header.user_query}</div>
                    <div className={'h-5'}></div>
                  </div>
                )}
                <p className={`font-bold text-base`}>Paxton Analysis Summary</p>
                {editingSession.summary.length == 0 && editingSession.loading && (
                  <div className={'text-sm text-gray-500 flex gap-x-2 items-center'}>
                    Generating summary... <CircularProgressContinuousSized size={12} thickness={8} />
                  </div>
                )}
                <div className={'text-sm'}>{editingSession.summary}</div>
              </div>

              {/* Render The Document */}

              <div className={'bg-white shadow py-5 px-2 md:px-5'}>
                <div id="render-docx2html" className={'msft-word-style'}>
                  {/* Generating annotations loader */}
                  {editingSession.loading && (
                    <div className={'text-sm text-gray-500 flex gap-x-2 items-center'}>
                      Analyzing and annotating document... <CircularProgressContinuousSized size={12} thickness={8} />
                    </div>
                  )}

                  {/* Error analyzing document */}
                  {editingSession.error && (
                    <div className={'text-red-700 mb-2 py-12'}>
                      {editingSession.errorString ?? 'An unexpected error occurred when analyzing this document. Please try again.'}
                    </div>
                  )}

                  {/* Errors with loading document */}
                  {!editingSession.error && (htmlLoadingErrorString || !renderReadyHtml) && !htmlLoading && !editingSession.loading && (
                    <div className={'grow flex py-12'}>
                      <div className={''}>
                        <div className={'text-red-700 mb-2'}>Error loading document.</div>
                        {}
                        <button
                          className={
                            'rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
                          }
                          onClick={() => {
                            setHtmlLoading(true)
                            setHtmlLoadingErrorString(null)
                            setOriginalCleanedHtml(null)
                            handleDocumentFetch()
                          }}
                        >
                          Retry
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Render Document */}
                  {renderReadyHtml && <div>{renderReadyHtml}</div>}
                </div>
              </div>
            </div>

            {/* Mobile Comments View */}
            {showMobileComments && activeCommentId && extractedComments && (
              <div className={'fixed w-full md:hidden'} style={{ bottom: 'env(safe-area-inset-bottom)', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)' }}>
                <div className={'bg-[#fef8e6] border-t-4 border-[#c6a46f] pb-4 max-h-[33vh] overflow-y-scroll'}>
                  <button className={'absolute right-0 py-[10px] px-4'} onClick={() => setShowMobileComments(false)}>
                    <CrossCircledIcon className={'h-6 w-6'} />
                  </button>
                  <RenderDocx2HtmlCommentsMobile commentTree={extractedComments} activeCommentId={activeCommentId} annotations={editingSession.annotations} />
                </div>
              </div>
            )}

            {/* Desktop Comments View */}
            <div className={'relative'}>
              {extractedComments && (
                <RenderDocx2HtmlComments
                  commentTree={extractedComments}
                  sortedCommentIds={sortedCommentIds}
                  commentTopPositions={commentPositions}
                  activeCommentId={activeCommentId}
                  activeCommentVisible={activeCommentVisible}
                  annotations={editingSession.annotations}
                  onClick={(commentId) => {
                    // Do not scroll on these clicks
                    setScrollToCommentId(null)
                    setActiveCommentVisible(true)
                    setActiveCommentId(commentId)
                  }}
                  onClose={() => {
                    setActiveCommentVisible(false)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
