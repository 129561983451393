import { kPaymentsPartnerPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'

type PostPartnerCodeParams = {
  partner_code: string
}

type PostPartnerCodeResponse = {
  detail?: string
  error?: boolean
}

export default async function postPartnerCode(params: PostPartnerCodeParams): Promise<PostPartnerCodeResponse> {
  // Create transaction id
  const sentry_transaction_id = nanoid()

  try {
    console.log('Posting Partner Code')
    const token = await getAuth().currentUser?.getIdToken(true)
    if (!token) throw Error(kErrorCodeAuthError)
    // Construct the body
    const body = JSON.stringify(params)
    // Construct request
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: body,
    }

    const apiUrl = kPaxtonAppApiBaseUrl() + `/${kPaymentsPartnerPath}`
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()
    if (!response.ok) {
      return {
        ...data,
        error: true,
      }
    }
    return data
  } catch (e) {
    console.error(e)

    // Capture to sentry
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(new Error(`Fetch error - status: ${kPaymentsPartnerPath}, detail: ${e}`), {
        extra: {
          requestUrl: kPaymentsPartnerPath,
          onLine: navigator.onLine,
          cookieEnabled: navigator.cookieEnabled,
        },
      })
    })

    throw e
  }
}
