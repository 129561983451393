import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import {
  DocumentEditingWsEventAnnotationsDataSchema,
  DocumentEditingWsEventBasePayloadSchema,
  DocumentEditingWsEventBasePayloadType,
  DocumentEditingWsEventHeaderDataSchema,
  DocumentEditingWsEventSummaryChunkDataSchema,
} from '../schema/document-editing-websocket.schema'
import { addEditingSessionFromHeaderEvent, addEditingSessionSummaryChunk, setEditingSessionAnnotations } from '../store/document-editing.slice'

export type DocumentEditingPayloadDispatcherArgs = {
  payload: any
  dispatchSuccessCallback?: (validatedPayload: DocumentEditingWsEventBasePayloadType) => void
  dispatch: Dispatch<AnyAction>
}

/**
 * Document Editing Payload Dispatcher
 * Validates and dispatches the payload to the appropriate state function
 * @param payload is the payload to dispatch
 * @param dispatchSuccessCallback is the callback to fire after the dispatch is successful
 */
export function documentEditingPayloadStateDispatcher(args: DocumentEditingPayloadDispatcherArgs): void {
  const { payload, dispatchSuccessCallback, dispatch } = args

  // Validate the base payload
  const basePayloadValidate = DocumentEditingWsEventBasePayloadSchema.safeParse(payload)
  if (!basePayloadValidate.success) {
    console.error('Failed to validate base payload.', basePayloadValidate.error)
    return
  }

  // Isolate the data
  const baseEventPayload = basePayloadValidate.data as DocumentEditingWsEventBasePayloadType

  // Dispatch the payload
  switch (baseEventPayload.event) {
    // Add a case for the header event
    case 'header': {
      // Validate
      const validated = DocumentEditingWsEventHeaderDataSchema.safeParse(baseEventPayload.data)
      if (!validated.success) {
        console.error('Failed to validate header payload.', validated.error)
        return
      }

      // Dispatch to state
      dispatch(addEditingSessionFromHeaderEvent(validated.data))
      break
    }

    // Add a case for the summary_chunk event
    case 'summary_chunk': {
      // Validate
      const validated = DocumentEditingWsEventSummaryChunkDataSchema.safeParse(baseEventPayload.data)
      if (!validated.success) {
        console.error('Failed to validate summary_chunk payload.', validated.error)
        return
      }

      // Dispatch to state
      dispatch(addEditingSessionSummaryChunk(validated.data))

      break
    }

    // editing_annotations
    case 'editing_annotations': {
      // Validate
      const validated = DocumentEditingWsEventAnnotationsDataSchema.safeParse(baseEventPayload.data)
      if (!validated.success) {
        console.error('Failed to validate annotations payload.', validated.error)
        return
      }

      // Dispatch to state
      dispatch(setEditingSessionAnnotations(validated.data))
      break
    }

    default:
      console.warn('Unknown event type received. Did you forget to handle this payload in the dispatcher?', payload)
  }

  // Fire the dispatch success callback
  if (dispatchSuccessCallback) dispatchSuccessCallback(baseEventPayload)
}
