import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useState, useEffect } from 'react'

type ConversationDeleteConfirmDialogProps = {
  title: string
  message: string
  // async onConfirm function
  onConfirm: () => Promise<void>
  closeDialog: () => void
  visible: boolean
}

export default function ConversationDeleteConfirmDialog(props: ConversationDeleteConfirmDialogProps) {
  const { title, message, onConfirm, closeDialog, visible } = props
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleConfirm = async () => {
    setLoading(true)
    setError(null)
    try {
      await onConfirm()
      closeDialog()
    } catch (error) {
      console.error(error)
      setError('There was an error deleting this conversation.')
    } finally {
      setLoading(false)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    // Allow only Enter and Escape keys, block any other key actions
    if (event.key === 'Enter') {
      event.preventDefault()
      handleConfirm()
    } else if (event.key === 'Escape') {
      closeDialog()
    } else {
      // Prevent any other key from triggering its default action or propagating
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener('keydown', handleKeyDown, true)
      return () => {
        document.removeEventListener('keydown', handleKeyDown, true)
      }
    }
  }, [visible])

  if (!visible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={closeDialog}></div>
      <div className="bg-white p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px]">
        <h2 className="text-lg font-semibold">{title}</h2>
        <p className="mt-2 text-sm whitespace-pre-wrap">{message}</p>
        {error && <p className={'mt-2 text-sm text-red-700'}>{error}</p>}
        <div className={'h-10'} />
        <div className={'flex gap-x-4 justify-end'}>
          <button
            className="inline-flex justify-center rounded-md bg-white px-5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={closeDialog}
          >
            Cancel
          </button>
          <button
            className="flex items-center gap-x-2 justify-center rounded-md bg-sky-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500"
            onClick={handleConfirm}
          >
            <div>Delete</div>
            {loading && <CircularProgressContinuousSized size={12} thickness={7} hexColor={'#ffffff'} />}
          </button>
        </div>
      </div>
    </div>
  )
}
