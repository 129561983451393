import { useOnlineStatus } from '@/context/online-status-context'
import { SignalSlashIcon } from '@heroicons/react/24/outline'
import React from 'react'

const DashboardDesktopConnectionBanner: React.FC = () => {
  const isOnline = useOnlineStatus()

  if (!isOnline) {
    return (
      <div className={'hidden lg:block bg-red-300 text-red-900 text-xs text-center p-1'}>
        <div className={'flex items-center justify-center gap-x-2'}>
          <div className={'w-4 h-4'}>
            <SignalSlashIcon />
          </div>
          <div className={''}>No connection</div>
        </div>
      </div>
    )
  }

  return null
}

export default DashboardDesktopConnectionBanner
