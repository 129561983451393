import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'

/**
 * Prepare RTK Query headers
 * Adds sentry transaction id and bearer token to the RTK-Querys
 * @param headers
 */
export async function prepareRtkQueryHeaders(headers: Headers): Promise<Headers> {
  // Add the auth token
  const token = await getAuth().currentUser?.getIdToken()
  if (token) {
    headers.set(`Authorization`, `Bearer ${token}`)
  }

  // Insert a Sentry transaction_id for the server to use
  const sentry_transaction_id = nanoid()
  headers.set('X-Transaction-Id', sentry_transaction_id)

  // Add the Content Type
  headers.set('Content-type', 'application/json; charset=UTF-8')

  return headers
}

/**
 * Handle RTK Query errors
 * Attach to transformErrorResponse to report errors to Sentry
 * @param baseQueryReturnValue
 * @param meta
 * @param _
 * @returns
 */
export async function transformRtkQueryResponse(
  baseQueryReturnValue: FetchBaseQueryError,
  meta: FetchBaseQueryMeta | undefined,
  _: any
): Promise<FetchBaseQueryError> {
  // Get the response
  const response = meta?.response

  // Get the error detail
  const statusCode = baseQueryReturnValue.status
  let errorDetail = baseQueryReturnValue.data

  // If errorDetail is JSON
  if (typeof errorDetail === 'object') {
    errorDetail = JSON.stringify(errorDetail)
  }

  // Get the sentry transaction id from the response header
  const transaction_id = response?.headers.get('X-Transaction-Id')

  // Log
  console.log(`Fetch error (${statusCode}), transaction: ${transaction_id}, detail: ${errorDetail}`)

  // Report to sentry except for rate limit errors
  if (statusCode !== 429) {
    Sentry.withScope((scope) => {
      if (typeof transaction_id == 'string') {
        scope.setTags({ transaction_id: transaction_id })
      } else {
        console.error('X-Transaction-Id missing in response headers: ', response?.headers)
      }
      Sentry.captureException(new Error(`RTK Fetch error (${statusCode}): ${errorDetail}`), {
        extra: {
          status: statusCode,
          detail: errorDetail,
          request: meta?.request,
          response: response,
          onLine: navigator.onLine,
          cookieEnabled: navigator.cookieEnabled,
        },
      })
    })
  }

  return baseQueryReturnValue
}
