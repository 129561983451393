import { useContext, useState } from 'react'
import UserList from './UserList'
import ChangeLogoDialog from './change-logo/ChangeLogoDialog'
import { AuthContext } from '@/context/auth-context'
import { deleteOrgLogo } from '../services/org-logo-services'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import ErrorComponent from '@/components/error/error-component'
import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

export default function AdminDashboardPage() {
  const { logoUrl, userAccountData } = useContext(AuthContext)

  // Local state
  const [changeLogoDialogVisible, setChangeLogoDialogVisible] = useState<boolean>(false)
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const [deleteError, setDeleteError] = useState<string | null>(null)

  // Inferred values
  const orgName = userAccountData?.orgData?.name ?? 'Your Organization'
  const orgId = userAccountData?.legacyDriveCompatibleOrganizationId
  const isOrgAdmin = userAccountData?.isOrgAdmin

  // Handle delete logo
  const handleDeleteLogo = async () => {
    if (!orgId) {
      setDeleteError('No organization id found.')
      return
    }

    try {
      setDeleteLoading(true)
      await deleteOrgLogo(userAccountData?.legacyDriveCompatibleOrganizationId)

      // Refresh the screen to see the update
      window.location.reload()
    } catch (e) {
      console.error('Could not delete org logo. Error: ', e)
      setDeleteError('Could not delete the logo.')
    } finally {
      setDeleteLoading(false)
    }
  }

  if (!isOrgAdmin) {
    return <ErrorComponent message={'You must be an organization admin to access this screen.'} code={'403'} title={'Access Denied'} />
  }

  return (
    <>
      <ChangeLogoDialog
        visible={changeLogoDialogVisible}
        onComplete={() => {
          setChangeLogoDialogVisible(false)

          // Refresh the screen to see the logo
          window.location.reload()
        }}
        onClose={() => setChangeLogoDialogVisible(false)}
      />

      <div className={'grow flex pt-16 lg:pt-5 px-2 lg:px-0 lg:pr-2 justify-center overflow-y-scroll'}>
        <div className={'grow max-w-5xl'}>
          <div className={'flex flex-col grow items-center bg-white rounded-md pt-4 mb-4'}>
            <img className="max-h-[100px] max-w-[200px] mb-1" src={logoUrl} alt={hostBrand}></img>
            <div className={'flex gap-x-4'}>
              <button className={'underline text-sky-600 text-xs mb-4'} onClick={() => setChangeLogoDialogVisible(true)}>
                Change Logo
              </button>
              {logoUrl != brandLogoUrl && (
                <button className={'flex items-center underline text-sky-600 text-xs mb-4'} onClick={handleDeleteLogo}>
                  Delete Logo{' '}
                  {deleteLoading && (
                    <span className={'pl-2'}>
                      <CircularProgressContinuousSized size={10} thickness={10} />
                    </span>
                  )}
                </button>
              )}
            </div>
            {deleteError && <p className={'text-red-600 text-s mb-4'}>{deleteError}</p>}
            <h1 className={'font-bold text-xl'}>{orgName}</h1>
            <p className={'text-sm mb-6'}>Manage your organization settings and team.</p>
          </div>
          <UserList />
          <div className={'h-10'}> </div>
        </div>
      </div>
    </>
  )
}
