/**
 * Guides Page
 */
export default function GuidesPage() {
  return (
    <>
      <div className={'flex-grow'}>
        <div className="bg-white shadow rounded-lg sm:max-w-screen-lg">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-xl font-semibold leading-6 text-gray-900">Guides</h2>
            <div className="my-2 text-sm text-gray-500">
              <p className="mb-2">Helpful tips from Paxton.</p>
            </div>
            <hr className={'my-3'} />
            <h3 className="text-base font-semibold leading-6 text-gray-900">Troubleshooting</h3>
            <div className="my-2 text-sm text-gray-500">
              <p className="mb-2">
                <span className={'font-bold'}>Firewall: </span>If you are having trouble submitting queries to Paxton, your firewall may be blocking some of
                Paxton's services. You can whitelist our servers to fix this problem. Your network administrator may need to take this action.
                <br />
                <br />
                Add the following URLs to your firewall's whitelist:
              </p>
              <ul className="list-disc list-inside">
                <li>app.paxton.ai</li>
                <li>api.paxton.ai</li>
                <li>api2.paxton.ai</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
