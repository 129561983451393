// reducerCurrentSourceWebsearchSetMetadata
import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import { ChatV2QueryMetadataWebsearchQuery, ChatV2QueryMetadataWebsearchQuerySchema, initialWebsearchMetadata } from '../schemas/chat-query-metadata-schema'

// Set the source_name for a conversation's currentSource
export const reducerCurrentSourceWebsearchSetMetadata = (
  state: ChatV2State,
  action: PayloadAction<{
    conversationId: string
    websearchMetadata: ChatV2QueryMetadataWebsearchQuery
  }>
) => {
  const { conversationId, websearchMetadata } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  if (conversation.currentSource == null)
    conversation.currentSource = {
      ...initialWebsearchMetadata,
    }

  const validation = ChatV2QueryMetadataWebsearchQuerySchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)
  if (conversation.currentSource == null) conversation.currentSource = { ...initialWebsearchMetadata }

  // Type the current source metadata
  const currentSource = (conversation.currentSource as ChatV2QueryMetadataWebsearchQuery)

  currentSource.type = websearchMetadata.type
  currentSource.site = websearchMetadata.site
}
