import { useContext, useEffect } from 'react'
import { AuthContext } from '@/context/auth-context'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { setStripePortalDialogVisible } from '@/store/slices/ui-state.slice'
import { SubscriptionStatus, SubscriptionType } from '@/firebase/auth/auth-jwt-schema'
import { useFetchCurrentPriceQuery } from '@/routes/dashboard/subscribe/store/subscription.slice.ts'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import CreateOrganizationCard from '@/components/orgs/create-organization-card'

const AccountPage = () => {
  const dispatch = useAppDispatch()
  const subscriptionState = useAppSelector((state: RootState) => state.subscriptionState)
  const formattedPrice = subscriptionState.currentSubscriptionFormattedPrice
  const currentSubscriptionFrequency = subscriptionState.currentSubscriptionFrequency
  const { user, userAccountData } = useContext(AuthContext)
  const isLoggedIn = user && !user.isAnonymous
  const { isLoading: isLoadingCurrentPrice, refetch: refetchCurrentPrice } = useFetchCurrentPriceQuery(undefined, {
    skip: !isLoggedIn || !userAccountData?.validSubscription,
  })

  useEffect(() => {
    if (isLoggedIn && userAccountData?.validSubscription) {
      refetchCurrentPrice()
    }
  }, [userAccountData, refetchCurrentPrice])

  let subscriptionStatusText
  switch (userAccountData?.subscriptionStatus) {
    case SubscriptionStatus.NONE:
      subscriptionStatusText = 'Blank'
      break
    case SubscriptionStatus.PAID:
      subscriptionStatusText = 'Paid'
      break
    case SubscriptionStatus.EXPIRED:
      subscriptionStatusText = 'Expired'
      break
    case SubscriptionStatus.TRIAL:
      subscriptionStatusText = 'Trial'
      break
    default:
      subscriptionStatusText = 'Trial'
  }

  const subscriptionType = userAccountData?.subscriptionType ?? SubscriptionType.STANDARD

  if (isLoadingCurrentPrice) {
    return (
      <div className={'flex-grow flex flex-col py-4 px-4 lg:px-0 mt-12 lg:mt-0 lg:mr-4 overflow-y-scroll'}>
        <div className="bg-white shadow rounded-lg">
          <div className="p-4">
            <h3 className="mb-2 text-base font-semibold leading-7 text-gray-900">Subscription Details</h3>
            <CircularProgressContinuous />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={'flex-grow flex flex-col py-4 px-4 lg:px-0 mt-12 lg:mt-0 lg:mr-4 overflow-y-scroll'}>
      <div className="bg-white shadow rounded-lg">
        <div className="p-4">
          <h3 className="mb-2 text-base font-semibold leading-7 text-gray-900">Subscription Details</h3>
          <p className="text-sm pb-1 text-gray-600">
            {formattedPrice}
            {` / ${currentSubscriptionFrequency} ${subscriptionType === SubscriptionType.EDUCATION ? '(student plan) ' : ''}- `}
            <button type="button" className="w-auto rounded-md text-sm px-0 font-semibold text-sky-600 hover:underline">
              <a className="" target="_blank" href="https://www.paxton.ai/pricing">
                Additional Pricing Information
              </a>
            </button>
          </p>
          <p className="mb-2 text-sm text-gray-600">Billed {currentSubscriptionFrequency}, cancel anytime.</p>
          <p className="mb-2 italic text-xs text-gray-600">Price above does not include promotions or discounts.</p>
          <div className={'inline-block'}>
            <button
              type="submit"
              className="w-full rounded-md bg-sky-600 px-3 py-2 mt-1 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 "
              onClick={() => dispatch(setStripePortalDialogVisible(true))}
            >
              Manage Subscription
            </button>
          </div>
          <hr className={'my-3'} />
          <div className="">
            <dl className="divide-y divide-gray-100 divide">
              <div className="pb-2">
                <dt className="text-sm font-bold text-gray-900">Status</dt>
                <dd className="text-sm text-gray-700">{subscriptionStatusText}</dd>
              </div>
              <div className="py-2">
                <dt className="text-sm font-bold text-gray-900">Account Type</dt>
                <dd className="text-sm text-gray-700">{userAccountData?.subscriptionType === SubscriptionType.EDUCATION ? 'Education' : 'Standard'}</dd>
              </div>
              <div className="pt-2">
                <dt className="text-sm font-bold text-gray-900">Email address</dt>
                <dd className="text-sm text-gray-700">{user?.email}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <CreateOrganizationCard />
    </div>
  )
}

export default AccountPage
