import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'

interface OnlineStatusContextProps {
  children: ReactNode
}

const OnlineStatusContext = createContext<boolean | undefined>(undefined)

export const useOnlineStatus = (): boolean => {
  const context = useContext(OnlineStatusContext)
  if (context === undefined) {
    throw new Error('useOnlineStatus must be used within an OnlineStatusProvider')
  }
  return context
}

export const OnlineStatusProvider: React.FC<OnlineStatusContextProps> = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)

  useEffect(() => {
    const updateOnlineStatus = (): void => {
      setIsOnline(navigator.onLine)
    }

    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return (): void => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])

  return <OnlineStatusContext.Provider value={isOnline}>{children}</OnlineStatusContext.Provider>
}
