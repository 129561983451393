import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export default function InlineTipError(message: string) {
  return (
    <div className="rounded-lg bg-red-50 p-3 my-2 grid grid-cols-[45px_auto] items-center">
      <span className={'inline-block h-8 w-8 text-red-700 rounded-lg p-1'}>
        <ExclamationTriangleIcon />
      </span>
      <span className={'inline-block text-xs text-red-900 text-opacity-70'}>{message}</span>
    </div>
  )
}
