import { CitatorCasesResponse } from '@/citator/schema/citation-metadata-schema'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { ChevronDownIcon, ExternalLinkIcon } from '@radix-ui/react-icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export type CitatorCasesCitedProps = {
  title: string
  isLoading: boolean
  isError: boolean
  refetch: () => void
  data: CitatorCasesResponse | undefined
  filterQuery: string
}

export default function CitatorCasesQueryView(props: CitatorCasesCitedProps) {
  const { title, isLoading, isError, refetch, data, filterQuery } = props

  // Local state
  const [expanded, setExpanded] = useState<boolean>(false)
  const actualExpanded = expanded || filterQuery != ''

  // console.log('DATA: ', data)

  // Is the data at the max cap?
  const maxCapLength = 1000 // current hard code max cap of 1000 cases
  const atMaxCap = data && data.length >= maxCapLength

  // Filter the data
  // Filters by case name
  const filteredData = data?.filter((item) => item.name.toLowerCase().includes(filterQuery.toLowerCase())) ?? []

  // Whether this is safe to display in a rapidly changing parent_id scenario
  // NOTICE: An error can occur after a previous RTk-Query has successfully executed and stored data. The errored RTK-Query will not update the data to null.
  const safeToDisplayData = !isError && !isLoading && data

  return (
    <div className={''}>
      <button
        className={'w-full flex px-2 items-center justify-between gap-x-2 rounded-md hover:bg-gray-100 transition-all ease-in-out duration-300'}
        onClick={() => setExpanded(!expanded)}
      >
        <div className={'font-bold text-sm text-left flex gap-x-2 items-center'}>
          <div>{title}</div>
          {safeToDisplayData && (
            <div>
              ({data.length}
              {atMaxCap && '+'})
            </div>
          )}
          {isLoading && (
            <div className={'px-1'}>
              <CircularProgressContinuousSized size={12} thickness={9} />
            </div>
          )}
        </div>
        {safeToDisplayData && data.length > 0 && (
          <div className={`${actualExpanded ? '-rotate-0' : '-rotate-90'} transition-all ease-in-out duration-300`}>
            <ChevronDownIcon width="24" height="24" />
          </div>
        )}
      </button>

      <div className={'px-2'}>
        {isError && (
          <div className={'pt-1'}>
            <div className={'text-red-700 text-sm'}>Error fetching data.</div>
            <button
              className={
                'flex-auto items-center rounded-md bg-sky-600 border-[1px] border-sky-600 px-2 py-1 my-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              }
              onClick={() => refetch()}
            >
              Retry
            </button>
          </div>
        )}

        {safeToDisplayData && data.length > 0 && filteredData.length === 0 && (
          <div className={'pt-1'}>
            <div className={'text-sm'}>No search results.</div>
          </div>
        )}

        {safeToDisplayData && actualExpanded && (
          <>
            {atMaxCap && <div className={'text-sm text-gray-500'}>Showing first {maxCapLength} cases.</div>}

            <ol className={`list-decimal pl-6 py-2 transition-all ease-in-out duration-300`}>
              {filteredData.map((item, index) => {
                // Show as link if we have a parent id and the document type is caselaw
                const showAsLink = item.parent_id && item.document_type == 'caselaw'

                return (
                  <li className={'marker:font-bold mb-2 text-sm'} key={`${item.name}-${index}`}>
                    {/* No parent id: plain text */}
                    {!showAsLink && <div className={'font-medium'}>{item.name}</div>}

                    {/* Has parent id: link */}
                    {showAsLink && (
                      <Link
                        to={`/dashboard/reference-viewer/caselaw/${item.parent_id}`}
                        target="_blank"
                        className={'flex items-end hover:text-sky-700 active:bg-sky-100'}
                      >
                        <span className={'inline text-sm font-medium text-sky-700 hover:text-sky-600 underline leading-tight'}>
                          {item.name}

                          <ExternalLinkIcon className={'inline w-4 h-4 pl-[4px] pb-[0px]'} />
                        </span>
                      </Link>
                    )}
                    <div>{item.bb_citation}</div>
                  </li>
                )
              })}
            </ol>
          </>
        )}
      </div>
    </div>
  )
}
