import { RootState } from '@/store/store'
import GlobalToastError from './GlobalToastError'
import GlobalToastSuccess from './GlobalToastSuccess'
import GlobalToastWarning from './GlobalToastWarning'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { GlobalToastType } from '@/constants/constants-ui'
import { closeGlobalToast } from '@/store/slices/global-toast.slice'

export default function GlobalToast() {
  // Store data
  const { open, message, type, durationMs } = useAppSelector((state: RootState) => state.globalToast)
  const dispatch = useAppDispatch()

  if (!open) return null

  // Close the toast after the duration
  setTimeout(() => {
    dispatch(closeGlobalToast())
  }, durationMs)

  // Return the appropriate toast component based on the type
  switch (type) {
    case GlobalToastType.SUCCESS:
      return <GlobalToastSuccess message={message} />

    case GlobalToastType.ERROR:
      return <GlobalToastError message={message} />

    case GlobalToastType.WARNING:
      return <GlobalToastWarning message={message} />

    default:
      return null
  }
}
