import { StorageReference, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import * as Sentry from '@sentry/browser'
import { getAuth } from 'firebase/auth'
import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'

export type UploadableFile = {
  file: File | Blob
  fileName: string
  id: string
}

type UploadFileHandlerArgs = {
  uploadableFile: UploadableFile
  currentFolder: StorageReference | null
  onProgress: (progress: number) => void
  onError: (error: Error) => void
  onComplete: () => void
}

/**
 * Upload File Handler
 * Based on Firebase Storage "Monitor Upload Progress" documentation
 * https://firebase.google.com/docs/storage/web/upload-files#monitor_upload_progress
 */
export default function uploadFileHandler(args: UploadFileHandlerArgs) {
  const { uploadableFile, currentFolder, onProgress, onError, onComplete } = args

  // Get the user's auth id
  const auth = getAuth()
  const uid = auth.currentUser?.uid

  if (uid == undefined) {
    throw new Error('Cannot run the uploadFileHandler: User is not authenticated. uid is undefined.')
  }

  // Assert that file of type Blob has a fileName
  if (uploadableFile.file instanceof Blob && !uploadableFile.fileName) {
    onError(new Error('Unexpected error uploading file.'))
    throw new Error('Cannot run the uploadFileHandler: UploadableFile type Blob must have a fileName.')
  }

  const storageFolder = currentFolder?.fullPath ?? `users/${uid}` // root if null

  // Determine name
  function fileName(): string {
    if (uploadableFile.fileName) {
      return uploadableFile.fileName
    }
    return uploadableFile.file instanceof File ? uploadableFile.file.name : 'file'
  }

  const fullStorageUrl = `${storageFolder}/${fileName()}`
  console.log('Uploading to storage URL: ', fullStorageUrl)

  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const storageRef = ref(storage, fullStorageUrl)
  const uploadTask = uploadBytesResumable(storageRef, uploadableFile.file)

  // Start the upload task
  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      onProgress(progress)
    },
    (error) => {
      // Handle unsuccessful uploads
      onError(error)
      Sentry.captureException(error)
    },
    () => {
      // Handle successful uploads on complete
      onComplete()
    }
  )
}
