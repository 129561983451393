import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { GlobalToastType, kGlobalToastDurationMs } from '@/constants/constants-ui'

// Define a type for the slice state
interface GlobalToastState {
  type: GlobalToastType
  message: string
  open: boolean
  durationMs: number
}

type OpenGlobalToastPayload = {
  type: GlobalToastType
  message: string
  durationMs?: number
}

// Define the initial state using that type
const initialState: GlobalToastState = {
  type: GlobalToastType.SUCCESS,
  message: '',
  open: false,
  durationMs: kGlobalToastDurationMs,
}

export const globalToastSlice = createSlice({
  name: 'globalToast',
  initialState,
  reducers: {
    openGlobalToast: (state, action: PayloadAction<OpenGlobalToastPayload>) => {
      // Update the state values
      state.message = action.payload.message
      state.type = action.payload.type
      state.durationMs = action.payload.durationMs || kGlobalToastDurationMs

      // Open the toast
      state.open = true
    },
    closeGlobalToast: (state) => {
      state.open = false
    },
  },
})

export const { openGlobalToast, closeGlobalToast } = globalToastSlice.actions

// Async Thunk - Show the toast and automatically close after the duration
export const showGlobalToast = createAsyncThunk('globalToast/showGlobalToast', async (payload: OpenGlobalToastPayload, { dispatch }) => {
  // Open the toast
  dispatch(openGlobalToast(payload))

  // Wait for the specified duration
  await new Promise((res) => setTimeout(res, payload.durationMs || kGlobalToastDurationMs))

  // Close the toast
  dispatch(closeGlobalToast())
})

export default globalToastSlice.reducer
