import { ReactNode, useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import { useParams } from 'react-router-dom'
import DashboardAuth from '@/routes/dashboard/auth/DashboardAuth'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { isPaxtonBrand } from '@/util/enterprise'
import { getBrandFromHost } from '@/util/enterprise'
import DashboardAuthHaiku from '@/routes/dashboard/auth/DashboardAuthHaiku'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'

type ChatV2AnonFeatureGuardProps = {
  children: ReactNode
  showAuthOnGuard: boolean
}

const hostBrand = getBrandFromHost()
/**
 * Anonymous Guard
 * Shows the sign in / sign up dialog if the user is anonymous
 */
export default function ChatV2AnonFeatureGuard(props: ChatV2AnonFeatureGuardProps) {
  const { children, showAuthOnGuard } = props
  const isPaxtonHost = isPaxtonBrand()

  const chatFeature = useParams().chatFeature

  // Auth context
  const authContext = useContext(AuthContext)
  const user = authContext?.user
  const userIsAnon = user?.isAnonymous

  // Allow non-anonymous users through
  if (userIsAnon == false) {
    return children
  }

  // Support trial usage of LRR for Paxton domain
  if (chatFeature === ChatV2Feature.lrr_v2 && isPaxtonHost) {
    return children
  }

  // Return the anonymous guard UX for all features except those allowed
  return showAuthOnGuard ? hostBrand === BrandEnum.HAIKU ? <DashboardAuthHaiku /> : <DashboardAuth /> : null
}
