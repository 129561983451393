import { ChatV2Conversation, ChatV2MessageReferenceType } from '@/chat-common/store/chat-v2.slice'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { kGcsUserMarkdownFilesBucketRef } from '@/constants/constants-gcs'
import { kGcsMdAllowedTags } from '@/constants/constants-ui'
import htmlTokenSequenceHighlighter from '@/util/html-processing/html-token-sequence-highlighter'
import markdownToHtmlString from '@/util/markdown-parsing/markdown-to-html'
import { getBlob, getStorage, ref } from 'firebase/storage'
import { Parser } from 'html-to-react'
import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react'
import sanitizeHtml from 'sanitize-html'
import { scrollToFirstHighlight } from '../reference-view-utils'

type ReferenceViewGcsMarkdownProps = {
  conversation: ChatV2Conversation
  setReferenceHtmlCallback?: Dispatch<SetStateAction<string>>
}

/**
 * Reference View GCS Markdown
 * 1. Retrieves a markdown source from GCS
 * 2. Converts it to sanitized HTML
 * 3. Highlights relevant snippets
 * @param props
 * @returns
 */
export default function ReferenceViewGcsMarkdown(props: ReferenceViewGcsMarkdownProps) {
  const { conversation, setReferenceHtmlCallback } = props
  const storage = getStorage(undefined, kGcsUserMarkdownFilesBucketRef)
  const containerRef = useRef<HTMLDivElement>(null)
  const renderTrigger: string = conversation.visibleReferenceRenderTrigger ?? ''
  const reference = conversation.visibleReference

  // Local state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [htmlString, setHtmlString] = useState<string | null>(null)

  // Define the function to load the HTML file
  async function fetchParseHighlightMd(reference: ChatV2MessageReferenceType) {
    console.log('ReferenceViewGcsMarkdown ref: ', reference)

    // Reset state
    setLoading(false)
    setError(false)
    setHtmlString(null)

    // Get the path from the reference
    const path = reference.metadata.storage_ref_full_path || reference.metadata.storage_path
    if (typeof path !== 'string') {
      console.error('gcsmd path is not a string.', reference)
      setError(true)
      return
    }

    // Load the HTML file from Firebase storage
    try {
      setLoading(true)

      // Add .md to the path
      const mdPath = path + '.md'

      // Fetch the md
      const storageRef = ref(storage, mdPath)
      const blob: Blob = await getBlob(storageRef)
      const blobType = blob.type

      // Make sure it's markdown parsing compatible before proceeding
      if (blobType !== 'text/plain' && blobType !== 'text/html' && blobType !== 'text/markdown') {
        console.error(`Cannot parse markdown from blob of this type: ${blob.type}, size: ${blob.size}`)
        setError(true)
        return
      }

      // Convert the blob to text
      const blobText = await blob.text()

      // Parse the markdown to HTML
      const htmlString = await markdownToHtmlString(blobText)

      // Convert the relevant snippets from .md strings into .html strings
      // Note: the same process that the original markdown went through, since
      // the highlight strings originate from the markdown and we need
      // consistency for matching
      const relevant_sentences_md = reference.relevant_sentences || []
      const relevant_sentences_html = await Promise.all(relevant_sentences_md.map((sentence) => markdownToHtmlString(sentence)))

      // NOTE: ENSURE BOTH SANITIZER PROCESSES BELOW ARE EQUAL
      const sanitizerOptions: sanitizeHtml.IOptions = {
        allowedTags: kGcsMdAllowedTags,
        allowedAttributes: {
          div: ['class'], // allow the table wrapper class on div to work
        },
      }

      // Sanitize the html
      const sanitizedHtml: string = sanitizeHtml(htmlString, sanitizerOptions)

      // Sanitize the relevant sentences
      const sanitizedRelevantSentences: string[] = relevant_sentences_html.map((sentence) => sanitizeHtml(sentence, sanitizerOptions))

      // Highlight in the sanitized HTML wherever there is a relevantSentences token sequence match
      const highlightedHtml = htmlTokenSequenceHighlighter(sanitizedHtml, sanitizedRelevantSentences)
      // console.log('highlightedHtml: ', highlightedHtml)

      setHtmlString(highlightedHtml)
      setReferenceHtmlCallback?.(highlightedHtml)
    } catch (e) {
      console.error(e)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  // Fetch the Reference Blob whenever the reference changes
  useEffect(() => {
    if (!reference) return

    // Call the function to load and set the HTML content
    fetchParseHighlightMd(reference)
  }, [reference, renderTrigger])

  // Scroll to the first highlighted portion whenever the HTML string changes
  useEffect(() => {
    scrollToFirstHighlight(containerRef, '.ref-highlight')
  }, [htmlString, renderTrigger])

  // If no reference
  if (!reference) return <div>No reference selected</div>

  // Show loading indicator
  if (loading) return <CircularProgressContinuousSized size={18} thickness={7} />

  // Show error
  if (error)
    return (
      <>
        {InlineTipError('Could not load this reference.')}
        <div>
          <button
            className={
              'inline rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 m-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
            }
            onClick={() => fetchParseHighlightMd(reference)}
          >
            Retry
          </button>
        </div>
      </>
    )

  // Configure the HTML parser
  const htmlParser = Parser()

  return (
    <div id="reference-view-gcsmd" ref={containerRef} className={'reference-view-html text-sm overflow-y-scroll py-2'}>
      {htmlParser.parse(htmlString)}
    </div>
  )
}
