import { ReactNode, useContext } from 'react'
import { useLocation } from 'react-router'
import { AuthContext } from '@/context/auth-context'
import DashboardAuth from '@/routes/dashboard/auth/DashboardAuth'
import { getBrandFromHost } from '@/util/enterprise'
import DashboardAuthHaiku from '@/routes/dashboard/auth/DashboardAuthHaiku'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'

const hostBrand = getBrandFromHost()
/**
 * Anonymous Guard
 * Shows the sign in / sign up dialog if the user is anonymous
 */
export default function AnonymousGuard({ children }: { children: ReactNode }) {
  const location = useLocation()

  // Auth Context
  const authContext = useContext(AuthContext)
  const user = authContext?.user
  const userIsAnon = user?.isAnonymous

  if (userIsAnon == true || userIsAnon == undefined) {
    console.log(`A permanent account is required to access ${location.pathname}`)
    return hostBrand === BrandEnum.HAIKU ? <DashboardAuthHaiku /> : <DashboardAuth />
  }

  return children
}
