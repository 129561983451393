import GuidesPage from '../guides/GuidesPage'
import ExamplesBlockAudit from './ExamplesBlockAudit'
import ExamplesBlockBooleanBuilder from './ExamplesBlockBooleanBuilder'
import ExamplesBlockCaseLaw from './ExamplesBlockCaseLaw'
import ExamplesBlockChat from './ExamplesBlockChat'
import ExamplesBlockChatYourFiles from './ExamplesBlockChatYourFiles'

export default function ExamplesPage() {
  return (
    <>
      <div className={'flex-grow flex flex-col py-4 pr-4 pl-4 lg:pl-0 mt-12 lg:mt-0 overflow-y-scroll'}>
        <GuidesPage />

        <div className={'pb-8'}>
          <div className="h-10" />
          <ExamplesBlockChat />
          <div className="h-10" />
          <ExamplesBlockChatYourFiles />
          <div className="h-10" />
          <ExamplesBlockCaseLaw />
          <div className="h-10" />
          <ExamplesBlockAudit />
          <div className="h-10" />
          <ExamplesBlockBooleanBuilder />
        </div>
      </div>
    </>
  )
}
