/**
 * Convert File To Scaled .png Blob
 * @returns {Blob} Resized image as a .png blob
 */
export function fileToPngBlob(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
  return new Promise((resolve, reject) => {
    // Create an image element
    const img = new Image()
    img.src = URL.createObjectURL(file)

    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        reject(new Error('Unable to get canvas context'))
        return
      }

      // Calculate the new size maintaining aspect ratio
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight
        }
      }

      // Set canvas size to the resized dimensions
      canvas.width = width
      canvas.height = height

      // Set fill style to transparent (to preserve transparency)
      ctx.fillStyle = 'rgba(0,0,0,0)'
      ctx.fillRect(0, 0, width, height)

      // Draw the resized image to the canvas
      ctx.drawImage(img, 0, 0, width, height)

      // Convert canvas to PNG blob
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob)
        } else {
          reject(new Error('Failed to convert canvas to blob'))
        }
      }, 'image/png')
    }

    img.onerror = () => {
      reject(new Error('Failed to load image'))
    }
  })
}
