import { ChatV2Feature, ChatV2MessageReferenceType } from '@/chat-common/store/chat-v2.slice'

/**
 * Escape Special Chars From Case Law Source
 * @param string
 * @returns the same string, but with special characters escaped
 */
export function escapeSpecialCharsFromHtmlSourceForSnippet(string: string): string {
  return string.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')
}

/**
 * Scroll To First Highlight
 * @param containerRef the container that will scroll (usually has the overflow-scroll property)
 * @param highlightClass the class we will look for the first instance of (includ the . at the beginning of the class name)
 * @returns {void}
 */
export function scrollToFirstHighlight(containerRef: React.RefObject<HTMLDivElement>, highlightClass: string): void {
  if (!containerRef.current) return

  // Get the first highlight
  const firstHighlightedSnippet = containerRef?.current?.querySelector<HTMLElement>(highlightClass)

  // Scroll to the first highlighted snippet
  if (firstHighlightedSnippet) {
    firstHighlightedSnippet.scrollIntoView({ behavior: 'smooth' })
  }
}

/**
 * Is Citator Enabled
 * Determines whether the citator should be enabled for this reference
 * @param feature the chat feature
 * @param reference the reference
 * @returns {boolean} whether the citator should be enabled for this reference
 */
export function isCitatorEnabled(feature: ChatV2Feature, reference: ChatV2MessageReferenceType): boolean {
  const isCaseLawFeatureV2 = feature === ChatV2Feature.caselaw && reference.metadata?.version === 2

  // The research feature returns both caselaw and lrr_v2 references, we can differentiate them by checking the reference_type
  const isResearchFeatureWithCaseLawReference = feature === ChatV2Feature.research && reference.metadata?.reference_type === ChatV2Feature.caselaw

  return isCaseLawFeatureV2 || isResearchFeatureWithCaseLawReference
}
