import React, { useContext } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { AuthContext } from '@/context/auth-context'
import { AccountBox, Api, Badge, BatchPrediction, ExitToAppOutlined, KeyboardArrowDown, Info, LiveHelp, Payment, Settings } from '@mui/icons-material'
import { kProduction } from '@/constants/constants-ui'
import { useNavigate } from 'react-router-dom'
import { kSegmentTrackOpenSupportChatClicked, kSegmentTrackSignOut } from '@/constants/constants-segment'
import { useHubspotConversations } from '@/context/hubspot-conversations-provider'
import signOutOfApp from '@/firebase/auth/sign-out'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import { getHelpCenterUrl } from '@/util/enterprise'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

// Style for fading out text that overflows the email / display name area
const fadeOutStyleHover = {
  content: '""',
  background: 'linear-gradient(to right, transparent, rgb(249 250 251))',
}
const fadeOutStyle = {
  content: '""',
  background: 'linear-gradient(to right, transparent, rgb(243 244 246))',
}

type Props = {
  onSelect: () => void
}

const DashboardSidebarAccountMenu: React.FC<Props> = (props: Props) => {
  const { onSelect } = props

  const navigate = useNavigate()
  const { toggleWidget } = useHubspotConversations()
  const { user, userAccountData } = useContext(AuthContext)
  const legacyCompatibleOrgId = userAccountData?.legacyDriveCompatibleOrganizationId
  const userIsAnon = user?.isAnonymous
  const isOrgAdmin = userAccountData?.isOrgAdmin
  const brand = userAccountData?.brand ?? BrandEnum.NONE
  const maskSentry = userAccountData?.brand == BrandEnum.HAIKU

  // Hover state
  const [isHovered, setIsHovered] = React.useState(false)

  // This is for logged in users only
  if (!user || userIsAnon) {
    return null
  }

  // const isPaxton = legacyCompatibleOrgId == 'paxton-eng-kfb92'

  // Menu elements
  const menuElements = [
    legacyCompatibleOrgId
      ? {
          hideFromAnon: true,
          name: 'Enterprise Account',
          icon: Badge,
          current: false,
          noClick: true,
          onClick: () => {
            return
          },
        }
      : {
          hideFromAnon: true,
          name: 'Manage subscription',
          icon: Payment,
          current: false,
          onClick: () => {
            navigate('/dashboard/account')
          },
        },

    ...(legacyCompatibleOrgId && isOrgAdmin
      ? [
          {
            hideFromAnon: true,
            name: 'Admin Dashboard',
            icon: Settings,
            current: false,
            onClick: () => {
              navigate('/dashboard/admin')
            },
          },
        ]
      : []),

    {
      name: 'Examples',
      icon: BatchPrediction,
      onClick: () => {
        navigate('/dashboard/examples')
      },
      excludedBrands: [BrandEnum.HAIKU],
    },
    {
      name: 'Help Center',
      icon: Info,
      onClick: () => {
        window.open(getHelpCenterUrl(), '_blank')
      },
    },
    {
      hideFromAnon: false,
      name: 'Chat with support',
      icon: LiveHelp,
      current: false,
      onClick: () => {
        // Track
        analytics.track(kSegmentTrackOpenSupportChatClicked)

        toggleWidget()
      },
      excludedBrands: [BrandEnum.HAIKU],
    },
    {
      hideFromAnon: true,
      name: 'Sign Out',
      icon: ExitToAppOutlined,
      current: false,
      onClick: () => {
        // Track
        analytics.track(kSegmentTrackSignOut)

        signOutOfApp(navigate)
      },
    },
    ...(!kProduction
      ? [
          {
            name: 'Log Token (dev only)',
            icon: Api,
            current: false,
            onClick: async () => {
              const token = await user.getIdToken(true) // force refresh true
              console.log(token)
            },
          },
        ]
      : []),
  ].filter((item) => ((userIsAnon && !item.hideFromAnon) || !userIsAnon) && !item.excludedBrands?.includes(brand))

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className="rounded-md p-1 text-gray-700 flex gap-x-[2px] items-center text-sm leading-6 font-medium cursor-pointer hover:text-sky-600 bg-gray-100 hover:bg-gray-50 outline-none"
          aria-label="Account Menu"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <AccountBox className={'text-gray-400 shrink-0'} viewBox="2 0 24 24" />
          <div className={'relative flex-grow overflow-hidden text-left flex-nowrap whitespace-nowrap' + (maskSentry ? ' sentry-mask' : '')}>
            {user.displayName ?? user.email}
            <span className="absolute right-0 top-0 w-6 h-full" style={isHovered ? fadeOutStyleHover : fadeOutStyle} />
          </div>
          <KeyboardArrowDown />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-50 min-w-[200px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >
          <ul role="list" className="space-y-1">
            {menuElements.map((item) => (
              <li key={item.name}>
                <DropdownMenu.Item
                  onClick={() => {
                    item.onClick()
                    onSelect()
                  }}
                  className={classNames(
                    item.current
                      ? 'bg-gray-50 text-sky-600'
                      : `text-gray-700 ${item.noClick ? 'outline-none ring-0 cursor-default' : 'hover:text-sky-600 hover:bg-gray-50 cursor-pointer'}`,
                    'rounded-md group flex items-center flex-nowrap gap-x-1 py-[2px] text-sm leading-6 w-full'
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-sky-600' : `${item.noClick ? '' : 'text-gray-400 group-hover:text-sky-600'}`,
                      'h-5 w-5 shrink-0'
                    )}
                    aria-hidden="true"
                    viewBox="0 0 30 24"
                  />
                  <div className={`whitespace-nowrap `}>{item.name}</div>
                </DropdownMenu.Item>
              </li>
            ))}
          </ul>

          <DropdownMenu.Arrow className="fill-white" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default DashboardSidebarAccountMenu
