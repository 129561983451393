import { Promotion } from '@/routes/dashboard/subscribe/schema/promo-code.schema.ts'

export function DueSectionResignup(props: { amountDue: number; originalPrice?: number | null; promotion?: Promotion | null }) {
  const amountDue = props.amountDue.toString().includes('.') ? props.amountDue.toFixed(2) : props.amountDue.toString()
  return (
    <div className="space-y-2 mb-3 bg-white rounded-lg">
      {/* Due when trial ends */}
      <div className="flex justify-between items-center">
        <span className="text-gray-700 font-semibold">Due today</span>
        <div className="flex items-center">
          {props.originalPrice && <span className="text-gray-400 line-through mr-2">${props.originalPrice}</span>}
          <span className="text-gray-500">${amountDue}</span>
        </div>{' '}
      </div>
      {/* Promotion description */}
      {props.promotion && (
        <div className="flex justify-between items-center gap-4">
          <span className="text-green-700 font-semibold">{props.promotion.code}</span>
          <span className="text-green-700">{props.promotion.coupon.name}</span>
        </div>
      )}
    </div>
  )
}

export default DueSectionResignup
