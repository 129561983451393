import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { ChatV2Message, ChatV2MessageType } from '../../store/chat-v2.slice'
import chatV2FormatResponseString from '@/chat-common/util/chat-v2-format-response'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { setMessageFeedbackIndicator, toggleMessageExpandReferences, toggleMessageExpandRelatedReferences } from '../../store/chat-v2-ux.slice'
import BlinkingCursor from '@/components/loaders/BlinkingCursor'
import { useState } from 'react'
import { ClipboardIcon, HandThumbDownIcon, HandThumbUpIcon, SignalSlashIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { useOnlineStatus } from '@/context/online-status-context'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import putMessageFeedback from '@/chat-common/fetch/put-message-feedback'
import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'
import getUniqueReferenceNumbersInMessage from '@/chat-common/util/chat-v2-get-references-in-message'
import ChatMessageBubbleReference from './chat-message-bubble-reference'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { ConversationItem, useGetUsersConversationListQuery } from '../../chat-v2-api'
import { sanitizeFileName } from '@/routes/dashboard/files/files-utils'
import { getBrandName } from '@/util/enterprise'
import { downloadResponseAsDocxRemote, copyResponseToClipboard } from '@/chat-common/util/download-conversation'
import ConfidenceIndicatorPopover from '@/components/confidence-indicator/views/ConfidenceIndicatorPopover'

type ChatResponseBubbleProps = {
  message: ChatV2Message
  openFeedbackDialog: (message: ChatV2Message, positive: boolean) => void
}

export default function ChatResponseBubble(props: ChatResponseBubbleProps) {
  const { trackEvent } = useAnalytics()
  const { message, openFeedbackDialog } = props
  const { metadata, text } = message
  const isOnline = useOnlineStatus()

  // UX Store
  const dispatch = useAppDispatch()
  const { messageExpandReferences, messageExpandRelatedReferences, messageFeedbackIndicators } = useAppSelector((state: RootState) => state.chatV2UxState)
  const expandReferences = messageExpandReferences.includes(metadata.message_id)
  const expandRelated = messageExpandRelatedReferences.includes(metadata.message_id)
  const conversation = useAppSelector((state: RootState) => state.chatV2State.conversations[metadata.conversation_id])

  // Load chat list for conversation data (RTK-query)
  const { data: dataChatList } = useGetUsersConversationListQuery(null)

  const messages = conversation?.messages ?? {}
  const messagesArray: ChatV2Message[] = Object.values(messages) ?? []

  // Check if this message is the most recent record in the messages record
  const isMostRecent: boolean = message.metadata.message_id == messagesArray.pop()?.metadata?.message_id
  const isResponse = metadata.message_type === ChatV2MessageType.response
  const showCursor = isMostRecent && isResponse && conversation.isLoading

  // Get message feedback indicator
  const messageFeedbackThumb = messageFeedbackIndicators[metadata.message_id] ?? null

  // Reference sorting
  const references = metadata.references
  const referencesInMessage = getUniqueReferenceNumbersInMessage(message) // May hallucinate reference numbers - use metadata.references as source of truth

  // Get used and unused references based on whether the metadata.references are found inside the message
  const usedReferences = Object.keys(references).filter((key) => referencesInMessage.includes(key))
  const unusedReferences = Object.keys(references).filter((key) => !referencesInMessage.includes(key))

  // Blink state - blink is false until 500ms after render
  // Desired UX: solid cursor while text is streaming, blinking cursor when text streaming is paused / waiting
  const [blink, setBlink] = useState(false)
  setTimeout(() => {
    setBlink(true)
  }, 500)

  // Trim the text
  const trimmedText = text.trim()

  // Format the message text if it's a response (creates footnote links)
  const formattedText = chatV2FormatResponseString(conversation, message)

  // Message header
  const messageHeader = getBrandName()

  // Count the references
  const referencesCount = Object.keys(references).length

  // Confidence Indicator Vars
  const confidenceLoading = false // always false for now because it's instantly returned after the message stream
  const confidence_label = message.confidence_label ?? null
  const showConfidence = !showCursor && message.confidence_label != null // Show confidence indicator when not streaming and when it is not null

  // Handle Copy
  const handleCopyMessageText = async () => {
    await copyResponseToClipboard(message, conversation)
    dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: 'Text copied to clipboard', durationMs: 2000 }))
  }

  const handleDownloadAsDocx = () => {
    try {
      const title = dataChatList?.conversations?.find((conversation: ConversationItem) => conversation.id === metadata.conversation_id)?.title
      const fileName = `${title ? sanitizeFileName(title) : 'Response'}`
      downloadResponseAsDocxRemote(message, conversation, dispatch, fileName)
      dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: fileName + ' downloaded', durationMs: 2000 }))
    } catch (error) {
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Response download failed', durationMs: 2000 }))
      throw error
    }
  }

  return (
    <>
      <div className={`p-2 rounded-lg text-base whitespace-pre-wrap bg-white`}>
        <div className={`font-bold text-xl mb-1`}>{messageHeader}</div>
        <div className={`text-sm leading-6 mb-1`}>
          {trimmedText == '' && !conversation.isLoading && (
            <div className={`text-gray-600`}>
              Response may still be generating. If you refreshed while the response was being generated, it may not yet be complete. Try refreshing again in a
              few moments.
            </div>
          )}
          <div id="chat-response-bubble" className="flex flex-col">
            {formattedText}
          </div>

          {showCursor && <BlinkingCursor blink={blink} />}
        </div>
        {metadata.message_type === ChatV2MessageType.client_error && !isOnline && isMostRecent && (
          <div className={'py-2 flex items-center gap-x-1 text-sm font-normal text-red-700'}>
            <div className={'w-5 h-5'}>
              <SignalSlashIcon />
            </div>
            <div className={''}>No connection</div>
          </div>
        )}

        {/* Message Action Icons */}
        {metadata.message_type === ChatV2MessageType.response && !conversation.isLoading && (
          <div className={`flex gap-x-2 items-center text-gray-500 text-xs mb-1`}>
            <button
              className={`p-1 mb-1 rounded-full hover:text-sky-700 active:bg-sky-100 ${messageFeedbackThumb == FeedbackThumb.UP ? 'bg-sky-100' : ''}`}
              onClick={() => {
                // Submit initial feedback
                putMessageFeedback({ message: message, thumb: FeedbackThumb.UP, thumb_text: '' })

                // Open dialog for additional feedback
                openFeedbackDialog(message, true)

                // Set the feedback icon
                dispatch(setMessageFeedbackIndicator({ messageId: metadata.message_id, thumb: FeedbackThumb.UP }))
              }}
            >
              <HandThumbUpIcon className={'h-5'} />
            </button>
            <button
              className={`p-1 mb-1 rounded-full hover:text-sky-700 active:bg-sky-100  ${messageFeedbackThumb == FeedbackThumb.DOWN ? 'bg-sky-100' : ''}`}
              onClick={() => {
                // Submit initial feedback
                putMessageFeedback({ message: message, thumb: FeedbackThumb.DOWN, thumb_text: '' })

                // Open dialog for additional feedback
                openFeedbackDialog(message, false)

                // Set the feedback icon
                dispatch(setMessageFeedbackIndicator({ messageId: metadata.message_id, thumb: FeedbackThumb.DOWN }))
              }}
            >
              <HandThumbDownIcon className={'h-5'} />
            </button>
            <button
              className={'p-1 mb-1 rounded-full hover:text-sky-700 active:bg-sky-100'}
              onClick={() => {
                console.log('copy')
                trackEvent(AnalyticsEvent.CopyChatResponse)
                handleCopyMessageText()
              }}
            >
              <ClipboardIcon className={'h-5'} />
            </button>
            <button
              className={'p-1 mb-1 rounded-full hover:text-sky-700 active:bg-sky-100'}
              onClick={() => {
                trackEvent(AnalyticsEvent.DownloadChatResponseToDocx)
                handleDownloadAsDocx()
              }}
            >
              <DocumentArrowDownIcon className={'h-5'} />
            </button>
          </div>
        )}

        {/* Confidence Indicator */}
        {showConfidence && (
          <div className={'px-1 mb-2'}>
            <ConfidenceIndicatorPopover confidence_label={confidence_label} loading={confidenceLoading} />
          </div>
        )}

        {/* References */}
        {referencesCount > 0 && (
          <div className={'border-t border-gray-200 pt-2 flex'}>
            <div
              className={`px-1 text-sm text-gray-500 flex items-center gap-x-2 cursor-pointer rounded-md hover:bg-gray-100`}
              onClick={() =>
                dispatch(
                  toggleMessageExpandReferences({
                    messageId: metadata.message_id,
                  })
                )
              }
            >
              {usedReferences.length} cited references
              <div className={``}>{expandReferences ? <ChevronDownIcon width="16" height="16" /> : <ChevronRightIcon width="16" height="16" />}</div>
            </div>
          </div>
        )}
        {expandReferences && (
          <div className={`text-sm mt-2 px-1`}>
            {usedReferences.map((referenceKey) => (
              <ChatMessageBubbleReference key={referenceKey} conversation={conversation} message={message} referenceKey={referenceKey} />
            ))}
            {unusedReferences.length > 0 && (
              <>
                <hr className={'mb-4 text-gray-200'} />
                <button
                  className={`px-1 text-sm text-gray-500 flex items-center gap-x-2 cursor-pointer rounded-md hover:bg-gray-100`}
                  onClick={() =>
                    dispatch(
                      toggleMessageExpandRelatedReferences({
                        messageId: metadata.message_id,
                      })
                    )
                  }
                >
                  {unusedReferences.length} related references:{' '}
                  <div className={``}>{expandRelated ? <ChevronDownIcon width="16" height="16" /> : <ChevronRightIcon width="16" height="16" />}</div>
                </button>
                {expandRelated &&
                  unusedReferences.map((referenceKey) => (
                    <ChatMessageBubbleReference key={referenceKey} conversation={conversation} message={message} referenceKey={referenceKey} />
                  ))}
              </>
            )}
          </div>
        )}
      </div>
      <div className={`h-2`} />
    </>
  )
}
