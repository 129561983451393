const daysUntilFromUnix = (unixTimestamp: number): number => {
  const oneDay = 24 * 60 * 60 * 1000 // milliseconds in a day
  const futureDate = new Date(unixTimestamp * 1000)
  const currentDate = new Date()

  const diffInTime = futureDate.getTime() - currentDate.getTime()
  const diffInDays = Math.ceil(diffInTime / oneDay)

  return diffInDays
}

export { daysUntilFromUnix }
