import { kGcsUserMarkdownFilesBucketRef } from '@/constants/constants-gcs'
import { getStorage, ref, getBlob } from 'firebase/storage'

// Initialize Firebase Storage For Markdown Bucket
const markdownStorageBucket = getStorage(undefined, kGcsUserMarkdownFilesBucketRef)

/**
 * Get a markdown file as a text string from Google Cloud Storage
 * @param path
 * @returns
 */
export async function getMarkdownFileFromGcs(path: string): Promise<string> {
  try {
    const fileRef = ref(markdownStorageBucket, path)

    // Get the blob
    const blob: Blob = await getBlob(fileRef)

    // Use FileReader to convert blob to text
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.readAsText(blob)
    })
  } catch (error) {
    console.error('Failed to download file: ', error)
    throw new Error('Failed to download file')
  }
}
