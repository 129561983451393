import { defaultNewCaseLawValues } from '../store/chat-v2-reducers-caselaw'
import { defaultNewResearchValues } from '../store/chat-v2-reducers-research'
import { defaultNewAssistantValues } from '../store/chat-v2-reducers-assistant'
import { ChatV2Feature } from '../store/chat-v2.slice'
import { initialWebsearchMetadata } from '@/chat-common/schemas/chat-query-metadata-schema'
/**
 * Get Default Query Metadata By Feature
 */
export default function getDefaultQueryMetadataByFeature(feature: ChatV2Feature) {
  switch (feature) {
    case ChatV2Feature.lrr:
      // This feature requires a user selection, the default can be null
      return null

    case ChatV2Feature.caselaw:
      return defaultNewCaseLawValues

    case ChatV2Feature.research:
      return defaultNewResearchValues

    case ChatV2Feature.documentquery:
      // This feature requires a user selection, the default can be null
      return null

    case ChatV2Feature.websearch:
      return initialWebsearchMetadata

    case ChatV2Feature.contractsearch:
      return {}

    case ChatV2Feature.assistant:
      return defaultNewAssistantValues

    default:
      return null
  }
}
