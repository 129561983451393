import { Docx2HtmlCommentTree } from '../schema/docx2html.schema'

/**
 * Docx2Html Identify and Markup Comments
 * This function identifies all HTML tags between two <span> tags with the same tracked-changes comment ID
 * and applies identification markup. This allows us to:
 *   1. Highlight the text
 *   2. Make the text interactive
 *
 * We also add an anchor tag immediately before the comment-start tag with the comment ID as the href.
 * This allows us to:
 *   1. Scroll to the comment when the user clicks on the comment in the comment list
 *   2. Identify the height of the comment in the content
 *      (empty <span> tags will always return a position of (0,0) and cannot be used for this purpose)
 *
 * @param htmlString
 * @param commentId
 * @param extractedComments allow us to include only top level comments
 * @returns
 */
export function docx2HtmlMarkupHighlightComments(htmlString: string, activeCommentId: string | null, extractedComments: Docx2HtmlCommentTree): string {
  // Parser
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')

  // Convert all text nodes to span elements
  convertAllTextNodesToSpan(doc)

  // Find the comment-start and comment-end elements
  const commentStartElements = doc.getElementsByClassName('comment-start')
  const commentEndElements = doc.getElementsByClassName('comment-end')

  // Filter commentStartElements to only include top level comments (from extractedComments)
  const topLevelCommentIds = Object.keys(extractedComments)
  const topLevelCommentStartElements = Array.from(commentStartElements).filter((el) => {
    const commentId = el.id
    return topLevelCommentIds.includes(commentId)
  })

  // For every comment start element, markup the tracked changes appropriately
  topLevelCommentStartElements.forEach((startElement) => {
    // Get the comment ID
    const commentId = startElement.id

    // Determine if this is the active comment
    const activeHighlight = activeCommentId === commentId

    // Find the corresponding end element
    const endElement = Array.from(commentEndElements).find((el) => el.id === commentId)

    // Validate the elements exist
    if (!startElement || !endElement) {
      console.error(`Could not find start or end comment for comment ID: ${commentId}. Start: ${typeof startElement}, End: ${typeof endElement}`)
      return htmlString
    }

    // Validate the endElement follows the startElement (so we don't create an infinite loop)
    const validFollow = startElement.compareDocumentPosition(endElement) & Node.DOCUMENT_POSITION_FOLLOWING
    if (!validFollow) {
      console.error(`Comment endElement does not follow the startElement. Comment ID: ${commentId}`)
      return htmlString
    }

    // Create an anchor element for the comment and set the id
    const anchorElement = doc.createElement('a')
    anchorElement.id = `comment-anchor-${commentId}`
    anchorElement.classList.add('scroll-mt-36', 'md:scroll-mt-52')

    // Insert the anchor element before the start element
    startElement.before(anchorElement)

    // Mark the starting and ending elements
    const startClass = `comment-start-${commentId}`
    const endClass = `comment-end-${commentId}`
    startElement.classList.add(startClass, `comment-marked`)
    endElement.classList.add(endClass, `comment-marked`)

    // Traverse and apply class
    traverseAndMarkupUntilCommentEnd(startElement, endClass, commentId, activeHighlight, extractedComments)
  })

  return doc.body.innerHTML
}

/**
 * Recursive: Traverse and markup until the comment end
 * - For every node between "comment-start" and "comment-end", convert text nodes to <span> elements
 *   and apply markup that allows for styling and interactivity of comments.
 *
 * - This recursive function needs to continue traversing down the current node tree,
 *   and potentially further up and across until the "comment-end" element is reached.
 *
 * Features:
 * - applies "doc-comment-click-trigger" class to enable on-click interactivity
 * - applies "part-of-comment-id-${commentId}" class so we know which commentId we are activating
 * - applies "active-highlight-comment" class if the comment is the active comment
 * @param startElement
 * @param endClass
 * @param commentId
 * @param activeHighlight
 */
function traverseAndMarkupUntilCommentEnd(
  startElement: Element,
  endClass: string,
  commentId: string,
  activeHighlight: boolean,
  extractedComments: Docx2HtmlCommentTree
): void {
  // Done flag
  let endReached = false
  let counter = 0

  // Comment type
  const commentType = extractedComments[commentId].commentType

  // Main Traversal Loop
  function mainTraversalLoop(element: Element) {
    if (endReached) return

    // If we've reached the end node, stop
    if (element?.classList.contains(endClass)) {
      endReached = true
      return
    }

    // Function: Mark all child nodes recursively
    function markChildNodes(thisElement: Element): void {
      if (endReached) return

      // If we've reached the end node, stop
      if (thisElement.classList.contains(endClass)) {
        endReached = true
        return
      }

      // IF not marked, markup the current element
      if (!thisElement.classList.contains(`part-of-comment-id-${commentId}`)) {
        // console.log(`Marking for comment ${commentId}: `, thisElement)
        thisElement.classList.add(`doc-comment-click-trigger`, `part-of-comment-id-${commentId}`, `part-of-comment-type-${commentType}`)
      }

      // If activeHighlight, add the active class
      if (activeHighlight) {
        thisElement.classList.add('active-highlight-comment')
      }

      // For every child node, convert text nodes to span elements and
      // recursively call the function to mark the element and its children
      // This for loop also checks all siblings of the firstChild of the thisElement
      for (let child = thisElement.firstElementChild; child !== null; child = child?.nextElementSibling ?? null) {
        markChildNodes(child)
      }

      counter = counter + 1
    }

    // Perform the initial markup
    markChildNodes(element)

    // Get the next element
    const nextElement = element.nextElementSibling

    // If we have a next element and we have not reached the end
    // start the recursive process again
    if (nextElement !== null && !endReached) {
      mainTraversalLoop(nextElement)
    }

    // IF
    // - sibling is null AND
    // - we have not reached the end AND
    // - we have a parent
    //
    // We need to move up to the parent node, and continue traversing
    if (nextElement == null && !endReached && element.parentElement) {
      mainTraversalLoop(element.parentElement)
    }
  }

  // Start the main traversal loop
  mainTraversalLoop(startElement)
}

/**
 * Convert Text Node to Span
 * If the next sibling is a text node and not empty, wrap it in a span tag
 * @param node
 * @returns {string | null} the id of the converted string if it was converted
 */
function convertTextNodeToSpan(document: Document, node: ChildNode | null): void {
  // If the next sibling is a text node and not empty, wrap it in a span tag
  if (node?.nodeType === Node.TEXT_NODE && node.textContent?.trim() != '') {
    // Create the span and replace the text node with the span
    const span = document.createElement('span')
    span.textContent = node.textContent
    span.classList.add('converted-span')
    node.replaceWith(span)
  }

  return
}

/**
 * Convert All Text Nodes to Span
 * This function converts all text nodes to span elements
 * @param doc
 */
function convertAllTextNodesToSpan(doc: Document): void {
  function convertAllNodes(nodes: NodeListOf<ChildNode>) {
    // Convert all plain text nodes to span elements
    // This is necessary to allow for the application of classes and interactivity
    Array.from(nodes).forEach((node) => {
      convertTextNodeToSpan(doc, node)

      // Recursively convert all children
      if (node.hasChildNodes()) {
        convertAllNodes(node.childNodes)
      }
    })
  }

  convertAllNodes(doc.body.childNodes)
}
