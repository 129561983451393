import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { store } from '../store'
import {
  addSources,
  addToStreamedResponseString,
  nullifyData,
  setSearchedQuery,
  setStreamError,
  setStreamLoading,
} from '../slices/boolean-builder-stream-response.slice'
import { kBooleanBuilderApiQueryPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError, kErrorNoStream } from '@/constants/constants-error-codes'
import { queryStreamErrorHandler, queryStreamResponseThrower } from '@/util/query-stream-error-handler'
import { nanoid } from 'nanoid'

type CallBooleanBuilderQueryStreamArgs = {
  query: string
  platform: string
}

/**
 * Call Document Interface Query Stream
 * - Receives a streaming response from the Document Interface API
 * - Updates the store with the response data as it is received
 * @param query
 */
export async function callBooleanBuilderQueryStream(args: CallBooleanBuilderQueryStreamArgs): Promise<any> {
  const { query, platform } = args

  // Reset the state
  store.dispatch(nullifyData())
  store.dispatch(setSearchedQuery(query))
  store.dispatch(setStreamLoading(true))

  const token = await getAuth().currentUser?.getIdToken()
  if (!token) throw Error(kErrorCodeAuthError)

  const sentry_transaction_id = nanoid()
  const apiUrl = kPaxtonAppApiBaseUrl() + kBooleanBuilderApiQueryPath
  const requestOptions: RequestInit = {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-Transaction-Id': sentry_transaction_id,
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify({
      query,
      platform,
      stream: true,
    }),
  }

  try {
    console.log(`Querying ${apiUrl} with ${JSON.stringify(requestOptions, null, 2)}`)

    const response = await fetch(apiUrl, requestOptions)
    // Generic response error handler
    await queryStreamResponseThrower(response)

    // Null check response body and stream
    if (!response.body || typeof response.body.getReader !== 'function') throw Error(kErrorNoStream)

    var sourceInProgress = ''
    const reader = response.body.getReader()
    const readChunk = async (): Promise<void> => {
      const { done, value } = await reader.read()

      if (done) {
        // Set streamed response loading as false (done)
        store.dispatch(setStreamLoading(false))
        return
      }

      // Decode the Uint8Array into a string
      const chunk = new TextDecoder().decode(value)
      console.log('value', value)
      console.log('chunk', chunk)

      try {
        if (!chunk.includes('pax-source-idfy#:') && !chunk.includes('pax-response-idfy#:')) {
          // the source can overflow and be split into multiple chunks
          // this implies all overflow chunks will be for sources.
          sourceInProgress += chunk
          console.log('noprefix', sourceInProgress)
        }

        // Update the sources state if we've received sources
        // The entire sources chunk should be received at once as a JSON objectn but it can overflow
        if (chunk.includes('pax-source-idfy#:')) {
          // Parse the JSON and add the sources JSON object to state

          let sourcesSplit = chunk.split('pax-source-idfy#:')
          console.log('sourcesSplits', sourcesSplit)

          if (sourcesSplit[0] === '') {
            if (sourceInProgress.length > 0) {
              console.log('sourceInProgress- flushing to store', sourceInProgress)
              store.dispatch(addSources(JSON.parse(sourceInProgress)))
              sourceInProgress = ''
            }
          }
          if (sourcesSplit.length > 2) {
            console.log('received N sources in chunk', sourcesSplit.length - 1)
            // get each element, add all but the last to the store
            // push the last element to the sourceInProgress
            if (sourcesSplit[0] != '') {
              sourceInProgress += sourcesSplit[0]
              store.dispatch(addSources(JSON.parse(sourceInProgress)))
            }
            for (let i = 1; i < sourcesSplit.length - 1; i++) {
              console.log('adding....', sourcesSplit[i])
              store.dispatch(addSources(JSON.parse(sourcesSplit[i])))
            }
            console.log('pushing the last one to in prog', sourcesSplit[sourcesSplit.length - 1])
            sourceInProgress = sourcesSplit[sourcesSplit.length - 1]
          } else {
            console.log('adding source', sourceInProgress[1])
            sourceInProgress = sourcesSplit[1]
          }
        }

        // Update the response state if we've received a response chunk
        // This will continue streaming response chunk updates until complete
        if (chunk.includes('pax-response-idfy#:')) {
          // in the event the last source was split into multiple chunks, handle the cleanup
          if (sourceInProgress.length > 0) {
            console.log('sourceInProgress- flushing to store', sourceInProgress)
            store.dispatch(addSources(JSON.parse(sourceInProgress)))
            sourceInProgress = ''
          }

          let responses = chunk.split('pax-response-idfy#:')
          for (let i = 1; i < responses.length; i++) {
            console.log('adding response', responses[i])
            store.dispatch(addToStreamedResponseString(responses[i]))
          }
        }
      } catch (error) {
        // Capture any errors that occur while adding the chunk to state
        console.error(`Error adding chunk to state: ${chunk}`)
        console.error(`SOURCE IN PROG ${sourceInProgress}`)
        throw error
      }

      // Call recursively until done
      await readChunk()
    }
    await readChunk()
  } catch (error) {
    // Handle any query stream errors
    console.error(error)

    queryStreamErrorHandler({
      error,
      query,
      streamErrorSetter: (message: string) => {
        store.dispatch(setStreamError(message))
      },
    })
  } finally {
    store.dispatch(setStreamLoading(false))
  }
}
