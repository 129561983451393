import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { WebsocketQueryStreamConstructorArgs, WebsocketQueryStreamType } from './websocket-schema'
import { nanoid } from 'nanoid'
import { getAuth } from '@firebase/auth'
import * as Sentry from '@sentry/browser'

/**
 * WebsocketQueryStream is a class provides
 * common handling of short lived websocket connections and
 * corresponding message and error handling capabilities for
 * features that require a websocket connection.
 *
 * @param queryStreamId - The identifier for this query stream
 */
export class WebsocketQueryStream {
  private streamType: WebsocketQueryStreamType
  private initialQueryPayload: any
  private onMessageHandler: (instance: WebsocketQueryStream, data: any) => void
  private onError: (instance: WebsocketQueryStream, event: Event) => void
  private onCloseAll: (instance: WebsocketQueryStream) => void
  private onCloseSuccess: (instance: WebsocketQueryStream) => void
  private onCloseUnexpected: (instance: WebsocketQueryStream, code: number, reason: string) => void
  private sentryTransactionId: string = nanoid()
  private queryStreamId: string | null = null

  /**
   * Constructor for the Websocket Query Stream
   * @param args - The arguments to pass to the Websocket Query Stream constructor
   */
  constructor(args: WebsocketQueryStreamConstructorArgs) {
    this.streamType = args.streamType
    this.initialQueryPayload = args.initialQueryPayload
    this.onMessageHandler = args.onMessageHandler
    this.onError = args.onError
    this.onCloseAll = args.onCloseAll
    this.onCloseSuccess = args.onCloseSuccess
    this.onCloseUnexpected = args.onCloseUnexpected
  }

  // Setters
  set setQueryStreamId(id: string) {
    this.queryStreamId = id
  }

  // Getters
  get getQueryStreamId(): string | null {
    return this.queryStreamId
  }

  // Get the endpoint for the websocket stream based on the stream type
  private get streamEndpoint(): string {
    switch (this.streamType) {
      case WebsocketQueryStreamType.CHATV2:
        return `${kPaxtonAppApiBaseUrl(true)}/api/v1/chat/ws`

      case WebsocketQueryStreamType.DOCUMENT_EDITING:
        return `${kPaxtonAppApiBaseUrl(true)}/api/v1/contract_analysis/ws`

      default:
        throw new Error(`Invalid WebsocketQueryStreamType: ${this.streamType}`)
    }
  }

  // Get the default extras to send to sentry
  private get sentryDefaultExtras(): Record<string, string> {
    return {
      streamType: this.streamType,
      streamEndpoint: this.streamEndpoint,
      initialQueryPayload: JSON.stringify(this.initialQueryPayload),
      online: navigator.onLine.toString(),
      cookiesEnabled: navigator.cookieEnabled.toString(),
      sentryTransactionId: this.sentryTransactionId,
      anonymousUser: getAuth().currentUser?.isAnonymous.toString() ?? 'null',
    }
  }

  /**
   * Execute the websocket query stream
   * @returns void
   */
  execute(): void {
    const ws = new WebSocket(this.streamEndpoint)

    // ============== onopen handler ==============>
    ws.onopen = async (event) => {
      if (!event.isTrusted) throw new Error('ws.onopen event is not trusted')
      console.log('WS Connection opened to: ', this.streamEndpoint)

      // Get bearer token
      const auth = getAuth()
      const token = await auth.currentUser?.getIdToken()

      // Send initial connection authentication
      // Send Auth Token and sentry transaction_id
      // This is always the first step and will allow the connection to remain open for this transaction
      await ws.send(JSON.stringify({ bearer: token, transaction_id: this.sentryTransactionId }))

      // Send the initial query payload
      console.log('Sending initial query payload: ', this.initialQueryPayload)
      await ws.send(JSON.stringify(this.initialQueryPayload))
    }

    // ============== onmessage handler ==============>
    ws.onmessage = (event) => {
      if (!event.isTrusted) throw new Error('ws.onmessage event is not trusted')

      const data = JSON.parse(event.data)
      console.log('Received message: ', data)

      // Handle the message
      this.onMessageHandler(this, data)
    }

    // ============== onerror handler ==============>
    ws.onerror = (event) => {
      console.warn('WS onerror: ', event)
      this.onError(this, event)

      // Report to sentry
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: this.sentryTransactionId })
        Sentry.captureException(new Error(`ws.onerror`), {
          extra: {
            ...this.sentryDefaultExtras,
            event: event,
            eventJson: JSON.stringify(event),
            eventType: event.type,
            eventIsTrusted: event.isTrusted,
          },
        })
      })
    }

    // ============== onclose handler ==============>
    ws.onclose = (event) => {
      if (!event.isTrusted) throw new Error('ws.onclose event is not trusted')

      // onclose all cases
      this.onCloseAll(this)

      // Successful close
      if (event.code === 1000) {
        this.onCloseSuccess(this)
        return
      } else {
        this.onCloseUnexpected(this, event.code, event.reason)

        // Report to sentry
        Sentry.withScope((scope) => {
          scope.setTags({ transaction_id: this.sentryTransactionId })
          Sentry.captureException(new Error(`ws.onclose - Code: ${event.code}. Reason: ${event.reason}`), {
            extra: {
              ...this.sentryDefaultExtras,
              event: event,
              eventJson: JSON.stringify(event),
              eventCode: event.code,
              eventReason: event.reason,
            },
          })
        })
      }
    }
  }
}
