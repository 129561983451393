import { ChatV2Conversation, ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { Source } from '@mui/icons-material'
import { useState } from 'react'
import { ChatV2QueryMetadataDocumentQuery } from '@/chat-common/schemas/chat-query-metadata-schema'
import DocumentQuerySelectionDialog from '../source-dialogs/documentquery-dialog'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'

type ChatControlsDocumentQueryProps = {
  conversation: ChatV2Conversation
}

export default function ChatControlsDocumentQuery(props: ChatControlsDocumentQueryProps) {
  const { conversation } = props
  const { trackEvent } = useAnalytics()
  // Local state for source window status
  const [sourceWindowOpen, setSourceWindowOpen] = useState<boolean>(false)

  if (!conversation) return null

  // Source information from the conversation
  const currentSource = conversation.currentSource as ChatV2QueryMetadataDocumentQuery | null
  const selectedFiles = currentSource?.selected_files ?? []

  // Format selected files to only show their names
  const fileNames = selectedFiles.map((file) => file.split('/').pop())

  return (
    <>
      <DocumentQuerySelectionDialog conversation={conversation} open={sourceWindowOpen} onClose={(value) => setSourceWindowOpen(value)} />
      <div className={'flex gap-x-2'}>
        <button
          onClick={() => {
            trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.documentquery })
            setSourceWindowOpen(true)
          }}
          className="rounded-md text-sm px-0 font-semibold text-sky-600 hover:underline"
        >
          Select source <Source fontSize="small" />
        </button>
        <div className={'text-sm text-gray-600'}>({fileNames.length} selected)</div>
      </div>
      {fileNames.length == 0 && <p className={'text-sm text-red-700 text-left'}>File selection is required</p>}
    </>
  )
}
