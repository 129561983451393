import { useForm } from 'react-hook-form'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import ResponseReferences from '../chat/components-shared/ResponseReferencesWrapper'
import { kSegmentTrackBooleanComposerQuerySubmit, kSegmentTrackFootnoteClick } from '@/constants/constants-segment'
import BooleanBuilderResponse from './BooleanBuilderResponse'
import { BooleanBuilderSource, setResponseHighlightedFootnote } from '@/store/slices/boolean-builder-stream-response.slice'
import { callBooleanBuilderQueryStream } from '@/store/query-streams/boolean-builder-query-stream'
import BooleanBuilderPlatformOptions from './BooleanBuilderPlatformOptions'
import ExamplesBlockBooleanBuilder from '../examples/ExamplesBlockBooleanBuilder'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'

/**
 * THIS IS A CLONE OF THE CHAT PAGE
 * IT COMMENTS OUT SEVERAL FEATURES UNUSED FOR THIS PAGE CURRENTLY, BUT WHICH MAY BE USED IN THE FUTURE
 */

type FormInputs = {
  query: string
  platform: string
}

const validationSchema = z.object({
  query: z.string().min(10, 'Please be more specific. At least 10 characters are required.'),
  platform: z.string().min(1, 'This is a required field'),
})

export default function AuditPage() {
  const { trackEvent } = useAnalytics()
  // Store data
  const { sources, streamedResponseString, streamLoading, streamError, responseUniqueFootnotes, responseHighlightedFootnote, searchedQuery } = useAppSelector(
    (state: RootState) => state.booleanBuilderApiResponse
  )

  // Actions
  const dispatch = useAppDispatch()

  // Local State
  // const [selectedPlatform, setSelectedPlatform] = useState('LexisNexis')

  // Filter sources for source display
  const usedSources: BooleanBuilderSource[] = sources.filter((source) => responseUniqueFootnotes.includes(source.footnoteNumber))
  const unUsedSources: BooleanBuilderSource[] = sources.filter((source) => !responseUniqueFootnotes.includes(source.footnoteNumber))

  // // Show suggestions if we have all the appropriate information
  // const showSuggestions = searchedQuery != null && streamedResponseString != null && searchedQuery != '' && streamedResponseString != '' && !streamLoading

  // Form Hook
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      query: '',
      platform: 'Default', // default is Default (must match the a platformId from the component)
    },
    resolver: zodResolver(validationSchema),
  })

  async function handleFormSubmit(data: FormInputs) {
    console.log('Form submit data: ', data)
    await executeQuery(data)
  }

  // Sign in error component
  function QueryErrorMessage() {
    if (streamError != '') {
      return InlineTipError(streamError)
    } else {
      return null
    }
  }

  async function executeQuery(formInputs: FormInputs) {
    if (streamLoading) return

    // Track
    analytics.track(kSegmentTrackBooleanComposerQuerySubmit, {
      platform: formInputs.platform,
    })

    callBooleanBuilderQueryStream({ query: formInputs.query, platform: formInputs.platform })
  }

  return (
    <>
      <div className={'flex-grow flex flex-col py-4 pr-4 pl-4 lg:pl-0 mt-12 lg:mt-0 overflow-y-scroll'}>
        <div className={'flex-grow'}>
          <div className="bg-white shadow rounded-lg sm:max-w-screen-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Boolean Composer</h3>
              <div className="my-2 text-sm text-gray-500">
                <p className="mb-2">Paxton can help you compose boolean search strings for LexisNexis and WestLaw using natural language.</p>
                <p className="mb-4 italic text-xs">* Paxton is not affiliated with LexisNexis or Westlaw.</p>
              </div>

              <BooleanBuilderPlatformOptions
                onChange={(platformId: string) => {
                  setValue('platform', platformId)
                }}
                selectedPlatformId={getValues('platform')}
              />

              <form className="" onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="w-full">
                  <label htmlFor="query" className="block mb-1 text-sm font-medium text-gray-700">
                    Describe the search criteria
                  </label>
                  <textarea
                    id="query"
                    rows={3}
                    {...register('query', { required: true })}
                    autoComplete="query"
                    required
                    className="block w-full rounded-md border-0 p-2 mb-4 text-gray-900 text-sm shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-3 focus:ring-2 focus:ring-inset focus:ring-sky-600"
                    placeholder="Type your search criteria here..."
                  />
                </div>
                {errors.query && <p className={'text-red-700'}>{errors.query.message}</p>}
                <div className={'text-right'}>
                  <button
                    type="submit"
                    className="w-full sm:w-auto rounded-md bg-sky-600 px-3 py-2 mt-1 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 "
                    onClick={() => {
                      trackEvent(AnalyticsEvent.BooleanComposerSubmission)
                    }}
                  >
                    Submit
                    <ButtonLoader loading={streamLoading} />
                  </button>
                </div>
              </form>

              <QueryErrorMessage />
            </div>
          </div>
          {searchedQuery && (
            <div className="mt-8 bg-white shadow rounded-lg sm:max-w-screen-lg">
              <div className="px-4 py-5 sm:p-6">
                <BooleanBuilderResponse
                  queryTitle={searchedQuery}
                  responseString={streamedResponseString}
                  footnoteClicked={(index) => {
                    // Track
                    analytics.track(kSegmentTrackFootnoteClick)

                    dispatch(setResponseHighlightedFootnote(index))
                  }}
                />
                <div className="h-6" />
                {/* Do not display while stream is loading
            {showSuggestions && (
              <SuggestedQuestions
                onSuggestionClick={(suggestion) => handleSuggestionClick(suggestion)}
                userContent={searchedQuery}
                assistantContent={streamedResponseString}
                display={!streamLoading}
              />
            )} */}

                <div className="h-6" />
                <ResponseReferences sectionTitle="References" sources={usedSources} display={true} responseHighlightedFootnote={responseHighlightedFootnote} />
                <div className="h-6" />
                {/* Do not display until we are done loading the whole response stream */}
                <ResponseReferences
                  sectionTitle="Related"
                  sources={unUsedSources}
                  display={!streamLoading}
                  responseHighlightedFootnote={responseHighlightedFootnote}
                />
              </div>
            </div>
          )}

          {/* Show examples if there is no searched query */}
          {!searchedQuery && (
            <>
              <div className="h-5" />
              <ExamplesBlockBooleanBuilder />
            </>
          )}
        </div>
      </div>
    </>
  )
}
