import { kChatV2ApiConversationsBasePath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { store } from '@/store/store'
import { getAuth } from 'firebase/auth'
import { z } from 'zod'
import { ChatV2Conversation, ChatV2Feature, ChatV2MessageSchema, chatV2AddConversation, chatV2AddConversationFromMessages } from '../store/chat-v2.slice'
import { ErrorComponentProps } from '@/components/error/error-component'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { k429RateLimitError, kAnonymousUserLimitMessage429 } from '@/constants/constants-strings'
import { AuthDialogType, openAuthDialog } from '@/store/slices/ui-state.slice'
import { isPendingConversationId } from '@/constants/constants-ui'

// Messages Response Schema (array of messages)
export const ChatV2MessagesResponseSchema = z.array(ChatV2MessageSchema)

/**
 * Load Missing Conversation Into State
 * IF: Conversation is not in state
 * - Fetches the messages for a given conversation id
 * - Inserts them into state
 *
 * This is for initial conversation loading only. Subsequent messages are handled by the websocket,
 * and dynamically update the existing conversation state.
 *
 * Not using RTK Query since we want to manipulate the conversation state client-side with elements
 * that are not saved to persistence from the server only while the chat is active
 * @param conversationId
 * @returns
 */
export default async function loadMissingConversationIntoState(
  conversationId: string,
  feature: string,
  onError: (errorProps: ErrorComponentProps) => void,
  onLoadingStatus: (isLoading: boolean) => void
) {
  // Assess if this is a pending id
  const pendingId: boolean = isPendingConversationId(conversationId)

  // Skip fetching if we already have this conversation or if its pending
  const conversation = store.getState().chatV2State.conversations[conversationId]
  if (pendingId || conversation?.isPending || conversation) {
    // console.log(`Conversation ${conversationId} is already in state or isPending. Skipping fetch.`)
    return
  }

  console.log(`Loading ${feature} conversation ${conversationId}...`)

  // Create transaction id
  const sentry_transaction_id = nanoid()

  try {
    // Set loading status
    onLoadingStatus(true)

    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw Error(kErrorCodeAuthError)

    // NO LONGER NEED TO SPECIFY FEATURE FOR THIS ENDPOINT
    // // Construct query params
    // const queryParams = new URLSearchParams({
    //   feature_name: feature,
    // }).toString()

    // Construct request
    const apiUrl = kPaxtonAppApiBaseUrl() + `${kChatV2ApiConversationsBasePath}/${conversationId}`
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    // console.log(`Querying ${apiUrl} with ${JSON.stringify(requestOptions, null, 2)}`)
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // Handle Errors and return early
    if (!response.ok) {
      let captureToSentry = false

      // Handle UX messages and decide whether to capture to sentry
      switch (response.status) {
        case 404:
          captureToSentry = true
          onError({
            code: response.status.toString(),
            title: 'Chat not found',
            message: `We could not find the chat with id: ${conversationId}`,
          })
          break

        case 429:
          // Don't capture for 429
          onError({
            code: response.status.toString(),
            title: 'Too many requests',
            message: k429RateLimitError,
          })

          // If 429 error and user is anon, show dialog
          if (getAuth().currentUser?.isAnonymous) {
            store.dispatch(
              openAuthDialog({
                authDialogType: AuthDialogType.SIGN_UP,
                tooltipMessage: kAnonymousUserLimitMessage429,
              })
            )
          }
          break

        default:
          captureToSentry = true
          onError({
            code: response.status.toString(),
            title: 'Error loading chat',
            message: `We could not load the chat with id: ${conversationId}. Please try again.`,
          })
          break
      }

      // Conditionally capture to sentry
      if (captureToSentry) {
        Sentry.withScope((scope) => {
          scope.setTags({ transaction_id: sentry_transaction_id })

          Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`), {
            extra: { requestUrl: apiUrl, requestOptions: requestOptions, response: data, onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
          })
        })
      }

      return
    }

    // End early if no messages
    if (!data.messages) {
      console.error('Message object missing in response')
      return
    }

    console.log('Conversation Messages: ', data)

    // If there are no messages, we have no metdata to use to prepopulate anything. Load an empty conversation with this id.
    // This was probably caused by a server error where the initial query was not saved
    if (data.messages.length === 0) {
      const emptyConversation: ChatV2Conversation = {
        id: conversationId,
        feature: feature as ChatV2Feature,
        created_at: '',
        isLoading: false,
        isError: false,
        messages: {},
        currentSource: null,
        currentTextInput: '',
        formValidationError: false,
        visibleReference: null,
      }
      store.dispatch(chatV2AddConversation(emptyConversation))
      return
    }

    // Validate the response data against the expected schema
    const validatedData = ChatV2MessagesResponseSchema.safeParse(data.messages)
    if (!validatedData.success) {
      throw new Error(`Invalid messages schema for loadMissingConversationIntoState: ${validatedData.error}`)
    }

    // Dispatch the messages
    store.dispatch(chatV2AddConversationFromMessages({ conversationId, feature: feature as ChatV2Feature, messages: validatedData.data }))
  } catch (e) {
    console.error(e)

    // Capture to sentry
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    onError({
      code: 'UNKNOWN ERROR',
      title: 'Error loading chat',
      message: `We could not load the chat with id: ${conversationId}. Please try again.`,
    })
  } finally {
    // Set loading status
    onLoadingStatus(false)
  }
}
