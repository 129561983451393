import { useParams } from 'react-router-dom'
import { useGetCaselawReferenceMetadataQuery } from '../apis/caselaw-reference-viewer.api'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import CaseLawReferenceRunCitatorButton from '@/citator/views/CaseLawRunCitatorReferenceButton'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import StandaloneReferenceViewCaselawTabs from './StandaloneReferenceViewCaselawTabs'
import { useTitle } from '@/hooks/use-title'

/**
 * Reference Viewer Case Law Page
 * Displays the source viewer and citator tabs for case law references
 * Fetches all necessary data required to display the tabs
 */
export default function StandaloneReferenceViewerCaseLawPage() {
  // Get the parent_id of the reference from the path parm
  const { parent_id } = useParams<{ parent_id: string }>()

  // Get data via RTK-Query
  const {
    data: dataMetadata,
    isLoading: isLoadingMetadata,
    isError: isErrorMetadata,
    refetch: refetchMetadata,
  } = useGetCaselawReferenceMetadataQuery(parent_id ?? '')

  // Set the page title
  const caseTitle = dataMetadata?.title ?? 'Paxton Reference Viewer'
  useTitle(caseTitle)

  return (
    <div className={'grow flex flex-col h-full overflow-y-scroll pb-10 pt-16 lg:pt-4'}>
      {isLoadingMetadata && (
        <div className={''}>
          <CircularProgressContinuousSized size={12} thickness={9} />
        </div>
      )}
      {isErrorMetadata && (
        <div className={'pl-2'}>
          {InlineTipError('Could not load this reference.')}
          <div>
            <button
              className={
                'items-center rounded-md bg-sky-600 border-[1px] border-sky-600 px-2 py-1 my-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              }
              onClick={() => refetchMetadata()}
            >
              Retry
            </button>
          </div>
        </div>
      )}
      {dataMetadata && (
        <div className={'w-full max-w-3xl mx-auto'}>
          <div className={'px-2'}>
            <h1 className="text-xl font-bold">{dataMetadata.title}</h1>
            {dataMetadata.bb_citation && <div>{dataMetadata.bb_citation}</div>}

            <CaseLawReferenceRunCitatorButton parent_id={dataMetadata.parent_id} tailwindTextSize="text-sm" onClickCallback={() => null} />
          </div>
          <div className={'mb-5'}></div>

          <div className={'bg-white rounded-lg'}>
            <StandaloneReferenceViewCaselawTabs metadata={dataMetadata} />
          </div>
        </div>
      )}
    </div>
  )
}
