import { kPaymentsSubscriptionPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'

type CreateStripeSubscriptionRequest = {
  setup_intent_id: string
  payment_intent_id: string
  billing_cycle: string
  promotion_id?: string | null
}

type CreateStripeSubscriptionResponse = {
  subscription_id?: string
  error?: boolean
  errorDetail?: string
}

export default async function createStripeSubscription({
  setup_intent_id: setup_intent_id,
  payment_intent_id: payment_intent_id,
  billing_cycle: billing_cycle,
  promotion_id: promotion_id,
}: CreateStripeSubscriptionRequest): Promise<CreateStripeSubscriptionResponse> {
  // Create transaction id
  const sentry_transaction_id = nanoid()

  console.log('POST Stripe Subscription Request')
  const token = await getAuth().currentUser?.getIdToken(true)
  if (!token) throw Error(kErrorCodeAuthError)

  const requestBody: {
    setup_intent_id: string
    payment_intent_id: string
    billing_cycle: string
    promotion_id?: string
  } = {
    setup_intent_id,
    payment_intent_id,
    billing_cycle,
  }

  if (promotion_id) {
    requestBody.promotion_id = promotion_id
  }

  const requestOptions: RequestInit = {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-Transaction-Id': sentry_transaction_id,
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(requestBody),
  }

  const apiUrl = kPaxtonAppApiBaseUrl() + `/${kPaymentsSubscriptionPath}`
  const response = await fetch(apiUrl, requestOptions)
  const data = await response.json()
  if (!response.ok || data.error != null || data.error != undefined || data.success == false) {
    throw new Error('Failed to create subscription.')
  }
  return data
}
