import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DocumentEditingSession } from './document-editing.models'
import {
  DocumentEditingWsEventAnnotationsDataType,
  DocumentEditingWsEventHeaderDataType,
  DocumentEditingWsEventSummaryChunkDataType,
} from '../schema/document-editing-websocket.schema'

// Define a type for the slice state
interface DocumentEditingState {
  editingSession: Partial<Record<string, DocumentEditingSession>>
}

// Define the initial state using that type
const initialState: DocumentEditingState = {
  editingSession: {},
}

export const documentEditingSlice = createSlice({
  name: 'documentEditingSlice',
  initialState,
  reducers: {
    // Add a new editing session
    addEditingSessionFromHeaderEvent: (state, action: PayloadAction<DocumentEditingWsEventHeaderDataType>) => {
      const header = action.payload

      state.editingSession[header.id] = {
        loading: true,
        error: false,
        summary: '',
        header: header,
        annotations: [],
      }
    },

    // Set loading state
    setEditingSessionLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload

      const session = state.editingSession[id]

      // if the session exists, set the loading state
      if (session) {
        session.loading = loading
      }
    },

    // Add summary chunk
    addEditingSessionSummaryChunk: (state, action: PayloadAction<DocumentEditingWsEventSummaryChunkDataType>) => {
      const { analysis_id, value } = action.payload

      const session = state.editingSession[analysis_id]

      // if the session exists, add the summary chunk
      if (session) {
        session.summary += value
      }
    },

    // Set Annotations
    setEditingSessionAnnotations: (state, action: PayloadAction<DocumentEditingWsEventAnnotationsDataType>) => {
      const annotationPayload = action.payload

      const session = state.editingSession[annotationPayload.analysis_id]

      // if the session exists, set the annotations
      if (session) {
        session.annotations = annotationPayload.annotations
      }
    },

    // Set Error
    setEditingSessionError: (state, action: PayloadAction<{ id: string; errorString?: string }>) => {
      const { id, errorString } = action.payload

      const session = state.editingSession[id]

      // if the session exists, set the error state
      if (session) {
        session.error = true

        if (errorString) {
          session.errorString = errorString
        }
      }
    },
  },
})

// Export action reducers
export const {
  addEditingSessionFromHeaderEvent,
  setEditingSessionLoading,
  addEditingSessionSummaryChunk,
  setEditingSessionAnnotations,
  setEditingSessionError,
} = documentEditingSlice.actions

export default documentEditingSlice.reducer
