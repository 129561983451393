/**
 * Docx2HtmlCommentType
 * The type of comment in the document based on the changes the comment is wrapping.
 * - insertion: The comment is an insertion.
 * - deletion: The comment is a deletion.
 * - replacement: The comment is a replacement (both insertion and deletion).
 * - noChange: The comment does not wrap any changes.
 */
export enum Docx2HtmlCommentType {
  insertion = 'insertion',
  deletion = 'deletion',
  replacement = 'replacement',
  no_change = 'no_change',
}

export type Docx2HtmlCommentTree = {
  [key: string]: {
    sortOrder: number
    paxtonComment: boolean
    commentType: Docx2HtmlCommentType
    comments: Docx2HtmlComment[]
  }
}

export type Docx2HtmlComment = {
  id: string
  text: string | null
  author: string | null
  date: string | null
}

/**
 * Docx2HtmlCommentsRelativeTop
 * A map of comment id to the relative top position of the comment in the document.
 */
export type Docx2HtmlCommentsRelativeTop = {
  [key: string]: number
}
