import { ChatV2Conversation, ChatV2Feature } from '../store/chat-v2.slice'

/**
 * Chat Submit Disabled
 * Global and feature-specific logic for disabling the chat submit button
 * @returns true if the chat submit button should be disabled
 */
export function chatSubmitDisabled(conversation: ChatV2Conversation | null) {
  // If there is no conversation
  if (!conversation) return true

  const feature = conversation.feature as ChatV2Feature
  const isLoading = conversation.isLoading
  const messages = conversation.messages ?? {}
  const currentSource = conversation.currentSource ?? null
  const currentTextInput = conversation.currentTextInput ?? ''
  const formValidationError = conversation.formValidationError ?? false

  // Global settings for all features
  if (isLoading || formValidationError || !currentSource) return true

  // Features That Require Selected Files
  if (feature == ChatV2Feature.documentquery) {
    // If selected files length == 0
    if (currentSource.selected_files.length == 0) return true
  }
  if (feature == ChatV2Feature.contractanalysis) {
    // If file path is empty
    if (currentSource.file_path == '') return true
  }

  if (feature == ChatV2Feature.documentSummarization) {
    // If selected file is empty
    if (currentSource.selected_file === '') return true
  }

  if (feature == ChatV2Feature.lrr_v2) {
    // If file path is empty
    const lrrSelections = currentSource.lrr_selections ?? []
    if (lrrSelections.length == 0) return true
  }

  if (feature == ChatV2Feature.research) {
    // If neither federal or state sources are selected
    if (currentSource.federal_court_ids == null && currentSource.state_court_ids == null) return true
  }

  // Features That Require Text Input (all features currently)
  // If text input is empty
  if (currentTextInput.trim() == '') return true

  // Features that only allow 1 message
  if (feature == ChatV2Feature.contractanalysis) {
    // If there are already messages
    if (Object.keys(messages).length > 0) return true
  }
}

export function newChatButtonLabel(feature: ChatV2Feature) {
  switch (feature) {
    case ChatV2Feature.contractanalysis:
      return 'New analysis'
    case ChatV2Feature.contractsearch:
      return 'New search'

    default:
      return 'New chat'
  }
}
