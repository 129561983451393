import { signInWithEmailAndPassword, getAuth } from 'firebase/auth'
import firebase_app from '../firebase-config'

/**
 * Sign In Email
 * No try catch, allow the calling UX function to handle errors
 * @param email
 * @param password
 * @returns
 */
export default async function signInEmail(email: string, password: string) {
  const auth = getAuth(firebase_app)

  // Sign in with email and password
  await signInWithEmailAndPassword(auth, email, password)

  return
}
