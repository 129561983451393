import { downloadDocxFileFromGcs } from '@/document-editing/apis/get-docx2html-from-gcs'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { FileDownloadOutlined } from '@mui/icons-material'
import { LinearProgress } from '@mui/material'
import { ArrowDownIcon, ArrowLeftIcon, ArrowUpIcon } from '@radix-ui/react-icons'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'
import { Link } from 'react-router-dom'

type RenderDocx2HtmlTopBarProps = {
  title: string
  editingSessionId: string
  loading: boolean
  currentIndex: number
  totalCount: number
  changeIndex: (index: number) => void
}

export default function RenderDocx2HtmlTopBar(props: RenderDocx2HtmlTopBarProps) {
  const { title, editingSessionId, loading, currentIndex, totalCount, changeIndex } = props

  // Local state
  const [downloadError, setDownloadError] = useState<boolean>(false)

  // Global UX State
  const uiState = useAppSelector((state: RootState) => state.uiState)
  const hideSidebars = uiState.hideSidebars

  // Handle download
  async function handleDownload() {
    const user = getAuth().currentUser
    if (!user) return

    // Construct the path
    const path = `users/${user.uid}/contract_analysis/${editingSessionId}.docx`

    // Strip the .docx extension
    let fileName = title.replace('.docx', '')
    fileName = `${fileName}_paxton_redlines.docx`

    // Initiate the download
    try {
      setDownloadError(false)
      await downloadDocxFileFromGcs(path, fileName)
    } catch (error) {
      setDownloadError(true)
    }
  }

  return (
    // Width of top bar is controlled by screen size (full on mobile), and status of sidebar visibility so that it always
    // visually takes up the entire top area
    <div
      className={`absolute z-10 ${hideSidebars ? 'w-full lg:-ml-8' : 'w-full lg:w-[calc(100%-224px)] lg:-ml-6'} mt-12 lg:mt-0 bg-white shadow-md text-sm mb-2`}
    >
      <div className={'flex-grow px-2 py-3 grid grid-cols-[30px_auto_195px] items-center gap-x-2'}>
        <Link to="/dashboard/document-editing/list" className={'flex items-center'}>
          <ArrowLeftIcon className={'pl-1 w-7 h-7'} />
        </Link>

        <div className={'flex items-center whitespace-nowrap overflow-scroll text-sm font-medium'}>{title}</div>
        <div className={'flex items-center justify-end'}>
          {/* Docx download button */}
          {!loading && (
            <button
              className={
                'flex gap-x-1 mr-1 items-end px-2 pr-3 py-1 rounded-md bg-sky-600 bg-opacity-10 hover:bg-opacity-25 transition-all duration-200 border-gray-600 font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
              }
              onClick={handleDownload}
            >
              <FileDownloadOutlined style={{ fontSize: 22 }} />
              {downloadError ? 'error' : '.docx'}
            </button>
          )}

          {/* Forward / Back buttons for annotations */}
          {totalCount > 0 && (
            <>
              <button
                className={'px-1'}
                onClick={() => {
                  if (currentIndex === 0) return

                  changeIndex(currentIndex - 1)
                }}
              >
                <ArrowUpIcon className={'w-5 h-5'} />
              </button>
              <div className={'bg-[#fef8ca] px-2 py-[2px] rounded-md text-base'}>
                {currentIndex + 1}&nbsp;/&nbsp;{totalCount}
              </div>{' '}
              <button
                className={'px-1'}
                onClick={() => {
                  if (currentIndex === totalCount - 1) return

                  changeIndex(currentIndex + 1)
                }}
              >
                <ArrowDownIcon className={'w-5 h-5'} />
              </button>
            </>
          )}
        </div>
      </div>
      {loading && (
        <div className={'flex grow'}>
          <LinearProgress className={'w-full'} />
        </div>
      )}
    </div>
  )
}
