import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FolderIcon } from '@heroicons/react/24/outline'

type CreateFolderDialogProps = {
  open: boolean
  onSave: (data: FormInputs) => void
  onClose: (value: boolean) => void
}

type FormInputs = {
  folderName: string
}

const validationSchema = z.object({
  folderName: z.string().min(1, 'This is a required field'),
})

export default function CreateFolderDialog(props: CreateFolderDialogProps) {
  const { open, onSave, onClose } = props

  const cancelButtonRef = useRef(null)

  // Form state
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      folderName: '',
    },
    resolver: zodResolver(validationSchema),
  })

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto px-4 py-20 lg:ml-[300px]">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden bg-white p-4 mx-2 rounded-md shadow-lg z-[1000] min-w-[275px] max-w-2xl sm:min-w-[600px]">
                <form className="" onSubmit={handleSubmit(onSave)}>
                  <div className="text-center sm:mt-0 sm:text-left">
                    <div className="w-full">
                      <h2 className="mb-2 text-lg font-semibold flex gap-x-2 items-center">
                        <FolderIcon className="h-10 p-2 rounded-full text-sky-700 bg-sky-100" />
                        New folder
                      </h2>
                      <input
                        id="folderName"
                        autoFocus={true}
                        {...register('folderName', { required: true })}
                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:text-sm sm:leading-6"
                        placeholder="Folder name"
                      />
                      {errors.folderName && <p className={'text-red-700'}>{errors.folderName.message}</p>}
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 sm:ml-3 sm:w-auto"
                    >
                      Create
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => onClose(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
