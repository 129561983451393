import { useContext, useEffect, useRef } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router'
import { AuthContext } from '../context/auth-context'
import GlobalToast from '@/components/ui/global-toast/GlobalToast'
import { OAuthProvider, getAuth, getRedirectResult, signInAnonymously, signInWithCredential, SAMLAuthProvider } from 'firebase/auth'
import AuthDialog from '@/components/auth/AuthDialog'
import { FirebaseError } from 'firebase/app'
import { OnlineStatusProvider } from '@/context/online-status-context'
import PWAUpdateModal from '@/components/modals/PWAUpdateModal'
import { Userpilot } from 'userpilot'
import { validateSSOCredentials } from '@/util/enterprise'
import { useAppDispatch } from '@/store/store-hooks'

/**
 * Root component
 * Global style wrappers and redirects
 * @returns
 */
export default function Root() {
  const authContext = useContext(AuthContext)
  const user = authContext?.user
  const navigate = useNavigate()
  const location = useLocation()
  const previousLocation = useRef(location)
  const dispatch = useAppDispatch()

  // Auth
  const auth = getAuth()

  // Navigation Observer
  useEffect(() => {
    // Construct full paths for segment analytics tracking
    const documentReferrer = document.referrer
    const origin = window.location.origin
    const previousPath = location.pathname != previousLocation.current.pathname ? previousLocation.current.pathname : null
    const currentPath = location.pathname

    // Get the previousUrl or the document refrerrer to use as the previousUrl
    const previousUrl = previousPath != null ? origin + previousPath : documentReferrer
    const currentUrl = origin + currentPath

    // console.log('origin:', origin)
    // console.log('documentReferrer:', documentReferrer)
    // console.log('previousUrl:', previousUrl)
    // console.log('currentUrl:', currentUrl)

    // Track the navigation event
    // See segment SPA implementation for details on how the referrer should be created
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/single-page-apps/
    analytics.page({
      referrer: previousUrl,
      url: currentUrl,
    })

    Userpilot.reload()

    // Update the previous location after logging
    previousLocation.current = location
  }, [location.pathname]) // re-fire when pathname changes

  // Startup Authentication Handler
  //  1. Checks if we're part of an existing social authentication redirect flow and handles appropriately
  //    a. Attempts to getRedirectResult  (this also auto-handles linking the credential to the anonymous account (see notes below in the error))
  //    b. ERRORS: If we receive error "auth/credential-already-in-use" - there is already a Google Account we can sign in to.
  //
  //  2. Anon Sign In: If redirectResult == null && existingUser == null, sign in anonymously
  //
  useEffect(() => {
    const startupAuthFlow = async () => {
      // Get the existing user for comparison
      const existingUser = auth.currentUser

      try {
        // Check for redirect result
        // Automatically links anonymous account to the redirect result account if new to system
        const redirectResult = await getRedirectResult(auth)

        // If result is null, there was no social redirect
        if (redirectResult == null && existingUser == null) {
          // console.log('No existing user and no social login redirect detected. Logging in anonymously.')

          signInAnonymously(auth)
          console.log('New anonymous user')
          return
        } else if (redirectResult != null) {
          await validateSSOCredentials(redirectResult, dispatch)
        }
      } catch (e) {
        if (e instanceof FirebaseError) {
          // OAuth SIGN IN FLOW:
          // If the getRedirectResult credential is already in use, it means there is an
          // existing Google (or Microsoft) account we can sign in to. No linking will take place.
          //
          // Handling this in the exception and using credentialFromError somehow appears to be
          // normal handling of getRedirectResult() for linking cases because
          // getRedirectResult seems to automatically attempt linking
          //
          // Attempt validateSSOCredentials to upgrade a user when pre-existing account is detected
          if (e.code == 'auth/credential-already-in-use' || e.code == 'auth/email-already-in-use') {
            console.log('Credential exists in system. Signing in with OAuth/SAML:', e)

            // Get the OAuth Credential from the error, supports Google and Generic OAuth
            const errorCredential = OAuthProvider.credentialFromError(e)
            if (errorCredential != null) {
              // Sign in with the error credential
              await signInWithCredential(auth, errorCredential)
              return
            }
            const samlErrorCredential = SAMLAuthProvider.credentialFromError(e)
            if (samlErrorCredential == null) throw new Error('Cannot sign in existing OAuth account with null eCred')
            // Sign in with the error credential
            await signInWithCredential(auth, samlErrorCredential)

            // Complete SSO validation if applicable (first time sign in with SAML from a login method)
            if (e?.code === 'auth/email-already-in-use') {
              await validateSSOCredentials(samlErrorCredential)
            }

            return
          }
        }
        // TODO: if the first one failed we need to check if the logged in user is saml and doesnt
        // have the brand key
        console.error('Error handling startupAuthFlow redirect result: ', e)
        throw e
      }
    }

    // Run the auth startup flow
    startupAuthFlow()
  }, [user])

  // Root redirect
  useEffect(() => {
    // console.log('Root redirect checking path: ', location.pathname)

    if (location.pathname == '/') {
      console.log("Redirecting from '/' root")
      navigate('/dashboard')
    }

    // Old path redirects
    if (location.pathname == '/dashboard/cfr') {
      navigate('/dashboard')
    }
  })

  return (
    <>
      <AuthDialog />
      <GlobalToast />
      <OnlineStatusProvider>
        <Outlet />
      </OnlineStatusProvider>
      <PWAUpdateModal />
    </>
  )
}
