import { z } from 'zod'

// Schema for the "important_cases" object
export const importantCasesResponseSchema = z.object({
  parent_id: z.string(),
  title: z.string(),
  citation: z.string().nullable(),
  jurisdiction: z.string(),
  review_label: z.string(),
  explanation: z.string(),
})

// Main schema for the entire JSON structure
export const caseStatusResponseSchema = z.object({
  parent_id: z.string(),
  title: z.string(),
  jurisdiction: z.string(),
  status: z.string(),
  important_cases: z.array(importantCasesResponseSchema),
})
export type CaseStatusResponse = z.infer<typeof caseStatusResponseSchema>
