import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import remarkGfm from 'remark-gfm'
import rehypeStringify from 'rehype-stringify'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rehypeWrap from 'rehype-wrap-all'
import rehypeRaw from 'rehype-raw'
import format from 'rehype-format'
import rehypeDocument from 'rehype-document'

/**
 * Markdown To HTML String
 * Converts markdown to sanitized HTML
 * @param markdown
 * @returns
 */
export default async function markdownToHtmlString(markdown: string): Promise<string> {
  const processor = unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkRehype, { allowDangerousHtml: true })
    .use(rehypeRaw)
    .use(rehypeDocument)
    .use(format)
    .use(rehypeWrap, { selector: 'table', wrapper: 'div.source-html-table-wrapper' }) // table wrapper style fix (for overflow scrolling) - NOTE: Will get stripped by sanitizer if classes are not allowed on divs
    .use(rehypeStringify, { allowDangerousHtml: true })

  // Process the markdown
  const result = await processor.process(markdown)
  const htmlString = result.toString()

  return htmlString
}
