import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

const AccountActionPage: React.FC = () => {
  const location = useLocation()
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const firebaseURL = `https://paxton-ai-prod.firebaseapp.com/__/auth/action?${queryParams}`

    // Perform the redirect
    window.location.href = firebaseURL
  }, [])

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-[240px]" src={brandLogoUrl} alt={hostBrand} />
        <div className="mt-10 text-center">
          <CircularProgress size={24} thickness={5} />
        </div>
      </div>
    </div>
  )
}

export default AccountActionPage
