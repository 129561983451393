export default function MobileFeaturedLogos() {
  return (
    <>
      <h3 className="mt-6 text-center font-bold text-lg">Featured In</h3>
      <div className="flex flex-wrap gap-x-4 gap-y-2 items-center justify-center">
        <img src="/images/logos/reuters-logo-svg.svg" alt="Logo 1" className="h-8 pr-2" />
        <img src="/images/logos/fortune-logo-svg.svg" alt="Logo 2" className="h-9 p-2" />
        <img src="/images/logos/forbes-logo-svg.svg" alt="Logo 2" className="h-11" />
        <img src="/images/logos/axios-logo-svg.svg" alt="Logo 2" className="h-5" />
        <img src="/images/logos/wsj-logo-svg.svg" alt="Logo 2" className="h-5" />
      </div>
    </>
  )
}
