import { Docx2HtmlCommentTree, Docx2HtmlCommentsRelativeTop } from '../schema/docx2html.schema'

/**
 * Get Comment Positions
 * Get the positions of the comments in the document according to the containerRef.
 * Combines with the containerRect offset to get the relative top position of the comment.
 * @param comments
 * @param containerRef
 * @returns {Docx2HtmlCommentsRelativeTop} key (commentId) value domRect (DOMRect) pairs
 */
export function docx2HtmlGetCommentPositions(comments: Docx2HtmlCommentTree, containerRef: React.RefObject<HTMLDivElement>): Docx2HtmlCommentsRelativeTop {
  if (containerRef.current == null) return {}
  // Get the keys of the comment tree
  const commentIds = Object.keys(comments)

  // Sort the commentIds by the sortOrder
  commentIds.sort((a, b) => comments[a].sortOrder - comments[b].sortOrder)

  // Get the container reference position
  const containerRect = containerRef.current.getBoundingClientRect()

  // Get the DOMRect of each comment
  const commentPositions: Docx2HtmlCommentsRelativeTop = {}
  commentIds.forEach((commentId) => {
    if (containerRef.current == null) return {}

    const element = containerRef.current.querySelector(`#comment-anchor-${commentId}`)
    if (element) {
      const domRect = element.getBoundingClientRect()
      const thisCommentTop = domRect.top - containerRect.top

      // Set the comment position
      commentPositions[commentId] = thisCommentTop
    }
  })

  return commentPositions
}
