import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import { ChatV2QueryMetadataDocumentQuery, ChatV2QueryMetadataDocumentQuerySchema } from '../schemas/chat-query-metadata-schema'

// Toggle a selected file
export const reducerCurrentSourceDocumentQueryToggleSelectedFile = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; filePath: string }>
) => {
  const { conversationId, filePath } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataDocumentQuerySchema.safeParse(conversation.currentSource)

  if (conversation.currentSource == null || validation.success) {
    // Get the existing currentSource and updated it with the new source_name
    const currentSource = conversation.currentSource as ChatV2QueryMetadataDocumentQuery

    // If currentSource exists, update it and set state
    if (currentSource) {
      const inList = currentSource.selected_files.some((path) => path === filePath)

      // If inList is true, remove the file from the list
      if (inList) {
        const newSelectedFiles = [...currentSource.selected_files]
        // Remove the file from the list
        const index = newSelectedFiles.findIndex((file) => file === filePath)
        if (index !== -1) {
          newSelectedFiles.splice(index, 1)
        }
        currentSource.selected_files = newSelectedFiles
      } else {
        const newSelectedFiles = [...currentSource.selected_files, filePath]
        currentSource.selected_files = newSelectedFiles
      }
    }

    // Else, construct a new currentSource with the selected file
    else {
      conversation.currentSource = {
        selected_files: [filePath],
      }
    }
  } else {
    throw new Error(`Attempting to ToggleSelectedFile for wrong metadata type: ${validation.error}`)
  }
}

// Clear all selected files
export const reducerCurrentSourceDocumentQueryClearAll = (state: ChatV2State, action: PayloadAction<{ conversationId: string }>) => {
  const { conversationId } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataDocumentQuerySchema.safeParse(conversation.currentSource)

  if (conversation.currentSource == null || validation.success) {
    // Set currentSource selected_files to empty array
    conversation.currentSource = {
      selected_files: [],
    }
  } else {
    throw new Error(`Attempting to ToggleSelectedFile for wrong metadata type: ${validation.error}`)
  }
}
