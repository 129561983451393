import { ChatV2Conversation } from '@/chat-common/store/chat-v2.slice'
import { useGetSourceContractSearchQuery } from '@/chat-common/api/source-apis'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { Parser } from 'html-to-react'
import sanitizeHtml from 'sanitize-html'
import { useRef } from 'react'

type ReferenceViewContractProps = {
  conversation: ChatV2Conversation
}

export default function ReferenceViewContentContract(props: ReferenceViewContractProps) {
  const { conversation } = props

  const reference = conversation.visibleReference
  console.log('Rendering reference: ', reference)
  const containerRef = useRef<HTMLDivElement>(null)

  const htmlParser = Parser()

  // Fetch the reference HTML string with RTK-Query
  const {
    data: sourceHtmlData,
    isLoading: sourceHtmlIsLoading,
    isError: sourceHtmlIsError,
    refetch,
  } = useGetSourceContractSearchQuery(reference?.metadata.s3_path)

  const formattedHTML = sourceHtmlData?.data.replace(/\n/g, '<br />')

  const sanitizedHtml = sanitizeHtml(formattedHTML || '')
  // Local state

  // Show loading indicator
  if (sourceHtmlIsLoading) return <CircularProgressContinuousSized size={18} thickness={7} />

  // Show error
  if (sourceHtmlIsError || !sourceHtmlData)
    return (
      <>
        {InlineTipError('Could not load this reference.')}
        <button
          className={
            'flex-auto items-center rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 m-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
          }
          onClick={refetch}
        >
          Retry
        </button>
      </>
    )

  return (
    <div id="lrr-reference" ref={containerRef} className={'reference-view-html text-sm overflow-y-scroll py-2'}>
      {htmlParser.parse(sanitizedHtml)}
    </div>
  )
}
