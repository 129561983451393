import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import { ChatV2QueryMetadataDocumentSummarization, ChatV2QueryMetadataDocumentSummarizationSchema } from '../schemas/chat-query-metadata-schema'

// Toggle a selected file
export const reducerCurrentSourceDocumentSummarizationToggleSelectedFile = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; selectedFile: string }>
) => {
  const { conversationId, selectedFile } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  const validation = ChatV2QueryMetadataDocumentSummarizationSchema.safeParse(conversation.currentSource)
  if (!validation.success) {
    // Set defaults
    conversation.currentSource = {
      selected_file: selectedFile,
    }

    // Return because we've already applied the file
    return
  }

  // Get the currentSource for manipulation
  const currentSource = conversation.currentSource as ChatV2QueryMetadataDocumentSummarization

  // If the selectedFile is already in the list, remove it (OPTIMIZED FOR SINGLE FILE ONLY)
  if (currentSource.selected_file.includes(selectedFile)) {
    currentSource.selected_file = ''
  } else {
    // Otherwise, add it to the list
    currentSource.selected_file = selectedFile
  }
}

// Clear all selected files
export const reducerCurrentSourceDocumentSummarizationClearAll = (state: ChatV2State, action: PayloadAction<{ conversationId: string }>) => {
  const { conversationId } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  const validation = ChatV2QueryMetadataDocumentSummarizationSchema.safeParse(conversation.currentSource)
  if (!validation.success) {
    // Set defaults
    conversation.currentSource = {
      file_path: '',
    }

    // Return because we've already applied the file
    return
  }

  // Get the currentSource for manipulation
  const currentSource = conversation.currentSource as ChatV2QueryMetadataDocumentSummarization

  // Clear all selected files
  currentSource.selected_file = ''
}
