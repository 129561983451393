import { FirebaseStorage, FullMetadata, getDownloadURL, uploadBytes } from 'firebase/storage'
import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { getMetadata, getStorage, ref, getBlob } from 'firebase/storage'
import { saveAs } from 'file-saver'

export function fileUploadIsProcessing(file: FullMetadata): boolean {
  const processSuccess: boolean = file.customMetadata?.process_success == 'True'
  const processSuccessTimestamp = file.customMetadata?.process_success_timestamp ? parseInt(file.customMetadata.process_success_timestamp) : null;

  // if process_success is true, but process_success_timestamp too new, then it is still processing
  if (processSuccess && processSuccessTimestamp !== null) {
    if (!fileUploadTimeIsValid(processSuccessTimestamp)) {
      return true;
    }
  }

  const isProcessingError: boolean = typeof file.customMetadata?.error == 'string' && file.customMetadata?.error != ''
  const processing: boolean = processSuccess == false && isProcessingError == false
  return processing
}

export function fileUploadTimeIsValid(process_success_timestamp: number | null): boolean {
  if (process_success_timestamp === null) {
    return true;
  }
  const nowUTCPosix = Math.floor(Date.now() / 1000); // Current time in POSIX timestamp (seconds)
  const delta10Seconds = 10; // 10 seconds
  const timeDiff = nowUTCPosix - process_success_timestamp;
  return timeDiff >= delta10Seconds;
}

export function getFileMetadataFromGCS(filePath: string) {
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const fileRef = ref(storage, filePath)
  const metadata = getMetadata(fileRef)
  return metadata
}

export function sanitizeFileName(fileName: string | undefined): string {
  return (
    fileName
      ?.substring(0, 230)
      .replace(/[/\\?%*:|"<>]/g, '')
      .trim() || 'Download'
  )
}

export async function downloadFile(filePath: string, fileName: string) {
  if (!filePath) {
    return
  }
  if (!fileName) {
    fileName = sanitizeFileName(filePath?.split('/')?.pop())
  }
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const fileRef = ref(storage, filePath)
  const blob = await getBlob(fileRef)
  saveAs(blob, fileName)
  return true
}

export async function copyFile(oldPath: string, newPath: string, storage: FirebaseStorage): Promise<void> {
  try {
    const oldFileRef = ref(storage, oldPath)
    const newFileRef = ref(storage, newPath)

    const url = await getDownloadURL(oldFileRef)

    const response = await fetch(url)
    const blob = await response.blob()

    await uploadBytes(newFileRef, blob)
  } catch (error) {
    console.error('Error copying file:', error)
    throw error
  }
}