import React, { useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { PersonRemove, Send, Warning } from '@mui/icons-material'
import { OrgAdminListUsersInvite } from '../schema/org-admin.api.schema'
import OrgAdminConfirmationDialog, { OrgAdminConfirmationDialogProps } from './OrgAdminConfirmationDialog'
import { useDeleteInviteMutation, useResendInviteMutation } from '../apis/org-admin.api'
import { kProduction } from '@/constants/constants-ui'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  inviteListItem: OrgAdminListUsersInvite
  refreshData: () => void
}

const InviteListItemMenu: React.FC<Props> = (props: Props) => {
  const { inviteListItem, refreshData } = props

  // Dialog state
  const [openConfirmationDialogProps, setOpenConfirmationDialogProps] = useState<OrgAdminConfirmationDialogProps | null>(null)

  // RTK-Query Mutation
  const [deleteInvite] = useDeleteInviteMutation()
  const [resendInvite] = useResendInviteMutation()

  // Menu elements
  const menuElements = [
    {
      name: 'Resend invitation',
      icon: Send,
      onClick: () => {
        setOpenConfirmationDialogProps({
          title: 'Resend invitation',
          message: `Are you sure you want to resend ${inviteListItem.invitee_email}'s invitation?`,
          visible: true,
          successMessage: 'The invitation has been resent.',
          onConfirm: async () => {
            // Delete the invite
            await resendInvite(inviteListItem.id).unwrap() // unwrap to allow popup to catch errors

            // Introduce an artificial delay
            await new Promise((resolve) => setTimeout(resolve, 1000))

            // Refresh the data
            await refreshData()
          },
          closeDialog: () => setOpenConfirmationDialogProps(null),
        })
      },
    },
    {
      name: 'Revoke invitation',
      icon: PersonRemove,
      onClick: () => {
        setOpenConfirmationDialogProps({
          title: 'Revoke invitation',
          message: `Are you sure you want to revoke ${inviteListItem.invitee_email}'s invitation?`,
          visible: true,
          onConfirm: async () => {
            // Delete the invite
            await deleteInvite(inviteListItem.id).unwrap() // unwrap to allow popup to catch errors
            await refreshData()
          },
          closeDialog: () => setOpenConfirmationDialogProps(null),
        })
      },
    },
  ]

  // Dev menu items
  if (!kProduction) {
    menuElements.push({
      name: 'Dev: Log invite',
      icon: Warning,
      onClick: () => {
        console.log('invite', inviteListItem)
      },
    })
  }

  return (
    <>
      {openConfirmationDialogProps && <OrgAdminConfirmationDialog {...openConfirmationDialogProps} />}

      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button
            className={`pt-1 rounded-md hover:bg-gray-200 transition-all duration-200 ease-in-out`}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <EllipsisVerticalIcon
              onClick={() => {
                console.log('clicked')
              }}
              width={22}
              height={22}
            />
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="z-50 min-w-[200px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
            sideOffset={5}
          >
            <ul role="list" className="space-y-1">
              {menuElements.map((item) => (
                <li key={item.name}>
                  <DropdownMenu.Item
                    onClick={(e) => {
                      e.stopPropagation()
                      item.onClick()
                    }}
                    className={classNames(
                      'text-gray-700 hover:text-sky-600 hover:bg-gray-50 rounded-md group flex items-center flex-nowrap gap-x-1 py-[4px] text-sm leading-6 cursor-pointer w-full'
                    )}
                  >
                    <item.icon className={classNames('text-gray-400 group-hover:text-sky-600 h-5 w-5 shrink-0')} aria-hidden="true" viewBox="0 0 28 24" />
                    <div className={`whitespace-nowrap text-sm`}>{item.name}</div>
                  </DropdownMenu.Item>
                </li>
              ))}
            </ul>
            <DropdownMenu.Arrow className="fill-white" />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  )
}

export default InviteListItemMenu
