import { kGcsUserFilesLlmOutputBucketRef } from '@/constants/constants-gcs'
import { getStorage, ref, getBlob } from 'firebase/storage'

// Initialize Firebase Storage
const storageBucket = getStorage(undefined, kGcsUserFilesLlmOutputBucketRef)

/**
 * Get the .html generated by the .docx2html conversion that has
 * been stored in Google Cloud Storage.
 * @param {string} filePath - The full path in GCS to the file
 * @returns {Promise<string>} - The raw .html file as a string
 */
export async function getDocx2HtmlFromGcs(filePath: string): Promise<string> {
  try {
    const fileRef = ref(storageBucket, filePath)

    // Get the blob
    const blob: Blob = await getBlob(fileRef)

    // Use FileReader to convert blob to text
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.readAsText(blob)
    })
  } catch (error) {
    console.error('Failed to download file: ', error)
    throw new Error('Failed to download file')
  }
}

/**
 * Download a .docx file from Google Cloud Storage
 * Allows the user's browser to download the file
 * @param filePath
 * @returns
 */
export async function downloadDocxFileFromGcs(filePath: string, fileName: string): Promise<void> {
  try {
    const fileRef = ref(storageBucket, filePath)

    // Get the blob
    const blob: Blob = await getBlob(fileRef)

    // Create a URL for the blob
    const url = URL.createObjectURL(blob)

    // Create an anchor element and simulate a click
    const a = document.createElement('a')
    a.href = url
    a.download = fileName as string
    a.click()

    // Clean up
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Failed to download file: ', error)
    throw new Error('Failed to download file')
  }
}
