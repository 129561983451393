import ChatControlsCaseLaw from './caselaw/chat-controls-caselaw'
import ChatControlsDocumentQuery from './chat-controls-documentquery'
import ChatControlsLRR from './chat-controls-lrr'
import ChatControlsDraftingChat from './chat-controls-drafting'
import ChatControlsWebsearch from './chat-controls-websearch'
import { ChatV2Conversation, ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import ChatControlsContractAnalysis from './chat-controls-contractanalysis'
import ChatControlsContractSearch from './chat-controls-contractsearch'
import ChatControlsDocumentSummarization from './chat-controls-documentsummarization'
import ChatControlsResearch from './chat-controls-research'

type ChatControlsProps = {
  conversation: ChatV2Conversation
}

/**
 * Chat Controls
 *
 * For a given conversation, loads the appropriate:
 * - Source selection dialog
 * - Form controls
 * @returns
 */
export default function ChatControls(props: ChatControlsProps) {
  const { conversation } = props

  switch (true) {
    case conversation.feature === ChatV2Feature.lrr_v2:
      return <ChatControlsLRR conversation={conversation} />

    case conversation.feature === ChatV2Feature.caselaw:
      return <ChatControlsCaseLaw conversation={conversation} />

    case conversation.feature === ChatV2Feature.research:
      return <ChatControlsResearch conversation={conversation} />

    case conversation.feature === ChatV2Feature.documentquery:
      return <ChatControlsDocumentQuery conversation={conversation} />

    case conversation.feature == ChatV2Feature.websearch:
      return <ChatControlsWebsearch conversation={conversation} />

    case conversation.feature == ChatV2Feature.drafting:
      return <ChatControlsDraftingChat conversation={conversation} />

    case conversation.feature == ChatV2Feature.contractanalysis:
      return <ChatControlsContractAnalysis conversation={conversation} />

    case conversation.feature == ChatV2Feature.contractsearch:
      return <ChatControlsContractSearch />

    case conversation.feature === ChatV2Feature.documentSummarization:
      return <ChatControlsDocumentSummarization conversation={conversation} />

    case conversation.feature === ChatV2Feature.assistant:
      return null

    default:
      console.warn(`No chat form controls defined for feature ${conversation.feature}.`)
      return null
  }
}
