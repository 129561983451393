import { z } from 'zod'

export const RecurringPriceSchema = z.object({
  aggregate_usage: z.string().nullable().optional(),
  interval: z.string(),
  interval_count: z.number(),
  trial_period_days: z.number().nullable().optional(),
  usage_type: z.string(),
})

export const PriceSchema = z.object({
  id: z.string(),
  object: z.literal('price'),
  active: z.boolean(),
  billing_scheme: z.string(),
  created: z.number(),
  currency: z.string(),
  custom_unit_amount: z.number().nullable().optional(),
  livemode: z.boolean(),
  lookup_key: z.string().nullable().optional(),
  metadata: z.record(z.string(), z.string()),
  nickname: z.string().nullable().optional(),
  product: z.string(),
  recurring: RecurringPriceSchema,
  tax_behavior: z.string(),
  tiers_mode: z.string().nullable().optional(),
  transform_quantity: z.string().nullable().optional(),
  type: z.string(),
  unit_amount: z.number(),
  unit_amount_decimal: z.string(),
})

export const PriceResponseSchema = z.object({
  price: PriceSchema.nullable(),
})
export type PriceResponse = z.infer<typeof PriceResponseSchema>
export type Price = z.infer<typeof PriceSchema>

export const CheckoutPricesSchema = z.object({
  standard_annual_price: PriceSchema,
  standard_monthly_price: PriceSchema,
  student_annual_price: PriceSchema,
  student_monthly_price: PriceSchema,
})
export type CheckoutPrices = z.infer<typeof CheckoutPricesSchema>
