type CommentCircleWithLetterProps = {
  word: string
}

export default function CommentCircleWithLetter(props: CommentCircleWithLetterProps) {
  const { word } = props

  // Isolate the first character
  const letter = word[0]

  return (
    <div className={'flex items-center justify-center rounded-full h-[22px] w-[22px] bg-sky-800 text-white'}>
      <div className={'text-[13px] font-bold'}>{letter}</div>
    </div>
  )
}
