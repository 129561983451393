import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { ChatV2Conversation, ChatV2Feature, ChatV2Message } from '../../store/chat-v2.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { useEffect, useRef } from 'react'
import { setUserQueryBubbleExpanded } from '@/chat-common/store/chat-v2-ux.slice'

// Fade out style for the expand toggle
const fadeOutStyle = {
  content: '""',
  background: 'linear-gradient(to bottom, transparent, rgb(243 244 246))',
}

type ChatUserQueryBubbleProps = {
  conversation: ChatV2Conversation
  message: ChatV2Message
}

export default function ChatUserQueryBubble(props: ChatUserQueryBubbleProps) {
  const { conversation, message } = props
  const { metadata, text } = message

  // console.log('Conversation: ', conversation)

  // Refs
  const messageContainerRef = useRef<HTMLDivElement>(null)

  // UX Store
  const dispatch = useAppDispatch()
  const { userQueryBubbleExpanded } = useAppSelector((state: RootState) => state.chatV2UxState)
  const contentExceedsOriginalHeight = Object.keys(userQueryBubbleExpanded).includes(metadata.message_id)
  const expanded = userQueryBubbleExpanded[metadata.message_id] ?? false

  // Show the toggle?
  const showToggle = contentExceedsOriginalHeight || expanded

  // Trim the text
  const trimmedText = text.trim()

  // Message header
  let messageHeader = 'You'
  if (metadata.feature == ChatV2Feature.contractanalysis) {
    // Get file name and apply it to the messageHeader
    const fileName = conversation.currentSource?.file_path?.split('/').pop()
    messageHeader = fileName ?? 'Contract Analysis'
  }

  const bubbleColor = 'bg-transparent'

  // Check the height of the message text exceeds the height of the container, and set global state if so
  // Only run if this record does not already exist in state since we only need to set it once
  useEffect(() => {
    if (contentExceedsOriginalHeight) return // We've already established that this message exceeds the original container height bounds

    const checkHeight = () => {
      if (!messageContainerRef.current) return

      const containerHeight = messageContainerRef.current.clientHeight
      const contentHeight = messageContainerRef.current.scrollHeight

      if (contentHeight > containerHeight) {
        dispatch(
          setUserQueryBubbleExpanded({
            messageId: metadata.message_id,
            expand: false, // Defaults to not expanded
          })
        )
      }
    }

    // Run the check on mount and on window resize
    checkHeight()
    window.addEventListener('resize', checkHeight)

    // Cleanup the event listener
    return () => window.removeEventListener('resize', checkHeight)
  }, [contentExceedsOriginalHeight])

  return (
    <>
      <div className={`p-2 rounded-lg text-base whitespace-pre-wrap ${bubbleColor}`}>
        <div className={`font-bold text-xl mb-1`}>{messageHeader}</div>
        <div ref={messageContainerRef} className={`text-sm leading-6 mb-1  overflow-hidden ${expanded ? '' : 'max-h-48'}`}>
          {trimmedText}
        </div>

        {/* Expand / Collapse Fade Gradient and Control */}
        {showToggle && (
          <>
            <div className={'h-7 w-full -mt-8 relative'} style={expanded ? {} : fadeOutStyle}></div>
            <div className={'h-1 w-full bg-gray-100 relative'}></div>
            <div className={'border-t border-gray-200 pt-2 flex'}>
              <div
                className={`px-1 text-sm text-gray-500 flex items-center gap-x-2 cursor-pointer rounded-md hover:bg-gray-100`}
                onClick={() =>
                  dispatch(
                    setUserQueryBubbleExpanded({
                      messageId: metadata.message_id,
                      expand: !expanded,
                    })
                  )
                }
              >
                {expanded ? (
                  <>
                    Hide <ChevronDownIcon className={'h-4'} />
                  </>
                ) : (
                  <>
                    Expand <ChevronRightIcon className={'h-4'} />
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={`h-2`} />
    </>
  )
}
