import { ChatV2Conversation, ChatV2Message } from '../../store/chat-v2.slice'
import chatV2FormatResponseStringPrintable from '@/chat-common/util/chat-v2-format-response-printable'
import getUniqueReferenceNumbersInMessage from '@/chat-common/util/chat-v2-get-references-in-message'
import ChatMessageBubbleReferencePrintable from './chat-message-bubble-reference-printable'
import { getBrandName } from '@/util/enterprise'

type ChatResponseBubbleProps = {
  conversation: ChatV2Conversation
  message: ChatV2Message
  openFeedbackDialog: (message: ChatV2Message, positive: boolean) => void
}

export default function ChatResponsePrintableBubble(props: ChatResponseBubbleProps) {
  const { message, conversation } = props
  const { metadata } = message

  const references = metadata.references
  const referencesInMessage = getUniqueReferenceNumbersInMessage(message)

  const usedReferences = Object.keys(references).filter((key) => referencesInMessage.includes(key))
  const unusedReferences = Object.keys(references).filter((key) => !referencesInMessage.includes(key))
  const formattedText = chatV2FormatResponseStringPrintable(conversation, message)
  const messageHeader = getBrandName()
  const referencesCount = Object.keys(references).length

  return (
    <div>
      <h2>{messageHeader}</h2>
      <p>{formattedText}</p>

      {referencesCount > 0 && (
        <div>
          <hr />
          <h3>References</h3>
          {usedReferences.map((referenceKey) => (
            <ChatMessageBubbleReferencePrintable key={referenceKey} conversation={conversation} message={message} referenceKey={referenceKey} />
          ))}
          {unusedReferences.length > 0 && (
            <>
              <hr />
              <h3>Related: </h3>
              {unusedReferences.map((referenceKey) => (
                <ChatMessageBubbleReferencePrintable key={referenceKey} conversation={conversation} message={message} referenceKey={referenceKey} />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  )
}
