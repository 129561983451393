import { ReactNode, useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import Onboarding from '@/onboarding/views/Onboarding'
import { isHaikuHost } from '@/util/enterprise'

type SurveyGuardProps = {
  children: ReactNode
}

export default function SurveyGuard(props: SurveyGuardProps) {
  const { children } = props
  const { user, userAccountData } = useContext(AuthContext)

  switch (true) {
    // Haiku Host - No Guard
    case isHaikuHost():
      return children

    // Anonymous Users - No Guard
    case user?.isAnonymous == true:
      return children

    case userAccountData?.legacyDriveCompatibleOrganizationId != null:
      return children

    // Pending Subscription - Show Loader
    case !userAccountData?.surveyCompleted:
      return <Onboarding />

    // All other cases
    default:
      return children
  }
}
