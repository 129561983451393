// import { z } from 'zod'

// export const kVisibleReferenceKey = 'show_ref'

export enum CaseLawReferenceViewTabs {
  source = 'source',
  citator = 'citator',
}

// // Zod schema definition
// const ReferenceViewUriDataSchema = z.object({
//   c_id: z.string(), // Conversation ID
//   m_id: z.string(), // Message ID
//   ref_n: z.string(), // Reference Number
//   tab: z.nativeEnum(CaseLawReferenceViewTabs), // Tab
// })
// export type ReferenceViewUriData = z.infer<typeof ReferenceViewUriDataSchema>

// /**
//  * Reference View URI Handler
//  * Encodes the data structure as a JSON string
//  * Decodes the URI data and validates it against zod schema
//  */
// export class ReferenceViewUriHandler {
//   // Getter for the data as a json string
//   static encodeUriData(data: ReferenceViewUriData): string {
//     return JSON.stringify(data, null, 0)
//   }

//   // Decode the URI data (and validate)
//   static decode(uriData: string | null): ReferenceViewUriData | null {
//     // Return null if the data is null
//     if (uriData === null) {
//       return null
//     }

//     const data = JSON.parse(uriData)

//     // Validate the data
//     const result = ReferenceViewUriDataSchema.safeParse(data)

//     // Throw an error if the data is invalid
//     if (!result.success) {
//       throw new Error('Invalid URI data', result.error)
//     }

//     return ReferenceViewUriDataSchema.parse(data)
//   }
// }
