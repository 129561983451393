import { CircularProgress, ThemeProvider, createTheme } from '@mui/material'

export const CircularProgressContinuousTheme = createTheme({
  palette: {
    primary: {
      main: '#0285c7', // sky-600 rgb(2, 132, 199)
    },
  },
})

// A spinning loader designed to fit into a tailwind ui button
export const CircularProgressContinuous = () => {
  return (
    <>
      <div className={''}>
        <div role="status" className="relative block h-[25px] pl-[5px]">
          <div className={'absolute z-0'}>
            <ThemeProvider theme={CircularProgressContinuousTheme}>
              <CircularProgress size={25} thickness={5} />
            </ThemeProvider>
          </div>
        </div>
      </div>
    </>
  )
}

// A spinning loader for custom sizing containers
type CircularProgressContinuousSizedProps = {
  size: number
  thickness: number
  hexColor?: string // e.g. '#0285c7'
}
export const CircularProgressContinuousSized = (props: CircularProgressContinuousSizedProps) => {
  const { size, thickness, hexColor = '#0285c7' } = props

  const theme = createTheme({
    palette: {
      primary: {
        main: hexColor,
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CircularProgress size={size} thickness={thickness} />
    </ThemeProvider>
  )
}
