import { visit } from 'unist-util-visit'

// This rehype plugin is used to search for and convert footnote references to span elements
// The span elements are then used to render a ChatFootnotePopover component inside a Markdown component
// The ChatFootnotePopover component is used to render a clickable button that triggers a popover
// The replacement is done by checking if the text node contains a footnote reference
// Footnote references are in the format '[1]', '[2]', etc.
// The output is a span element with a data attribute 'data-footnote' containing the reference number
// See src/chat-common/util/chat-v2-format-response.tsx for how the span element is used
//
// Example usage:
// ```
// import { rehypeCustomFootnotes } from './chat-remark-footnote'
// import { rehypeRaw } from 'rehype-raw'
// import { Markdown } from './chat-markdown'
//
// <Markdown
//   rehypePlugins={[rehypeRaw, rehypeCustomFootnotes]}
//   components={{
//     span: ({ node, ...props }) => {
//       if ('data-footnote' in props) {
//         const footnoteIndex = props['data-footnote'] as string
//         ...
//       }
//   }}
// >
//   {content}
// </Markdown>
// ```

export function rehypeCustomFootnotes() {
  return (tree: any) => {
    visit(tree, 'text', (node, index, parent) => {
      const footnoteRegex = /\[\d+\]/g
      const matches = node.value.matchAll(footnoteRegex)

      // Array to hold new nodes
      const children = []
      let lastIndex = 0

      for (const match of matches) {
        const start = match.index
        const end = start + match[0].length

        // Push preceding text if any
        if (start > lastIndex) {
          children.push({
            type: 'text',
            value: node.value.slice(lastIndex, start),
          })
        }

        // Push a span with a data attribute for the footnote
        children.push({
          type: 'element',
          tagName: 'span',
          properties: {
            className: ['footnote-reference'],
            dataFootnote: match[0].slice(1, -1),
          },
          children: [{ type: 'text', value: match[0] }],
        })

        lastIndex = end
      }

      // Push remaining text if any
      if (lastIndex < node.value.length) {
        children.push({
          type: 'text',
          value: node.value.slice(lastIndex),
        })
      }

      // Replace the original text node with the new nodes
      parent.children.splice(index, 1, ...children)
    })
  }
}
