import React from 'react'
import { BillingCycle } from '@/firebase/auth/auth-jwt-schema'

interface BillingCycleToggleProps {
  billingCycle: BillingCycle
  handleBillingCycleSelect: (cycle: BillingCycle) => void
}

const BillingCycleToggle: React.FC<BillingCycleToggleProps> = ({ billingCycle, handleBillingCycleSelect }) => {
  return (
    <div className="flex justify-center max-w-[14rem] m-auto">
      <div className="relative flex w-full p-1 bg-white dark:bg-slate-900 rounded-md">
        <span className="absolute inset-0 m-1 pointer-events-none" aria-hidden="true">
          <span
            className={`absolute inset-0 w-1/2 bg-sky-700 rounded-md shadow-sm transform transition-transform duration-150 ease-in-out ${
              billingCycle === BillingCycle.ANNUAL ? 'translate-x-0' : 'translate-x-full'
            }`}
          ></span>
        </span>
        <button
          className={`relative flex-1 text-sm font-medium h-7 rounded-md transition-colors duration-150 ease-in-out ${
            billingCycle === BillingCycle.ANNUAL ? 'text-white' : 'text-slate-500 dark:text-slate-400'
          }`}
          onClick={() => handleBillingCycleSelect(BillingCycle.ANNUAL)}
        >
          Annual
        </button>
        <button
          className={`relative flex-1 text-sm font-medium h-7 rounded-md transition-colors duration-150 ease-in-out ${
            billingCycle === BillingCycle.ANNUAL ? 'text-slate-500 dark:text-slate-400' : 'text-white'
          }`}
          onClick={() => handleBillingCycleSelect(BillingCycle.MONTHLY)}
        >
          Monthly
        </button>
      </div>
    </div>
  )
}

export default BillingCycleToggle
