import SignInForm from '@/components/auth/sign-in-form'
import SignUpForm from '@/components/auth/sign-up-form'
import MicrosoftSSOForm from '@/components/auth/microsoft/microsoft-sso-form'
import { AuthDialogType, switchAuthDialog } from '@/store/slices/ui-state.slice'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import SendPasswordResetForm from '@/components/auth/send-password-reset-form'
import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

export default function InviteAuth() {
  // Watch global UI state
  const { authDialogType } = useAppSelector((state: RootState) => state.uiState)

  const dispatch = useAppDispatch()

  return (
    <div className={'flex-grow flex flex-col justify-center items-center lg:min-h-screen py-5'}>
      <img className="mx-auto w-[200px] mb-4" src={brandLogoUrl} alt={hostBrand} />
      <h1 className={'mb-4 text-center text-xl font-bold tracking-tight text-gray-900'}>Accept Invitation</h1>
      <div className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-md sm:p-6">
        {authDialogType === AuthDialogType.SIGN_IN && hostBrand !== BrandEnum.HAIKU && (
          <SignInForm
            switchToSignUp={() => dispatch(switchAuthDialog(AuthDialogType.SIGN_UP))}
            switchToForgotPassword={() => {
              dispatch(switchAuthDialog(AuthDialogType.FORGOT_PASSWORD))
            }}
            onSuccess={() => null}
          />
        )}
        {authDialogType === AuthDialogType.FORGOT_PASSWORD && (
          <SendPasswordResetForm
            goToSignIn={() => dispatch(switchAuthDialog(AuthDialogType.SIGN_IN))}
            formTitle="Password Reset"
            formDescription="You will receive an email with a link to reset your password."
            buttonText="Submit"
          />
        )}
        {authDialogType === AuthDialogType.SIGN_UP && hostBrand !== BrandEnum.HAIKU && (
          <SignUpForm title="Sign up" switchToSignIn={() => dispatch(switchAuthDialog(AuthDialogType.SIGN_IN))} onSuccess={() => null} allowReferral={false} />
        )}
        {(authDialogType === AuthDialogType.SIGN_IN || authDialogType === AuthDialogType.SIGN_UP) && hostBrand === BrandEnum.HAIKU && (
          <MicrosoftSSOForm onSuccess={() => null} />
        )}
      </div>
    </div>
  )
}
