import { MultiChoiceSingleSelect } from '@/components/survey/MultiChoiceSingleSelect'
import { MultiChoiceMultiSelect } from '@/components/survey/MultiChoiceMultiSelect'
import { TextInput } from '@/components/survey/TextInput'

export enum QuestionType {
  MULTIPLE_CHOICE_SINGLE_SELECT = 'multiple choice single select',
  MULTIPLE_CHOICE_MULTI_SELECT = 'multiple choice multi select',
  TEXT_INPUT = 'text input',
}

export type Question = {
  question: string
  inputs: (MultiChoiceSingleSelect | MultiChoiceMultiSelect | TextInput)[]
}

export const questions: Question[] = [
  {
    question: "What's your primary use for Paxton AI?",
    inputs: [
      {
        type: QuestionType.MULTIPLE_CHOICE_SINGLE_SELECT,
        options: [
          { value: 'Conducting legal research' },
          { value: 'Drafting legal documents' },
          { value: 'Getting answers to questions about your documents' },
          { value: 'Analyze your contracts' },
          { value: 'Other', hasTextInput: true },
        ],
        stateKey: 'primaryUse',
      },
    ],
  },
  {
    question: 'What is your current professional role?',
    inputs: [
      {
        type: QuestionType.MULTIPLE_CHOICE_SINGLE_SELECT,
        options: [
          { value: 'I am part of a law firm' },
          { value: 'I am part of a small or medium business' },
          { value: 'I am part of an in-house corporate legal department' },
          { value: 'I am part of a corporate marketing, product, HR, or other non-legal department' },
          { value: 'I am a startup founder or early employee' },
          { value: 'I am an individual' },
          { value: 'I am a law student' },
          { value: 'Other', hasTextInput: true },
        ],
        stateKey: 'role',
      },
    ],
  },
  {
    question: 'Which tools or services have you used for legal research before?',
    inputs: [
      {
        type: QuestionType.MULTIPLE_CHOICE_MULTI_SELECT,
        options: [
          { value: 'Westlaw' },
          { value: 'LexisNexis' },
          { value: 'Bloomberg Law' },
          { value: 'Google Scholar' },
          { value: 'None' },
          { value: 'Other', hasTextInput: true },
        ],
        stateKey: 'legalResearchTools',
      },
    ],
  },
  {
    question: 'What is your biggest challenge with your current legal tools?',
    inputs: [
      {
        type: QuestionType.MULTIPLE_CHOICE_SINGLE_SELECT,
        options: [
          { value: 'Cost' },
          { value: 'Complexity of use' },
          { value: 'Inadequate support' },
          { value: 'Insufficient features' },
          { value: 'Integration issues with other tools' },
          { value: 'Other', hasTextInput: true },
        ],
        stateKey: 'biggestChallenge',
      },
    ],
  },
  {
    question: 'How often do you anticipate using Paxton AI?',
    inputs: [
      {
        type: QuestionType.MULTIPLE_CHOICE_SINGLE_SELECT,
        options: [
          { value: 'Multiple times a day' },
          { value: 'Daily' },
          { value: 'A few times a week' },
          { value: 'Weekly' },
          { value: 'Monthly' },
          { value: 'Rarely' },
        ],
        stateKey: 'usageFrequency',
      },
    ],
  },
]
