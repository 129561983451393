import DiffMatchPatch from 'diff-match-patch'

/**
 * Create HTML Diff String
 * Uses diff-match-patch: https://www.npmjs.com/package/diff-match-patch
 *
 * The originalText will be marked up with additions and removals
 * @param originalText
 * @param replacementText
 * @returns
 */
export function createHtmlDiffString(originalText: string, replacementText: string): any {
  const dmp = new DiffMatchPatch()

  // Process the text into separated words for word-level diffing
  const { chars1, chars2, lineArray } = diff_linesToWords_(originalText, replacementText)
  // console.log('chars1: ', chars1)
  // console.log('chars2: ', chars2)
  // console.log('lineArray: ', lineArray)

  // Run through diff_main to get the diff array
  const mainDiff = dmp.diff_main(chars1, chars2, false)

  // Convert back to lines with _charsToLines_ (works on linesToWords)
  dmp.diff_charsToLines_(mainDiff, lineArray)

  // Perform semantic cleanup (combine character diffs into words)
  dmp.diff_cleanupSemantic(mainDiff)
  // console.log('Diff semantic cleanup: ', mainDiff)

  // Convert the diff to HTML with Paxton styles
  const paxtonHtml = diffToPaxtonHtml(mainDiff)
  // console.log('paxtonHtml: ', paxtonHtml)

  return paxtonHtml
}

/**
 * Paxton Diff-Match-Patch Styling
 * Applies the Paxton styling to the diff-match-patch diff output
 * Based on the example diff_prettyHtml in the diff-match-patch repository
 * @returns string
 */
function diffToPaxtonHtml(diffs: DiffMatchPatch.Diff[]) {
  const html = []
  const pattern_amp = /&/g
  const pattern_lt = /</g
  const pattern_gt = />/g
  const pattern_para = /\n/g
  for (let x = 0; x < diffs.length; x++) {
    const op = diffs[x][0] // Operation (insert, delete, equal)
    const data = diffs[x][1] // Text of change.
    const text = data.replace(pattern_amp, '&amp;').replace(pattern_lt, '&lt;').replace(pattern_gt, '&gt;').replace(pattern_para, '&para;<br>')
    switch (op) {
      case DiffMatchPatch.DIFF_INSERT:
        html[x] = `<span class="bg-[#d6f5e0] text-[#0f2f11] py-[2px] px-1 rounded-md">+&nbsp;${text}</span>`
        break
      case DiffMatchPatch.DIFF_DELETE:
        html[x] = `<span class="bg-[#fcd4d4] text-[#371818] py-[2px] px-1 rounded-md">-&nbsp;${text}</span>`
        break
      case DiffMatchPatch.DIFF_EQUAL:
        html[x] = `<span>${text}</span>`
        break
    }
  }
  return html.join(' ')
}

/**
 * Diff diff_linesToWords_
 * Based on this documentation: https://github.com/google/diff-match-patch/wiki/Line-or-Word-Diffs
 * Creates a word-mode diff from two texts
 * This function is based on diff_linesToChars_ but is updated to split the text into words
 * The diff_charsToLines_ function can work on words and does not need to be updated
 */
function diff_linesToWords_(text1: string, text2: string) {
  const wordArray: string[] = []
  const wordHash: { [x: string]: number } = {}

  wordArray[0] = ''

  // tslint:disable-next-line:variable-name
  const diff_linesToWordsMunge_ = (text: string) => {
    let chars = ''
    let wordArrayLength = wordArray.length
    tokenize(text, (word) => {
      const wordHasHasProperty = Object.prototype.hasOwnProperty.call(wordHash, word) || wordHash[word] !== undefined
      if (wordHasHasProperty) {
        chars += String.fromCharCode(wordHash[word])
      } else {
        chars += String.fromCharCode(wordArrayLength)
        wordHash[word] = wordArrayLength
        // tslint:disable-next-line:no-increment-decrement
        wordArray[wordArrayLength++] = word
      }
    })
    return chars
  }

  const chars1 = diff_linesToWordsMunge_(text1)
  const chars2 = diff_linesToWordsMunge_(text2)
  return { chars1, chars2, lineArray: wordArray }
}

/*****************************************
 * TOKENIZER FUNCTIONS
 ****************************************/
const WORD_BOUNDARY_PATTERN = /\W/

function indexOfWordBoundary(target: string, startIndex: number): number {
  const n = target.length
  for (let i = startIndex; i < n; i += 1) {
    if (WORD_BOUNDARY_PATTERN.test(target[i])) {
      return i
    }
  }
  return -1
}

export default function tokenize(text: string, callback: (word: string) => void): void {
  let wordStart = 0
  let wordEnd = -1
  while (wordEnd < text.length - 1) {
    wordEnd = indexOfWordBoundary(text, wordStart)
    if (wordEnd !== -1) {
      if (wordStart !== wordEnd) {
        const word = text.substring(wordStart, wordEnd)
        callback(word)
      }
      const punct = text[wordEnd]
      callback(punct)
      wordStart = wordEnd + 1
    } else {
      const word = text.substring(wordStart, text.length)
      callback(word)
      wordEnd = text.length
      break
    }
  }
}
