import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useAcceptInviteMutation } from '@/organizations/apis/org-admin.api'
import { ArrowRightIcon, CheckIcon } from '@radix-ui/react-icons'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '@/context/auth-context'
import signOutOfApp from '@/firebase/auth/sign-out'
import { useHubspotConversations } from '@/context/hubspot-conversations-provider'
import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

type InviteAcceptProps = {
  inviteId: string,
  brand?: string,
}

export default function InviteAccept(props: InviteAcceptProps) {
  const { inviteId, brand } = props
  const { user, userAccountData, refreshUserAccountData } = useContext(AuthContext)
  const navigate = useNavigate()
  const { toggleWidget } = useHubspotConversations()

  // Inferred values
  const email = user?.email
  const isOrgAccount = userAccountData?.isOrgAccount ?? false
  const orgName = userAccountData?.orgData?.name

  // Local state
  const [pollingStarted, setPollingStarted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<boolean>(false)

  // RTK-Query Mutation
  const [acceptInvite] = useAcceptInviteMutation()

  // ======================================================================
  // POLLING NOTE
  // The pending payment poll won't work
  // for existing users who are already paid or trialed
  // who are accepting an invite. So we have a dedicated
  // poll here.
  // ======================================================================

  // Poll for invite status
  // Polls until the user's JWT is updated with the organization_id
  // Then triggers an AuthContext refresh with the latest user data
  async function pollForInviteStatus() {
    console.log('Polling for invite status...')
    setPollingStarted(true)

    // Refresh user account data
    await refreshUserAccountData()

    // Check for the organization_id in the user's account data
    // Return if the user is in an organization
    if (isOrgAccount) {
      console.log('Poll found user is in an organization.')
      setSuccess(true)
      return
    }

    // Delay of 3 seconds
    await new Promise((resolve) => setTimeout(resolve, 3000))

    // Check again
    pollForInviteStatus()
  }

  // Handle Accept
  async function handleAccept() {
    console.log('Handling invitation acceptance...')

    try {
      setLoading(true)
      setError(null)

      await acceptInvite({ invite_id: inviteId, brand }).unwrap();
      // Add an artificial delay to show the loading spinner
      await new Promise((resolve) => setTimeout(resolve, 3000))

      // Trigger the poll to start
      if (!pollingStarted) {
        await pollForInviteStatus()
      }
    } catch (e: any) {
      if (e.status === 404) {
        setError('404: The invitation could not be found. Try again or contact your organization admin.')
      } else {
        setError('There was an error accepting your invitation.')
      }

      setLoading(false)
    }

    // Loading status is intended to continue until polling finds the user in an org
  }

  // On mount
  // If the user is already in an org, immediately mark as successful
  useEffect(() => {
    if (isOrgAccount) {
      console.log('User is already in an organization.')
      setSuccess(true)
      return
    }
  }, [isOrgAccount])

  return (
    <div className={'grow flex flex-col justify-center items-center min-h-screen py-5'}>
      <img className="mx-auto w-[200px] mb-4" src={brandLogoUrl} alt={hostBrand} />
      <div className="rounded-lg bg-white px-4 pb-4 pt-5 mb-5 text-left shadow-xl transition-all sm:w-full sm:max-w-md sm:p-6">
        {(email || orgName) && (
          <div className={'mb-2  text-center'}>
            <span className="font-semibold">{email}</span>
          </div>
        )}
        {!loading && !success && !error && (
          <div className={'flex flex-col items-center gap-x-2 justify-center'}>
            <div className={'mb-4 text-center'}>Click below to accept your invitation.</div>
            <button
              className={
                'rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              }
              onClick={handleAccept}
            >
              <div className={'flex gap-x-2 items-center'}>
                Accept Invitation <CheckIcon />
              </div>
            </button>
          </div>
        )}

        {loading && !success && (
          <div className={'flex items-center gap-x-2 justify-center'}>
            <div>Accepting invitation... </div>
            <CircularProgressContinuousSized size={18} thickness={7} />
          </div>
        )}
        {error && (
          <div className={'flex flex-col items-center gap-x-2 justify-center'}>
            <div className={'mb-4 text-red-700 text-center'}>{error}</div>
            <button
              className={
                'rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              }
              onClick={handleAccept}
            >
              Retry
            </button>
          </div>
        )}
        {success && (
          <div className={'flex flex-col items-center gap-x-2 justify-center'}>
            <div className={'mb-4 text-center'}>
              You have successfully joined {orgName && orgName}
              {!orgName && 'the organization'}
            </div>
            <button
              className={
                'rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              }
              onClick={() => navigate('/')}
            >
              <div className={'flex gap-x-2 items-center'}>
                Continue to Paxton <ArrowRightIcon />
              </div>
            </button>
          </div>
        )}
      </div>
      <div className={'flex gap-5'}>
        <button
          onClick={() => {
            signOutOfApp(navigate)
          }}
          className="font-semibold leading-6 text-sky-600 hover:text-sky-500"
        >
          Sign out
        </button>
        {hostBrand != BrandEnum.HAIKU && (
          <button
            onClick={() => {
              toggleWidget()
            }}
            className="font-semibold leading-6 text-sky-600 hover:text-sky-500"
          >
            Chat with support
          </button>
        )}
      </div>
    </div>
  )
}
