import { store } from '@/store/store'
import { citatorStateAddCaseStatus, citatorStateSetError, citatorStateSetLoading, citatorStateSetProcessing } from '../store/citator-state.slice'
import { getAuth } from 'firebase/auth'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { nanoid } from 'nanoid'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { kCitatorGetCaseStatusApiPath } from '@/constants/constants-api-paths'
import * as Sentry from '@sentry/react'
import { caseStatusResponseSchema } from '../schema/get-case-status-schema'

/**
 * Get Or Enqueue Case Status
 * The case status will either be returned or the server will kick off a processing job.
 * The data or processing status will be stored in the state.
 * @param parent_id
 */
export async function getOrEnqueueCaseStatus(parent_id: string) {
  // Set loading status
  store.dispatch(citatorStateSetLoading({ parent_id, loading: true }))
  store.dispatch(citatorStateSetError({ parent_id, error: false }))

  const sentry_transaction_id = nanoid()

  const token = await getAuth().currentUser?.getIdToken()
  if (!token) throw Error(kErrorCodeAuthError)

  // Construct request
  const apiUrl = kPaxtonAppApiBaseUrl() + `/${kCitatorGetCaseStatusApiPath}/${parent_id}`
  const requestOptions: RequestInit = {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-Transaction-Id': sentry_transaction_id,
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  }

  try {
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // Handle Response Codes
    const responseCode = response.status
    switch (responseCode) {
      // Success - should contain data - validate and store data in state
      case 200:
        console.log('200: Cached case status found: ', data)
        validateAndStoreResponse(parent_id, data)
        break

      // Accepted - processing job started - set as processing in state
      case 202:
        console.log('202: Case status processing job started for ', parent_id)
        store.dispatch(citatorStateSetProcessing({ parent_id, jobProcessing: true }))
        break

      // Already submitted - set as processing in state
      case 208:
        console.log(`208: Case status already submitted for ${parent_id}`)
        store.dispatch(citatorStateSetProcessing({ parent_id, jobProcessing: true }))
        break

      // All other cases represent an error - set error in state
      default: {
        store.dispatch(citatorStateSetError({ parent_id, error: true }))
        // Capture to sentry
        Sentry.withScope((scope) => {
          scope.setTags({ transaction_id: sentry_transaction_id })

          Sentry.captureException(new Error(`Case Status Fetch error - status: ${response.status}, detail: ${data.detail}`), {
            extra: { requestUrl: apiUrl, requestOptions: requestOptions, response: data, onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
          })
        })
        return
      }
    }
  } catch (error) {
    console.error('Failed to fetch case status: ', error)
    store.dispatch(citatorStateSetError({ parent_id, error: true }))
  } finally {
    store.dispatch(citatorStateSetLoading({ parent_id, loading: false }))
  }
}

/**
 * Validate and store response
 * @param parent_id the case we are fetching the status for
 * @param data the data we are validating
 * @returns {void}
 */
function validateAndStoreResponse(parent_id: string, data: any): void {
  // Validate response
  const responseParsed = caseStatusResponseSchema.safeParse(data)
  if (!responseParsed.success) {
    console.error(responseParsed.error)
    store.dispatch(citatorStateSetError({ parent_id, error: true }))
    return
  }

  // Dispatch response
  store.dispatch(citatorStateAddCaseStatus(responseParsed.data))
  return
}
