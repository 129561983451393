import { EnvelopeOpenIcon } from '@heroicons/react/24/outline'

export default function ReferralInviteMessage() {
  return (
    <div className="rounded-lg bg-sky-50 p-3 my-2 grid grid-cols-[45px_auto] items-center">
      <span className={'inline-block h-8 w-8 text-sky-600 rounded-lg p-1'}>
        <EnvelopeOpenIcon />
      </span>
      <span className={'inline-block text-xs text-sky-700 text-opacity-70'}>Referral code active!</span>
    </div>
  )
}
