import { ReactNode, useContext } from 'react'
import { useLocation } from 'react-router'
import { AuthContext } from '@/context/auth-context'
/**
 * Org Admin Guard
 * Shows a message if the user is not an organization admin
 * Otherwise renders the children
 */
export default function OrgAdminGuard({ children }: { children: ReactNode }) {
  const location = useLocation()

  // Auth Context
  const { userAccountData } = useContext(AuthContext)

  if (userAccountData?.role !== 'admin') {
    console.log(`An organization admin account is required to access ${location.pathname}`)
    return (
      <div className={'flex-grow flex flex-col justify-center items-center lg:min-h-screen py-2 pb-4'}>
        You must be an admin to access the organization admin dashboard.
      </div>
    )
  }

  return children
}
