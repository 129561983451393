import { useParams } from 'react-router'
import { ChatV2Feature } from '../store/chat-v2.slice'
import {
  NewChatIntroExample,
  kChatV2CaseLawExamples,
  kChatV2DocumentQueryExamples,
  kChatV2LRRExamples,
  kChatV2WebSearchExamples,
  kChatV2DraftingChatExamples,
  kChatV2ContractAnalysisExamples,
  kChatV2ContractSearchExamples,
  kChatV2ContractSearchExampleTitle,
  kChatV2DocumentSummarizationExamples,
  kChatV2ResearchExamples,
  kChatV2AssistantExamples,
} from '../chat-v2-examples'

export default function NewChatIntro() {
  const { chatFeature } = useParams()

  // Handle bad params
  // Ensure featureId exists and is equivalent to a feature enum
  if (!chatFeature || !Object.values(ChatV2Feature).includes(chatFeature as ChatV2Feature)) {
    console.error(`Invalid featureId ${chatFeature}.`)
    return null
  }

  let examples: NewChatIntroExample[] = []
  switch (true) {
    case chatFeature === ChatV2Feature.lrr_v2:
      examples = kChatV2LRRExamples
      break
    case chatFeature === ChatV2Feature.caselaw:
      examples = kChatV2CaseLawExamples
      break
    case chatFeature === ChatV2Feature.research:
      examples = kChatV2ResearchExamples
      break
    case chatFeature === ChatV2Feature.documentquery:
      examples = kChatV2DocumentQueryExamples
      break
    case chatFeature === ChatV2Feature.websearch:
      examples = kChatV2WebSearchExamples
      break
    case chatFeature === ChatV2Feature.drafting:
      examples = kChatV2DraftingChatExamples
      break
    case chatFeature === ChatV2Feature.contractanalysis:
      examples = kChatV2ContractAnalysisExamples
      break
    case chatFeature === ChatV2Feature.contractsearch:
      examples = kChatV2ContractSearchExamples
      break
    case chatFeature === ChatV2Feature.documentSummarization:
      examples = kChatV2DocumentSummarizationExamples
      break
    case chatFeature === ChatV2Feature.assistant:
      examples = kChatV2AssistantExamples
      break
    default:
      console.error(`No examples defined for feature ${chatFeature}.`)
      return null
  }

  // Feature-based helpHeader
  const helpHeader = () => {
    switch (true) {
      case chatFeature === ChatV2Feature.contractanalysis:
        return 'Analyze a contract'
      case chatFeature === ChatV2Feature.contractsearch:
        return kChatV2ContractSearchExampleTitle
      case chatFeature == ChatV2Feature.assistant:
        return 'How may I be of assistance?'
      default:
        return 'How can I help?'
    }
  }

  const headerAlignmentClass = chatFeature === ChatV2Feature.assistant ? 'text-left ml-1' : 'text-center'

  return (
    <div className={'flex flex-grow items-end'}>
      <div className="p-4">
        <h2 className={`text-lg font-bold mb-4 ${headerAlignmentClass}`}>{helpHeader()}</h2>
        <div className="grid md:grid-cols-2 gap-2 sm:gap-4">
          {examples.map((item, index) => (
            <div key={index} className="border p-2 sm:p-4 rounded-lg">
              <h3 className="font-bold text-sm">{item.title}</h3>
              <p className="text-sm mt-1 text-gray-500">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
