import { ChevronUpIcon, ChevronDownIcon } from '@radix-ui/react-icons'
import * as Collapsible from '@radix-ui/react-collapsible'
import { Link } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { NavigationItem } from './dashboard-sidebar'

type SidebarProps = {
  open: boolean
  onOpenChange: () => void
  title: string
  items: NavigationItem[]
  isAnonUser: boolean
  setSidebarOpen: (open: boolean) => void
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const CollapsibleItem = (props: SidebarProps) => {
  const { open, onOpenChange, setSidebarOpen, title, items, isAnonUser } = props

  return (
    <Collapsible.Root className="w-full mb-2" open={open} onOpenChange={onOpenChange}>
      <Collapsible.Trigger className="flex w-full pr-2 justify-between">
        <span className="text-[15px] font-semibold leading-[25px]">{title}</span>
        {open ? <ChevronUpIcon className={'hover:text-sky-600 self-center h-4 w-4'} /> : <ChevronDownIcon className="self-center h-4 w-4" />}
      </Collapsible.Trigger>

      <Collapsible.Content className="CollapsibleContent">
        <div>
          {items.map((item, i) => {
            return (
              <Link
                to={item.href}
                onClick={() => setSidebarOpen(false)}
                className={classNames(
                  item.current ? 'bg-gray-50 text-sky-600' : 'text-gray-700 hover:text-sky-600 hover:bg-gray-50',
                  'group flex gap-x-3 items-center px-2 py-1 text-sm leading-6',
                  item.subItem != true ? '' : 'ml-4 pl-1.5 border-l-[1px] border-l-gray-200'
                )}
                key={`${i}-${item.name}`}
              >
                <item.icon
                  fontSize="small"
                  className={classNames(item.current ? 'text-sky-600' : 'text-gray-400 group-hover:text-sky-600', item.subItem != true ? 'h-5 w-5' : 'h-4 w-4')}
                  aria-hidden="true"
                />
                <span className={item.subItem != true ? '' : 'text-xs'}>{item.name}</span>
                {isAnonUser && !item.allowAnon && <LockClosedIcon className={'h-4 ml-auto text-gray-400'} />}
                {!isAnonUser && item.new && (
                  <div className={'text-2xs leading-none ml-auto pt-[1.5px] pb-[2.5px] px-[5px] rounded-full bg-purple-500 text-white'}>beta</div>
                )}
              </Link>
            )
          })}
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  )
}

export default CollapsibleItem
