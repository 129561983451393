import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import {
  ChatV2QueryMetadataContractSearch,
  ChatV2QueryMetadataContractSearchSchema,
  initialContractSearchMetadata,
} from '../schemas/chat-query-metadata-schema'

// Set the source_name for a conversation's currentSource
export const reducerCurrentSourceContractSearchMetadata = (
  state: ChatV2State,
  action: PayloadAction<{
    conversationId: string
    contractSearchMetadata: ChatV2QueryMetadataContractSearch
  }>
) => {
  const { conversationId, contractSearchMetadata } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  if (conversation.currentSource == null)
    conversation.currentSource = {
      ...initialContractSearchMetadata,
    }

  const validation = ChatV2QueryMetadataContractSearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)

  const currentSource = conversation.currentSource as ChatV2QueryMetadataContractSearch

  currentSource.chunk_id = contractSearchMetadata.chunk_id
  currentSource.document_id = contractSearchMetadata.document_id
  currentSource.title = contractSearchMetadata.title
  currentSource.source = contractSearchMetadata.source
  currentSource.s3_path = contractSearchMetadata.s3_path
}
