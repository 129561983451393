type OnboardingHeaderProps = {
  title: string
  description: string
}

const OnboardingHeader: React.FC<OnboardingHeaderProps> = ({ title, description }) => {
  return (
    <div className="">
      <h1 className="text-4xl font-bold">{title}</h1>
      <p className="text-lg text-gray-500">{description}</p>
    </div>
  )
}

export default OnboardingHeader
