import { useOnlineStatus } from '@/context/online-status-context'
import { SignalSlashIcon } from '@heroicons/react/24/outline'
import React from 'react'

const DashboardMobileConnectionBanner: React.FC = () => {
  const isOnline = useOnlineStatus()

  if (!isOnline) {
    return (
      <div className={'flex items-center gap-x-1 text-xs font-normal text-red-700'}>
        <div className={'w-4 h-4'}>
          <SignalSlashIcon />
        </div>
        <div className={''}>No connection</div>
      </div>
    )
  }

  return null
}

export default DashboardMobileConnectionBanner
