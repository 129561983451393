import BlinkingCursor from '@/components/loaders/BlinkingCursor'
import { getBrandName } from '@/util/enterprise'

type ChatLoadingBubbleProps = {
  message: string
}

export default function ChatLoadingBubble(props: ChatLoadingBubbleProps) {
  const { message } = props

  // // Check if the most recent message is a user query
  // const mostRecentMessageIsUserQuery = Object.values(messages).reverse()[0].metadata.message_type === ChatV2MessageType.user_query

  // // Get most recent message of type response
  // // Responses have multiple stages (receive metadata with no text, receiving text stream)
  // const mostRecentResponse =
  //   Object.values(messages)
  //     .reverse()
  //     .find((message) => message.metadata.message_type === ChatV2MessageType.response) ?? null

  // // Statuses
  // // finding sources...
  // // analyzing sources...
  // // generating response...
  // function GenerateLoadingMessage() {
  //   // These conditions no longer hold true, use a timer instead
  //   // // If the most recent message is a user_query, we are still finding sources
  //   // if (mostRecentMessageIsUserQuery) return 'Finding relevant sources'

  //   // // If the most recent response has no text, we are analyzing sources
  //   // if (mostRecentResponse?.text === '') return 'Analyzing sources'

  // }

  // // If most recent response has text, don't displat
  // if (!mostRecentMessageIsUserQuery && mostRecentResponse?.text !== '') return null

  return (
    <>
      <div className={`p-2 rounded-lg text-base whitespace-pre-wrap bg-white`}>
        <div className={`font-bold text-base mb-1`}>
          <div>{getBrandName()}</div>
        </div>
        <div className={`text-sm leading-6 mb-1`}>
          <div className={'inline-block'}>{message}</div> <BlinkingCursor blink={true} />
        </div>
      </div>
      <div className={`h-2`} />
    </>
  )
}
