import { useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import { AnalyticsEvent, AnalyticsEventProperties } from '@/analytics/schema/events.schema.ts'
import { DataLayerObject } from '@/analytics/schema/tag-manager.schema.ts'
import Cookies from 'js-cookie'

declare global {
  interface Window {
    dataLayer: Array<DataLayerObject>
  }
}

const getHubSpotTrackingCookie = (): string | undefined => {
  return Cookies.get('hubspotutk')
}

export const useAnalytics = () => {
  const { user } = useContext(AuthContext)

  // Grab HubSpot tracking cookie
  const hubspotUtk = getHubSpotTrackingCookie()

  const trackEvent = (event: AnalyticsEvent, properties?: AnalyticsEventProperties[AnalyticsEvent]) => {
    console.log(`Tracking event: ${event}`, { ...properties, user: user?.email })

    window.dataLayer = window.dataLayer || []

    const eventData: DataLayerObject = {
      event,
      userEmail: user?.email || '',
      ...properties,
      hubspotUtk,
    }

    window.dataLayer.push(eventData)
  }

  return { trackEvent }
}
