import { ArrowsRightLeftIcon, BeakerIcon, CheckBadgeIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'

const examplesList = [
  {
    title: 'Compare policies to standards',
    description: 'Does the document 1 policy comply with document 2 standards?',
    icon: CheckBadgeIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Compare contract revisions',
    description: 'What are the implications of the changes between document 1 and document 2?',
    icon: ArrowsRightLeftIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    title: 'Compare agreements between parties',
    description: 'What are the differences between these two NDA documents?',
    icon: ShieldCheckIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
  },
  {
    title: 'Check collateral for compliance',
    description: 'Does the ad copy in document 1 comply with the regulations in document 2?',
    icon: BeakerIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
  },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function ExamplesBlockAudit() {
  return (
    <>
      <div className="bg-white shadow rounded-lg sm:max-w-screen-lg">
        <div className="mb-5 px-4 py-5 sm:p-6">
          <h1 className={'text-base font-semibold leading-6 text-gray-900'}>How to compare documents</h1>
          <p className={'my-2 text-sm text-gray-500'}>Paxton can expertly evaulate and compare documents. Here are some examples. Feel free to experiment.</p>
        </div>
      </div>
      <div className="sm:max-w-screen-lg divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        {examplesList.map((example, exampleIndex) => (
          <div
            key={example.title}
            className={classNames(
              exampleIndex === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              exampleIndex === 1 ? 'sm:rounded-tr-lg' : '',
              exampleIndex === examplesList.length - 2 ? 'sm:rounded-bl-lg' : '',
              exampleIndex === examplesList.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-500'
            )}
          >
            <div>
              <span className={classNames(example.iconBackground, example.iconForeground, 'inline-flex rounded-lg p-3 ring-4 ring-white')}>
                <example.icon className="h-6 w-6" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">{example.title}</h3>
              <p className="mt-2 text-sm text-gray-500">"{example.description}"</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
