import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import NewChatIntro from './new-chat-intro'
import runChatV2WebsocketQuery, { ChatV2WebsocketQueryArgs } from '../chat-v2-websocket'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import {
  ChatV2MessageType,
  ChatV2Message,
  chatV2InsertUserQueryMessage,
  chatV2SetConversationCurrentTextInput,
  ChatV2Feature,
  ChatV2Conversation,
} from '../store/chat-v2.slice'
import { nanoid } from 'nanoid'
import { ArrowUpIcon, ChevronDownIcon } from '@radix-ui/react-icons'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import NewChatButton from './new-chat-button'
import ChatControls from './form-source-controls/chat-controls'
import ChatResponseBubble from './message-bubbles/chat-response-bubble'
import ChatSuggestedQuestionsBubble from './message-bubbles/chat-suggested-questions-bubble'
import ChatLoadingBubble from './message-bubbles/chat-loading-bubble'
import ChatClientErrorMessageBubble from './message-bubbles/chat-client-error-bubble'
import { useOnlineStatus } from '@/context/online-status-context'
import { getAuth } from 'firebase/auth'
import { AuthDialogType, openAuthDialog } from '@/store/slices/ui-state.slice'
import { RootState } from '@/store/store'
import MessageFeedbackDialog from './dialogs/message-feedback-dialog'
import ChatUserQueryBubble from './message-bubbles/chat-user-query-bubble'
import { chatSubmitDisabled } from './chat-window-utils'
import ChatWindowDropZone from './chat-window-dropzone'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'

type ChatWindowProps = {
  conversationMessagesLoading: boolean
}

enum ChatStatusMessage {
  findingSources = 'Finding relevant sources...',
  analyzingQuery = 'Analyzing query...',
  analyzingSources = 'Analyzing sources...',
  generatingResponse = 'Generating response...',
  draftingResponse = 'Drafting response...',
  reviewingContract = 'Reviewing contract...',
  compilingSummary = 'Compiling summary and suggestions...',
  generatingSummary = 'Generating summary...',
}

export default function ChatWindow(props: ChatWindowProps) {
  const { conversationMessagesLoading } = props
  const { chatId } = useParams()
  const dispatch = useAppDispatch()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const isOnline = useOnlineStatus()
  const auth = getAuth()
  const isAnonymous = auth.currentUser?.isAnonymous ?? false
  const { trackEvent } = useAnalytics()

  // MessageFeedbackDialog state
  const [messageFeedbackDialogVisible, setMessageFeedbackDialogVisible] = useState<boolean>(false)
  const [messageFeedbackDialogMessage, setMessageFeedbackDialogMessage] = useState<ChatV2Message | null>(null)
  const [messageFeedbackDialogPositive, setMessageFeedbackDialogPositive] = useState<boolean | null>(null)

  // MessageFeedbackDialog open
  function openMessageFeedbackDialog(message: ChatV2Message, positive: boolean) {
    setMessageFeedbackDialogMessage(message)
    setMessageFeedbackDialogPositive(positive)
    setMessageFeedbackDialogVisible(true)
  }

  // Refs
  const scrollingChatWindowRef = useRef<HTMLDivElement>(null)

  // First scroll to bottom at chat load
  const [stickToBottom, setStickToBottom] = useState<boolean>(true)

  // Watch the state for this conversation
  const conversation = useAppSelector((state: RootState) => (chatId ? state.chatV2State.conversations[chatId] : null))
  const feature = conversation?.feature as ChatV2Feature
  const messages = conversation?.messages ?? {}
  const currentTextInput = conversation?.currentTextInput ?? ''
  const formValidationError = conversation?.formValidationError ?? false

  // Whether this is a discontinued feature
  const discontinuedFeature = feature == ChatV2Feature.lrr

  // Whether the current conversation is loading or if this is a pending conversation
  const isLoading: boolean = conversation?.isLoading || false

  // Loading Message State
  const [loadingMessage, setLoadingMessage] = useState<string>('Generating response...')

  // Convert record to an array of messages
  const messagesArray = Object.values(messages)

  // Get most recent message
  const mostRecentMessage = Object.values(messages).reverse()[0] ?? null

  // Is the most recent message a user_query?
  const mostRecentMessageIsUserQuery = mostRecentMessage?.metadata?.message_type === ChatV2MessageType.user_query

  // Does the most recent message contain no text?
  const mostRecentMessageHasNoText = mostRecentMessage?.text === ''

  // Show loading bubble?
  const showLoadingBubble = isLoading && (mostRecentMessageIsUserQuery || mostRecentMessageHasNoText)

  // Get last submitted user_query message
  const lastUserQueryMessage = messagesArray.filter((message) => message.metadata.message_type === ChatV2MessageType.user_query).pop() ?? null

  // Only show retry click on error if a last user query message exists to get the text from
  const retryClickHasMessageToUse: boolean = lastUserQueryMessage != null

  // Form submit disabled
  const submitDisabled = chatSubmitDisabled(conversation)

  // Messages cycle
  let timeoutId1: NodeJS.Timeout | null = null
  let timeoutId2: NodeJS.Timeout | null = null

  const draftingTimeoutLength = 2000
  const contractAnalysisLength = 7500
  const standardTimeoutLength = 15000

  function triggerLoadingMessagesCycle() {
    switch (feature) {
      case ChatV2Feature.drafting:
        setLoadingMessage(ChatStatusMessage.analyzingQuery)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatStatusMessage.draftingResponse)
        }, draftingTimeoutLength)
        break
      case ChatV2Feature.contractanalysis:
        setLoadingMessage(ChatStatusMessage.reviewingContract)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatStatusMessage.compilingSummary)
        }, contractAnalysisLength)
        break
      case ChatV2Feature.documentSummarization:
        setLoadingMessage(ChatStatusMessage.analyzingQuery)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatStatusMessage.generatingSummary)
        }, standardTimeoutLength)
        break
      default:
        setLoadingMessage(ChatStatusMessage.findingSources)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatStatusMessage.analyzingSources)
          timeoutId2 = setTimeout(() => {
            setLoadingMessage(ChatStatusMessage.generatingResponse)
          }, standardTimeoutLength)
        }, standardTimeoutLength)
        break
    }
  }

  function cancelLoadingMessagesCycle() {
    if (timeoutId1) clearTimeout(timeoutId1)
    if (timeoutId2) clearTimeout(timeoutId2)

    // Reset timeout IDs to null
    timeoutId1 = null
    timeoutId2 = null

    // Optionally, reset the loading message
    setLoadingMessage('')
  }

  /**
   * Handle Send Message
   * Handles sending the message when we have an existing conversation id
   *
   * IF: we have no conversation id (pending or real from server) in the URL param, we are creating a new conversation
   * 1. Create a pending conversation including the user's message
   * 2. Submit the query to the server with the appropriate args so the dispatcher can apply the appropriate handoff data
   * 3. The ChatV2Page will detect the conversationId from the server (and fromPendingId provided) and navigate
   *
   * @param e
   * @returns
   */
  const handleSendMessage = (messageOverride?: string) => {
    // Return early if submit is disabled, but not if messageOverride is provided
    // messageOverride comes from buttons that override the text box input
    if (submitDisabled && !messageOverride) return

    const message = messageOverride ?? currentTextInput

    if (conversation == null) {
      throw new Error('Cannot send message without a conversation.')
    }

    // Validate a current source is set
    if (!conversation.currentSource) {
      console.error('Cannot submit message to server without a current source set.')
      return
    }

    // Construct the user message object from the form values for the UX
    const userMessage: ChatV2Message = {
      text: message,
      metadata: {
        message_id: nanoid(),
        feature: feature,
        message_type: ChatV2MessageType.user_query,
        created_at: new Date().toISOString(),
        conversation_id: conversation.id,
        is_error: false,
        get_follow_up_questions: false,
        references: {},
      },
    }

    // Create args to submit the query
    const args: ChatV2WebsocketQueryArgs = {
      feature: feature,
      conversation: conversation,
      userMessage,
      anonymousRateLimitedExceededFunction: function (): void {
        console.warn('Need to implement UX handling here for anonymousRateLimitedExceededFunction().')
      },
    }

    // 1. Insert the user's message into the pending conversation immediately for display
    dispatch(chatV2InsertUserQueryMessage({ conversationId: conversation.id, message: userMessage }))

    // Cancel existing loader messages cycle and trigger a new one
    cancelLoadingMessagesCycle()
    triggerLoadingMessagesCycle()

    // Run the query on the new message
    runChatV2WebsocketQuery(args, dispatch)

    // Clear the form
    dispatch(chatV2SetConversationCurrentTextInput({ conversationId: conversation.id, text: '' }))

    // Update the height of the text area
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '40px' // starting height of area
    }

    // Track the new message
    trackEvent(AnalyticsEvent.NewChatMessage, { feature: feature })
  }

  // Construct bubbles
  function BubbleConstructor(props: { conversation: ChatV2Conversation; message: ChatV2Message }) {
    const { conversation, message } = props
    const { metadata } = message
    const { message_type } = metadata

    // Skip rendering the latest response bubble if we are displaying the loading bubble
    if (showLoadingBubble && message_type == ChatV2MessageType.response && message?.metadata?.message_id === mostRecentMessage?.metadata.message_id) {
      return
    }

    switch (message_type) {
      case ChatV2MessageType.suggested_questions:
        return (
          <ChatSuggestedQuestionsBubble
            message={message}
            conversationIsLoading={isLoading}
            disabled={formValidationError}
            onSuggestionClick={(suggestion: string) => {
              console.log('Submitting the suggestion: ', suggestion)
              handleSendMessage(suggestion)
            }}
          />
        )

      case ChatV2MessageType.user_query:
        return <ChatUserQueryBubble conversation={conversation} message={message} />

      case ChatV2MessageType.response:
        return <ChatResponseBubble message={message} openFeedbackDialog={openMessageFeedbackDialog} />

      case ChatV2MessageType.client_error:
        return <ChatClientErrorMessageBubble message={message} openFeedbackDialog={openMessageFeedbackDialog} />
    }
  }

  // Render messages
  function ConversationMessages(props: { conversation: ChatV2Conversation; messages: ChatV2Message[] }) {
    const { conversation, messages } = props

    return messages.map((message) => <BubbleConstructor key={`${message.metadata.message_id}`} conversation={conversation} message={message} />)
  }

  function scrollToBottom() {
    const container = scrollingChatWindowRef.current
    if (!container) return

    container.scrollTop = container.scrollHeight
  }

  useEffect(() => {
    const container = scrollingChatWindowRef.current
    if (!container) return

    const handleScroll = () => {
      const scrollHeight = container.scrollHeight
      const scrollTop = container.scrollTop
      const clientHeight = container.clientHeight

      const bufferHeight = 2
      const scrollPosition = scrollHeight - scrollTop - clientHeight
      const atBottom = scrollPosition < bufferHeight

      // console.log(`Scroll height: ${scrollHeight}, scroll top: ${scrollTop}, client height: ${clientHeight}`)
      // console.log(`Scrolling position: ${scrollPosition}, at bottom? ${atBottom}`)
      if (atBottom) {
        setStickToBottom(true)
      } else {
        setStickToBottom(false)
      }
    }

    container.addEventListener('scroll', handleScroll)
    return () => container?.removeEventListener('scroll', handleScroll)
  }, [])

  /**
   * Stick To Bottom
   * Conditionally Scroll To The Bottom of Chat Window
   *
   * If not at the bottom, clicking the arrow indicator will scroll
   */
  useEffect(() => {
    const container = scrollingChatWindowRef.current
    if (!container) return

    if (stickToBottom) {
      scrollToBottom()
    }
  }, [conversation, chatId]) // Call every time messagesArray changes

  return (
    <>
      {messageFeedbackDialogMessage && messageFeedbackDialogPositive != null && (
        <MessageFeedbackDialog
          message={messageFeedbackDialogMessage}
          positive={messageFeedbackDialogPositive}
          visible={messageFeedbackDialogVisible}
          onClose={() => {
            setMessageFeedbackDialogVisible(false)
            setMessageFeedbackDialogMessage(null)
            setMessageFeedbackDialogPositive(null)
          }}
        />
      )}

      <div className="flex flex-grow flex-col items-center">
        <div ref={scrollingChatWindowRef} className="flex-1 flex-grow overflow-y-scroll w-full">
          {/* This div will contain the conversation and will be scrollable */}
          <div className="max-w-3xl mx-auto h-full">
            {/* Render circular progress indicator if we're loading the messages */}
            {conversationMessagesLoading && (
              <div className={'w-full h-full flex justify-center items-center'}>
                <CircularProgressContinuousSized size={30} thickness={7} hexColor={'#0285c7'} />
              </div>
            )}

            {/* Render the new chat intro if we are on the new chat screen and have not sent an initial message */}
            {!conversationMessagesLoading && (!conversation || messagesArray.length == 0) && (
              <div className={' h-full flex flex-col pb-5'}>
                <NewChatIntro />
                <ChatWindowDropZone
                  onUpdate={() => {
                    scrollToBottom()
                  }}
                />
                <div className={'flex justify-center'}>
                  {/* Render the new chat button if there is no chatId  */}
                  <div className={'flex-grow max-w-xs'}>{!chatId && <NewChatButton />}</div>
                </div>
              </div>
            )}

            {conversation && (
              <>
                {/* Render the conversation messages */}
                <ConversationMessages conversation={conversation} messages={messagesArray} />

                {/* Conditionally shows loading statuses for the current conversation */}
                {showLoadingBubble && <ChatLoadingBubble message={loadingMessage} />}

                {/* Show a retry button conditionally */}
                {mostRecentMessage?.metadata?.message_type == ChatV2MessageType.client_error && retryClickHasMessageToUse && isOnline && (
                  <>
                    <button
                      className={`flex-auto items-center rounded-md bg-sky-600 border-[1px] border-sky-600 px-3 py-2 mt-1 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500`}
                      onClick={() => {
                        // If the last user query message is null, we cannot retry
                        if (lastUserQueryMessage == null) return
                        handleSendMessage(lastUserQueryMessage.text)
                      }}
                    >
                      Retry
                    </button>
                    <div className={'h-3'} />
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {/* This form will stay at the bottom of the screen */}
        {/* Show only if we have an active conversation */}
        {discontinuedFeature && (
          <div className={'pt-4 pb-12 font-bold text-center'}>
            We've updated Paxton's AI engine and this chat cannot be continued.
            <br />
            Please start a new chat.
          </div>
        )}
        {conversation && !discontinuedFeature && (
          <>
            <div className={'w-full pb-4 pt-2 max-w-3xl m-auto px-5 border-t'}>
              {/* Floating go to bottom button */}

              <div
                onClick={() => {
                  scrollToBottom()
                }}
                className={`w-full flex justify-center items-center cursor-pointer ${stickToBottom ? 'hidden' : 'block'}`}
              >
                <div
                  className={
                    'absolute -mt-16 w-8 h-8 grid place-items-center rounded-full bg-sky-600 bg-opacity-50 hover:bg-opacity-80 transition-all duration-500 ease-in-out text-white text-opacity-90'
                  }
                >
                  <ChevronDownIcon width="24" height="24" />
                </div>
              </div>

              {/* Chat controls */}
              {/* Hide after 1 message for contractanalysis */}
              {[ChatV2Feature.contractanalysis, ChatV2Feature.documentSummarization].includes(feature) && messagesArray.length > 1 && (
                <div className={'text-sm text-center py-2'}>
                  This feature currently supports 1 message per {feature == ChatV2Feature.contractanalysis ? 'analysis' : 'chat'}. Create a new{' '}
                  {feature == ChatV2Feature.contractanalysis ? 'analysis' : 'chat'} to make changes.
                </div>
              )}
              {(![ChatV2Feature.contractanalysis, ChatV2Feature.documentSummarization].includes(feature) || messagesArray.length <= 1) && (
                <div>
                  <div className={'px-1 mb-2 flex flex-col items-start'}>
                    <ChatControls conversation={conversation} />
                  </div>
                  {isAnonymous && (
                    <div className={'flex justify-center w-full text-xs text-center'}>
                      <div className={'flex gap-x-2 items-end bg-sky-800 bg-opacity-80 py-1 px-4 rounded-t-md'}>
                        <div className={'text-white'}>Save your work:</div>
                        <button
                          className={'text-white underline px-1 hover:text-opacity-80'}
                          onClick={() => {
                            dispatch(
                              openAuthDialog({
                                authDialogType: AuthDialogType.SIGN_UP,
                              })
                            )
                          }}
                        >
                          Sign up
                        </button>
                        <button
                          className={'text-white underline hover:text-opacity-80'}
                          onClick={() => {
                            dispatch(
                              openAuthDialog({
                                authDialogType: AuthDialogType.SIGN_IN,
                              })
                            )
                          }}
                        >
                          Sign in
                        </button>
                      </div>
                    </div>
                  )}
                  <form className="mt-auto w-full p-2 grid gap-x-2 items-end grid-cols-[auto_40px] border rounded-lg">
                    <textarea
                      ref={textAreaRef}
                      className="resize-none flex-1 p-2 border-none focus:ring-0 bg-transparent"
                      value={currentTextInput}
                      onChange={(e) => {
                        dispatch(chatV2SetConversationCurrentTextInput({ conversationId: conversation.id, text: e.target.value }))

                        const target = e.target as HTMLTextAreaElement
                        target.style.height = 'auto'
                        target.style.height = `${target.scrollHeight}px`
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault() // Prevent default to avoid line break in textarea
                          handleSendMessage() // Call your form's submit handler
                        }
                      }}
                      placeholder={feature == ChatV2Feature.contractanalysis ? 'Provide context here' : 'Enter a prompt here'}
                      rows={1}
                      style={{ lineHeight: '1.5', maxHeight: '12rem' }}
                    />

                    {isLoading ? (
                      <div className={'w-10 h-10 grid place-items-center rounded-md bg-sky-600 bg-opacity-50'}>
                        <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
                      </div>
                    ) : (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault()
                          handleSendMessage()
                        }}
                        disabled={submitDisabled}
                        className={`w-10 h-10 bg-sky-600 text-white rounded-md grid place-items-center ${submitDisabled ? 'opacity-50' : 'opacity-100'}`}
                      >
                        <ArrowUpIcon width="20" height="20" />
                      </button>
                    )}
                  </form>
                  <div className={'px-1'}>
                    <p className={'text-xs text-gray-500 text-center mt-1 hidden sm:block'}>new line: shift + enter</p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}
