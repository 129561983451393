import { z } from 'zod'

// CitationMetadata Schema
// Replication of the Citator Python CitationMetadata class
const CitationMetadataSchema = z.object({
  name: z.string().describe('The name of the document.'),
  document_type: z.string().describe("The type of document: either 'law' or 'caselaw'."),
  citation: z.string().optional().nullable().describe('The citation string for the document (optional).'),
  bb_citation: z.string().optional().nullable().describe('The Bluebook citation string for the document (optional).'),
  parent_id: z.string().optional().nullable().describe('The ID of the parent document (optional).'),
  jurisdiction: z.string().optional().nullable().describe('The jurisdiction of the document (optional).'),
  text: z.string().optional().nullable().describe('The text content of the document (optional).'),
})
export type CitationMetadata = z.infer<typeof CitationMetadataSchema>

// CitatorCasesCitedResponse Schema
// For cases_cited, citing_cases
export const CitatorCasesResponseSchema = z.array(CitationMetadataSchema)
export type CitatorCasesResponse = z.infer<typeof CitatorCasesResponseSchema>
