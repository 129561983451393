import { confidenceLabelToDotStyle } from '../services/confidence-indicator-to-dot-style'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'

export type ConfidenceIndicatorDotProps = {
  confidence_label: string | null
  loading: boolean
  sizePixels?: number
  isHovered: boolean
}

export default function ConfidenceIndicatorDot(props: ConfidenceIndicatorDotProps) {
  const { confidence_label, loading, sizePixels = 12, isHovered } = props

  // Dot Style
  // If loading: animate between dark and light with the state interval
  // If not loading: use the confidence label to determine the style
  const dotStyle = confidenceLabelToDotStyle(confidence_label, sizePixels, isHovered)

  return loading ? <CircularProgressContinuousSized size={sizePixels} thickness={7} /> : <div style={dotStyle}></div>
}
