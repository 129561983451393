import { signInWithEmailAndPassword, getAuth, signOut } from 'firebase/auth'
import firebase_app from '../firebase-config'

/**
 * Checks that the email is from our Paxton domain
 * @param email - The email to check
 * @returns boolean - True if the email is from our Paxton domain
 */
function isValidPaxtonDomain(email: string) {
  return email.endsWith('@paxton.ai')
}

/**
 * Sign In Email for verified Paxton only
 * No try catch, allow the calling UX function to handle errors
 * @param email
 * @param password
 * @returns
 */
export default async function signInEmailPaxton(email: string, password: string) {
  const auth = getAuth(firebase_app)

  if (!isValidPaxtonDomain(email)) {
    throw new Error('Invalid domain')
  }

  // Sign in with email and password
  const result = await signInWithEmailAndPassword(auth, email, password)

  // Check if email is verified
  if (!result.user?.emailVerified) {
    console.warn('Email not verified')
    signOut(auth)
    throw new Error('Email not verified')
  }
}
