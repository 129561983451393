import { kGcsOrgHiddenFolder, kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { kOrganizationLogoFileName } from '@/constants/constants-ui'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import { deleteObject, getBlob, getStorage, ref } from 'firebase/storage'
import { getLogoFromBrand } from '@/util/enterprise'

/**
 * Fetch Org Logo As URL
 * Fetches the organization logo
 * - IF EXISTS: Converts the file to a data URL
 * - ELSE: Returns a default paxton logo URL
 * @param orgId the legacy compatible organization id (where we look in GCS for the file)
 * @returns {URL}
 */
export async function fetchOrgLogoAsUrl(orgId: string | null, brand: BrandEnum = BrandEnum.NONE): Promise<string> {
  const defaultLogoPath = getLogoFromBrand(brand)

  if (!orgId) {
    defaultLogoPath
  }

  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const logoStoragePath = `tenants/${orgId}/${kGcsOrgHiddenFolder}/${kOrganizationLogoFileName}`
  const logoStorageRef = ref(storage, logoStoragePath)

  // Fetch the file bytes
  try {
    const blob = await getBlob(logoStorageRef)
    const logoDataUrl = URL.createObjectURL(blob)
    return logoDataUrl
  } catch (e) {
    // The org logo is not loaded, return the default logo
    return defaultLogoPath
  }
}

/**
 * Delete Org Logo
 * Deletes the organization logo file from GCS
 * @param orgId
 * @returns
 */
export async function deleteOrgLogo(orgId: string | null): Promise<void> {
  if (!orgId) {
    return
  }

  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const logoStoragePath = `tenants/${orgId}/${kGcsOrgHiddenFolder}/${kOrganizationLogoFileName}`
  const logoStorageRef = ref(storage, logoStoragePath)

  // Delete the file
  await deleteObject(logoStorageRef)

  return
}
