import { kOrgUserOAuthBrandOnboardPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'

export default async function onboardBrandOAuth() {
  console.log(`onboarding SSO user`)

  // Create transaction id
  const sentry_transaction_id = nanoid()

  // Construct body params
  const body = {
  }

  try {
    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw Error(kErrorCodeAuthError)

    // Construct request
    const apiUrl = kPaxtonAppApiBaseUrl() + kOrgUserOAuthBrandOnboardPath
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    }

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // Handle Errors and return early
    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`), {
          extra: { requestUrl: apiUrl, requestOptions: requestOptions, response: data, onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
        })
      })

      return false
    }

    return true
  } catch (e) {
    console.error(e)

    // Capture to sentry
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    return false
  }
}
