import { kPaymentsSetupIntentPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'

export type CreateStripeSetupIntentResponse = {
  client_secret?: string
  setup_intent_id?: string
  error?: boolean
  errorDetail?: string
}

export default async function createStripeSetupIntent(): Promise<CreateStripeSetupIntentResponse> {
  // Create transaction id
  const sentry_transaction_id = nanoid()

  console.log('POST Stripe Subscription Request')
  const token = await getAuth().currentUser?.getIdToken(true)
  if (!token) throw Error(kErrorCodeAuthError)
  // Construct request
  const requestOptions: RequestInit = {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-Transaction-Id': sentry_transaction_id,
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  }

  const apiUrl = kPaxtonAppApiBaseUrl() + `/${kPaymentsSetupIntentPath}`
  const response = await fetch(apiUrl, requestOptions)
  const data = await response.json()
  if (data.success == false || data.error != null || data.error != undefined) {
    throw new Error(`Fetch error - status: ${kPaymentsSetupIntentPath}, detail: ${data.error}`)
  }
  return data
}
