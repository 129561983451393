import { z } from 'zod'

export type DocumentEditingWsQueryPayloadType = {
  user_query: string
  file_path: string
}

// Base Websocket Event Payload
export const DocumentEditingWsEventBasePayloadSchema = z.object({
  event: z.string(),
  data: z.any(),
})
export type DocumentEditingWsEventBasePayloadType = z.infer<typeof DocumentEditingWsEventBasePayloadSchema>

// header Event Data
export const DocumentEditingWsEventHeaderDataSchema = z.object({
  id: z.string(),
  title: z.string(),
  user_query: z.string(),
  file_path: z.string(),
  version: z.string().nullable(),
  created_at: z.string(),
})
export type DocumentEditingWsEventHeaderDataType = z.infer<typeof DocumentEditingWsEventHeaderDataSchema>

// summary_chunk event data
export const DocumentEditingWsEventSummaryChunkDataSchema = z.object({
  analysis_id: z.string(),
  value: z.string(),
})
export type DocumentEditingWsEventSummaryChunkDataType = z.infer<typeof DocumentEditingWsEventSummaryChunkDataSchema>

// annotations event data
// Annotation Schema
export const DocumentEditingAnnotationSchema = z.object({
  annotation_id: z.string().nullable().optional(),
  comment_id: z.string().nullable().optional(),
  source_text: z.string(),
  issue: z.string(),
  replacement_text: z.string(),
})
export type DocumentEditingAnnotationType = z.infer<typeof DocumentEditingAnnotationSchema>

// Annotations Event Data
export const DocumentEditingWsEventAnnotationsDataSchema = z.object({
  analysis_id: z.string(),
  annotations: z.array(DocumentEditingAnnotationSchema),
})
export type DocumentEditingWsEventAnnotationsDataType = z.infer<typeof DocumentEditingWsEventAnnotationsDataSchema>
