import { ConfidenceIndicatorLanguage } from '../models/confidence-indicator-language'
import { confidenceIndicatorLanguageMap } from '../static-data/confidence-indicator-language-map'

/**
 * Convert confidence label to language
 * Defaults to unknown
 * @param confidence_label
 * @returns
 */
export function confidenceLabelToLanguage(confidence_label: string | null): ConfidenceIndicatorLanguage {
  if (!confidence_label) {
    return confidenceIndicatorLanguageMap.unknown
  }

  return confidenceIndicatorLanguageMap[confidence_label] ?? confidenceIndicatorLanguageMap.unknown
}
