import { useContext, useEffect } from 'react'
import { AuthContext } from '../../../context/auth-context'
import azureAuthRedirect from '../../../firebase/auth/azure-auth-redirect'

type MicrosoftSSOFormProps = {
  onSuccess: () => void
}

export default function MicrosoftSSOForm(props: MicrosoftSSOFormProps) {
  const { onSuccess } = props

  const authContext = useContext(AuthContext)
  const user = authContext?.user

  // Redirect if the user is not null
  useEffect(() => {
    if (user != null && !user.isAnonymous) {
      onSuccess()
    }
  }, [onSuccess, user])

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="mt-2 grid grid-cols-1 gap-4 w-fit">
          <button
            onClick={() => azureAuthRedirect()}
            className="flex w-full items-center justify-center gap-3 rounded-md bg-[#ffffff] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F] border border-slate-200 hover:shadow transition duration-150"
          >
            <img className="w-6 h-6" src="https://www.svgrepo.com/show/349454/microsoft.svg" loading="lazy" alt="microsoft logo" />
            <span className="font-semibold leading-6 text-black">Sign In</span>
          </button>
        </div>
      </div>
    </>
  )
}
