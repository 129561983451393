import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import { ChatV2QueryMetadataLrrV2, ChatV2QueryMetadataLrrV2Schema } from '../schemas/chat-query-metadata-schema'


// Set the source_name for a conversation's currentSource
export const reducerCurrentSourceLRRAddSource = (state: ChatV2State, action: PayloadAction<{ conversationId: string; source_name: string, path: string | undefined }>) => {
  const initialCurrentSourceState: ChatV2QueryMetadataLrrV2 = {
    lrr_selections: [],
  }
  
  const { conversationId, source_name, path } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]
  const paths = path ? [path] : []

  // If the source is already in the currentSource, return
  if(conversation.currentSource && isSourceAlreadyInSelection(source_name, conversation.currentSource?.lrr_selections)) return 

  // Validate currentSource against schema, if invalid re-establish the currentSource
  const validation = ChatV2QueryMetadataLrrV2Schema.safeParse(conversation.currentSource)
  if (!validation.success) {
    conversation.currentSource = initialCurrentSourceState

    conversation.currentSource.lrr_selections.push({
      paths: paths,
      source_name: source_name,
    })
  } else {
    // Get the existing currentSource and updated it with the new source_name
    const currentSource = conversation.currentSource as ChatV2QueryMetadataLrrV2
    currentSource.lrr_selections.push({
      paths: paths,
      source_name: source_name,
    })  
    conversation.currentSource = currentSource
  }
}

// Remove the source_name for a conversation's currentSource
export const reducerCurrentSourceLRRRemoveSource = (state: ChatV2State, action: PayloadAction<{ conversationId: string; source_name: string }>) => {
  const { conversationId, source_name } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]
  try {
    // Validate currentSource against schema
    const validation = ChatV2QueryMetadataLrrV2Schema.safeParse(conversation.currentSource)
    if (!validation.success) {
      throw new Error('Cannot remove source from currentSource, invalid schema', validation.error)
    }
    // Get the existing currentSource
    const currentSource = conversation.currentSource as ChatV2QueryMetadataLrrV2
    // Remove the source object from the array
    currentSource.lrr_selections = currentSource.lrr_selections.filter((entry) => entry.source_name !== source_name)
  } catch (error) {
    console.error('An error occurred while removing the source from currentSource:', error)
    conversation.currentSource = { lrr_selections: [] } as ChatV2QueryMetadataLrrV2
    return
  }
}

export const reducerCurrentSourceLrrPathsAdd = (state: ChatV2State, action: PayloadAction<{ conversationId: string; source_name: string; path: string }>) => {
  const { conversationId, source_name, path } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataLrrV2Schema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error('Cannot add path to currentSource, invalid schema', validation.error)

  // Get the existing currentSource
  const currentSource = conversation.currentSource as ChatV2QueryMetadataLrrV2

  // Find the source object in the array and add the path
  const sourceEntry = currentSource.lrr_selections.find((entry) => entry.source_name === source_name)
  if (!sourceEntry) throw new Error(`Cannot find source ${source_name} in currentSource`)

  // Add the path to the source entry
  sourceEntry.paths.push(path)

  // Update the source object in the array
  const updatedSourceEntries = currentSource.lrr_selections.map((entry) => {
    if (entry.source_name === source_name) return sourceEntry
    return entry
  })

  // Update the currentSource
  currentSource.lrr_selections = updatedSourceEntries
}

export const reducerCurrentSourceLrrPathsRemove = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; source_name: string; path: string }>
) => {
  const { conversationId, source_name, path } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataLrrV2Schema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error('Cannot remove path to currentSource, invalid schema', validation.error)

  // Get the existing currentSource
  const currentSource = conversation.currentSource as ChatV2QueryMetadataLrrV2

  // Find the source object in the array and add the path
  const sourceEntry = currentSource.lrr_selections.find((entry) => entry.source_name === source_name)
  if (!sourceEntry) throw new Error(`Cannot find source ${source_name} in currentSource`)

  // Remove the path from the source entry
  sourceEntry.paths = sourceEntry.paths.filter((p) => p !== path)

  // Update the source object in the array
  const updatedSourceEntries = currentSource.lrr_selections.map((entry) => {
    if (entry.source_name === source_name) return sourceEntry
    return entry
  })

  // Update the currentSource
  currentSource.lrr_selections = updatedSourceEntries
}

export const reducerCurrentSourceLrrRemoveAll = (state: ChatV2State, action: PayloadAction<{ conversationId: string }>) => {
  const { conversationId } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Reset to initial state
  conversation.currentSource = {
    lrr_selections: [],
  }
}

const isSourceAlreadyInSelection = (
  sourceValue: string,
  currentSourceSelections: {
    paths: string[]
    source_name: string
  }[]
): boolean => {
  return currentSourceSelections.find((entry) => entry.source_name === sourceValue) !== undefined
}
