import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { getBrandName } from '@/util/enterprise'

const brandName = getBrandName()

export default function AuthIncentive() {
  return (
    <>
      <div className="max-w-xl p-6 text-gray-700">
        <h2 className="text-3xl mb-2">Welcome to {brandName}!</h2>
        <p className="mb-2">Transform your legal practice with our specialized AI Legal Assistant, a trusted resource for thousands of attorneys.</p>
        <ul className="list-none">
          <li className={'items-center mb-2 whitespace-nowrap'}>
            <CheckCircleIcon className={'h-6 mr-2 inline-block align-middle text-white bg-green-500 rounded-full'} />
            <p className={'inline-block text-sm align-middle leading-5 whitespace-normal'}>
              Tailored Legal Insights: Quick, AI-powered legal analysis for informed decision-making.
            </p>
          </li>
          <li className={'items-center mb-2 whitespace-nowrap'}>
            <CheckCircleIcon className={'h-6 mr-2 inline-block align-middle text-white bg-green-500 rounded-full'} />
            <p className={'inline-block text-sm align-middle leading-5 whitespace-normal'}>AI-Powered Drafting: Efficient, accurate document creation tool.</p>
          </li>
          <li className={'items-center mb-2 whitespace-nowrap'}>
            <CheckCircleIcon className={'h-6 mr-2 inline-block align-middle text-white bg-green-500 rounded-full'} />
            <p className={'inline-block text-sm align-middle leading-5 whitespace-normal'}>
              Scalable Solutions: Adaptable, value-driven platform for any practice size.
            </p>
          </li>
        </ul>
        <div className="h-5" />
        <h3 className="font-bold mb-2">Featured In</h3>
        <div className="mt-4 flex flex-wrap gap-x-4 gap-y-2 items-center">
          <img src="/images/logos/reuters-logo-svg.svg" alt="Logo 1" className="h-8 pr-2" />
          <img src="/images/logos/fortune-logo-svg.svg" alt="Logo 2" className="h-9 p-2" />
          <img src="/images/logos/forbes-logo-svg.svg" alt="Logo 2" className="h-11" />
          <img src="/images/logos/axios-logo-svg.svg" alt="Logo 2" className="h-5" />
          <div className={'basis-full h-0'}></div>
          <img src="/images/logos/wsj-logo-svg.svg" alt="Logo 2" className="h-5" />
        </div>
      </div>
    </>
  )
}
