import { ChatV2Message } from '@/chat-common/store/chat-v2.slice'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useState } from 'react'

type ChatSuggestedQuestionsBubbleProps = {
  message: ChatV2Message
  onSuggestionClick: (suggestion: string) => void
  disabled: boolean
  conversationIsLoading: boolean
}

export default function ChatSuggestedQuestionsBubble(props: ChatSuggestedQuestionsBubbleProps) {
  const { message, onSuggestionClick, disabled, conversationIsLoading } = props

  // Local state
  const [clickedSuggestion, setClickedSuggestion] = useState<boolean>(false)

  const loading = message.metadata.suggested_questions_loading
  const isError = message.metadata.is_error
  let suggestions = message.metadata.suggested_questions?.suggestions ?? []

  // Trim to a max of 3 suggestions
  suggestions = suggestions.slice(0, 3)

  return (
    <div className={`pr-2 py-0 mt-0 pb-5 whitespace-pre-wrap `}>
      <div className={'border-l border-gray-300 ml-5 pl-5 py-1'}>
        <div className={`font-bold mb-1 text-xs text-gray-800`}>Follow up questions</div>
        {loading && (
          <div className={'flex items-center gap-x-2'}>
            <CircularProgressContinuousSized size={14} thickness={7} hexColor={'#0285c7'} />{' '}
            <p className={'inline text-xs'}>Generating follow up questions...</p>
          </div>
        )}

        {isError && <div className={'text-red-700 text-xs pb-1'}>Error generating follow up questions.</div>}

        {clickedSuggestion && disabled && (
          <div className={'text-red-700 text-xs pb-1'}>Please correct any form errors before selecting a follow up question.</div>
        )}

        {clickedSuggestion && conversationIsLoading && (
          <div className={'text-red-700 text-xs pb-1'}>Please wait for the current message to complete loading.</div>
        )}

        <ol className={'list-disc pl-4'}>
          {suggestions.length > 0 &&
            suggestions.map((suggestion) => {
              return (
                <li
                  key={`${message.metadata.message_id}_${suggestion.suggestion_number}`}
                  className={`mb-1 last-of-type:mb-0 leading-none ${conversationIsLoading ? '' : ''}`}
                >
                  <button
                    onClick={() => {
                      setClickedSuggestion(true)

                      if (conversationIsLoading) return
                      if (!disabled) {
                        onSuggestionClick(suggestion.suggestion)
                      }
                    }}
                    className={`text-xs text-left underline hover:text-sky-600 ${
                      conversationIsLoading ? 'text-gray-400 hover:text-gray-400 no-underline cursor-default' : 'cursor-pointer text-sky-700'
                    }`}
                  >
                    {suggestion.suggestion}
                  </button>
                </li>
              )
            })}
        </ol>
      </div>
    </div>
  )
}
