export type NewChatIntroExample = {
  title: string
  description: string
}

export const kChatV2DraftingChatExamples: NewChatIntroExample[] = [
  {
    title: 'Draft Interrogatories in a Business Dispute',
    description:
      'Generate interrogatories for a business dispute by identifying key issues and formulating precise questions. Focus on contract breaches, financial transactions, and internal communications. Ensure each interrogatory is clear, concise, and designed to elicit specific, actionable responses.',
  },
  {
    title: 'Drafting a Subscription Agreement for a Private Equity Fund',
    description:
      'Create a subscription agreement for a private equity fund, ensuring compliance with the Securities Act of 1933 and addressing the terms of investment, transfer restrictions, and representations and warranties by the investor.',
  },
  {
    title: 'Drafting an Internal Email Regarding Case Strategy',
    description:
      'Compose an email to a colleague in your law firm outlining the proposed strategy for defending a client against a potential class action lawsuit related to consumer protection claims, and request feedback on the legal arguments and defenses.',
  },
  {
    title: 'Drafting a Marketing Blog Post on Regulatory Changes in Healthcare Law',
    description:
      'Write a blog post discussing recent regulatory changes in healthcare law, focusing on the impact of new legislation on healthcare providers, compliance requirements, and potential implications for industry stakeholders.',
  },
]

export const kChatV2LRRExamples: NewChatIntroExample[] = [
  {
    title: 'Federal Authority',
    description: 'Conduct research on federal statutory and regulatory authority.',
  },
  {
    title: 'Laws and Regulations Across All 50 States',
    description: 'What is the statute of limitation for a breach of contract claim in Kansas?',
  },
  {
    title: 'Code of Federal Regulations',
    description: 'What are the relevant regulatory rules I should be aware of for my fintech startup?',
  },
  {
    title: 'Civil Procedure',
    description: 'What is the deadline to respond to a discovery request in Texas?',
  },
]

export const kChatV2CaseLawExamples: NewChatIntroExample[] = [
  {
    title: 'Case Law Research',
    description: 'Find and analyze relevant case law pertaining to specific legal issues or precedents in a current case.',
  },
  {
    title: 'Case Law Summary',
    description: 'Provide summaries of significant case laws, highlighting key legal principles and judgments applicable to a case.',
  },
  {
    title: 'Comparative Case Analysis',
    description: 'Compare and contrast similar case laws to determine the applicability and potential impact on a current legal matter.',
  },
  {
    title: 'Case Law Application',
    description: 'Assess how established case laws can be applied to the facts and circumstances of a specific case, to form legal arguments or defenses.',
  },
]

export const kChatV2ResearchExamples: NewChatIntroExample[] = [
  {
    title: 'State Research',
    description: "Find statutes and cases relevant to the issue of liability for a landlord if a tenant's guest was injured in NY.",
  },
  {
    title: 'Comparative Analysis for Federal Sources',
    description: 'Do the First Circuit and Ninth Circuit differ on whether websites are places of public accommodation under the ADA?',
  },
  {
    title: 'State and Federal Research',
    description: 'How have Delaware state and related federal courts interpreted the 2024 Moelis decision?',
  },
  {
    title: 'Statutory Interpretation',
    description: "How have courts interpreted the Federal Trade Commission's (FTC) definition of 'deceptive practices'?",
  },
]

export const kChatV2DocumentQueryExamples: NewChatIntroExample[] = [
  {
    title: 'Q&A with Case Files',
    description: 'Review the documents to find evidence that the defendant Mr. Doe knew about the dangerous condition before plaintiff Ms. Smith was injured.',
  },
  {
    title: 'Find a Needle in the Haystack',
    description: 'Does a new regulatory proposal contain any language that would affect my business?',
  },
  {
    title: 'Evidence Sorting and Analysis',
    description: 'Sorting and analysis of evidence to uncover key patterns and inconsistencies.',
  },
  {
    title: 'Regulatory Compliance Check',
    description: "What do my company's policies say about hiring an out of state contractor?",
  },
]

export const kChatV2WebSearchExamples: NewChatIntroExample[] = [
  {
    title: 'Legal Updates',
    description: 'Search for recent rulings and regulatory developments.',
  },
  {
    title: 'Litigation Trend Analysis',
    description: 'Analyze current trends in litigation within a specific area of law or industry.',
  },
  {
    title: 'Regulatory Change Alerts',
    description: 'Stay updated on new regulations or changes in legal standards affecting client interests.',
  },
  {
    title: 'Competitor Legal Activity Monitoring',
    description: 'Monitor legal actions and filings involving industry competitors or key players.',
  },
]

export const kChatV2FeatureOverviewExamples: NewChatIntroExample[] = [
  {
    title: 'Laws & Regulations',
    description: 'Explore and interpret laws and regulations to create insights for case strategy and compliance.',
  },
  {
    title: 'Case Law',
    description: 'Create summaries and analytical insights to inform legal arguments and strategies.',
  },
  {
    title: 'File Analysis',
    description: 'Upload your documents to summarize data, make comparisons, check compliance, and analyze key insights.',
  },
  {
    title: 'Web & News',
    description: 'Analyze current trends and monitor legal actions and filings involving industry competitors or key players.',
  },
]

export const kChatV2ContractAnalysisExamples: NewChatIntroExample[] = [
  {
    title: 'Risk Assessment',
    description: 'Analyze contracts for potential liabilities and problematic clauses.',
  },
  {
    title: 'Clause Identification',
    description: 'Quickly locate specific clauses within complex contracts.',
  },
  {
    title: 'Negotiation Preparation',
    description: 'Identify areas of a contract where better terms might be negotiated.',
  },
  {
    title: 'Compliance Check',
    description: 'Ensure contracts adhere to relevant regulations and industry standards.',
  },
]

export const kChatV2ContractSearchExampleTitle = 'Search hundreds of thousands of example contracts with Paxton'

export const kChatV2ContractSearchExamples: NewChatIntroExample[] = [
  {
    title: 'Employment Contract Template',
    description: 'Find a standard employment contract template for a software development position that includes non-compete and confidentiality clauses.',
  },
  {
    title: 'NDA for a New Project',
    description: 'I need a non-disclosure agreement suitable for discussions with potential partners about an upcoming tech product launch.',
  },
  {
    title: 'Website Terms of Use Template',
    description:
      'Find a comprehensive Website Terms of Use template that can be customized for an online retail store, focusing on user conduct, copyright information, and dispute resolution mechanisms.',
  },
  {
    title: 'Employee Separation Agreement',
    description: 'Locate a separation agreement template that includes terms for severance pay and the return of company property.',
  },
]

export const kChatV2DocumentSummarizationExamples: NewChatIntroExample[] = [
  {
    title: 'Case Summary',
    description: 'Identify case name, parties involved, court, decision date, and summarize the key legal issue and ruling.',
  },
  {
    title: 'Legal Argument Overview',
    description: "Outline the document's main legal arguments, the statutes or cases cited, and the conclusion reached.",
  },
  {
    title: 'Research Synopsis',
    description: 'Outline crucial findings and applicable laws.',
  },
  {
    title: 'Contract Overview',
    description: 'List parties, terms, and main obligations.',
  },
]

// TODO: Replace these with new examples for Assistant (currently just a copy of drafting)
export const kChatV2AssistantExamples: NewChatIntroExample[] = [
  {
    title: 'Draft Interrogatories in a Business Dispute',
    description:
      'Generate interrogatories for a business dispute by identifying key issues and formulating precise questions. Focus on contract breaches, financial transactions, and internal communications. Ensure each interrogatory is clear, concise, and designed to elicit specific, actionable responses.',
  },
  {
    title: 'Drafting a Subscription Agreement for a Private Equity Fund',
    description:
      'Create a subscription agreement for a private equity fund, ensuring compliance with the Securities Act of 1933 and addressing the terms of investment, transfer restrictions, and representations and warranties by the investor.',
  },
  {
    title: 'Drafting an Internal Email Regarding Case Strategy',
    description:
      'Compose an email to a colleague in your law firm outlining the proposed strategy for defending a client against a potential class action lawsuit related to consumer protection claims, and request feedback on the legal arguments and defenses.',
  },
  {
    title: 'Drafting a Marketing Blog Post on Regulatory Changes in Healthcare Law',
    description:
      'Write a blog post discussing recent regulatory changes in healthcare law, focusing on the impact of new legislation on healthcare providers, compliance requirements, and potential implications for industry stakeholders.',
  },
]
