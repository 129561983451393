import { WebsocketQueryStream } from './websocket-query-stream'

export enum WebsocketQueryStreamType {
  CHATV2 = 'CHATV2',
  DOCUMENT_EDITING = 'DOCUMENT_EDITING',
}

/**
 * Websocket Query Stream Constructor Arguments
 * Arguments to pass to the Websocket Query Stream constructor
 */
export type WebsocketQueryStreamConstructorArgs = {
  streamType: WebsocketQueryStreamType
  initialQueryPayload: any
  onMessageHandler: (instance: WebsocketQueryStream, data: any) => void
  onError: (instance: WebsocketQueryStream, event: Event) => void
  onCloseAll: (instance: WebsocketQueryStream) => void
  onCloseSuccess: (instance: WebsocketQueryStream) => void
  onCloseUnexpected: (instance: WebsocketQueryStream, code: number, reason: string) => void
}
