/**
 * Capitalizes the first letter of each word in a given string.
 *
 * @param str the string to be capitalized.
 * @returns {string} a string with the first letter of each word capitalized.
 */
export const capitalizeFirstLetters = (str: string | null | undefined): string => {
  if (!str) return ''
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
