import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'

// Define a type for the slice state
interface AiQueryResultsState {
  value: string
}

// Define the initial state using that type
const initialState: AiQueryResultsState = {
  value: '',
}

export const aiQueryResultsSlice = createSlice({
  name: 'aiQueryResults',
  initialState,
  reducers: {
    addAiQueryResult: (state, action: PayloadAction<string>) => {
      console.log('Temporarily just sets the value. TODO: Build the list.')
      state.value = action.payload
    },
  },
})

export const { addAiQueryResult } = aiQueryResultsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectAiQueryResults = (state: RootState) => state.aiQueryResults.value

export default aiQueryResultsSlice.reducer
