import * as Popover from '@radix-ui/react-popover'
import { Cross2Icon, ExternalLinkIcon } from '@radix-ui/react-icons'
import ConfidenceIndicatorDot from './ConfidenceIndicatorDot'
import { confidenceLabelToLanguage } from '../services/confidence-label-to-language'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { getHelpCenterUrl, getBrandName } from '@/util/enterprise'

type ConfidenceIndicatorPopoverProps = {
  confidence_label: string | null
  loading: boolean
}

export default function ConfidenceIndicatorPopover(props: ConfidenceIndicatorPopoverProps) {
  const { confidence_label, loading } = props
  const [isHovered, setIsHovered] = useState(false)

  const confidenceLanguage = confidenceLabelToLanguage(confidence_label)
  const label = loading ? 'Assessing confidence...' : confidenceLanguage.componentLabel
  const tips = confidenceLanguage.popupTips

  // Build Help Center URL
  const helpCenterUrl = getHelpCenterUrl('tools/confidence-indicator')
  const brandName = getBrandName(true)

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className={`group flex flex-grow gap-x-1 items-center text-sm text-gray-500 hover:bg-gray-100 rounded-md`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <ConfidenceIndicatorDot confidence_label={confidence_label} loading={loading} isHovered={isHovered} />
          <div>{label}</div>
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="rounded max-w-xs xs:max-w-screen-xs sm:max-w-screen-sm bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.50),0_10px_20px_-15px_hsla(206,22%,7%,.2)] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >
          <div className={'text-sm '}>
            <div className={'flex gap-x-2 items-center mb-3 py-3 px-5 border-b'}>
              <ConfidenceIndicatorDot confidence_label={confidence_label} loading={loading} isHovered={false} sizePixels={12} />
              <div className={'font-bold'}>{label} Confidence Answer</div>
            </div>
            <div className={'px-5 pb-2'}>
              <div className={'mb-2'}>{confidenceLanguage.popupDescription}</div>
              {tips.length > 0 && (
                <>
                  <div className={'mb-1 font-bold'}>Tips:</div>
                  <ul className={'list-disc pl-5 pb-1'}>
                    {confidenceLanguage.popupTips.map((tip, index) => (
                      <li key={`conf-tip-${index}`} className={'mb-1'}>
                        {tip}
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <Link to={helpCenterUrl} target="_blank" className={'flex items-end hover:text-sky-700 active:bg-sky-100 pt-1 pb-2'}>
                <span className={'inline text-sm font-bold text-sky-700 hover:text-sky-600 underline leading-tight'}>
                  <QuestionMarkCircleIcon className={'inline w-5 h-5 mr-1 pb-[0px]'} />
                  {brandName} Help Center
                  <ExternalLinkIcon className={'inline w-4 h-4 pl-[4px] pb-[0px]'} />
                </span>
              </Link>
            </div>
          </div>
          <Popover.Close
            className="rounded-full h-[25px] w-[25px] inline-flex items-center justify-center text-gray-500 absolute top-[5px] right-[5px] hover:bg-gray-100 outline-none cursor-pointer"
            aria-label="Close"
          >
            <Cross2Icon />
          </Popover.Close>
          <Popover.Arrow className="fill-white" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
