import { z } from 'zod'
import { DocumentEditingAnnotationSchema } from './document-editing-websocket.schema'

export enum DocumentEditingVersionEnum {
  contract_analysis_v1 = 'contract_analysis_v1',
  contract_analysis_v2 = 'contract_analysis_v2',
}

// Single Analyses List Item
const DocumentEditingAnalysesListItemSchema = z.object({
  id: z.string(),
  title: z.string(),
  created_at: z.string(),
})
export type DocumentEditingAnalysesListItem = z.infer<typeof DocumentEditingAnalysesListItemSchema>

// Document Editing Get All Response Object
export const GetAnalysesListResponseSchema = z.object({
  analyses: z.array(DocumentEditingAnalysesListItemSchema),
})
export type GetAnalysesListResponse = z.infer<typeof GetAnalysesListResponseSchema>

// Document Editing Get Single Response Object
export const GetAnalysisResponseSchema = z.object({
  title: z.string(),
  created_at: z.string(),
  file_path: z.string(),
  user_query: z.string(),
  summary: z.string(),
  version: z.string().nullable(),
  annotations: z.array(DocumentEditingAnnotationSchema),
})
export type GetAnalysisResponse = z.infer<typeof GetAnalysisResponseSchema>
