type SourceMap = {
  [key: string]: string[]
}

const STATE_TO_STATE_COURT_MAP: SourceMap = {
  alabama: ['Ala.', 'Ala. Civ. App.', 'Ala. Crim. App.'],
  alaska: ['Alaska', 'Alaska Ct. App.'],
  arizona: ['Ariz.', 'Ariz. Ct. App.'],
  arkansas: ['Ark.', 'Ark. Ct. App.'],
  california: ['Cal.', 'Cal. Ct. App.', 'Cal. Super.'],
  colorado: ['Colo.', 'Colo. App.'],
  connecticut: ['Conn.', 'Conn. App. Ct.'],
  delaware: ['Del.', 'Del. Ch.', 'Del. Super. Ct.'],
  'district of columbia': ['D.C.'],
  florida: ['Fla.', 'Fla. Dist. Ct. App.'],
  georgia: ['Ga.', 'Ga. Ct. App.'],
  guam: ['Guam'],
  hawaii: ['Haw.', 'Haw. Ct. App.'],
  idaho: ['Idaho', 'Idaho Ct. App.'],
  illinois: ['Ill.', 'Ill. App. Ct.'],
  indiana: ['Ind.', 'Ind. App.', 'Ind. Ct. App.'],
  iowa: ['Iowa', 'Iowa Ct. App.'],
  kansas: ['Kan.', 'Kan. Ct. App.'],
  kentucky: ['Ky.', 'Ky. Ct. App.'],
  louisiana: ['La.', 'La. Ct. App.'],
  maine: ['Me.'],
  maryland: ['Md.', 'Md. Ct. Spec. App.'],
  massachusetts: ['Mass.', 'Mass. App. Ct.'],
  michigan: ['Mich.', 'Mich. Ct. App.'],
  minnesota: ['Minn.', 'Minn. Ct. App.'],
  mississippi: ['Miss.', 'Miss. Ct. App.'],
  missouri: ['Mo.', 'Mo. Ct. App.'],
  montana: ['Mont.'],
  nebraska: ['Neb.', 'Neb. Ct. App.'],
  nevada: ['Nev.'],
  'new hampshire': ['N.H.'],
  'new jersey': ['N.J.', 'N.J. Super.', 'N.J. Super. Law Div.', 'N.J. Ch.', 'N.J. Super. Ch. Div.'],
  'new mexico': ['N.M.', 'N.M. Ct. App.'],
  'new york': ['N.Y.', 'N.Y. App. Div.', 'N.Y. App. Term'],
  'north carolina': ['N.C.', 'N.C. Ct. App.'],
  'north dakota': ['N.D.', 'N.D. Ct. App.'],
  'northern mariana islands': ['N. Mar. I.'],
  ohio: ['Ohio', 'Ohio Ct. App.'],
  oklahoma: ['Okla.', 'Okla. Civ. App.', 'Okla. Crim. App.'],
  oregon: ['Or.', 'Or. Ct. App.'],
  pennsylvania: ['Pa.', 'Pa. Super. Ct.', 'Pa. Cmmw. Ct.'],
  'puerto rico': [],
  'rhode island': ['R.I.', 'R.I. Super.'],
  'south carolina': ['S.C.', 'S.C. Ct. App.'],
  'south dakota': ['S.D.'],
  tennessee: ['Tenn.', 'Tenn. Ct. App.', 'Tenn. Crim. App.'],
  texas: ['Tex.', 'Tex. App.', 'Tex. Crim. App.', 'Tex. Civ. App.'],
  utah: ['Utah', 'Utah Ct. App.'],
  vermont: ['Vt.'],
  virginia: ['Va.', 'Va. Ct. App.'],
  'virgin islands': [],
  washington: ['Wash.', 'Wash. Ct. App.'],
  'west virginia': ['W. Va.'],
  wisconsin: ['Wis.', 'Wis. Ct. App.'],
  wyoming: ['Wyo.'],
}

const STATE_TO_FEDERAL_COURT_MAP: SourceMap = {
  alabama: ['11th Cir.', 'M.D. Ala.', 'N.D. Ala.', 'S.D. Ala.', 'Bankr. M.D. Ala.', 'Bankr. N.D. Ala.', 'Bankr. S.D. Ala.'],
  alaska: ['9th Cir.', 'D. Alaska', 'Bankr. D. Alaska', 'B.A.P. 9th Cir.'],
  arizona: ['9th Cir.', 'D. Ariz.', 'Bankr. D. Ariz.', 'B.A.P. 9th Cir.'],
  arkansas: ['8th Cir.', 'E.D. Ark.', 'W.D. Ark.', 'Bankr. E.D. Ark.', 'Bankr. W.D. Ark.', 'B.A.P. 8th Cir.'],
  california: [
    '9th Cir.',
    'C.D. Cal.',
    'E.D. Cal.',
    'N.D. Cal.',
    'S.D. Cal.',
    'Bankr. C.D. Cal.',
    'Bankr. E.D. Cal.',
    'Bankr. N.D. Cal.',
    'Bankr. S.D. Cal.',
    'B.A.P. 9th Cir.',
  ],
  colorado: ['10th Cir.', 'D. Colo.', 'Bankr. D. Colo.', 'B.A.P. 10th Cir.'],
  connecticut: ['2d Cir.', 'D. Conn.', 'Bankr. D. Conn.', 'B.A.P. 2d Cir.'],
  delaware: ['3d Cir.', 'D. Del.', 'Bankr. D. Del.'],
  'district of columbia': ['D.D.C.', 'Bankr. D.D.C.'],
  florida: ['11th Cir.', 'M.D. Fla.', 'N.D. Fla.', 'S.D. Fla.', 'Bankr. M.D. Fla.', 'Bankr. N.D. Fla.', 'Bankr. S.D. Fla.'],
  georgia: ['11th Cir.', 'M.D. Ga.', 'N.D. Ga.', 'S.D. Ga.', 'Bankr. M.D. Ga.', 'Bankr. N.D. Ga.', 'Bankr. S.D. Ga.'],
  guam: ['9th Cir.', 'D. Guam', 'Bankr. D. Guam', 'B.A.P. 9th Cir.'],
  hawaii: ['9th Cir.', 'D. Haw.', 'Bankr. D. Haw.', 'B.A.P. 9th Cir.'],
  idaho: ['9th Cir.', 'D. Idaho', 'Bankr. D. Idaho', 'B.A.P. 9th Cir.'],
  illinois: ['7th Cir.', 'C.D. Ill.', 'N.D. Ill.', 'S.D. Ill.', 'Bankr. C.D. Ill.', 'Bankr. N.D. Ill.', 'Bankr. S.D. Ill.'],
  indiana: ['7th Cir.', 'N.D. Ind.', 'S.D. Ind.', 'Bankr. N.D. Ind.', 'Bankr. S.D. Ind.'],
  iowa: ['8th Cir.', 'N.D. Iowa', 'S.D. Iowa', 'Bankr. N.D. Iowa', 'Bankr. S.D. Iowa', 'B.A.P. 8th Cir.'],
  kansas: ['10th Cir.', 'D. Kan.', 'Bankr. D. Kan.', 'B.A.P. 10th Cir.'],
  kentucky: ['6th Cir.', 'E.D. Ky.', 'W.D. Ky.', 'Bankr. E.D. Ky.', 'Bankr. W.D. Ky.', 'B.A.P. 6th Cir.'],
  louisiana: ['5th Cir.', 'E.D. La.', 'M.D. La.', 'W.D. La.', 'Bankr. E.D. La.', 'Bankr. M.D. La.', 'Bankr. W.D. La.'],
  maine: ['1st Cir.', 'D. Me.', 'Bankr. D. Me.', 'B.A.P. 1st Cir.'],
  maryland: ['4th Cir.', 'D. Md.', 'Bankr. D. Md.'],
  massachusetts: ['1st Cir.', 'D. Mass.', 'Bankr. D. Mass.', 'B.A.P. 1st Cir.'],
  michigan: ['6th Cir.', 'E.D. Mich.', 'W.D. Mich.', 'Bankr. E.D. Mich.', 'Bankr. W.D. Mich.', 'B.A.P. 6th Cir.'],
  minnesota: ['8th Cir.', 'D. Minn.', 'Bankr. D. Minn.', 'B.A.P. 8th Cir.'],
  mississippi: ['5th Cir.', 'N.D. Miss.', 'S.D. Miss.', 'Bankr. N.D. Miss.', 'Bankr. S.D. Miss.'],
  missouri: ['8th Cir.', 'E.D. Mo.', 'W.D. Mo.', 'Bankr. E.D. Mo.', 'Bankr. W.D. Mo.', 'B.A.P. 8th Cir.'],
  montana: ['9th Cir.', 'D. Mont.', 'Bankr. D. Mont.', 'B.A.P. 9th Cir.'],
  nebraska: ['8th Cir.', 'D. Neb.', 'Bankr. D. Neb.', 'B.A.P. 8th Cir.'],
  nevada: ['9th Cir.', 'D. Nev.', 'Bankr. D. Nev.', 'B.A.P. 9th Cir.'],
  'new hampshire': ['1st Cir.', 'D.N.H.', 'Bankr. D.N.H.', 'B.A.P. 1st Cir.'],
  'new jersey': ['3d Cir.', 'D.N.J.', 'Bankr. D.N.J.'],
  'new mexico': ['10th Cir.', 'D.N.M.', 'Bankr. D.N.M.', 'B.A.P. 10th Cir.'],
  'new york': [
    '2d Cir.',
    'E.D.N.Y.',
    'N.D.N.Y.',
    'S.D.N.Y.',
    'W.D.N.Y.',
    'Bankr. E.D.N.Y.',
    'Bankr. N.D.N.Y.',
    'Bankr. S.D.N.Y.',
    'Bankr. W.D.N.Y.',
    'B.A.P. 2d Cir.',
  ],
  'north carolina': ['4th Cir.', 'E.D.N.C.', 'M.D.N.C.', 'W.D.N.C.', 'Bankr. E.D.N.C.', 'Bankr. M.D.N.C.', 'Bankr. W.D.N.C.'],
  'north dakota': ['8th Cir.', 'D.N.D.', 'Bankr. D.N.D.', 'B.A.P. 8th Cir.'],
  'northern mariana islands': [],
  ohio: ['6th Cir.', 'N.D. Ohio', 'S.D. Ohio', 'Bankr. N.D. Ohio', 'Bankr. S.D. Ohio', 'B.A.P. 6th Cir.'],
  oklahoma: ['10th Cir.', 'E.D. Okla.', 'N.D. Okla.', 'W.D. Okla.', 'Bankr. E.D. Okla.', 'Bankr. N.D. Okla.', 'Bankr. W.D. Okla.', 'B.A.P. 10th Cir.'],
  oregon: ['9th Cir.', 'D. Or.', 'Bankr. D. Or.'],
  pennsylvania: ['3d Cir.', 'E.D. Pa.', 'M.D. Pa.', 'W.D. Pa.', 'Bankr. E.D. Pa.', 'Bankr. M.D. Pa.', 'Bankr. W.D. Pa.'],
  'puerto rico': ['1st Cir.', 'D.P.R.', 'Bankr. D.P.R.', 'B.A.P. 1st Cir.'],
  'rhode island': ['1st Cir.', 'D.R.I.', 'Bankr. D.R.I.', 'B.A.P. 1st Cir.'],
  'south carolina': ['4th Cir.', 'D.S.C.', 'Bankr. D.S.C.'],
  'south dakota': ['8th Cir.', 'D.S.D.', 'Bankr. D.S.D.', 'B.A.P. 8th Cir.'],
  tennessee: ['6th Cir.', 'E.D. Tenn.', 'M.D. Tenn.', 'W.D. Tenn.', 'Bankr. E.D. Tenn.', 'Bankr. M.D. Tenn.', 'Bankr. W.D. Tenn.', 'B.A.P. 6th Cir.'],
  texas: ['5th Cir.', 'E.D. Tex.', 'N.D. Tex.', 'S.D. Tex.', 'W.D. Tex.', 'Bankr. E.D. Tex.', 'Bankr. N.D. Tex.', 'Bankr. S.D. Tex.', 'Bankr. W.D. Tex.'],
  utah: ['10th Cir.', 'D. Utah', 'Bankr. D. Utah', 'B.A.P. 10th Cir.'],
  vermont: ['2d Cir.', 'D. Vt.', 'Bankr. D. Vt.', 'B.A.P. 2d Cir.'],
  virginia: ['4th Cir.', 'E.D. Va.', 'W.D. Va.', 'Bankr. E.D. Va.', 'Bankr. W.D. Va.'],
  'virgin islands': ['3d Cir.', 'D.V.I.', 'Bankr. D.V.I.'],
  washington: ['9th Cir.', 'E.D. Wash.', 'W.D. Wash.', 'Bankr. E.D. Wash.', 'Bankr. W.D. Wash.', 'B.A.P. 9th Cir.'],
  'west virginia': ['4th Cir.', 'N.D.W. Va.', 'S.D.W. Va.', 'Bankr. N.D.W. Va.', 'Bankr. S.D.W. Va.'],
  wisconsin: ['7th Cir.', 'E.D. Wis.', 'W.D. Wis.', 'Bankr. E.D. Wis.', 'Bankr. W.D. Wis.'],
  wyoming: ['10th Cir.', 'D. Wyo.', 'Bankr. D. Wyo.', 'B.A.P. 10th Cir.'],
}

const STATE_LRR_SOURCES_MAP: SourceMap = {
  alabama: ['alabama-administrative-code', 'alabama-court-rules', 'code-of-alabama'],
  alaska: ['alaska-administrative-code', 'alaska-court-rules', 'alaska-statutes'],
  arizona: ['arizona-administrative-code', 'arizona-court-rules', 'arizona-revised-statutes'],
  arkansas: ['arkansas-administrative-code', 'arkansas-code-of-1987', 'arkansas-court-rules'],
  california: ['california-code-of-regulations', 'california-codes', 'california-constitution', 'california-court-rules'],
  colorado: ['colorado-administrative-code', 'colorado-court-rules', 'colorado-revised-statutes'],
  connecticut: ['connecticut-administrative-code', 'connecticut-court-rules', 'general-statutes-of-connecticut'],
  delaware: ['delaware-administrative-code', 'delaware-code', 'delaware-court-rules'],
  'district of columbia': ['district-of-columbia-administrative-code', 'district-of-columbia-court-rules', 'district-of-columbia-official-code'],
  florida: ['florida-administrative-code', 'florida-court-rules', 'florida-statutes'],
  georgia: ['code-of-georgia', 'georgia-administrative-code', 'georgia-court-rules'],
  guam: [],
  hawaii: ['hawaii-administrative-rules', 'hawaii-court-rules', 'hawaii-revised-statutes'],
  idaho: ['idaho-administrative-code', 'idaho-code', 'idaho-court-rules'],
  illinois: ['illinois-administrative-code', 'illinois-compiled-statutes', 'illinois-court-rules'],
  indiana: ['indiana-administrative-code', 'indiana-code', 'indiana-court-rules'],
  iowa: ['code-of-iowa', 'iowa-administrative-code', 'iowa-court-rules'],
  kansas: ['kansas-administrative-code', 'kansas-court-rules', 'kansas-statutes'],
  kentucky: ['kentucky-administrative-regulations', 'kentucky-court-rules', 'kentucky-revised-statutes'],
  louisiana: ['louisiana-administrative-code', 'louisiana-court-rules', 'louisiana-revised-statutes'],
  maine: ['maine-administrative-code', 'maine-court-rules', 'maine-statutes'],
  maryland: ['code-of-maryland', 'maryland-administrative-code', 'maryland-court-rules'],
  massachusetts: ['code-of-massachusetts-regulations', 'general-laws-of-massachusetts', 'massachusetts-court-rules'],
  michigan: ['michigan-administrative-code', 'michigan-compiled-laws', 'michigan-court-rules'],
  minnesota: ['minnesota-administrative-rules', 'minnesota-court-rules', 'minnesota-statutes'],
  mississippi: ['mississippi-administrative-code', 'mississippi-code-1972', 'mississippi-court-rules'],
  missouri: ['missouri-administrative-code', 'missouri-court-rules', 'missouri-revised-statutes'],
  montana: ['montana-administrative-code', 'montana-code'],
  'northern mariana islands': [],
  nebraska: ['revised-statutes-of-nebraska', 'nebraska-administrative-code', 'nebraska-rules'],
  nevada: ['nevada-administrative-code', 'nevada-court-rules', 'nevada-revised-statutes'],
  'new hampshire': ['new-hampshire-administrative-code', 'new-hampshire-court-rules', 'new-hampshire-revised-statutes'],
  'new jersey': ['new-jersey-administrative-code', 'new-jersey-rules-of-court', 'new-jersey-statutes'],
  'new mexico': ['new-mexico-administrative-code', 'new-mexico-court-rules', 'new-mexico-statutes-1978'],
  'new york': ['consolidated-laws-of-new-york', 'new-york-codes-rules-and-regulations'],
  'north carolina': ['general-statutes-of-north-carolina', 'north-carolina-administrative-code', 'north-carolina-court-rules'],
  'north dakota': ['north-dakota-administrative-code', 'north-dakota-century-code', 'north-dakota-court-rules'],
  ohio: ['ohio-administrative-code', 'ohio-court-rules', 'ohio-revised-code'],
  oklahoma: ['oklahoma-administrative-code', 'oklahoma-statutes'],
  oregon: ['oregon-administrative-code', 'oregon-court-rules', 'oregon-revised-statutes'],
  pennsylvania: ['pennsylvania-code-rules-and-regulations', 'pennsylvania-local-court-rules', 'pennsylvania-statutes'],
  'puerto rico': ['laws-of-puerto-rico'],
  'rhode island': ['general-laws-of-rhode-island', 'rhode-island-administrative-code', 'rhode-island-court-rules'],
  'south carolina': ['code-of-laws-of-south-carolina-1976', 'south-carolina-code-of-regulations', 'south-carolina-court-rules'],
  'south dakota': ['south-dakota-administrative-rules', 'south-dakota-codified-laws'],
  tennessee: ['tennessee-administrative-code', 'tennessee-code', 'tennessee-court-rules'],
  texas: ['texas-administrative-code', 'texas-codes', 'texas-court-rules'],
  utah: ['utah-administrative-code', 'utah-code', 'utah-court-rules'],
  vermont: ['vermont-administrative-code', 'vermont-court-rules', 'vermont-statutes'],
  virginia: ['code-of-virginia', 'virginia-administrative-code', 'virginia-court-rules'],
  'virgin islands': [],
  washington: ['revised-code-of-washington', 'washington-administrative-code', 'washington-court-rules'],
  'west virginia': ['west-virginia-administrative-code', 'west-virginia-code', 'west-virginia-court-rules'],
  wisconsin: ['wisconsin-administrative-code', 'wisconsin-statutes'],
  wyoming: ['wyoming-administrative-code', 'wyoming-court-rules', 'wyoming-statutes'],
}

const FEDERAL_COURTS = ['U.S.', 'Fed. Cl.', 'C.C.P.A.', "Ct. Int'l Trade", 'U.S.T.C.', 'B.T.A', 'Fed. Cir.']

const FEDERAL_LRR_SOURCES = ['code-of-federal-regulations', 'constitution-of-united-states', 'united-states-code']

/**
 * Retrieves state court IDs based on the provided state name.
 *
 * @param state - The name of the state.
 * @returns A list of state court IDs.
 */
export function getStateCourtIds(state: string | null): string[] {
  if (!state) return []
  return STATE_TO_STATE_COURT_MAP[state?.toLowerCase()]
}

/**
 * Retrieves federal court IDs based on the provided state name.
 *
 * @param state - The name of the state.
 * @returns A list of federal court IDs.
 */
export function getFederalCourtIdsByState(state: string | null): string[] {
  if (!state) return []
  return STATE_TO_FEDERAL_COURT_MAP[state?.toLowerCase()]
}

/**
 * Retrieves standard federal court IDs (ones applicable to any state).
 *
 * @returns A list of standard federal court IDs.
 */
export function getStandardFederalCourtIds(): string[] {
  return FEDERAL_COURTS
}

/**
 * Retrieves state LRR sources based on the provided state name.
 *
 * @param state - The name of the state.
 * @returns A list of state LRR sources.
 */
export function getStateLRRSources(state: string | null): string[] {
  if (!state) return []
  return STATE_LRR_SOURCES_MAP[state?.toLowerCase()]
}

/**
 * Retrieves standard federal LRR sources (ones which accompany any federal court and are applicable to any state).
 *
 * @returns A list of standard federal LRR sources.
 */
export function getStandardFederalLRRSources(): string[] {
  return FEDERAL_LRR_SOURCES
}

/**
 * Infers the state selected based on a list of state court IDs.
 *
 * @param stateCourtIds - An array of court IDs.
 * @returns The name of the state or null if not found.
 */
export function inferSelectedStateFromCourtIds(stateCourtIds: string[] | null): string | null {
  if (!stateCourtIds) return null
  for (const state in STATE_TO_STATE_COURT_MAP) {
    if (stateCourtIds.some((courtId) => STATE_TO_STATE_COURT_MAP[state].includes(courtId))) {
      return state
    }
  }
  return null
}
