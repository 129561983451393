import { UserAccountData } from '@/firebase/auth/user-account-data'
import { isPaxtonBrand } from '@/util/enterprise'
import ValidationError from '@/util/errors/validation-error'

export const REFERRAL_CODE_KEY = 'referralCode'

/**
 * Validates if the referral code is exactly 10 characters long and
 * consists of only alphanumeric characters, hyphens (`-`), and underscores (`_`).
 *
 * @param referralCode - The referral code to validate
 * @returns `true` if the referral code is valid, otherwise `false`
 */
export function isValidReferralCode(referralCode: string | null | undefined): boolean {
  if (typeof referralCode !== 'string') {
    return false
  }
  return /^[a-zA-Z0-9-_]{10}$/.test(referralCode)
}

/**
 * Validates the referral code and user account data.
 * Checks the format of the referral code and ensures the user is not part of an organization account,
 * and the user's brand is either `PAXTON` or `NONE`.
 *
 * @param referralCode - The referral code to validate
 * @param userAccountData - The user account data object to validate
 * @throws `ValidationError` if the referral code or user account data is invalid
 */
export function validateReferralCodeAndUserAccount(referralCode: string, userAccountData: UserAccountData | null): void {
  if (!isValidReferralCode(referralCode)) {
    throw new ValidationError('Invalid referral code')
  }

  if (!userAccountData) {
    throw new ValidationError('No userAccountData in context')
  }

  if (userAccountData.isOrgAccount) {
    throw new ValidationError('User has an org account')
  }

  if (!isPaxtonBrand()) {
    throw new ValidationError('User has an enterprise brand')
  }
}

/**
 * Retrieves and validates the referral code from the URL or sessionStorage.
 *
 * @returns An object containing the valid referral code or an error message if invalid.
 */
export function retrieveReferralCode(): { code: string | null; error: string | null } {
  const params = new URLSearchParams(window.location.search)
  const urlReferralCode = params.get(REFERRAL_CODE_KEY)

  if (urlReferralCode) {
    if (isValidReferralCode(urlReferralCode)) {
      sessionStorage.setItem(REFERRAL_CODE_KEY, urlReferralCode)
      return { code: urlReferralCode, error: null }
    } else {
      return { code: null, error: 'The referral code in the link appears to be invalid. Please verify the link and try again.' }
    }
  }

  const storedReferralCode = sessionStorage.getItem(REFERRAL_CODE_KEY)
  if (storedReferralCode && isValidReferralCode(storedReferralCode)) {
    return { code: storedReferralCode, error: null }
  }

  return { code: null, error: null }
}

/**
 * Removes the referral code from sessionStorage.
 */
export function removeReferralCode(): void {
  sessionStorage.removeItem(REFERRAL_CODE_KEY)
}
