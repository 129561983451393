import { ChatV2Conversation } from '@/chat-common/store/chat-v2.slice'
import { ExternalLinkIcon } from '@radix-ui/react-icons'

type ReferenceViewWebProps = {
  conversation: ChatV2Conversation
}

export default function ReferenceViewContentWeb(props: ReferenceViewWebProps) {
  const { conversation } = props

  const reference = conversation.visibleReference
  console.log('Rendering reference: ', reference)

  // If no reference
  if (!reference) return <div>No reference selected</div>

  const published_at = reference.metadata.published_at
  const title = reference.metadata.title
  const url = reference.metadata.url
  const snippet_text = reference.metadata.snippet_text

  return (
    <div className="reference-view-html overflow-y-scroll py-2">
      <div id="web-reference" className={'text-sm'}>
        <div className={'font-bold mb-1'}>{title}</div>
        <div className={'text-gray-500 mb-5'}>{published_at}</div>
        <div className={''}>{snippet_text}</div>
        <a target={'_blank'} href={url} className={'pt-2 flex gap-x-2 items-center font-bold underline text-sky-800 hover:text-sky-600'}>
          <div className={'inline-block'}>Visit source</div>
          <ExternalLinkIcon />
        </a>
      </div>
    </div>
  )
}
