import { ChatV2Feature } from '../store/chat-v2.slice'

export function getStorageFolderNameByFeature(chatFeature: ChatV2Feature): string {
  switch (chatFeature) {
    case ChatV2Feature.contractanalysis:
      return 'Contract Analysis Uploads'
    case ChatV2Feature.documentquery:
      return 'File Analysis Uploads'
    case ChatV2Feature.documentSummarization:
      return 'File Summarizer Uploads'
    case ChatV2Feature.assistant:
      return 'Assistant Uploads'
    default:
      return 'Chat Uploads'
  }
}
