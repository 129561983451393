import { kReferralCodePath } from '@/constants/constants-api-paths'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'

export default async function getReferralCode(): Promise<string | null> {
  const sentry_transaction_id = nanoid()

  try {
    const user = getAuth().currentUser
    const token = await user?.getIdToken(true)
    if (!token) throw new Error(kErrorCodeAuthError)

    console.log(`Attempting to get or create referral code for user ${user?.uid}`)

    const apiUrl = kPaxtonAppApiBaseUrl() + kReferralCodePath
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
    }

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (!response.ok || !data.referral_code) {
      const error = new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`)
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(error, {
          extra: {
            requestUrl: apiUrl,
            requestOptions: JSON.stringify(requestOptions),
            response: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
      return null
    }

    const referralCode = data.referral_code

    if (response.status === 201) console.log(`Referral code ${referralCode} created for user ${user?.uid}`)
    if (response.status === 200) console.log(`Referral code ${referralCode} retrieved for user ${user?.uid}`)

    return referralCode
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })
      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    return null
  }
}
