import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { LinearProgress } from '@mui/material'
import { ArrowDownIcon, ArrowLeftIcon, ArrowUpIcon } from '@radix-ui/react-icons'
import { Link } from 'react-router-dom'
import { DocumentEditingAnnotationType } from '../schema/document-editing-websocket.schema'

type DocumentEditingSessionTopBarProps = {
  editingSessionId: string
  documentName: string
  loading: boolean
  annotations: DocumentEditingAnnotationType[]
  currentAnnotationIndex: number
  setNewAnnotationIndex: (index: number) => void
}

export default function DocumentEditingSessionTopBar(props: DocumentEditingSessionTopBarProps) {
  const { documentName, loading, annotations, currentAnnotationIndex, setNewAnnotationIndex } = props

  // Global UX State
  const uiState = useAppSelector((state: RootState) => state.uiState)
  const hideSidebars = uiState.hideSidebars

  return (
    // Width of top bar is controlled by screen size (full on mobile), and status of sidebar visibility so that it always
    // visually takes up the entire top area
    <div
      className={`absolute z-10 ${hideSidebars ? 'w-full lg:-ml-8' : 'w-full lg:w-[calc(100%-224px)] lg:-ml-6'} mt-12 lg:mt-0 bg-white shadow-md text-sm mb-2`}
    >
      <div className={'flex-grow px-2 py-3 grid grid-cols-[30px_auto_140px] items-center gap-x-2'}>
        <Link to="/dashboard/document-editing/list" className={'flex items-center'}>
          <ArrowLeftIcon className={'pl-1 w-7 h-7'} />
        </Link>

        <div className={'flex items-center whitespace-nowrap overflow-scroll font-semibold text-base'}>{documentName}</div>
        <div className={'flex items-center justify-end'}>
          {annotations.length > 0 && (
            <>
              <button
                className={'px-1'}
                onClick={() => {
                  if (currentAnnotationIndex === 0) return

                  setNewAnnotationIndex(currentAnnotationIndex - 1)
                }}
              >
                <ArrowUpIcon className={'w-5 h-5'} />
              </button>
              <div className={'bg-[#fef8ca] px-2 py-[2px] rounded-md text-base'}>
                {currentAnnotationIndex + 1}&nbsp;/&nbsp;{annotations.length}
              </div>{' '}
              <button
                className={'px-1'}
                onClick={() => {
                  if (currentAnnotationIndex === annotations.length - 1) return

                  setNewAnnotationIndex(currentAnnotationIndex + 1)
                }}
              >
                <ArrowDownIcon className={'w-5 h-5'} />
              </button>
            </>
          )}
        </div>
      </div>
      {loading && (
        <div className={'flex grow'}>
          <LinearProgress className={'w-full'} />
        </div>
      )}
    </div>
  )
}
