import { kChatV2ApiConversationsBasePath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'

type PatchConversationTitleParams = {
  conversationID: string
  newTitle: string
}
export default async function patchConversationTitle(params: PatchConversationTitleParams): Promise<void> {
  // Create transaction id
  const sentry_transaction_id = nanoid()

  try {
    console.log('Patching conversation title.')
    const token = await getAuth().currentUser?.getIdToken(true)
    if (!token) throw Error(kErrorCodeAuthError)
    const { conversationID } = params
    const body = JSON.stringify({ new_title: params.newTitle })
    // Construct request
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'PATCH',
      body: body,
    }

    const apiUrl = kPaxtonAppApiBaseUrl() + `${kChatV2ApiConversationsBasePath}/${conversationID}/title`
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // Handle Errors and return early
    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            response: data,
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
    }

    return data
  } catch (e) {
    console.error(e)

    // Capture to sentry
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
  }
}
