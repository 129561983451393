import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import { ChatV2QueryMetadataResearch, ChatV2QueryMetadataResearchSchema } from '../schemas/chat-query-metadata-schema'

// Default new research source
export const defaultNewResearchValues: ChatV2QueryMetadataResearch = {
  state_lrr_selections: null,
  federal_lrr_selections: null,
  state_court_ids: null,
  federal_court_ids: null,
  case_law_year_low: null,
  case_law_year_high: null,
  include_unpublished_cases: false,
}

// Set case law date range
export const reducerCurrentSourceResearchSetCaseLawDateRange = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; yearLow: number | null; yearHigh: number | null }>
) => {
  const { conversationId, yearLow, yearHigh } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) {
    // If not a match, set defaults
    conversation.currentSource = {
      ...defaultNewResearchValues,
      case_law_year_low: yearLow,
      case_law_year_high: yearHigh,
    }
  }

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Update the year values
  currentSource.case_law_year_low = yearLow
  currentSource.case_law_year_high = yearHigh
}

// ADD FEDERAL COURTS
export const reducerCurrentSourceResearchAddFederalCourtIds = (state: ChatV2State, action: PayloadAction<{ conversationId: string; courtIds: string[] }>) => {
  const { conversationId, courtIds } = action.payload
  const conversation = state.conversations[conversationId]

  if (conversation.currentSource == null) {
    conversation.currentSource = {
      ...defaultNewResearchValues,
      federal_court_ids: courtIds,
    }
  }

  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Add the new selections and ensure they are unique
  const newSelections = Array.from(new Set([...(currentSource.federal_court_ids ?? []), ...courtIds]))
  currentSource.federal_court_ids = newSelections
}

// REMOVE FEDERAL COURTS
export const reducerCurrentSourceResearchRemoveFederalCourtIds = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; courtIds: string[] }>
) => {
  const { conversationId, courtIds } = action.payload
  const conversation = state.conversations[conversationId]

  if (conversation.currentSource == null) {
    conversation.currentSource = {
      ...defaultNewResearchValues,
      federal_court_ids: courtIds,
    }
  }

  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Remove the new selections
  let newSelections: string[] | null = (currentSource.federal_court_ids ?? []).filter((courtId) => !courtIds.includes(courtId))

  // If there are no selections left, set to null (an empty list to the BE means all courts)
  if (newSelections.length === 0) {
    newSelections = null
    // Clear federal LRR sources
    currentSource.federal_lrr_selections = null
  }

  currentSource.federal_court_ids = newSelections
}

// REMOVE ALL FEDERAL COURTS
export const reducerCurrentSourceResearchRemoveAllFederalCourtIds = (state: ChatV2State, action: PayloadAction<{ conversationId: string }>) => {
  const { conversationId } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error('Cannot remove source from currentSource, invalid schema', validation.error)

  // Get the existing currentSource
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Reset to initial state
  currentSource.federal_court_ids = null

  // Clear federal LRR sources
  currentSource.federal_lrr_selections = null
}

// ADD STATE COURTS
export const reducerCurrentSourceResearchAddStateCourtIds = (state: ChatV2State, action: PayloadAction<{ conversationId: string; courtIds: string[] }>) => {
  const { conversationId, courtIds } = action.payload
  const conversation = state.conversations[conversationId]

  if (conversation.currentSource == null) {
    conversation.currentSource = {
      ...defaultNewResearchValues,
      state_court_ids: courtIds,
    }
  }

  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Add the new selections and ensure they are unique
  const newSelections = Array.from(new Set([...(currentSource.state_court_ids ?? []), ...courtIds]))
  currentSource.state_court_ids = newSelections
}

// REMOVE STATE COURTS
export const reducerCurrentSourceResearchRemoveStateCourtIds = (state: ChatV2State, action: PayloadAction<{ conversationId: string; courtIds: string[] }>) => {
  const { conversationId, courtIds } = action.payload
  const conversation = state.conversations[conversationId]

  if (conversation.currentSource == null) {
    conversation.currentSource = {
      ...defaultNewResearchValues,
      state_court_ids: courtIds,
    }
  }

  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Remove the new selections
  let newSelections: string[] | null = (currentSource.state_court_ids ?? []).filter((courtId) => !courtIds.includes(courtId))

  // If there are no selections left, set to null (an empty list to the BE means all courts)
  if (newSelections.length === 0) {
    newSelections = null
    // Clear state LRR sources
    currentSource.state_lrr_selections = null
  }
  currentSource.state_court_ids = newSelections
}

// REMOVE ALL STATE COURTS
export const reducerCurrentSourceResearchRemoveAllStateCourtIds = (state: ChatV2State, action: PayloadAction<{ conversationId: string }>) => {
  const { conversationId } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error('Cannot remove source from currentSource, invalid schema', validation.error)

  // Get the existing currentSource
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Reset to initial state
  currentSource.state_court_ids = null

  // Clear state LRR sources
  currentSource.state_lrr_selections = null
}

// ADD FEDERAL LRR SOURCE
export const reducerCurrentSourceResearchAddFederalLRRSource = (state: ChatV2State, action: PayloadAction<{ conversationId: string; source_name: string }>) => {
  const { conversationId, source_name } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema, if invalid re-establish the currentSource
  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) {
    conversation.currentSource = {
      ...defaultNewResearchValues,
      federal_lrr_selections: [],
    }
    conversation.currentSource.federal_lrr_selections.push({
      paths: [],
      source_name: source_name,
    })
  } else {
    // Get the existing currentSource and update it with the new source_name
    const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

    // If currentSource exists, update it and set state
    currentSource.federal_lrr_selections = currentSource.federal_lrr_selections ?? []

    // Check if the source_name already exists in federal_lrr_selections
    const sourceExists = currentSource.federal_lrr_selections.some((source) => source.source_name === source_name)

    // Only add the source if it doesn't already exist
    if (!sourceExists) {
      currentSource.federal_lrr_selections.push({
        paths: [],
        source_name: source_name,
      })
    }

    conversation.currentSource = currentSource
  }
}

// REMOVE FEDERAL LRR SOURCE
export const reducerCurrentSourceResearchRemoveFederalLRRSource = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; source_name: string }>
) => {
  const { conversationId, source_name } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error('Cannot remove source from currentSource, invalid schema', validation.error)

  // Get the existing currentSource
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Remove the source object from the array
  currentSource.federal_lrr_selections = (currentSource.federal_lrr_selections ?? []).filter((entry) => entry.source_name !== source_name)
  conversation.currentSource = currentSource
}

// REMOVE ALL FEDERAL LRR SOURCES
export const reducerCurrentSourceResearchRemoveAllFederalLRRSources = (state: ChatV2State, action: PayloadAction<{ conversationId: string }>) => {
  const { conversationId } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error('Cannot remove source from currentSource, invalid schema', validation.error)

  // Get the existing currentSource
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Reset to initial state
  currentSource.federal_lrr_selections = null
}

// ADD STATE LRR SOURCE
export const reducerCurrentSourceResearchAddStateLRRSource = (state: ChatV2State, action: PayloadAction<{ conversationId: string; source_name: string }>) => {
  const { conversationId, source_name } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema, if invalid re-establish the currentSource
  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) {
    conversation.currentSource = {
      ...defaultNewResearchValues,
      state_lrr_selections: [],
    }
    conversation.currentSource.state_lrr_selections.push({
      paths: [],
      source_name: source_name,
    })
  } else {
    // Get the existing currentSource and update it with the new source_name
    const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

    // If currentSource exists, update it and set state
    currentSource.state_lrr_selections = currentSource.state_lrr_selections ?? []

    // Check if the source_name already exists in state_lrr_selections
    const sourceExists = currentSource.state_lrr_selections.some((source) => source.source_name === source_name)

    // Only add the source if it doesn't already exist
    if (!sourceExists) {
      currentSource.state_lrr_selections.push({
        paths: [],
        source_name: source_name,
      })
    }

    conversation.currentSource = currentSource
  }
}

// REMOVE STATE LRR SOURCE
export const reducerCurrentSourceResearchRemoveStateLRRSource = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; source_name: string }>
) => {
  const { conversationId, source_name } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error('Cannot remove source from currentSource, invalid schema', validation.error)

  // Get the existing currentSource
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Remove the source object from the array
  currentSource.state_lrr_selections = (currentSource.state_lrr_selections ?? []).filter((entry) => entry.source_name !== source_name)
  conversation.currentSource = currentSource
}

// REMOVE ALL STATE LRR SOURCES
export const reducerCurrentSourceResearchRemoveAllStateLRRSources = (state: ChatV2State, action: PayloadAction<{ conversationId: string }>) => {
  const { conversationId } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error('Cannot remove source from currentSource, invalid schema', validation.error)

  // Get the existing currentSource
  const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

  // Reset to initial state
  currentSource.state_lrr_selections = null
}

export const reducerCurrentSourceResearchSetIncludeUnpublishedCases = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; includeUnpublishedCases: boolean }>
) => {
  const { conversationId, includeUnpublishedCases } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  const validation = ChatV2QueryMetadataResearchSchema.safeParse(conversation.currentSource)

  if (!validation.success) {
    if (conversation.currentSource == null) {
      conversation.currentSource = {
        ...defaultNewResearchValues,
        includeUnpublishedCases: includeUnpublishedCases,
      }
    }
  } else {
    // Type the current source metadata
    const currentSource = conversation.currentSource as ChatV2QueryMetadataResearch

    // Set the include unpublished cases value as not null boolean
    currentSource.include_unpublished_cases = includeUnpublishedCases
  }
}
