import React, { useState, useContext } from 'react'
import { questions } from '@/components/survey/SurveyQuestions.ts'
import { AuthContext } from '@/context/auth-context'
import { setSurveyResponse } from '../store/onboarding.slice'
import { useAppDispatch } from '@/store/store-hooks'
import { usePostSurveyResponseMutation } from '@/onboarding/store/onboarding.slice.ts'
import { Userpilot } from 'userpilot'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store' // Adjust this import based on your actual store setup

type OnboardingFormProps = {
  visible: boolean
}

const OnboardingWrapup: React.FC<OnboardingFormProps> = ({ visible }) => {
  const dispatch = useAppDispatch()
  const currentSurveyResponse = useSelector((state: RootState) => state.onboardingState.surveyResponse)
  const [formData, setFormData] = useState({
    tools: [] as string[],
    otherTool: '',
    challenges: '',
    otherChallenge: '',
    frequency: '',
    otherFrequency: '',
  })
  const [finalLoading, setFinalLoading] = useState(false)
  const [postSurveyResponse, { isLoading, error }] = usePostSurveyResponseMutation()
  const { refreshUserAccountData } = useContext(AuthContext)

  if (!visible) return null

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        tools: checked ? [...prev.tools, value] : prev.tools.filter((tool) => tool !== value),
      }))
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }))
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const finalTools = formData.tools
      .map((tool) => (tool === 'Other' ? `Other: ${formData.otherTool}` : tool))
      .filter((tool) => tool !== 'Other' || formData.otherTool.trim() !== '')
    const finalChallenge = formData.challenges === 'Other' ? `Other: ${formData.otherChallenge}` : formData.challenges
    const finalFrequency = formData.frequency === 'Other' ? `Other: ${formData.otherFrequency}` : formData.frequency

    const updatedSurveyResponse = {
      ...currentSurveyResponse,
      legalResearchTools: finalTools.join(', '),
      biggestChallenge: finalChallenge,
      usageFrequency: finalFrequency,
    }

    dispatch(setSurveyResponse({ responseKey: 'legalResearchTools', responseValue: finalTools.join(', ') }))
    dispatch(setSurveyResponse({ responseKey: 'biggestChallenge', responseValue: finalChallenge }))
    dispatch(setSurveyResponse({ responseKey: 'usageFrequency', responseValue: finalFrequency }))

    try {
      const result = await postSurveyResponse(updatedSurveyResponse).unwrap()
      if (result.success) {
        setFinalLoading(true)
        await new Promise((resolve) => setTimeout(resolve, 2000))
        refreshUserAccountData()
        console.log('Triggering userpilot walkthrough video')
        Userpilot.trigger('_ALPYpNNyY')
      }
    } catch (error) {
      console.error('Failed to submit survey response:', error)
    }
  }

  const question3Input = questions[2].inputs[0]
  const question3Options = 'options' in question3Input ? question3Input.options : []
  const question4Input = questions[3].inputs[0]
  const question4Options = 'options' in question4Input ? question4Input.options : []
  const question5Input = questions[4].inputs[0]
  const question5Options = 'options' in question5Input ? question5Input.options : []

  const isFormValid = formData.tools.length > 0 && formData.challenges !== '' && formData.frequency !== ''

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-10 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
        <fieldset>
          <legend className="sr-only">Tools</legend>
          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
            <div aria-hidden="true" className="text-sm font-semibold leading-6 text-gray-900">
              Tools
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg">
                <p className="text-sm leading-6 text-gray-600">Which tools or services have you used for legal research before?</p>
                <div className="mt-6 space-y-6">
                  {question3Options.map((option) => (
                    <div key={option.value} className="flex items-center gap-x-3">
                      <input
                        id={`tools-${option.value}`}
                        name="tools"
                        type="checkbox"
                        value={option.value}
                        checked={formData.tools.includes(option.value)}
                        onChange={handleInputChange}
                        className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600 cursor-pointer"
                      />
                      <label htmlFor={`tools-${option.value}`} className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                        {option.value}
                      </label>
                    </div>
                  ))}
                  {formData.tools.includes('Other') && (
                    <div className="mt-2 ml-7">
                      <input
                        type="text"
                        name="otherTool"
                        value={formData.otherTool}
                        onChange={handleInputChange}
                        placeholder="Please specify"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend className="sr-only">Challenges</legend>
          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
            <div aria-hidden="true" className="text-sm font-semibold leading-6 text-gray-900">
              Challenges
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg">
                <p className="text-sm leading-6 text-gray-600">What is your biggest challenge with your current legal tools?</p>
                <div className="mt-6 space-y-6">
                  {question4Options.map((option) => (
                    <div key={option.value} className="flex items-center gap-x-3">
                      <input
                        id={`challenges-${option.value}`}
                        name="challenges"
                        type="radio"
                        value={option.value}
                        checked={formData.challenges === option.value}
                        onChange={handleInputChange}
                        className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600 cursor-pointer"
                      />
                      <label htmlFor={`challenges-${option.value}`} className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                        {option.value}
                      </label>
                    </div>
                  ))}
                  {formData.challenges === 'Other' && (
                    <div className="mt-2 ml-7">
                      <input
                        type="text"
                        name="otherChallenge"
                        value={formData.otherChallenge}
                        onChange={handleInputChange}
                        placeholder="Please specify"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend className="sr-only">Frequency</legend>
          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
            <div aria-hidden="true" className="text-sm font-semibold leading-6 text-gray-900">
              Frequency
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg">
                <p className="text-sm leading-6 text-gray-600">How often do you anticipate using Paxton AI?</p>
                <div className="mt-6 space-y-6">
                  {question5Options.map((option) => (
                    <div className="flex items-center gap-x-3" key={option.value}>
                      <input
                        id={`frequency-${option.value}`}
                        name="frequency"
                        type="radio"
                        value={option.value}
                        checked={formData.frequency === option.value}
                        onChange={handleInputChange}
                        className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600 cursor-pointer"
                      />
                      <label htmlFor={`frequency-${option.value}`} className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                        {option.value}
                      </label>
                    </div>
                  ))}
                  {formData.frequency === 'Other' && (
                    <div className="mt-2 ml-7">
                      <input
                        type="text"
                        name="otherFrequency"
                        value={formData.otherFrequency}
                        onChange={handleInputChange}
                        placeholder="Please specify"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div className="flex items-center justify-end p-3 space-x-4">
        {!isFormValid && <p className="text-sm text-gray-500 italic">Please complete all questions to submit.</p>}
        <button
          type="submit"
          className="flex items-center gap-2 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!isFormValid || isLoading || finalLoading}
        >
          Submit
          {(isLoading || finalLoading) && <CircularProgressContinuousSized hexColor={'#eee'} size={20} thickness={4} />}
        </button>
      </div>
      {error && <p className="mt-2 text-sm text-red-600">There was an error submitting your response.</p>}
    </form>
  )
}

export default OnboardingWrapup
