// Firebase Authentication JWT Schema
// The JWT may contain state values pertinent to application rendering
// They are defined here

export enum SubscriptionStatus {
  NONE = 'none',
  ORGANIZATION = 'organization',
  PAID = 'paid',
  EXPIRED = 'expired',
  TRIAL = 'trial',
  PRE_TRIAL = 'pre_trial',
  PAXTON = 'paxton',
  DELETED = 'deleted',
  PARTNER = 'partner',
}

export enum SurveyStatus {
  NONE = 'none',
  COMPLETED = 'completed',
}

export enum InferredPollingState {
  NONE = 'none',
  POLL_SUBSCRIPTION = 'poll_subscription',
  POLL_PAYMENT = 'poll_payment',
}

export enum SubscriptionType {
  STANDARD = 'standard',
  EDUCATION = 'education',
}

export enum BillingCycle {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export type userAccountData = {
  subscriptionStatus: SubscriptionStatus
  subscriptionType: SubscriptionType
  trialEnd: number
  defaultPayment: boolean
  billingCycle: BillingCycle
}

export enum BrandEnum {
  NONE = 'none',
  PAXTON = 'paxton',
  HAIKU = 'haiku',
}
