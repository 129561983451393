import { useContext, useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { AuthContext } from '@/context/auth-context'
import { useNavigate } from 'react-router-dom'
import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

export default function CallbackPageSubsription() {
  const { userAccountData } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (userAccountData?.validSubscription) {
      navigate('/dashboard')
    }
  }, [userAccountData, navigate])

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto w-[300px]" src={brandLogoUrl} alt={hostBrand} />
          {/* <h2 className="mt-6 text-center text-1xl leading-9 tracking-tight text-gray-900">Updating subscription details...</h2> */}
          <div className="mt-10 text-center">
            <CircularProgress size={24} thickness={5} />
          </div>
        </div>
      </div>
    </>
  )
}
