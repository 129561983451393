import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export enum AuthDialogType {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export type RetitleConversationAction = {
  visible: boolean
  conversationID?: string
  currentConversationTitle?: string
}

// Define a type for the slice state
interface UIState {
  showAuthDialog: boolean
  authDialogType: AuthDialogType
  authDialogTooltipMessage?: string
  hideSidebars: boolean
  stripePortalDialogVisible: boolean
  retitleConversationDialogVisible: boolean
  retitleConversationID?: string
  currentConversationTitleForRetitle?: string
  showLegacyStripeCheckout: boolean
  legacyStripeCheckoutMessage: string
  referralDialogVisible: boolean
}

// Define the initial state using that type
const initialState: UIState = {
  showAuthDialog: false,
  authDialogType: AuthDialogType.SIGN_UP,
  authDialogTooltipMessage: undefined,
  hideSidebars: false,
  stripePortalDialogVisible: false,
  retitleConversationDialogVisible: false,
  retitleConversationID: undefined,
  currentConversationTitleForRetitle: undefined,
  showLegacyStripeCheckout: false,
  legacyStripeCheckoutMessage: '',
  referralDialogVisible: false,
}

export const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    openAuthDialog: (state, action: PayloadAction<{ authDialogType: AuthDialogType; tooltipMessage?: string }>) => {
      console.log('Opening auth dialog...')
      state.showAuthDialog = true
      state.authDialogType = action.payload.authDialogType
      state.authDialogTooltipMessage = action.payload.tooltipMessage
    },
    switchAuthDialog: (state, action: PayloadAction<AuthDialogType>) => {
      state.authDialogType = action.payload
    },
    closeAuthDialog: (state) => {
      state.showAuthDialog = false
    },
    toggleSidebarVisibility: (state) => {
      state.hideSidebars = !state.hideSidebars
    },
    setStripePortalDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.stripePortalDialogVisible = action.payload
    },
    setRetitleConversationVisible: (state, action: PayloadAction<RetitleConversationAction>) => {
      state.retitleConversationDialogVisible = action.payload.visible
      state.retitleConversationID = action.payload.conversationID
      state.currentConversationTitleForRetitle = action.payload.currentConversationTitle
    },
    setLegacyStripeCheckoutVisible: (state, action: PayloadAction<boolean>) => {
      state.showLegacyStripeCheckout = action.payload
    },
    setLegacyStripeCheckoutMessage: (state, action: PayloadAction<string>) => {
      state.legacyStripeCheckoutMessage = action.payload
    },
    clearLegacyStripeCheckoutMessage: (state) => {
      state.legacyStripeCheckoutMessage = ''
    },
    setReferralDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.referralDialogVisible = action.payload
    },
  },
})

export const {
  openAuthDialog,
  switchAuthDialog,
  closeAuthDialog,
  toggleSidebarVisibility,
  setStripePortalDialogVisible,
  setRetitleConversationVisible,
  setLegacyStripeCheckoutVisible,
  setLegacyStripeCheckoutMessage,
  clearLegacyStripeCheckoutMessage,
  setReferralDialogVisible,
} = uiStateSlice.actions

export default uiStateSlice.reducer
