import { z } from 'zod'

const UserSchema = z.object({
  // Define UserSchema fields here
})

const UserInviteSchema = z.object({
  invitee_email: z.string().email(),
})

export const OrganizationSchema = z.object({
  id: z.string().uuid().optional(),
  name: z.string().optional(),
  tenant_id: z.string().nullable().optional(),
  tenant_name: z.string().nullable().optional(),
  seats: z.number().int().optional(),
  subscription_status: z.string().optional(),
  has_access: z.boolean(),
  users: z.array(UserSchema).optional(),
  invites: z.array(UserInviteSchema).optional(),
})

export const OrganizationResponseSchema = z.object({
  organization: OrganizationSchema,
})

export type Organization = z.infer<typeof OrganizationSchema>
export type OrganizationResponse = z.infer<typeof OrganizationResponseSchema>

export const SurveySchema = z.object({
  role: z.string(),
  primaryUse: z.string(),
  legalResearchTools: z.string(),
  biggestChallenge: z.string(),
  usageFrequency: z.string(),
})

export type Survey = z.infer<typeof SurveySchema>
export const SurveyResponseSchema = z.object({
  success: z.boolean(),
})
export type SurveyRequestResponse = z.infer<typeof SurveyResponseSchema>
