import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CaseStatusResponse } from '../schema/get-case-status-schema'

export type CaseStatusState = {
  loading: boolean
  error: boolean
  jobProcessing: boolean
  data: CaseStatusResponse | null
}

const defaultCaseStatusState: CaseStatusState = {
  loading: false,
  error: false,
  jobProcessing: false,
  data: null,
}

// Define a type for the slice state
interface CitatorState {
  caseStatuses: Partial<Record<string, CaseStatusState>>
}

// Define the initial state using that type
const initialState: CitatorState = {
  caseStatuses: {},
}

export const citatorSlice = createSlice({
  name: 'citatorState',
  initialState,
  reducers: {
    citatorStateAddCaseStatus: (state, action: PayloadAction<CaseStatusResponse>) => {
      const caseStatusState = state.caseStatuses[action.payload.parent_id] ?? defaultCaseStatusState
      state.caseStatuses[action.payload.parent_id] = { ...caseStatusState, data: action.payload, jobProcessing: false }
    },
    citatorStateSetProcessing: (state, action: PayloadAction<{ parent_id: string; jobProcessing: boolean }>) => {
      const caseStatusState = state.caseStatuses[action.payload.parent_id] ?? defaultCaseStatusState
      state.caseStatuses[action.payload.parent_id] = { ...caseStatusState, jobProcessing: action.payload.jobProcessing }
    },
    citatorStateSetLoading: (state, action: PayloadAction<{ parent_id: string; loading: boolean }>) => {
      const caseStatusState = state.caseStatuses[action.payload.parent_id] ?? defaultCaseStatusState
      state.caseStatuses[action.payload.parent_id] = { ...caseStatusState, loading: action.payload.loading }
    },
    citatorStateSetError: (state, action: PayloadAction<{ parent_id: string; error: boolean }>) => {
      const caseStatusState = state.caseStatuses[action.payload.parent_id] ?? defaultCaseStatusState
      state.caseStatuses[action.payload.parent_id] = { ...caseStatusState, error: action.payload.error }
    },
  },
})

// Export action reducers
export const { citatorStateAddCaseStatus, citatorStateSetProcessing, citatorStateSetLoading, citatorStateSetError } = citatorSlice.actions

export default citatorSlice.reducer
