export const kErrorCodeAuthError = 'auth-error'
export const kErrorCodeNoFilesSelected = 'no-files-selected'
export const kErrorCodeNoSourceSelected = 'no-source-selected'
export const kErrorCodeDocumentsTooLong = 'documents-too-long'
export const kErrorCode429 = '429-rate-limit'
export const kErrorCode404 = '404-not-found'
export const kErrorCode404CaseLaw = '404-not-found-case-law'
export const kErrorCode503 = '503-service-unavailable'
export const kErrorUnknown = 'unknown-error'
export const kErrorNoStream = 'no-stream'

// Failed to fetch error - this is generated if a fetch() request fails fue to network issues
const kErrorFailedToFetch = 'TypeError: Failed to fetch'

export function translateError(error: Error | string): string {
  // Ensure error is being treated as a string for comparison
  const errorMessage = error instanceof Error ? error.message : error.toString()

  switch (errorMessage) {
    case kErrorCodeAuthError:
      return 'Unable to authenticate. Please refresh or try again.'
    case kErrorCodeNoFilesSelected:
      return 'Select at least one file to continue.'
    case kErrorCodeNoSourceSelected:
      return 'Select a source to continue.'
    case kErrorCode429:
      return 'Too many requests. Your limit will reset by tomorrow.'
    case kErrorCode404:
      return 'No matches have been found.'
    case kErrorCode404CaseLaw:
      return 'No matching cases have been found. Your filters or query may be too specific.'
    case kErrorCode503:
      return 'Paxton is temporarily too busy. Please try again in a few seconds.'
    case kErrorCodeDocumentsTooLong:
      return 'The length of the documents is too long, exceeding 350k characters. Try again with subsections of the documents. We are working on expanding the capacity.'
    case kErrorNoStream:
      return 'Error connecting to response stream. Please try again.'
    case kErrorUnknown:
      return 'There has been an error processing your request. Please try again.'
    case kErrorFailedToFetch:
      return 'Error reaching Paxton. Paxton may be temporarily unavailable or a firewall may be blocking your request.'
    default:
      return 'An unexpected error has occurred. Please try again.'
  }
}
