import { useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import { Navigate } from 'react-router-dom'
import AccountPage from '@/routes/account/account-page.tsx'

const AccountPageGuard = () => {
  const { user, userAccountData } = useContext(AuthContext)
  const isOrgAccount = userAccountData?.isOrgAccount

  if (userAccountData && !isOrgAccount && !user?.isAnonymous) {
    return <AccountPage />
  }

  return <Navigate to="/dashboard" replace />
}

export default AccountPageGuard
