import { XCircleIcon } from '@heroicons/react/24/outline'
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

interface HubspotConversationsContextType {
  toggleWidget: () => void
  isOpen: boolean
  unreadMessagesCount: number
}

const HubspotConversationsContext = createContext<HubspotConversationsContextType | null>(null)

const HUBSPOT_INLINE_EMBED_ELEMENT_ID = 'hubspot-conversations-inline-embed-selector'

export const HubspotConversationsProvider = ({ children }: { children: ReactNode }) => {
  const [isReady, setIsReady] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)

  const hideWidget = useCallback(() => {
    setIsOpen(false)
  }, [])

  const showWidget = useCallback(() => {
    if (!isReady) return

    window.HubSpotConversations.widget.load()
    window.HubSpotConversations.widget.open()

    setIsOpen(true)
  }, [isReady])

  const toggleWidget = useCallback(() => {
    console.log('Toggle widget called')
    if (isOpen) {
      hideWidget()
    } else {
      showWidget()
    }
  }, [hideWidget, isOpen, showWidget])

  const onConversationsReady = useCallback(() => {
    setIsReady(true)
  }, [])

  useEffect(
    function init() {
      if (window.HubSpotConversations) {
        onConversationsReady()
      } else {
        window.hsConversationsOnReady = [onConversationsReady]
      }
    },
    [onConversationsReady]
  )

  useEffect(
    function addEventListeners() {
      if (!isReady) return

      function listener(payload: { unreadCount: number }) {
        setUnreadMessagesCount(payload.unreadCount)
      }

      window.HubSpotConversations.on('unreadConversationCountChanged', listener)

      return () => {
        window.HubSpotConversations.off('unreadConversationCountChanged', listener)
      }
    },
    [isReady]
  )

  useEffect(
    function refreshConversationsOnRouteChange() {
      if (!isReady) return

      window.HubSpotConversations.widget.refresh()
    },
    [isReady]
  )

  return (
    <HubspotConversationsContext.Provider value={{ isOpen, toggleWidget, unreadMessagesCount }}>
      {children}

      {ReactDOM.createPortal(
        <div>
          <div
            onClick={() => hideWidget()}
            className={`z-50 h-10 w-10 fixed rounded-full right-[5px] bottom-[495px] cursor-pointer bg-white ${isOpen ? 'block' : 'hidden'}`}
          >
            <XCircleIcon />
          </div>

          <div
            className={`overflow-hidden z-40 fixed bottom-5 right-5 h-[500px] w-[320px] sm:w-[376px] rounded-lg bg-white shadow-md ${
              isOpen ? 'block' : 'hidden'
            }`}
            id={HUBSPOT_INLINE_EMBED_ELEMENT_ID}
          />
        </div>,

        document.body
      )}
    </HubspotConversationsContext.Provider>
  )
}

export function useHubspotConversations() {
  const context = useContext(HubspotConversationsContext)

  if (context === null) {
    throw new Error('useHubspotConversations must be used within HubspotConversationsProvider')
  }

  return context
}
