import { useState, useEffect } from 'react'

type SidebarItemState = {
  itemId: string //Van possibly type this with an Enum. Example: SidebarItem.DraftAnalysis
  isOpen: boolean
}

enum SidebarItemId {
  DraftAnalysis = 'draft-analysis',
  Research = 'research',
  Drives = 'drives',
  Tools = 'tools',
}

/**
 * Custom hook for managing the state of sidebar items.
 * @returns An object containing the sidebar items state and a function to set the open state of a sidebar item.
 */
const useSidebarItem = () => {
  // Default state of sidebar items defined by product.
  const defaultState: SidebarItemState[] = [
    { itemId: SidebarItemId.DraftAnalysis, isOpen: true },
    { itemId: SidebarItemId.Research, isOpen: true },
    { itemId: SidebarItemId.Drives, isOpen: true },
    { itemId: SidebarItemId.Tools, isOpen: false },
  ]

  // State variables
  const [sidebarItemsState, setSidebarItemsState] = useState(defaultState)

  //Check if local storage has data - If it does, set the state to that data.
  useEffect(() => {
    try {
      const sidebarItemsStateFromLocalStorage = localStorage.getItem('sidebarItemsState')
      if (sidebarItemsStateFromLocalStorage) {
        setSidebarItemsState(JSON.parse(sidebarItemsStateFromLocalStorage))
      }
    } catch (error) {
      console.error('Failed to parse sidebar items state from localStorage:', error)
      localStorage.removeItem('sidebarItemsState')
    }
  }, [])

  /**
   * Sets the open state of a sidebar item identified by its itemId.
   * @param {string} itemId - The unique identifier of the sidebar item, usually category id. Example: 'draft-analysis'
   */
  const setSidebarItemOpen = (itemId: string) => {
    setSidebarItemsState((prevState) => {
      const newSidebarState = prevState.map((item) => {
        if (item.itemId === itemId) {
          return { ...item, isOpen: !item.isOpen }
        }
        return item
      })
      localStorage.setItem('sidebarItemsState', JSON.stringify(newSidebarState))
      return newSidebarState
    })
  }

  return {
    sidebarItemsState,
    setSidebarItemOpen,
  }
}

export default useSidebarItem
