// Environment
export const kProduction = import.meta.env.MODE === 'production'

// Default logo path
export const kDefaultLogoPath = '/images/paxton-web-600.png'
export const kHaikuLogoPath = '/images/logos/haiku-logo.png'
export const kOrganizationLogoFileName = 'organization-logo.png'

// Global Toast
export const kGlobalToastDurationMs = 10000

export enum GlobalToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

// Conversations - Pending
export const kConversationPendingPrefix = 'pen-'
export function isPendingConversationId(conversationId: string | null | undefined): boolean {
  if (!conversationId) return false

  return conversationId.startsWith(kConversationPendingPrefix)
}

// Anonymous user limit message 429
export const kAnonymousUserLimitMessage429 = 'Limit reached. To continue using Paxton, please create an account..'

// File Processing Time (estimated)
export const kFileProcessingTimeMs = 70000

// Max combined documents length
export const kMaxCombinedDocumentsLength = 350000

// Case law source sanitization allowed HTML tags
// See default options: https://www.npmjs.com/package/sanitize-html
// REMOVED:
// - 'a' (links)
export const kCaseLawSourceSanitizationAllowedTags = [
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'main',
  'ol',
  'p',
  'pre',
  'ul',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'kbd',
  'mark',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'time',
  'u',
  'var',
  'wbr',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
]

// GCS MD Allowed Tags
export const kGcsMdAllowedTags = [...kCaseLawSourceSanitizationAllowedTags]

// Docx2Html Allowed Tags
export const kDocx2HtmlAllowedTags = [...kCaseLawSourceSanitizationAllowedTags, 'title']

// LRR Source
// See default options: https://www.npmjs.com/package/sanitize-html
// REMOVED:
// - 'a' (links)
export const kLrrSourceSanitizationAllowedTags = [
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'main',
  'ol',
  'p',
  'pre',
  'ul',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'kbd',
  'mark',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'time',
  'u',
  'var',
  'wbr',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
]

// Document Editing Markdown -> HTML Allowed HTML Tags
// See default options: https://www.npmjs.com/package/sanitize-html
// REMOVED:
// - 'a' (links)
export const kDocumentEditingAllowedTags = [
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'button',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'main',
  'ol',
  'p',
  'pre',
  'ul',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'kbd',
  'mark',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'time',
  'u',
  'var',
  'wbr',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'font',
]
