import { ConfidenceIndicatorDotStyleDictionary } from '../models/confidence-indicator-style'

/**
 * Convert confidence label to color
 * Returns a tailwind CSS color that can be utilized inside tailwind CSS classes
 * Defaults to gray
 * @param confidence_label
 * @returns
 */
export function confidenceLabelToDotStyle(confidence_label: string | null, sizePixels = 12, isHovered: boolean): React.CSSProperties {
  // Common styles to all colors
  const commonStyles: React.CSSProperties = {
    borderRadius: '100%',
    width: `${sizePixels}px`,
    height: `${sizePixels}px`,
    transition: 'all 0.5s ease-in-out',
  }

  // State specific styles
  const confidenceIndicatorDotStyles: ConfidenceIndicatorDotStyleDictionary = {
    loading: {
      ...commonStyles,
      border: '1px solid rgba(2, 132, 199, 0.9)',
      background: isHovered ? 'linear-gradient(145deg, #38bdf8, #0369a1)' : 'linear-gradient(145deg, #38bdf8, #0c4a6e)',
    },
    unknown: {
      ...commonStyles,
      border: '1px solid rgba(156, 163, 175, 0.9)',
      background: isHovered ? 'linear-gradient(145deg, #e5e7eb, #9ca3af)' : 'linear-gradient(145deg, #e5e7eb, #6b7280)',
    },
    low: {
      ...commonStyles,
      border: '1px solid rgba(220, 38, 38, 0.9)',
      background: isHovered ? 'linear-gradient(145deg, #fa9898, #b91c1c)' : 'linear-gradient(145deg, #fa9898, #991b1b)',
    },
    medium: {
      ...commonStyles,
      border: '1px solid rgba(234, 179, 8, 0.9)',
      background: isHovered ? 'linear-gradient(145deg, #fef08a, #facc15)' : 'linear-gradient(145deg, #fef08a, #eab308)',
    },
    high: {
      ...commonStyles,
      border: '1px solid rgba(22, 163, 74, 0.9)',
      background: isHovered ? 'linear-gradient(145deg, #86efac, #15803d)' : 'linear-gradient(145deg, #86efac, #166534)',
    },
  }

  return confidenceIndicatorDotStyles[confidence_label ?? 'unknown'] ?? confidenceIndicatorDotStyles.unknown
}
