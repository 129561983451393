// Chat Interface Events
export const kSegmentTrackQuerySubmit = 'Query Submitted - Laws Regs'
export const kSegmentTrackFollowUpQuestionClick = 'Follow Up Question Clicked - Laws Regs'
export const kSegmentTrackFootnoteClick = 'Footnote Clicked'
export const kSegmentTrackSelectFileForQueryClicked = 'Select Files For Query Clicked'
export const kSegmentTrackFileUploadQuerySubmitted = 'Query Submitted - Your Files'
export const kSegmentTrackFileUploadFollowUpQuestionClick = 'Follow Up Question Clicked - Your Files'

// Audit Interface Events
export const kSegmentTrackAuditQuerySubmit = 'Audit Query Submitted'

// Boolean Composer  Events
export const kSegmentTrackBooleanComposerQuerySubmit = 'Boolean Composer Query Submitted'
export const kSegmentTrackBooleanComposerCopySearchString = 'Boolean Composer Copy Search String'

// Case Law Events
export const kSegmentTrackCaseLawQuerySubmit = 'Case Law Query Submitted'

// Drafting events
export const kSegmentTrackDraftingQuerySubmit = 'Drafting Query Submitted'

// Web search events
export const kSegmentTrackWebSearchSubmit = 'Web Search Submitted'

// File Upload Events
export const kSegmentTrackFileUploadStarted = 'File Upload Started'
export const kSegmentTrackFolderCreation = 'Folder Created'
export const kSegmentTrackLogoChanged = 'Logo Changed'

// Auth Events
export const kSegmentTrackSignIn = 'Sign In'
export const kSegmentTrackSignUp = 'Sign Up'
export const kSegmentTrackSignOut = 'Sign Out'
export const kSegmentTrackSocialSignInSignUp = 'Social Sign In / Sign Up'

// Misc Events
export const kSegmentTrackOpenSupportChatClicked = 'Open Support Chat Clicked'
export const kSegmentTrackAccessCodeSubmit = 'Access Code Submitted'
