import { useRef } from 'react'
import loadMissingConversationIntoState from '@/chat-common/fetch/fetch-missing-conversation'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import ChatWindow from '@/chat-common/components/chat-window'
import ErrorComponent, { ErrorComponentProps } from '@/components/error/error-component'
import { useAppSelector } from '@/store/store-hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReferenceViewerColumn from '@/chat-common/components/reference-viewer/reference-viewer-column'
import ReferenceViewerSlideover from '@/chat-common/components/reference-viewer/reference-viewer-slideover'
import { RootState } from '@/store/store'
import debounce from 'lodash.debounce'
import { isPendingConversationId } from '@/constants/constants-ui'

// export const ReferenceColumnWidthContext = React.createContext<number>(0)

export default function ChatV2Page() {
  const { chatFeature, chatId } = useParams()
  const navigate = useNavigate()

  // Check if this is a valid feature
  const validFeature: boolean = Object.values(ChatV2Feature).includes(chatFeature as ChatV2Feature)

  // Watch this conversation in the state for changes
  const thisConversation = useAppSelector((state: RootState) => (chatId ? state.chatV2State.conversations[chatId] : null))

  // Whether this is a pending conversation
  const isPendingConversation = isPendingConversationId(chatId)

  // Watch for a new conversation triggers in the state (will trigger some UX actions / redirects)
  const newConversationTrigger = useAppSelector((state: RootState) => state.chatV2State.newConversationIdUXTrigger)

  // Conversation load error details
  const [conversationLoadError, setConversationLoadError] = useState<ErrorComponentProps | null>(null)
  const [conversationLoading, setConversationLoading] = useState<boolean>(false)

  // Column width ref
  const referenceColumnRef = useRef<HTMLDivElement | null>(null)

  // Track breakpoint for source viewer renderer format (column or slideover)
  const [isLargeScreen, setIsLargeScreen] = useState(false)
  const largeScreenWidthBreakpoint = 940

  // SCREEN RESIZE HANDLER
  // Functions that should run every time the screen size changes
  // Debounced to reduce the number of calls on resize
  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      // Set the screen size state
      setIsLargeScreen(window.innerWidth >= largeScreenWidthBreakpoint)
    }, 300)

    window.addEventListener('resize', debouncedHandleResize)

    debouncedHandleResize() // Initial calculation

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      debouncedHandleResize.cancel() // Cancel any pending debounced calls
    }
  }, [])

  /**
   * REDIRECT
   * - Pending Conversation After Refresh
   *
   * After refresh, the pending conversation id will only exist inside the URL.
   * IF:
   * - There is a pending conversation id in the url
   * - AND no pending conversation exists in state
   * THEN: redirect to remove the pending conversation id from the url
   */
  useEffect(() => {
    if (!chatId || !isPendingConversation) return
    if (!validFeature || !chatFeature) return

    // If it exists in state, return early because we don't need to redirect because the user just created it (not post refresh)
    if (thisConversation) return

    // Else, it's not in state.
    // Check if a pending conversation id is in the url and redirect
    if (isPendingConversation) {
      console.log('Redirecting from stale pending conversation')
      navigate(`/dashboard/chat/${chatFeature}`)
    }
  })

  /**
   * REDIRECT
   * - Redirect from viewed pending conversation to new received conversation
   * - For when the server returns the official conversation and ID for what we started as a pending conversation client-side
   *
   * When a new conversation is detected in state...
   * - IF the new conversation is for this feature
   * - IF the new conversation has a matching fromPendingId value of the current screen
   * - THEN: Navigate to the new conversation id
   */
  useEffect(() => {
    if (!newConversationTrigger || !chatId || !validFeature) return
    console.log('New conversation detected.', newConversationTrigger.id)

    // Check if the new conversation is for this feature
    if (newConversationTrigger.feature !== chatFeature) {
      // console.log(`New conversation is not for this feature. ${newConversation.feature} !== ${chatFeature}`)
      return
    }

    // Check if the new conversation has a matching fromPendingId value of the current pending conversation
    if (newConversationTrigger.fromPendingId === chatId) {
      // console.log(`New conversation has matching fromPendingId value. Redirecting from ${chatId} to ${newConversationTrigger.id}`)
      navigate(`/dashboard/chat/${chatFeature}/${newConversationTrigger.id}`)
    }
  }, [navigate, newConversationTrigger])

  /**
   * Chat ID Change
   *
   * Load chat messages:
   * - If the chatId is defined
   * - If we don't have any messages for this chat in our state slice, load them
   */
  useEffect(() => {
    // Return early if either of these are undefined
    if (!chatId) return
    if (!validFeature || !chatFeature) return

    // Reset the error
    setConversationLoadError(null)

    // Load the conversation messages if we don't have them in state
    loadMissingConversationIntoState(
      chatId,
      chatFeature,
      (errorProps: ErrorComponentProps) => {
        setConversationLoadError(errorProps)
      },
      (isLoading: boolean) => {
        setConversationLoading(isLoading)
      }
    )
  }, [chatId])

  // Ensure chatFeature mates our feature enums and show 404 error if it does not
  if (!validFeature) {
    console.error(`Chat feature ${chatFeature} is not a valid feature.`)

    // Return 404 error component
    return <ErrorComponent code={'404'} title={'Not Found'} message={'Please ensure you have selected an existing chat feature.'} />
  }

  // Conversation
  const visibleReference = thisConversation?.visibleReference ?? null
  const showReference: boolean = thisConversation != null && visibleReference != null

  return (
    <>
      {conversationLoadError && <ErrorComponent {...conversationLoadError} />}

      {/* Display 1 or 2 columns depending on whether showReference is true and screen size */}
      {!conversationLoadError && (
        <div className={`flex flex-grow gap-x-2`}>
          <div className={'w-1/2 mt-12 lg:mt-0 mx-2 lg:mx-0 flex flex-grow justify-center'}>
            <ChatWindow conversationMessagesLoading={conversationLoading} />
          </div>

          {/* Source view as second column - large screens */}
          {thisConversation && showReference && isLargeScreen && (
            <div ref={referenceColumnRef} className={'w-1/2 mt-12 lg:mt-0 flex flex-grow overflow-hidden'}>
              <ReferenceViewerColumn conversation={thisConversation} />
            </div>
          )}

          {/* Source view as slideover - small screens */}
          {thisConversation && !isLargeScreen && <ReferenceViewerSlideover open={showReference} conversation={thisConversation} />}
        </div>
      )}
    </>
  )
}
