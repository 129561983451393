import { CircularProgress, ThemeProvider, createTheme } from '@mui/material'

// A spinning loader designed to fit into a tailwind ui button
interface ButtonLoaderOption {
  loading: boolean
}

export const buttonLoaderTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
})

export const ButtonLoader = ({ loading }: ButtonLoaderOption) => {
  if (loading) {
    return (
      <div role="status" className="ml-2 inline-block align-middle">
        <ThemeProvider theme={buttonLoaderTheme}>
          <CircularProgress size={14} thickness={7} />
        </ThemeProvider>
      </div>
    )
  } else {
    return null
  }
}
