import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * THIS IS ESSENTIALLY A CLONE OF THE DOCUMENT INTERFACE STREAM RESPONSE SLICE
 * IT CONTAINS UNNESSARY AND UNUSED TYPE DEFINITIONS FOR THE DOCUMENT AUDIT STREAM RESPONSE
 */

// Define a type for the slice state
type BooleanBuilderStreamResponse = {
  sources: BooleanBuilderSource[]
  searchedQuery: string
  streamedResponseString: string
  responseUniqueFootnotes: number[]
  responseHighlightedFootnote: number
  streamLoading: boolean
  streamError: string
}

export type BooleanBuilderSource = {
  id: number
  metadata: Record<string, any>
  text: string
  footnoteNumber: number
}

// Define the initial state using that type
const initialState: BooleanBuilderStreamResponse = {
  sources: [],
  searchedQuery: '',
  streamedResponseString: '',
  responseUniqueFootnotes: [],
  responseHighlightedFootnote: 0,
  streamLoading: false,
  streamError: '',
}

export const booleanBuilderStreamResponseSlice = createSlice({
  name: 'booleanBuilderStreamResponse',
  initialState,
  reducers: {
    nullifyData: () => initialState,
    addSources: (state, action: PayloadAction<BooleanBuilderSource>) => {
      // For each source, add a footnote number
      action.payload.footnoteNumber = state.sources.length + 1
      state.sources.push(action.payload)
    },
    setSearchedQuery: (state, action: PayloadAction<string>) => {
      state.searchedQuery = action.payload
    },
    // Add the newly received payload data to the existing string in state
    addToStreamedResponseString: (state, action: PayloadAction<string>) => {
      state.streamedResponseString += action.payload

      // Add any new footnotes to the set of unique footnotes
      const regex = /\[(\d+)\]/g
      let matches = new Set<number>()
      let match
      while ((match = regex.exec(state.streamedResponseString)) !== null) {
        // Avoid infinite loops
        if (match[0].length === 0) {
          regex.lastIndex++
        }
        matches.add(parseInt(match[1]))
        state.responseUniqueFootnotes = Array.from(matches)
      }
    },
    setResponseHighlightedFootnote: (state, action: PayloadAction<number>) => {
      state.responseHighlightedFootnote = action.payload
    },
    setStreamLoading: (state, action: PayloadAction<boolean>) => {
      state.streamLoading = action.payload
    },
    setStreamError: (state, action: PayloadAction<string>) => {
      state.streamError = action.payload
    },
  },
})

export const { nullifyData, addSources, addToStreamedResponseString, setStreamLoading, setStreamError, setResponseHighlightedFootnote, setSearchedQuery } =
  booleanBuilderStreamResponseSlice.actions

export default booleanBuilderStreamResponseSlice.reducer
