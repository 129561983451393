import { kPaymentsPreAuthorizationPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/react'

export type createHoldPaymentRequest = {
  setupIntentID: string
  userAgent: string
}

type createHoldPaymentResponse = {
  payment_intent_id: string
  success: boolean
  status: string
  error?: boolean
  errorDetail?: string
}

export default async function createHoldPayment({
  setupIntentID: setup_intent_id,
  userAgent: user_agent,
}: createHoldPaymentRequest): Promise<createHoldPaymentResponse> {
  const transactionID = nanoid()

  console.log('POST Stripe Pre-Authorization Request')
  const token = await getAuth().currentUser?.getIdToken(true)
  if (!token) throw Error(kErrorCodeAuthError)
  // Construct request
  const requestOptions: RequestInit = {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-Transaction-Id': transactionID,
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify({
      setup_intent_id,
      user_agent,
    }),
  }

  const apiUrl = kPaxtonAppApiBaseUrl() + `/${kPaymentsPreAuthorizationPath}`
  const response = await fetch(apiUrl, requestOptions)
  const data = await response.json()
  if (data.success == false || data.error != null || data.error != undefined || !response.ok) {
    if (data.error != null && data.error != undefined && data.error != '') {
      throw new Error(data.error)
    } else {
      Sentry.captureException(new Error(`Customer checkout: Hold payment creation failed.`), {
        extra: {
          holdPaymentData: data,
        },
      })
      throw new Error('Failed to create pre-authorization.', data)
    }
  }
  return data
}
