import { z } from 'zod'

// Case Metadata Response Schema
export const CaselawReferenceMetadataResponseSchema = z.object({
  html: z.string().nullable().optional(),
  title: z.string(),
  citation: z.string().nullable().optional(),
  decide_date: z.string().nullable().optional(), // Consider using a date type if appropriate
  bb_citation: z.string().optional().nullable().describe('The Bluebook citation string for the document (optional).'),
  jurisdiction_code: z.string().nullable().optional(),
  parent_id: z.string(), // Or consider z.number() if parent_id is a numeric identifier
})
export type CaselawReferenceMetadataResponse = z.infer<typeof CaselawReferenceMetadataResponseSchema>

// Case HTML Response Schema
export const CaselawReferenceHtmlResponseSchema = z.object({
  data: z.string(),
})
export type CaselawReferenceHtmlResponse = z.infer<typeof CaselawReferenceHtmlResponseSchema>
