import { ReactNode, useContext, useEffect } from 'react'
import TestimonialPlusSignup from '@/routes/dashboard/subscribe/TestimonialPlusSignup'
import FullPageLoader from '@/components/loaders/FullPageLoader'
import { AuthContext } from '@/context/auth-context'
import OrgAccessDeniedPage from './OrgAccessDeniedPage'
import TestimonialPlusResignup from '@/routes/dashboard/subscribe/TestimonialPlusResignup'
import { isPaxtonBrand } from '@/util/enterprise'
import { useFetchTrialDaysQuery, useFetchCheckoutPricesQuery } from '@/routes/dashboard/subscribe/store/subscription.slice'

const isPaxton = isPaxtonBrand()

/**
 * Subscription Guard
 * Guards the app from use by users who have not subscribed after their trial period.
 */
type SubscriptionGuardProps = {
  children: ReactNode
}

export default function SubscriptionGuard(props: SubscriptionGuardProps) {
  const { children } = props
  const { user, userAccountData } = useContext(AuthContext)
  const { isLoading: isLoadingTrialDays, refetch: refetchTrialDays } = useFetchTrialDaysQuery()
  const { isLoading: isLoadingCheckoutPrices, refetch: refetchCheckoutPrices } = useFetchCheckoutPricesQuery(undefined, {
    skip: !user,
  })

  useEffect(() => {
    if (user) {
      refetchTrialDays()
      refetchCheckoutPrices()
    }
  }, [userAccountData, refetchTrialDays, refetchCheckoutPrices])

  // Conditional Rendering Based On User And Subscription Status
  switch (true) {
    // Anonymous Users - No Guard
    case user?.isAnonymous == true:
      return children

    // Valid Subscription - No Guard
    case userAccountData?.validSubscription:
      return children

    case isLoadingTrialDays || isLoadingCheckoutPrices:
      return <FullPageLoader />

    // Pending Subscription - Show Loader
    case userAccountData?.pendingSubscription:
      return <FullPageLoader />

    // Invalid Subscription - Individual - Show Paywall (Paxton Only)
    case userAccountData?.invalidSubscription && isPaxton:
      return <TestimonialPlusResignup />

    // Pending Payment (Paxton Only)
    case userAccountData?.pendingPaymentMethod && isPaxton:
      return <TestimonialPlusSignup />

    // Invalid Subscription - Organization
    case userAccountData?.invalidSubscription && userAccountData?.isOrgAccount:
      return <OrgAccessDeniedPage />

    // null user
    case !user:
      return <FullPageLoader />

    // All other cases
    default:
      console.error('Invalid rendering condition for subscription guard: ', user, userAccountData)
      return children
  }
}
