import { UploadTaskStatus } from './FilesDropZone'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { CircularProgressDeterminate } from '@/components/loaders/CircularProgressDeterminate'
import { useEffect, useRef } from 'react'

interface FileUploadStatusPopoverProps {
  expanded: boolean
  tasks: Map<string, UploadTaskStatus>
  setExpanded: (expanded: boolean) => void
}

/**
 * FileUploadStatusPopover - modified from tailwindcss-radix boilerplate
 * https://github.com/ecklf/tailwindcss-radix/blob/main/demo/components/popover.tsx
 * @param props
 * @returns
 */
const FileUploadStatusPopover = (props: FileUploadStatusPopoverProps) => {
  const { expanded, tasks, setExpanded } = props

  // Scroll to the bottom of the list when tasks update
  const scrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scrollRef.current) {
      const { scrollHeight } = scrollRef.current
      scrollRef.current.scrollTo({
        top: scrollHeight,
        behavior: 'smooth', // Smoothly scroll
      })
    }
  }, [tasks])

  // Create an array of status components
  const statusComponents: JSX.Element[] = []
  for (const [key, value] of tasks) {
    statusComponents.push(
      <div key={key} className={'mb-4 grid grid-cols-[auto_30px] items-center'}>
        <div className={'inline-block align-middle text-sm overflow-hidden'}>
          <p>{value.uploadableFile.fileName}</p>
          <p className={'text-red-600 text-xs'}>{value.errorMessage.length == 0 ? '' : value.errorMessage}</p>
        </div>
        <div className={'inline-block align-middle'}>
          <CircularProgressDeterminate value={value.progress} error={value.errorMessage.length > 0 ? true : false} />
        </div>
      </div>
    )
  }

  // Count number of completed tasks
  let completedTasks = 0
  for (const [key, value] of tasks) {
    if (key && value.complete) {
      completedTasks++
    }
  }

  return (
    <div className={`fixed bottom-0 right-6 inline-block text-left w-96`}>
      <div className={'overflow-hidden rounded-t-lg bg-white shadow'}>
        <div className="mb-4">
          <div className="px-5 pt-5 flex justify-between cursor-pointer" onClick={() => setExpanded(!expanded)}>
            <div>{completedTasks} uploads complete</div>
            <div>{expanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</div>
          </div>
          <hr className={(expanded ? ' h-px mt-4' : ' h-0') + ' bg-gray-200 border-0 dark:bg-gray-700'}></hr>

          <div className={'overflow-y-scroll  transition-all' + (expanded ? ' h-96' : ' h-0')} ref={scrollRef}>
            <div className={'px-5 py-5'}>{statusComponents}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { FileUploadStatusPopover }
