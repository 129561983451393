import { getOrEnqueueCaseStatus } from '@/citator/repository/get-case-status'
import { CaseStatusState } from '@/citator/store/citator-state.slice'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { ArrowPathIcon, BoltIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, ExternalLinkIcon } from '@radix-ui/react-icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export type CitatorImportantCasesProps = {
  parentId: string
  caseStatusState: CaseStatusState | undefined
  filterQuery: string
}

export default function CitatorImportantCases(props: CitatorImportantCasesProps) {
  const { parentId, caseStatusState, filterQuery } = props
  const isLoading = caseStatusState?.loading
  const hasError = caseStatusState?.error
  const isProcessing = caseStatusState?.jobProcessing
  const importantCases = caseStatusState?.data?.important_cases

  // Local state
  const [expanded, setExpanded] = useState<boolean>(false)

  // Filter the cases by name
  const filteredData = importantCases?.filter((item) => item.title.toLowerCase().includes(filterQuery.toLowerCase())) ?? []

  return (
    <div className={''}>
      <button
        className={'w-full flex px-2 items-center justify-between gap-x-2 rounded-md hover:bg-gray-100 transition-all ease-in-out duration-300'}
        onClick={() => setExpanded(!expanded)}
      >
        <div className={'font-bold text-sm text-left flex gap-x-2'}>
          <div>Important Cases</div>
          {importantCases && importantCases.length > 0 && <div>({importantCases.length ?? 0})</div>}
          {isLoading && (
            <div className={'px-1'}>
              <CircularProgressContinuousSized size={12} thickness={9} />
            </div>
          )}
        </div>
        {importantCases && importantCases.length > 0 && (
          <div className={`${expanded ? '-rotate-0' : '-rotate-90'} transition-all ease-in-out duration-300`}>
            <ChevronDownIcon width="24" height="24" />
          </div>
        )}
      </button>

      <div className={'px-2'}>
        {isProcessing && (
          <>
            <div className="rounded-lg bg-sky-50 px-4 py-3 mt-2 grid grid-cols-[55px_auto] items-start">
              <div className={'flex text-sm h-10 w-10 mt-1 font-bold text-sky-900 bg-sky-100 rounded-lg p-[6px] ring-4 ring-white items-center justify-center'}>
                <BoltIcon />
              </div>
              <div className={'text-sm text-sky-900'}>
                <span className={'font-bold'}>Analysis in progress</span>
                <br />
                The citator analysis can take some time. We will email you a link to the results when they are ready.
              </div>
            </div>
            <button
              className={
                'flex gap-x-2 items-center rounded-md bg-sky-600 border-[1px] border-sky-600 px-2 py-1 my-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              }
              onClick={() => {
                // Fetch case status
                getOrEnqueueCaseStatus(parentId)
              }}
            >
              <div>Refresh</div>{' '}
              <div className={'w-5 h-5'}>
                <ArrowPathIcon />
              </div>
            </button>
          </>
        )}

        {hasError && (
          <div className={''}>
            <div className={'text-red-700 text-sm'}>Error: could not retrieve important cases.</div>
            <button
              className={
                'flex-auto items-center rounded-md bg-sky-600 border-[1px] border-sky-600 px-2 py-1 my-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500'
              }
              onClick={() => {
                // Fetch case status
                getOrEnqueueCaseStatus(parentId)
              }}
            >
              Retry
            </button>
          </div>
        )}

        {!importantCases || (importantCases.length === 0 && <div className={' text-sm'}>No important cases found.</div>)}

        {importantCases && importantCases.length > 0 && filteredData.length === 0 && (
          <div className={''}>
            <div className={'text-sm'}>No search results.</div>
          </div>
        )}

        {/* List of important cases */}
        {importantCases && (
          <ol className={`list-decimal pl-6 transition-all ease-in-out duration-300 overflow-hidden ${expanded ? '' : 'max-h-28'}`}>
            {filteredData.map((item, index) => {
              return (
                <li className={'marker:font-bold mb-5 text-sm'} key={`${item.parent_id}-${index}`}>
                  <Link
                    to={`/dashboard/reference-viewer/caselaw/${item.parent_id}`}
                    target="_blank"
                    className={'flex items-end hover:text-sky-700 active:bg-sky-100'}
                  >
                    <span className={'inline text-sm font-bold text-sky-700 hover:text-sky-600 underline leading-tight'}>
                      {item.title}
                      <ExternalLinkIcon className={'inline w-4 h-4 pl-[4px] pb-[0px]'} />
                    </span>
                  </Link>
                  <div className={'mb-2'}>
                    {item.citation}, {item.jurisdiction}
                  </div>
                  <div className={'font-bold'}>Review: {item.review_label.replaceAll('_', ' ')}</div>
                  <div className={'whitespace-pre-wrap'}>{item.explanation}</div>
                </li>
              )
            })}
          </ol>
        )}

        {/* White blur */}
        {importantCases && filteredData.length > 0 && (
          <div className={'relative z-10 -mt-6 mb-3 w-full h-6 bg-gradient-to-b from-transparent to-white text-center text-xs'}>
            <button className={'mt-6 text-sky-600 underline font-bold rounded-lg px-[4px] py-[1px]'} onClick={() => setExpanded(!expanded)}>
              {expanded ? 'collapse' : 'expand'}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
