import { ConfidenceIndicatorLanguageDictionary } from '../models/confidence-indicator-language'
import { getBrandName } from '@/util/enterprise'

export const confidenceIndicatorLanguageMap: ConfidenceIndicatorLanguageDictionary = {
  unknown: {
    componentLabel: 'Unknown',
    popupTitle: 'Unknown Confidence',
    popupDescription: 'The confidence level for this response is unavailable.',
    popupTips: [],
  },
  low: {
    componentLabel: 'Low',
    popupTitle: 'Low Confidence',
    popupDescription: `${getBrandName()} has low confidence with this response. This can occur for a number of reasons including ambiguous language or insufficient context in the query, asking complex, multi-part questions, or lack of available reference data. Consider rephrasing your query to generate a higher-confidence response.`,
    popupTips: [
      'Ask one question per request.',
      'Be precise. Avoid vague and broad language.',
      'Provide more context and intent.',
      'Specify the type of response you are looking for.',
    ],
  },
  medium: {
    componentLabel: 'Medium',
    popupTitle: 'Medium Confidence',
    popupDescription: `${getBrandName()} has medium confidence about the accuracy and relevance of this response. The query may have used ambiguous language or insufficient context. It is also possible that the query is focused on a particularly novel or complex area of law lacking definitive answers. Exercise caution with medium-confidence responses.`,
    popupTips: [
      'Look for inconsistencies, unstated assumptions, and where clarification might be needed.',
      'Consider whether you asked a question on a novel or complex area of law.',
      'Include additional context or sources for reference.',
      'Split complex requests into multiple simpler requests.',
      'Ensure the type of response you are looking for is clear.',
    ],
  },
  high: {
    componentLabel: 'High',
    popupTitle: 'High Confidence',
    popupDescription: `${getBrandName()} has high confidence with this response. While high-confidence answers indicate the highest level of reliability, we encourage users to check the sources cited to ensure the quality and accuracy of the response.`,
    popupTips: [
      'If the topic is complex or has significant implications, seek additional perspectives.',
      'Assess the sources of the information and consider including additional sources.',
      'Incomplete reference data can sometimes increase confidence.',
    ],
  },
}
