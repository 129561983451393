import { visit } from 'unist-util-visit'
import { Plugin } from 'unified'
import { Element, Root } from 'hast'

interface PluginInput {
  tagName: string
  newTagName: string
}

/**
 * Rehype Tag Name Change
 * For the provided array of from and to tags, change the tag name in the rehype tree
 * @param input
 * @returns
 */
const rehypeTagNameChange: Plugin<[PluginInput[]], Root> = (input) => {
  return (tree) => {
    visit(tree, 'element', (node: Element) => {
      // For each input, change the tag name
      input.forEach((input) => {
        if (node.tagName === input.tagName) {
          // console.log(`Found a <${input.fromTag}> element: `, node)
          node.tagName = input.newTagName
        }
      })
    })
  }
}

export default rehypeTagNameChange

// Create a boiler rehype plugin
