import { ChatV2Conversation, ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { Source } from '@mui/icons-material'
import DraftingChatDialog from '../source-dialogs/drafting-chat-dialog'
import { useState } from 'react'
import { ChatV2QueryMetadataDrafting } from '@/chat-common/schemas/chat-query-metadata-schema'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'

type ChatControlsDraftingChatProps = {
  conversation: ChatV2Conversation
}

export default function ChatControlsDraftingChat(props: ChatControlsDraftingChatProps) {
  const { conversation } = props
  const { trackEvent } = useAnalytics()

  // Local state for source window status
  const [sourceWindowOpen, setSourceWindowOpen] = useState<boolean>(false)

  if (!conversation) return null

  // Source information from the conversation
  const currentSource = conversation.currentSource as ChatV2QueryMetadataDrafting | null
  const sourceFieldsCount = currentSource != null ? Object.values(currentSource).filter((value) => value?.length > 0).length : 0

  return (
    <>
      <div className="mb-0 pb-0">
        <div className="rounded-lg bg-yellow-50 p-4 my-4 grid grid-cols-[45px_auto] items-center">
          <div className={'flex h-7 w-7 text-yellow-700 bg-yellow-50 rounded-lg p-1 ring-4 ring-white items-center justify-center'}>
            <ExclamationTriangleIcon />
          </div>
          <span className={'text-xs text-yellow-900 text-opacity-70'}>
            This tool does not have access to external research materials.
            <br />
            Utilize Paxton's research tools for information on laws, regulations, court cases, etc.
          </span>
        </div>
      </div>
      <DraftingChatDialog conversation={conversation} open={sourceWindowOpen} onClose={(value) => setSourceWindowOpen(value)} />
      <button
        onClick={() => {
          trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.drafting })
          setSourceWindowOpen(true)
        }}
        className="w-auto rounded-md text-sm px-0 font-semibold text-sky-600 hover:underline"
      >
        Configure additional document settings (optional) <Source fontSize="small" />
      </button>
      <div className={'mt-1'}>
        <p className="text-sm text-gray-500">{sourceFieldsCount > 0 ? `(${sourceFieldsCount} setting${sourceFieldsCount > 1 ? 's' : ''} present)` : ''}</p>
      </div>
    </>
  )
}
