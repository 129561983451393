import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import OnboardingProgress from '../components/OnboardingProgress'
import OnboardingHeader from '../components/OnboardingHeader'
import OnboardingWelcome from '../components/OnboardingWelcome'
import OnboardingAccountSetup from '../components/OnboardingAccountSetup'
import OnboardingWrapup from '../components/OnboardingWrapup'

const Onboarding: React.FC = () => {
  const { steps, currentStep } = useAppSelector((state: RootState) => state.onboardingState)
  const currentTitle = steps[currentStep - 1].stageLabel
  const currentDescription = steps[currentStep - 1].description
  return (
    <div className="container mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white rounded-lg shadow-lg m-4 p-8 flex flex-grow flex-col space-y-8">
      <OnboardingProgress steps={steps} currentStep={currentStep} />
      <OnboardingHeader title={currentTitle} description={currentDescription} />
      <OnboardingWelcome visible={currentStep === 1} />
      <OnboardingAccountSetup visible={currentStep === 2} />
      <OnboardingWrapup visible={currentStep === 3} />
    </div>
  )
}

export default Onboarding
