import { store } from '@/store/store'
import { chatV2Api } from '@/chat-common/chat-v2-api'
import { paxtonAppApi } from '@/store/apis/paxton-app-api'
import { nullifyData as booleanNullify } from '@/store/slices/boolean-builder-stream-response.slice'
import { nullifyData as auditNullify } from '@/store/slices/document-audit-stream-response.slice'
import { nullifyData as chatUxNullify } from '@/chat-common/store/chat-v2-ux.slice'
import { nullifyData as chatNullify } from '@/chat-common/store/chat-v2.slice'
import { nullifyData as subscriptionNullify } from '@/routes/dashboard/subscribe/store/subscription.slice'
import { getAuth, signOut } from 'firebase/auth'
import { NavigateFunction } from 'react-router'

/**
 * Sign Out Of App
 * Calls the firebase authentication sign out function
 */
export default async function signOutOfApp(navigate?: NavigateFunction) {
  const auth = getAuth()

  // Clear global states
  // Legacy features
  store.dispatch(auditNullify())
  store.dispatch(booleanNullify())

  // Chat
  store.dispatch(chatNullify())
  store.dispatch(chatUxNullify())

  // Conversation list
  store.dispatch(chatV2Api.util.resetApiState())

  // Subscription
  store.dispatch(subscriptionNullify())

  // Suggested questions
  store.dispatch(paxtonAppApi.util.resetApiState())

  // Navigate to dashboard
  if (navigate) navigate('/dashboard')

  // Firebase sign out
  await signOut(auth)
}
