import { ChatV2QueryMetadataLrrV2 } from '@/chat-common/schemas/chat-query-metadata-schema'
import { chatV2CurrentSourceLrrPathsAdd, chatV2CurrentSourceLrrPathsRemove } from '@/chat-common/store/chat-v2.slice'
import { LRRDrillDownItem } from '@/constants/constants-data-lrr-drilldown'
import { LrrSourceSource } from '@/store/apis/hosted-filters-api'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useState } from 'react'

type LrrSourceDialogDrillDownProps = {
  source: LrrSourceSource
  conversationId: string
}

export default function LrrSourceDialogPathDrillDown(props: LrrSourceDialogDrillDownProps) {
  const { source, conversationId } = props

  // Store
  const dispatch = useAppDispatch()
  const conversationState = useAppSelector((state: RootState) => state.chatV2State)
  const conversation = conversationState.conversations[conversationId] ?? null
  const currentSource = conversation?.currentSource as ChatV2QueryMetadataLrrV2 | null
  const currentSourceSelections = currentSource?.lrr_selections ?? []

  const thisSourceSelection = currentSourceSelections.find((selection) => selection.source_name === source.value) ?? null
  const selectedDrillDownPaths = thisSourceSelection?.paths ?? []

  // Local State
  const [showDrillDown, setShowDrillDown] = useState(selectedDrillDownPaths.length > 0)
  const [query, setQuery] = useState('')

  // Inferred
  const children = source.children as LRRDrillDownItem[]

  // Recursively render the children, each with additional padding to the left
  // Use styling to always have rendered, and CSS to show hide to improve perceived performance
  const renderChildren = (children: LRRDrillDownItem[], nestLevel: number, matchFutureLevels: boolean) => {
    return children.map((thisChild, index) => {
      // Does this item have children?
      const childHasChildren = thisChild.children.length > 0

      // Does this child match the query?
      const childQueryMatch = thisChild.label.toLowerCase().includes(query.toLowerCase())

      // This element is checked if:
      // - It is checked AND all of its children are checked
      // OR
      // - It is checked AND It has no children
      const selfChecked = selectedDrillDownPaths.includes(thisChild.path)
      const childrenChecked = childHasChildren ? thisChild.children.every((child) => selectedDrillDownPaths.includes(child.path)) : false
      const checked = childHasChildren ? selfChecked && childrenChecked : selfChecked

      // Construct parent path for conditional use below - isolate the path by trimming the last segment of this child
      const parentPath = thisChild.path.substring(0, thisChild.path.lastIndexOf('/'))

      return (
        <div key={thisChild.path + `-${nestLevel}-${index}`} className={`${showDrillDown ? 'block' : 'hidden'}`}>
          <div className={`border-l-2 ml-[11px] pl-[11px]`}>
            <div key={index} className={`pl-1 pb-3 items-center flex gap-x-3 ${childQueryMatch || matchFutureLevels ? 'block' : 'hidden'}`}>
              <input
                id={thisChild.label}
                type="checkbox"
                checked={checked}
                // On change, or remove from the selected drill down paths based on new checkbox value
                onChange={(event) => {
                  // console.log(`Toggled path ${thisChild.path} to ${event.target.checked}`)

                  if (event.target.checked) {
                    // If it has children, check itself and check all children
                    if (childHasChildren) {
                      dispatch(chatV2CurrentSourceLrrPathsAdd({ conversationId, source_name: source.value, path: thisChild.path }))
                      for (const nextLayerChild of thisChild.children) {
                        dispatch(chatV2CurrentSourceLrrPathsAdd({ conversationId, source_name: source.value, path: nextLayerChild.path }))
                      }
                    } else {
                      // just check itself
                      dispatch(chatV2CurrentSourceLrrPathsAdd({ conversationId, source_name: source.value, path: thisChild.path }))
                    }
                  } else {
                    // If it has children, remove itself and remove all children, and remove its parent
                    if (childHasChildren) {
                      dispatch(chatV2CurrentSourceLrrPathsRemove({ conversationId, source_name: source.value, path: thisChild.path }))
                      for (const nextLayerChild of thisChild.children) {
                        dispatch(chatV2CurrentSourceLrrPathsRemove({ conversationId, source_name: source.value, path: nextLayerChild.path }))
                      }
                      // Remove parent
                      dispatch(chatV2CurrentSourceLrrPathsRemove({ conversationId, source_name: source.value, path: parentPath }))
                    } else {
                      // remove itself and remove parent
                      dispatch(chatV2CurrentSourceLrrPathsRemove({ conversationId, source_name: source.value, path: thisChild.path }))
                      dispatch(chatV2CurrentSourceLrrPathsRemove({ conversationId, source_name: source.value, path: parentPath }))
                    }
                  }
                }}
                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
              />
              <label htmlFor={thisChild.label}>{thisChild.label}</label>
            </div>

            {thisChild.children.length > 0 && renderChildren(thisChild.children, nestLevel + 1, childQueryMatch)}
          </div>
          {children.length == index + 1 && childQueryMatch && <div className={'h-5'}></div>}
        </div>
      )
    })
  }

  if (children.length === 0) return null

  return (
    <>
      <div className={'pl-11'}>
        <div className={'pl-1 pb-2 items-center flex gap-x-3'}>
          <input
            type="checkbox"
            name="drill-down-checkbox"
            id="drill-down-checkbox"
            checked={showDrillDown}
            onChange={(event) => {
              // If unchecking, remove all selected drilldown paths
              if (!event.target.checked) {
                children.forEach((child) => {
                  chatV2CurrentSourceLrrPathsRemove({ conversationId: conversationId, source_name: child.id, path: child.path })
                })
              }
              setShowDrillDown(!showDrillDown)
            }}
            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
          />
          <label htmlFor="drill-down-checkbox" className={'text-sm font-bold flex-shrink-0 py-2'}>
            Filter by section
          </label>
          {showDrillDown && (
            <>
              <input
                id={'drill-down-search'}
                name={'drill-down-search'}
                className="w-full rounded-md border-0 bg-gray-100 px-3 py-2 text-gray-900 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                type="text"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              />
            </>
          )}
        </div>

        {renderChildren(children, 0, false)}
      </div>
    </>
  )
}
